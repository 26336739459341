import * as M from '@material-ui/core';

export const Text = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 10,
  fontWeight: 500,
  color: theme.palette.primary.blackMain,
  margin: '3px 0 0'
}));

export const Title = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 15,
  fontWeight: 600,
  color: theme.palette.primary.blackMain,
}));

export const ShipmentIdLabel = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBoldItalic',
  fontSize: 10,
  fontWeight: 600,
  fontStyle: 'italic',
  color: '#4a4a4a',
  borderRadius: 4,
  backgroundColor: '#fff0cf',
  padding: '3px 5px',
  margin: '0px 7px',
}));