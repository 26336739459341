import React, {useEffect, useState} from 'react';
import * as M from '@material-ui/core';
import * as S from './styles';
import {AxlSimpleBox} from "../AxlBox";
import {SHIPMENT_STATUS_TO_COLORS} from "../../constants/shipment";
import moment from "moment-timezone";
import AxlTimeLine from "../AxlTimeLine";
import AxlModal from "../AxlModal";
import AddressHistoryList, {AddressHistoryPopup} from "../AddressHistoryList";
import DeliveryHistoryList from "../DeliveryHistoryList";
import {
  AddressChangeRequestForm,
  AssignmentNoteForm,
  DropoffEditForm,
  NotesEditForm, ShipmentCustomerForm,
  ShipmentNoteForm,
  ViewPODIdScan
} from "./forms";
import PODPreview from "../PODPreview";
import ImagePreviewer from "../ImagePreviewer";
import {useRecoilValue} from "recoil";
import {noteSelector} from "../../containers/CallCenterResults/states";
import _ from "lodash";
import {
  ClientSelectedState, ShipmentSelectedState,
  AssignmentSelectedState, DropoffSelectedState,
  DropoffPODSelectedState, ShipmentNoteSelectedState,
  AssignmentNoteSelectedState, DriverSelectedState,
  CustomerShipmentSelectedState, ShipmentLabelSelectedState
} from "../../states/callCenterStates";
import api, {addFeedback, getFeedback} from "../../repositories/Api";
import {useRecoilState, useResetRecoilState} from "recoil/dist";
import {serviceLevelToMap} from "../../constants/shipment";
import {DROPOFF_POD_TYPE} from "../../constants/stop";
import ShipmentEventsContainer from "../ShipmentEventsContainer";
import {userState} from "../../atoms";
import {ShipmentFeedback, ShipmentFeedbackContainer} from "../ShipmentFeedback";
import {ThumbDown as ThumbDownIcon, ThumbUp as ThumbUpIcon} from "@material-ui/icons";
import AxlButton from "../Button";

export function CallCenterShipmentDetail({isHideChangeRequest}) {
  // Local states
  const [isClose, setClose] = useState({});
  const [feedbackData, setFeedbackData] = useState({});
  const [feedbackLoading, setFeedbackLoading] = useState(true);
  // Global states
  const [user, setUser] = useRecoilState(userState);
  const [shipmentNote, setShipmentNoteSelected] = useRecoilState(ShipmentNoteSelectedState);
  const [assignmentNote, setAssignmentNoteSelectedState] = useRecoilState(AssignmentNoteSelectedState);
  const [clientSelected, setClientSelected] = useRecoilState(ClientSelectedState);
  const [assignmentSelected, setAssignmentSelected] = useRecoilState(AssignmentSelectedState);
  const [dropoffSelected, setDropoffSelected] = useRecoilState(DropoffSelectedState);
  const [dropoffPODSelected, setDropoffPODSelected] = useRecoilState(DropoffPODSelectedState);
  const shipmentSelected = useRecoilValue(ShipmentSelectedState);
  const [driverSelected, setDriverSelected] = useRecoilState(DriverSelectedState);
  const customerShipmentSelected = useRecoilValue(CustomerShipmentSelectedState);
  const ShipmentLabel = useRecoilValue(ShipmentLabelSelectedState);

  const close = (name = '') => setClose({[name]: true});

  const open = (name) => setClose({[name]: false});

  const toggle = (name) => setClose({[name]: !isClose[name]});

  const getFeedbackData = () => {
    setFeedbackLoading(true);
    getFeedback(dropoffSelected.id).then(res => {
      if(res.ok && res.status === 200) {
        setFeedbackData(res.data);
      } else {
        setFeedbackData({});
      }
      setFeedbackLoading(false);
    });
  };

  const getPod = () => {
    api.get(`stops/${dropoffSelected.id}/pod`).then(res => {
      if(res.status === 200) {
        setDropoffPODSelected(res.data);
      }
    });
  }

  useEffect(() => {
    // Load client
    if(shipmentSelected && shipmentSelected.client_id) {
      api.get(`clients/${shipmentSelected.client_id}`).then(res => {
        if(res.status === 200) {
          setClientSelected(res.data);
        }
      })
    }

    // Load notes
    if(shipmentSelected) {
      api.get(`notes/SH_${shipmentSelected.id}`).then(res => {
        if(res.status === 200 && res.data) {
          setShipmentNoteSelected(res.data);
        }
      });
      if(shipmentSelected.assignment_id) {
        api.get(`notes/AS_${shipmentSelected.assignment_id}`).then(res => {
          if(res.status === 200 && res.data) {
            setAssignmentNoteSelectedState(res.data);
          }
        });
      }
    }

  }, [shipmentSelected]);

  useEffect(() => {
    if(dropoffSelected && dropoffSelected.id) {
      // Load dropoff stop info
      getPod();
      // Load feedback info
      getFeedbackData();
    } else {
      setFeedbackData({});
      setFeedbackLoading(false);
    }
  }, [dropoffSelected]);

  const shipmentHistoryRender = function(shipments) {
    if(!shipments) return [];

    return(shipments.map((shipment, id) => <div key={shipment.id}>
      <S.Text_2>{shipment.content}</S.Text_2>
      <S.Text_3>{`${moment(shipment.ts).format("MM/DD/YYYY HH:mm")} - by ${_.get(shipment, 'subject.attributes.username')}`}</S.Text_3>
    </div>));
  };

  const handleAddFeedback = (params) => {
    if(dropoffSelected && dropoffSelected.id) {
      addFeedback(dropoffSelected.id, params).then(res => {
        if(res.ok && res.status === 200) {
          // Load feedback info
          getFeedbackData();
          setClose({['FEEDBACK_FORM']: true});
        }
      });
    }
  };

  // Display Time Window
  let timeWindow = '-';
  if(shipmentSelected) {
    const isSameDay = moment(shipmentSelected.dropoff_earliest_ts).isSame(shipmentSelected.dropoff_latest_ts, 'day');
    const DE_Format = moment(shipmentSelected.dropoff_earliest_ts).format('MM/DD/YYYY hA');
    const DL_Format = moment(shipmentSelected.dropoff_latest_ts).format(isSameDay ? 'hA' : 'MM/DD/YYYY hA');
    timeWindow = DE_Format + '-' + DL_Format;
  }
  // POD display
  const pictures = dropoffPODSelected.filter(pod => pod.type === DROPOFF_POD_TYPE.picture);
  const signatures = dropoffPODSelected.filter(pod => pod.type === DROPOFF_POD_TYPE.signature);
  const idscans = dropoffPODSelected.filter(pod => pod.type === DROPOFF_POD_TYPE.idscan);

  // Notes dislay
  const isShowShipmentNoteEdit = !!(shipmentNote && shipmentNote.map(s => _.isEqual(_.get(s, 'subject.uid'), 'US_' + _.get(user, 'user.id'))).filter(s => s).length);
  const isShowAssignmentNoteEdit = !!(assignmentNote && assignmentNote.map(s => _.isEqual(_.get(s, 'subject.uid'), 'US_' + _.get(user, 'user.id'))).filter(s => s).length);

  // Form display
  const isDropffEditFormDisplay = (shipmentSelected && shipmentSelected.status !== 'DROPOFF_SUCCEEDED') || false;

  // Feedback display
  const isShowFeedback      = !!dropoffSelected;
  const thumbFeedbackColor  = _.get(feedbackData, 'thumb') ? '#4abc4e' : '#d0021b';

  return(<AxlSimpleBox bgcolor={'primary.main'} square width={1} padding={3}>
      <M.Grid container>
        <M.Grid item xs>
          <S.Title>{ShipmentLabel && ShipmentLabel.driver_label || '-'} <S.ShipmentIdLable>{_.defaultTo(shipmentSelected && shipmentSelected.id, '_')}</S.ShipmentIdLable></S.Title>
          <M.Grid container spacing={1}>
            <M.Grid item>
              {(clientSelected && clientSelected.logo_url) ?
                <M.Avatar src={clientSelected.logo_url} style={{width: 45, height: 45}} /> :
                <M.Avatar src={`/logo.svg`} style={{width: 45, height: 45}} />}
            </M.Grid>
            <M.Grid item xs>
              <S.CustomerName>{_.get(clientSelected, 'name', '-')}</S.CustomerName>
              <S.Text>{_.defaultTo(serviceLevelToMap[_.get(shipmentSelected, 'service_level', '')], '')} (Scheduled Time Window: <S.B>{timeWindow}</S.B>)</S.Text>
            </M.Grid>
          </M.Grid>
        </M.Grid>
        <M.Grid item>
          <M.Box paddingTop={1}>
            <S.Text>{`Shipment Status:`}</S.Text>
            <S.ShipmentStatus color={SHIPMENT_STATUS_TO_COLORS()[_.get(shipmentSelected, 'status', 'CREATED')]}>{_.get(shipmentSelected, 'status', '-')}</S.ShipmentStatus>
            <br />
            <S.Label>{`Tracking code: `}<S.TrackingLink
              target={'_blank'}
              href={`${process.env.REACT_APP_TRACKING_CODE_URL}/${_.get(shipmentSelected, 'tracking_code', '')}`}>
                {_.get(shipmentSelected, 'tracking_code', '-')}
            </S.TrackingLink></S.Label>
          </M.Box>
        </M.Grid>
      </M.Grid>
      <M.Box my={2}>
        <M.Grid container alignItems={'center'}>
          <M.Grid item xs>
            <S.SectionTitle>{`Customer Info:`}</S.SectionTitle>
          </M.Grid>
          <AxlModal isClose={isClose['CUSTOMER_FORM']} onRendered={() => open('CUSTOMER_FORM')} trigger={<S.Button>{`Edit`}</S.Button>}>
            <ShipmentCustomerForm onClose={() => close('CUSTOMER_FORM')}/>
          </AxlModal>
        </M.Grid>
        <AxlSimpleBox bgcolor={`primary.grayFourteenth`} padding={2} mt={1} mb={2}>
          <M.Grid container spacing={1}>
            <M.Grid item xs>
              <S.Label>{`Customer name:`}</S.Label>
              <S.Text>{_.get(shipmentSelected, 'customer.name', '-')}</S.Text>
            </M.Grid>
            <M.Grid item xs>
              <S.Label>{`Email:`}</S.Label>
              <S.Text>{_.get(shipmentSelected, 'customer.email', '-')}</S.Text>
            </M.Grid>
            <M.Grid item xs>
              <S.Label>{`Phone number:`}</S.Label>
              <S.Text>{_.get(shipmentSelected, 'customer.phone_number', '-')}</S.Text>
            </M.Grid>
          </M.Grid>
        </AxlSimpleBox>
      </M.Box>
      <M.Box my={2}>
        <M.Grid container alignItems={'center'}>
          <S.SectionTitle>{`Dropoff Info:`}</S.SectionTitle>
          <M.Grid item xs>
            <M.Grid container justify={'flex-end'}>
              {isDropffEditFormDisplay && <AxlModal isClose={isClose['DROPOFF_EDIT_FORM']} onRendered={() => open('DROPOFF_EDIT_FORM')} isOpen={false} trigger={<S.Button>{`Edit`}</S.Button>}>
                <DropoffEditForm onClose={() => close('DROPOFF_EDIT_FORM')}/>
              </AxlModal>}
              {!isHideChangeRequest && <AxlModal isClose={isClose['ADDRESS_CHANGE_REQUEST_FORM']} onRendered={() => open('ADDRESS_CHANGE_REQUEST_FORM')} isOpen={false} trigger={<S.Button>{`Address Change Request`}</S.Button>}>
                <AddressChangeRequestForm onClose={() => close('ADDRESS_CHANGE_REQUEST_FORM')}/>
              </AxlModal>}
              <AxlModal isClose={isClose['ADDRESS_HISTORY']} onRendered={() => open('ADDRESS_HISTORY')} trigger={<S.Button>{`Address History`}</S.Button>}>
                <AddressHistoryPopup onClose={() => close('ADDRESS_HISTORY')}/>
              </AxlModal>
              <AxlModal isOpen={false} isClose={isClose['SHIPMENT_HISTORY']} onRendered={() => open('SHIPMENT_HISTORY')} trigger={<S.Button>{`Shipment History`}</S.Button>}>
                <S.ShipmentHistoryContainer>
                  <S.PopupTitle>{`SHIPMENT HISTORY`}</S.PopupTitle>
                  <ShipmentEventsContainer onClose={() => close('SHIPMENT_HISTORY')} />
                </S.ShipmentHistoryContainer>
              </AxlModal>
            </M.Grid>
          </M.Grid>
        </M.Grid>
      </M.Box>
      <M.Box my={2}>
        <AxlSimpleBox bgcolor={`primary.grayFourteenth`} padding={2} mt={1} mb={2}>
          <M.Grid container direction={'row'} spacing={2}>
            <M.Grid item xs>
              <S.Label>{`Address:`}</S.Label>
              <S.Text>{_.get(shipmentSelected, 'dropoff_address.street', '-')}</S.Text>
              <S.Text>{_.get(shipmentSelected, 'dropoff_address.street2', '-')}</S.Text>
              <S.Text>{shipmentSelected && shipmentSelected.dropoff_address &&
              `${shipmentSelected.dropoff_address.city} ${shipmentSelected.dropoff_address.state}, ${shipmentSelected.dropoff_address.zipcode}`}</S.Text>
            </M.Grid>
            <M.Grid item xs>
              <S.Label>{`Access Code:`}</S.Label>
              <S.Text>{_.get(shipmentSelected, 'dropoff_access_code', 'N/A')}</S.Text>
            </M.Grid>
          </M.Grid>
          <M.Grid container direction={'row'} spacing={2}>
            <M.Grid item xs>
              <S.Label>{`Dropoff note:`}</S.Label>
              <S.Text>{_.get(shipmentSelected, 'dropoff_note', '-')}</S.Text>
            </M.Grid>
            <M.Grid item xs>
              <S.Label>{`Additonal Instructions:`}</S.Label>
              <S.Text>{_.get(shipmentSelected, 'dropoff_additional_instruction', '-')}</S.Text>
            </M.Grid>
          </M.Grid>
          <M.Grid container direction={'row'} spacing={2}>
            <M.Grid item xs>
              <S.Label>{`Requested delivery window:`}</S.Label>
              <S.Text>{_.get(customerShipmentSelected, 'delivery_instruction', 'N/A')}</S.Text>
            </M.Grid>
            <M.Grid item xs>
              <S.Label>{`Actual Dropoff:`}</S.Label>
              <S.Text>{_.defaultTo(dropoffSelected && moment(dropoffSelected.actual_departure_ts).format('MM/DD/YYYY HH:mmA'), 'N/A')}</S.Text>
            </M.Grid>
          </M.Grid>
          <M.Grid container direction={'row'} spacing={2}>
            <M.Grid item xs>
              <S.Label>{`Reason:`}</S.Label>
              <S.Text>{_.get(dropoffSelected, 'reason', 'N/A')}</S.Text>
            </M.Grid>
            <M.Grid item xs>
              <S.Label>{`Remark:`}</S.Label>
              <S.Text>{_.get(dropoffSelected, 'remark', 'N/A')}</S.Text>
            </M.Grid>
          </M.Grid>
          <M.Box>
            <S.Label>{`Proof of Delivery:`}</S.Label>
            <M.Grid container spacing={2}>
              <AxlModal
                closeTheme={'light'}
                disabled={!pictures.length}
                bgcolor={'primary.transparent'}
                isOpen={false}
                isClose={isClose['delivery_proof_photo_required']}
                onRendered={() => setClose({['delivery_proof_photo_required']: false})}
                trigger={
                  <M.Grid item>
                    <S.Text_1>{`Photo `}{shipmentSelected && shipmentSelected.delivery_proof_photo_required && <S.TextRequired>{`(required)`}</S.TextRequired>}</S.Text_1>
                    <S.ImagePreviewRequired>
                      <S.ImageCounter>{`(${pictures.length})`}</S.ImageCounter>
                      {!!pictures[0] && <S.ImagePreviewShow src={pictures[0]?.url} width={'100%'} height={'auto'} />}
                    </S.ImagePreviewRequired>
                    <M.Box textAlign={'center'}><S.LinkView>{`View`}</S.LinkView></M.Box>
                  </M.Grid>}>
                <ImagePreviewer shipmentId={_.get(shipmentSelected, 'id')} images={pictures} textBottomAlign={'center'} callback={() => setClose({['delivery_proof_photo_required']: true})} />
              </AxlModal>
              <M.Grid item><S.DividerContainer><M.Divider orientation={'vertical'} /></S.DividerContainer></M.Grid>
              <AxlModal closeTheme={'light'} disabled={!signatures.length} isOpen={false} bgcolor={'primary.transparent'} trigger={
                <M.Grid item>
                  <S.Text_1>{`Signature `}{shipmentSelected && shipmentSelected.signature_required && <S.TextRequired>{`(required)`}</S.TextRequired>}</S.Text_1>
                  <S.ImagePreviewRequired>
                    <S.Text>{`(${signatures.length})`}</S.Text>
                    {!!signatures[0] && <S.ImagePreviewShow src={signatures[0]?.data} width={'100%'} height={'auto'} />}
                  </S.ImagePreviewRequired>
                  <M.Box textAlign={'center'}><S.LinkView>{`View`}</S.LinkView></M.Box>
                </M.Grid>}>
                <ImagePreviewer shipmentId={_.get(shipmentSelected, 'id')} images={signatures} textBottomAlign={'center'} />
              </AxlModal>
              <M.Grid item><S.DividerContainer><M.Divider orientation={'vertical'} /></S.DividerContainer></M.Grid>
              <AxlModal closeTheme={'main'} disabled={!idscans.length} isOpen={false} trigger={
                <M.Grid item>
                  <S.Text_1>{`ID Scan `}{shipmentSelected && shipmentSelected.id_required && <S.TextRequired>{`(required)`}</S.TextRequired>}</S.Text_1>
                  <S.ImagePreview><S.Text>{`(${idscans.length})`}</S.Text></S.ImagePreview>
                  <M.Box textAlign={'center'}><S.LinkView>{`View`}</S.LinkView></M.Box>
                </M.Grid>}>
                <ViewPODIdScan pod={idscans[0]} />
              </AxlModal>
            </M.Grid>
          </M.Box>
        </AxlSimpleBox>
      </M.Box>
      <M.Box my={2}>
        <M.Grid container alignItems={'center'}>
          <S.SectionTitle>{`Notes:`}</S.SectionTitle>
          <M.Grid item xs>
            <M.Grid container justify={'flex-end'}>
              { (isShowShipmentNoteEdit || isShowAssignmentNoteEdit) && <AxlModal isClose={isClose['NOTE_EDIT_FORM']} onRendered={() => open('NOTE_EDIT_FORM')} isOpen={false} trigger={<S.Button>{`Edit`}</S.Button>}>
                <NotesEditForm
                  isShowShipmentNote={isShowShipmentNoteEdit}
                  isShowAssignmentNote={isShowAssignmentNoteEdit}
                  onClose={() => close('NOTE_EDIT_FORM')} />
              </AxlModal>}
              { !isShowShipmentNoteEdit && <AxlModal isClose={isClose['SHIPMENT_NOTE_FORM']} onRendered={() => open('SHIPMENT_NOTE_FORM')} isOpen={false} trigger={<S.Button>{`Add shipment note`}</S.Button>}>
                <ShipmentNoteForm onClose={() => close('SHIPMENT_NOTE_FORM')} />
              </AxlModal>}
              { !isShowAssignmentNoteEdit && <AxlModal isClose={isClose['ASSIGNMENT_EDIT_FORM']} onRendered={() => open('ASSIGNMENT_EDIT_FORM')} isOpen={false} trigger={<S.Button>{`Add assignment note`}</S.Button>}>
                <AssignmentNoteForm onClose={() => close('ASSIGNMENT_EDIT_FORM')} />
              </AxlModal>}
              { isShowFeedback && <AxlModal width={900} isClose={isClose['FEEDBACK_FORM']} onRendered={() => open('FEEDBACK_FORM')} isOpen={false} trigger={<S.Button loading={feedbackLoading}>{`Feedback`}</S.Button>}>
                <ShipmentFeedback
                  addingFeedback={feedbackLoading}
                  data={feedbackData}
                  toggleFeedback={() => toggle('FEEDBACK_FORM')}
                  selectedStop={dropoffSelected}
                  addFeedback={handleAddFeedback}
                  onClose={() => close('FEEDBACK_FORM')} />
              </AxlModal>}
            </M.Grid>
          </M.Grid>
        </M.Grid>
        <AxlSimpleBox bgcolor={`primary.grayFourteenth`} padding={2} mt={1} mb={2}>
          <M.Grid container direction={'column'}>
            <M.Grid item xs>
              <S.Label>{`Shipment note:`}</S.Label>
              {(shipmentNote && shipmentNote.length) ? <AxlTimeLine items={shipmentHistoryRender(shipmentNote)} /> : <S.Text>{`N/A`}</S.Text>}
            </M.Grid>
            <M.Grid item xs>
              <S.Label>{`Assignment note:`}</S.Label>
              {(assignmentNote && assignmentNote.length) ? <AxlTimeLine items={shipmentHistoryRender(assignmentNote)} /> : <S.Text>{`N/A`}</S.Text>}
            </M.Grid>
          </M.Grid>
          <M.Grid container direction={'column'}>
            <M.Grid item xs>
              <S.Label>
                {`Feedback:`}
                {!_.isUndefined(_.get(feedbackData, 'thumb')) &&
                  <M.Avatar style={{display: 'inline-flex', width: 20, height: 20, margin: '0 5px', backgroundColor: thumbFeedbackColor}}>{_.get(feedbackData, 'thumb') ?
                    <ThumbUpIcon style={{fontSize: 12, color: '#FFF'}} /> :
                    <ThumbDownIcon style={{fontSize: 12, color: '#FFF'}} />}
                  </M.Avatar>}
              </S.Label>
            </M.Grid>
            <M.Grid item xs>
              <M.Grid container>
                {_.get(feedbackData, 'tags', []).map((tag, idx) => <M.Grid key={idx} item xs={4}>
                  <M.FormControlLabel control={<M.Checkbox checked={true} />} label={tag} />
                </M.Grid>)}
              </M.Grid>
            </M.Grid>
            {_.get(feedbackData, 'comment') && <M.Grid item xs>
              <S.Label>{`Comment: `}<M.Typography component={'span'}>{_.get(feedbackData, 'comment')}</M.Typography></S.Label>
            </M.Grid>}
          </M.Grid>
        </AxlSimpleBox>
      </M.Box>
      <M.Box my={2}>
        <S.SectionTitle>{`Driver Info:`}</S.SectionTitle>
        <AxlSimpleBox bgcolor={`primary.grayFourteenth`} padding={2} mt={1} mb={2}>
          <M.Grid container direction={'row'} spacing={3}>
            <M.Grid item>
              <S.DriverAvatar>
                <img style={{maxWidth: '100%', maxHeight: '100%'}} src={_.get(driverSelected, 'photo', '/logo.svg')} />
              </S.DriverAvatar>
            </M.Grid>
            <M.Grid item xs>
              <M.Grid container>
                <M.Grid item xs={12} sm={6} md>
                  <S.Label>{`Driver name:`}</S.Label>
                  <S.Text>
                    {(driverSelected && (driverSelected.first_name || driverSelected.middle_name || driverSelected.last_name) &&
                    `${driverSelected.first_name || ''} ${driverSelected.middle_name || ''} ${driverSelected.last_name || ''}`) || 'N/A'}
                  </S.Text>
                </M.Grid>
                <M.Grid item xs={12} sm={6} md>
                  <S.Label>{`Phone number:`}</S.Label>
                  <S.Text>{_.get(driverSelected, 'phone_number', 'N/A')}</S.Text>
                </M.Grid>
                <M.Grid item xs={12} sm={6} md>
                  <S.Label>{`Vehicle make: `}</S.Label>
                  <S.Text>{_.get(driverSelected, 'vehicle_make', 'N/A')}</S.Text>
                </M.Grid>
                <M.Grid item xs={12} sm={6} md>
                  <S.Label>{`Vehicle model:`}</S.Label>
                  <S.Text>{_.get(driverSelected, 'vehicle_model', 'N/A')}</S.Text>
                </M.Grid>
              </M.Grid>
              <M.Grid container>
                <M.Grid item xs={12} sm={6} md>
                  <S.Label>{`Driver ID:`}</S.Label>
                  <S.Text>{_.get(driverSelected, 'id', 'N/A')}</S.Text>
                </M.Grid>
                <M.Grid item xs={12} sm={6} md>
                  <S.Label>{`Driver license:`}</S.Label>
                  <S.Text>{_.get(driverSelected, 'driver_license', 'N/A')}</S.Text>
                </M.Grid>
                <M.Grid item xs={12} sm={6} md>
                  <S.Label>{`Vehicle color:`}</S.Label>
                  <S.Text>{_.get(driverSelected, 'vehicle_color', 'N/A')}</S.Text>
                </M.Grid>
                <M.Grid item xs={12} sm={6} md>
                  <S.Label>{`State:`}</S.Label>
                  <S.Text>{_.get(driverSelected, 'driver_license_state', 'N/A')}</S.Text>
                </M.Grid>
              </M.Grid>
            </M.Grid>
          </M.Grid>
        </AxlSimpleBox>
      </M.Box>
    </AxlSimpleBox>);
}