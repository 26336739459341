/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable indent */
/* eslint-disable react/no-deprecated */
/* eslint-disable quotes */
/* eslint-disable quote-props */
/* eslint-disable max-classes-per-file */

import React, { useState, useMemo } from 'react';
import ReactMapGL, { LinearInterpolator } from 'react-map-gl';
import DeckGL from 'deck.gl';

import StopToolTip from './StopToolTip';
import { iconMappingObj, stopColorEncode, renderPath } from './helper';

const MapCanvas = (props) => {
  const { stops, shipment } = props;
  const [hoverStop, setHoverStop] = useState(null);

  const renderResult = useMemo(() => {
    if (!stops || !shipment) return [];
    return renderPath(stops, setHoverStop);
  }, [stops, shipment]);
  const [viewport, setViewport] = useState({
    latitude: shipment?.dropoff_address.lat,
    longitude: shipment?.dropoff_address.lng,
    zoom: 15,
    transitionDuration: 500,
  });
  return (
    <ReactMapGL
      {...viewport}
      mapStyle={`https://api.maptiler.com/maps/streets/style.json?key=${process.env.REACT_APP_MAP_TILER_KEY}`}
      transitionInterpolator={new LinearInterpolator()}
      onViewportChange={(nextViewport) => setViewport(nextViewport)}
      width={'100%'}
      height={'100%'}
    >
      <DeckGL {...viewport} layers={[...renderResult]} />
      <StopToolTip hoverStop={hoverStop} />
    </ReactMapGL>
  );
};

export default MapCanvas;
