import React from 'react';
import * as M from '@material-ui/core';
import {AxlSimpleBox} from "../AxlBox";

export const Container = M.styled(AxlSimpleBox)(theme => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: '100%'
}));

export const Inner = M.styled(AxlSimpleBox)(theme => ({
  flex: 1,
  overflow: 'hidden'
}));

export const Title = M.styled(M.Typography)({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 18,
  fontWeight: 600,
  color: '#4a4a4a',
  padding: '0 20px'
});

export const Text = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 11,
  fontWeight: 600,
  textAlign: 'right',
  color: theme.palette.text.select
}));
export const AddressContainer = M.styled(M.Box)(({theme}) => ({
  padding: theme.spacing(2),
}));