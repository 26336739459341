import React, { useRef } from 'react';
import * as M from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';


import AxlButton from '../Button';
import { FormChatContainer, SendButton } from './styles';
import UploadButton from '../UploadButton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: 'none',
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  formChatContainer: {
    height: '100%',
  },
}));
/**
 sendMessage: {
  body: "123"
  id: "63c8193d-2992-4525-8096-b0bed46802e6"
  sendAt: "2020-07-17T10:33:59.202Z"
  sender: {uid: "US_472", name: "dzung", avatar: ""}
  type: "MESSAGE"
}
 */
export default function FormChat({ sendMessage }) {
  const classes = useStyles();
  const content = useRef('');
  return (
    <FormChatContainer width={1} marginTop={1}>
      <M.Grid container justify={'center'}>
        <IconButton className={classes.iconButton} aria-label="menu">
          {/* <UploadButton /> */}
        </IconButton>
        <M.Grid xs item>
          <M.Grid xs item container justify={'center'} className={classes.formChatContainer}>
            <InputBase
              onKeyPress={(ev) => {
                if (ev.key === 'Enter' && !ev.shiftKey) {
                  ev.preventDefault();
                  sendMessage(content);
                }
              }}
              inputRef={content}
              multiline
              fullWidth
              className={classes.input}
              placeholder="Type your message here …"
              inputProps={{ 'aria-label': 'search google maps' }}
            />
          </M.Grid>
        </M.Grid>
        <SendButton
          color={'primary.periwinkle'}
          variant={'text'}
          onClick={() => {
            sendMessage(content);
          }}
        >{`SEND`}</SendButton>
      </M.Grid>
    </FormChatContainer>
  );
}
