import React, { useRef } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import produce from 'immer';
import { useRecoilState } from 'recoil';
import { get } from 'lodash';
import * as S from './styles';
import AxlInput from '../AxlInput';
import AxlButton from '../Button';

import { updateDropOffAddress } from '../../repositories/Api';
import { conversationState } from '../../containers/ChatBoxContainer/states';

export default function DropoffStreetEditForm({ shipmentId, closePopup }) {
  const [conversation, setConversation] = useRecoilState(conversationState);
  const dropoffAddress = get(conversation, 'shipment.dropoff_address', null);
  const addressValue = useRef(get(dropoffAddress, 'street2', ''));
  return (
    <S.Container>
      <S.Title>{`Dropoff ADDRESS`}</S.Title>
      <form noValidate autoComplete="off">
        <S.Label>{`Address Line 1`}</S.Label>
        <S.Text>{get(dropoffAddress, 'street', '')}</S.Text>
        <S.Label>{`Address Line 2`}</S.Label>
        <AxlInput
          color={`secondary`}
          inputRef={addressValue}
          placeholder={get(dropoffAddress, 'street2', '')}
          label="Outlined"
          variant="outlined"
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <S.Label>{`City`}</S.Label>
            <S.Text>{get(dropoffAddress, 'city', '')}</S.Text>
          </Grid>
          <Grid item xs={12} sm={4}>
            <S.Label>{`State`}</S.Label>
            <S.Text>{get(dropoffAddress, 'state', '')}</S.Text>
          </Grid>
          <Grid item xs={12} sm={4}>
            <S.Label>{`Zipcode`}</S.Label>
            <S.Text>{get(dropoffAddress, 'zipcode', '')}</S.Text>
          </Grid>
        </Grid>
        <Box textAlign={'right'} paddingTop={3}>
          <AxlButton
            bgcolor={'primary.main'}
            color={'primary.gray'}
            variant="outlined"
            onClick={closePopup}
          >{`CANCEL`}</AxlButton>
          <AxlButton
            onClick={() => {
              const address = produce(dropoffAddress, (draftState) => {
                draftState.street2 = addressValue.current.value;
              });
              updateDropOffAddress(shipmentId, address).then((res) => {
                if (res.ok || res.status === 200) {
                  setConversation(produce(conversation, (draft) => {
                    draft.shipment.dropoff_address = address;
                  }));
                  closePopup();
                }
              });
            }}
            bgcolor={'primary.green'}
            color={'primary.main'}
            variant="contained"
            disableElevation
          >{`SAVE`}</AxlButton>
        </Box>
      </form>
    </S.Container>
  );
}
