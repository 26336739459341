import { styled } from '@material-ui/core';
import { Box } from '@material-ui/core';

export const LeftSide = styled(`div`)(() => ({
  width: '65%',
  float: 'left',
  boxSizing: 'border-box',
  padding: '0 10px',
}));

export const RightSide = styled(`div`)(() => ({
  width: '35%',
  height: '100%',
  float: 'left',
  boxSizing: 'border-box',
  padding: '0 10px',
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
}));

export const Main = styled(Box)(() => ({
  overflow: 'hidden',
  position: 'relative',
  marginTop: '20px',
}));

export const MapContainer = styled(Box)(({theme}) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.whiteTwo,
}));