import { atom, selector } from 'recoil';
import produce from 'immer';

export const activeListState = atom({
  key: 'active_state',
  default: [
    {
      id: '',
      name: '',
      unViewedMessage: '',
      ts: 0,
      shipmentId: '',
    },
  ],
});

export const lengthActiveState = selector({
  key: 'length_active_state',
  get: ({ get }) => {
    const { length } = get(activeListState);
    return length;
  },
});

export const attendingState = atom({
  key: 'attending_state',
  default: [
    {
      id: '',
      name: '',
      unViewedMessage: '',
      ts: 0,
      shipmentId: '',
    },
  ],
});
