import React, {useEffect} from 'react';
import * as M from '@material-ui/core';
import * as S from './styles';
import AxlInput from "../AxlInput";
import AxlCheckbox from "../AxlCheckbox";

export default function DropdownTableList({trigger, onOpen = () => {}, children}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onOpen();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return(<S.Container>
    {React.cloneElement(trigger, {onClick: handleClick})}
    <S.Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}>
      {children}
    </S.Menu>
  </S.Container>);
}