export default {
    container: {
        minWidth: '260px',
        fontSize: '13px',
        lineHeight: '18px'
    },
    label: {
        fontWeight: '500',
        // color: Colors.lightGrey,
        marginBottom: '3px'
    },
}