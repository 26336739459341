import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(() => ({
  tableContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  boxRoot: {
    height: 'calc(100vh - 146px)',
    boxSizing: 'border-box',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    position: 'relative',
  },
  head: {
    border: '1px solid #e4e4e4',
    borderBottomWidth: '10px',
  },
  w30: {
    width: '30%',
  },
  tBody: {
    minHeight: '500px',
    backgroundColor: '#fff',
  },
  textRight: {
    textAlign: 'right',
    zIndex: 1,
  },
  leftIcon: {
    color: '#b7b2f5',
    fontSize: '14px',
    marginRight: '10px',
  },
  textBold: {
    fontFamily: 'AvenirNext-DemiBold',
  },
  trackingCode: { color: '#9b9b9b' },
  icon: { width: '14px' },
  loadingContainer: {
    position: 'absolute',
    top: '0',
    backgroundColor: 'rgb(54 54 54 / 30%)',
    zIndex: '2',
  },
}));

export const TableHeadSearch = withStyles({
  root: {
    color: '#fff',
    padding: '15px 10px',
    fontSize: '11px',
    lineHeight: 'normal',
    fontFamily: 'AvenirNextLTPro-Heavy',
    textTransform: 'uppercase',
    width: '25%',
    position: 'sticky',
    top: '0',
    zIndex: 2,
    backgroundColor: '#b7b2f5',
    '&.w30': { width: '30%' },
    '&.w20': { width: '20%' },
  },
})(TableCell);

export const IconBtn = withStyles({
  root: {
    marginLeft: '5px',
  },
  disabled: {
    opacity: '0.3',
  },
})(IconButton);

export const TableRowCustom = withStyles({
  root: {
    '&.MuiTableRow-hover': {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(136, 127, 255, 0.1) ',
      },
    },
  },
  hover: {
    color: 'red',
  },
})(TableRow);

export const Cell = withStyles({
  root: {
    border: '5px solid rgb(228, 228, 228)',
    color: '#5a5a5a',
    fontSize: '13px',
    padding: '10px',
    position: 'relative',
    '&.first': {
      backgroundColor: 'rgba(136, 127, 255, 0.1)',
    },
    '&.rounded': {
      '&::after': {
        content: '""',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        border: '1px solid',
      },
    },
  },
})(TableCell);

export const SearchPagination = withStyles({
  root: {
    marginTop: '10px',
    '& .MuiPaginationItem-root': {
      color: 'rgb(74, 74, 74)',
      minWidth: '24px',
      height: '24px',
      '&.MuiPaginationItem-page.Mui-selected ': {
        backgroundColor: '#887fff',
        color: '#fff',
      },
    },
  },
})(Pagination);

export const Col = styled('div')({
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '&.start': {
    justifyContent: 'flex-start',
  },
  '&.flex1': {
    flex: '1',
  },
  '&.padding0': {
    padding: 0,
  },
});

export const Text = styled('div')({
  fontFamily: 'AvenirNext-Medium',
  fontSize: '11px',
  letterSpacing: '-0.1px',
  color: '#9b9b9b',
});
export const Row = styled('div')({
  display: 'flex',
  marginBottom: '5px',
});

export const PaginationContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '5px 0 0',
});

export const ColorStatus = {
  ASSIGNED: '#5a5a5a',
  CANCELLED_AFTER_PICKUP: '#d0021b',
  CANCELLED_BEFORE_PICKUP: '#d0021b',
  CREATED: '#5a5a5a',
  DELIVERED: '#4abc4e',
  DELIVERY_FAILED: '#d0021b',
  DROPOFF_DELAY: '',
  DROPOFF_EN_ROUTE: '',
  DROPOFF_FAILED: '#d0021b',
  DROPOFF_READY: '',
  DROPOFF_SUCCEEDED: '#4abc4e',
  EN_ROUTE: '',
  GEOCODED: '#5a5a5a',
  GEOCODE_FAILED: '#d0021b',
  GEOCODE_FAILED_DROPOFF: '#d0021b',
  GEOCODE_FAILED_PICKUP: '#d0021b',
  GEOCODE_FAILED_WITH_SUGGESTIONS: '#d0021b',
  PENDING: '#f5a623',
  PICKUP_DELAY: '#f5a623',
  PICKUP_EN_ROUTE: '',
  PICKUP_FAILED: '#d0021b',
  PICKUP_READY: '',
  PICKUP_SUCCEEDED: '#4abc4e',
  REATTEMPT_DELIVERED: '',
  REATTEMPT_DELIVERY_FAILED: '#d0021b',
  REATTEMPT_EN_ROUTE: '',
  REATTEMPTING: '',
  RECEIVED: '',
  RETURN_DELAY: '',
  RETURN_EN_ROUTE: '',
  RETURN_READY: '',
  RETURN_SUCCEEDED: '#4abc4e',
  SUCCEEDED: '#4abc4e',
  FAILED: '#d0021b',
};
export default useStyles;
