import React, {useEffect, useState} from 'react';
import * as M from '@material-ui/core';
import * as S from './styles';
import {AxlSimpleBox} from "../AxlBox";
import {SHIPMENT_STATUS_TO_COLORS} from "../../constants/shipment";
import moment from "moment-timezone";
import AxlTimeLine from "../AxlTimeLine";
import AxlModal from "../AxlModal";
import AddressHistoryList, {AddressHistoryPopup} from "../AddressHistoryList";
import DeliveryHistoryList from "../DeliveryHistoryList";
import {AssignmentNoteForm, DropoffEditForm, NotesEditForm, ShipmentNoteForm, ViewPODIdScan} from "./forms";
import PODPreview from "../PODPreview";
import ImagePreviewer from "../ImagePreviewer";
import ShipmentListResult from "../ShipmentListResult";
import AxlButton from "../Button";
import {useRecoilState} from "recoil/dist";
import {
  BestGuessResultByDateFilters,
  BestGuessResultByDateSelector,
  BestGuessResultFilters,
  BestGuessResultSelector,
  CallSessionState
} from "../../states/callCenterStates";
import _ from 'lodash';
import api from "../../repositories/Api";

export function CallCenterBestGuess({loadingMore, sorting, ...props}) {
  // Local states
  const [isLoading, setLoading] = useState(loadingMore || false);
  // Global states
  const [bestGuessResults, setBestGuessResults] = useRecoilState(BestGuessResultSelector);
  const [searchFilter, setSearchFilter] = useRecoilState(BestGuessResultFilters);
  const [searchByDateFilter, setSearchByDateFilter] = useRecoilState(BestGuessResultByDateFilters);
  const [bestGuessShipmentResultsByDate, setBestGuessShipmentResultsByDate] = useRecoilState(BestGuessResultByDateSelector);
  const {total = 0, from = 0, size} = bestGuessShipmentResultsByDate;
  // Variables
  const groupByCustomers = bestGuessResults.results.length && _.groupBy(bestGuessResults.results, (s) => {
    return s.shipment.customer.phone_number;
  }) || [];
  const customers = Object.values(groupByCustomers);

  const loadMore = () => {
    const q = {
      loadmore: true,
      filters: {
        from: searchByDateFilter.filters.from + 5
      }
    };
    const filters = _.merge({}, searchByDateFilter, q);
    setSearchByDateFilter(filters);
  };

  useEffect(() => {
    api.post(`shipments/search`, searchByDateFilter.filters).then(res => {
      if(res.status === 200 && res.data && res.data.results) {
        if(searchByDateFilter.loadmore) {
          const data = _.concat(bestGuessShipmentResultsByDate.results, res.data.results);
          const obj = {
            ...res.data,
            results: data
          };
          setBestGuessShipmentResultsByDate(obj);
        } else {
          setBestGuessShipmentResultsByDate(res.data);
        }
      }
    })
  }, [searchByDateFilter]);

  return(<AxlSimpleBox bgcolor={'primary.main'} square width={1} padding={3}>
    {customers.map((arr, i) =>
      <BestGuessCustomerInfo key={i} customer={arr[0].shipment.customer} />)}
    <ShipmentListResult
      sorting={sorting}
      shipments={bestGuessShipmentResultsByDate && bestGuessShipmentResultsByDate.results} />
    {(total > size && total > from) && <M.Box textAlign={`center`}>
      <AxlButton
        loading={loadingMore} disabled={loadingMore}
        onClick={loadMore}
        variant={`outlined`}
        padding={`3px 10px`}
        color={loadingMore ? 'primary.grayEleventh' : `primary.periwinkle` }
        bgcolor={loadingMore ? `primary.grayEleventh` : `primary.main`}><S.Text_4>{`+ Load more`}</S.Text_4></AxlButton>
    </M.Box>}
  </AxlSimpleBox>);
}

function BestGuessCustomerInfo({customer = {}, ...props}) {
  return(<M.Box my={2}>
      <S.SectionTitle>{`Customer Info:`}</S.SectionTitle>
      <AxlSimpleBox bgcolor={`primary.graySixth`} padding={2} mt={1} mb={2}>
        <M.Grid container spacing={1}>
          <M.Grid item xs>
            <S.Label>{`Customer name:`}</S.Label>
            <S.Text>{_.defaultTo(customer.name, '-')}</S.Text>
          </M.Grid>
          <M.Grid item xs>
            <S.Label>{`Email:`}</S.Label>
            <S.Text>{_.defaultTo(customer.email, '-')}</S.Text>
          </M.Grid>
          <M.Grid item xs>
            <S.Label>{`Phone number:`}</S.Label>
            <S.Text>{_.defaultTo(customer.phone_number, '-')}</S.Text>
          </M.Grid>
        </M.Grid>
      </AxlSimpleBox>
    </M.Box>)
}