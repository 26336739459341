import React, {useEffect, useState} from 'react';
import * as S from './styles';
import AxlInput from "../AxlInput";
import * as M from '@material-ui/core';
import AxlButton from "../Button";
import {variantModal} from "../../themes/modal";
import AxlTable from "../AxlTable";
import {useRecoilState} from "recoil/dist";
import {
  CallSessionState, NoGuessResultFilters,
  NoGuessSearchResultSeletor,
  RematchShipmentSelector,
  ShipmentSearchResultSelector
} from "../../states/callCenterStates";
import api from "../../repositories/Api";
import RematchTableList from "../RematchTableList";

export default function RematchToOther({onClose}) {
  // Local states
  const [text, setText] = useState('');
  const [rematching, setRematching] = useState(false);
  const [onKey, setOnKey] = useState(false);
  const [isSearching, setSearching] = useState(false);
  // Global states
  const [callSession, setCallSesison] = useRecoilState(CallSessionState);
  const [shipmentResults, setShipmentResults] = useRecoilState(NoGuessSearchResultSeletor);
  const [searchFilter, setSearchFilter] = useRecoilState(NoGuessResultFilters);
  const [shipmentRematch, setShipmetRematch] = useRecoilState(RematchShipmentSelector);

  const onChange = (e) => {
    setText(e.target.value);
  }

  const onSearch = (e) => {
    if(e.key === 'Enter' && !!text) {
      setOnKey(true);
    }
  }

  useEffect(() => {
    if(!!text || onKey) {
      const params = Object.assign({}, searchFilter, {q: text});
      setSearching(true);
      api.post(`/shipments/search`, params).then(res => {
        if(res.status === 200 && res.data) {
          setShipmentResults(res.data.results);
        }
        // Reset
        setOnKey(false);
        setSearching(false);
      })
    }
  }, [searchFilter.filters, onKey])

  const rematch = () => {
    if(!shipmentRematch || !callSession) return;

    const params = {
      attr: {
      ...(shipmentRematch.shipment ? {shipment_id: shipmentRematch.shipment.id} : {}),
      ...(shipmentRematch.shipment ? {assignment_id: shipmentRematch.shipment.assignment_id} : {}),
      ...(shipmentRematch.driver ? {driver_id: shipmentRematch.driver.id} : {}),
      }
    };
    setRematching(true);
    api.patch(`call_center/${callSession.id}/attr`, params).then(res => {
      if(res.status === 200 && res.data) {
        setCallSesison(res.data);
        onClose();
      }
      setRematching(false);
    })
  }

  return(<S.Container {...variantModal.big}>
    <S.Title>{`REMATCH CALL TO ANOTHER SHIPMENT`}</S.Title>
    <S.SearchContainer my={2}>
      <AxlInput
        onChange={(e) => onChange(e)}
        onKeyDown={(e) => onSearch(e)}
        theme={'main'} spacing={0} autoFocus fullWidth
        placeholder={`Search by customer name, phone number, address...`} />
    </S.SearchContainer>
    <M.TableContainer>
      <RematchTableList items={shipmentResults || []}/>
    </M.TableContainer>
    <S.Controls align={'center'} mt={2.5}>
      <AxlButton onClick={onClose} padding={'7px 30px'} spacing={1} bgcolor={'primary.grayEleventh'} color={'primary.grayTenth'} variant="outlined"><S.TextControl>{`Cancel`}</S.TextControl></AxlButton>
      <AxlButton
        padding={'8px 30px'}
        spacing={1}
        bgcolor={shipmentRematch ? 'primary.greenThird' : 'primary.graySixth'}
        color={'primary.main'}
        variant="contained"
        disableElevation
        disabled={!shipmentRematch}
        loading={rematching}
        onClick={rematch}><S.TextRematch>{`Rematch`}</S.TextRematch></AxlButton>
    </S.Controls>
  </S.Container>);
}
