import React, { useRef } from 'react';
import Box from '@material-ui/core/Box';
import produce from 'immer';
import { useRecoilState } from 'recoil';
import { get } from 'lodash';

import * as S from './styles';
import AxlInput from '../AxlInput';
import AxlButton from '../Button';

import { updateDropOffNote } from '../../repositories/Api';
import { conversationState } from '../../containers/ChatBoxContainer/states';

export default function DropoffNotesEditForm({closePopup}) {
  const [conversation, setConversation] = useRecoilState(conversationState);
  const dropoffNote = get(conversation, 'shipment.dropoff_note', '');
  const shipmentId = get(conversation, 'shipment.id', '');
  const noteValue = useRef(dropoffNote);

  return (
    <S.Container>
      <S.Title>{`DROPOFF NOTE`}</S.Title>
      <form noValidate autoComplete="off">
        <AxlInput
          color={`secondary`}
          multiline
          rows={5}
          placeholder={dropoffNote}
          inputRef={noteValue}
        />
        <Box textAlign={'right'} paddingTop={3}>
          <AxlButton bgcolor={'primary.main'} color={'primary.gray'} variant="outlined" onClick={closePopup}>{`CANCEL`}</AxlButton>
          <AxlButton
            bgcolor={'primary.green'}
            color={'primary.main'}
            variant="contained"
            disableElevation
            onClick={() => {
              updateDropOffNote(shipmentId, noteValue.current.value).then((res) => {
                if (res.ok || res.status === 200) {
                  setConversation(
                    produce(conversation, (draft) => {
                      draft.shipment.dropoff_note = noteValue.current.value;
                    }),
                  );
                  closePopup();
                }
              });
            }}
          >{`SAVE`}</AxlButton>
        </Box>
      </form>
    </S.Container>
  );
}
