import React, {useEffect, useRef} from 'react';
import * as M from '@material-ui/core';
import * as S from "./styles";
import AxlInput from "../AxlInput";
import AxlButton from "../Button";
import AxlCheckbox from "../AxlCheckbox";

export default function LeaveNoteForm(props) {
  const { driverName, sendNote, onClose } = props;
  const content = useRef('');

  return(
    <S.Container>
      <S.Title>{`LEAVE NOTE`}</S.Title>
      <form noValidate autoComplete="off">
        {/* <M.Box marginBottom={3}>
          <M.Grid container>
            <S.Label>{`Recipient`}</S.Label>
            <M.Grid item xs>
              <AxlCheckbox color={'default'} label={`Driver ${driverName}`} />
              <AxlCheckbox color={'default'} label={'Dispatch'} />
            </M.Grid>
          </M.Grid>
        </M.Box> */}
        <M.Grid container>
          <M.Grid item xs>
            <AxlInput color={`secondary`} placeholder={`Summarize chat with customer to driver...`} multiline rows={6} inputRef={content} />
          </M.Grid>
        </M.Grid>
        <M.Box textAlign={'right'} paddingTop={3}>
          <AxlButton padding={`7px 15px`} onClick={onClose} bgcolor={'primary.main'} color={'primary.gray'} variant="outlined"><S.CancelText>{`CANCEL`}</S.CancelText></AxlButton>
          <AxlButton padding={`8px 15px`} bgcolor={'primary.greenThird'} color={'primary.main'} variant="contained" disableElevation onClick={() => {
            sendNote(content, (res) => {
              if(res.ok || res.status === 200) {
                onClose();
              }
            })
          }}><S.SaveText>{`SEND`}</S.SaveText></AxlButton>
        </M.Box>
      </form>
    </S.Container>
  );
}