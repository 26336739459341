import React, { useEffect, useRef } from 'react';
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import * as M from '@material-ui/core';
// Style
import useStyle from './styles';
import { Container } from '@material-ui/core';

import { userState } from '../atoms';
import { getUser } from '../repositories/HttpRepository';
import { lightTheme } from '../themes';
import Header from '../components/Header';
import OverviewContainer from './OverviewContainer';
import SearchContainer from './SearchContainer';
import CallCenterContainer from './CallCenterContainer';
import CallCenterResults from './CallCenterResults';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import SearchDetail from '../components/SearchDetail';

function RootContainer(props) {
  const [user, setUser] = useRecoilState(userState);
  const styles = useStyle();
  const history = useHistory();
  useEffect(() => {
    getUser().then((resp) => {
      if (resp.ok) {
        setUser(resp.data);
      } else {
        window.location = `${process.env.REACT_APP_OAUTH_URL}/login?next=${encodeURI(window.location.href)}`;
      }
    });
  }, []);
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <React.Suspense
        fallback={
          <M.Box width={'100vw'} height={'100vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <M.CircularProgress size={75} thickness={1} />
          </M.Box>
        }
      >
        <Router>
          <ThemeProvider theme={lightTheme}>
            {!!user && <Header user={user} />}
            <Container maxWidth={false} classes={{ root: styles.root }}>
              <M.Box height={1} paddingTop={2} paddingBottom={2}>
                <Switch>
                  <Route exact path="/call-center/results">
                    <CallCenterResults />
                  </Route>
                  <Route exact path="/call-center">
                    <CallCenterContainer />
                  </Route>
                  <Route exact path="/search">
                    <SearchContainer />
                  </Route>
                  <Route exact path="/search/detail/:id">
                    <SearchDetail />
                  </Route>
                  <Route path={['/', '/messages']}>
                    <OverviewContainer />
                  </Route>
                </Switch>
              </M.Box>
            </Container>
          </ThemeProvider>
        </Router>
      </React.Suspense>
    </MuiPickersUtilsProvider>
  );
}

export default RootContainer;
