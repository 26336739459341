import React from 'react';
import {makeStyles, styled, withStyles} from "@material-ui/core/styles";
import * as M from "@material-ui/core";

export const Container = M.styled(`div`)(({theme}) => ({
  height: "100%"
}));

export const Inner = M.styled(`div`)(({theme}) => ({
  height: "100%"
}));

export const buttonStyled = makeStyles(theme => ({
  root: {
    padding: 0
  },
  label: {
    fontFamily: 'AvenirNext-Bold',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#2a2444'
  },
  text: {
    borderRadius: 15,
    backgroundColor: '#ffffff',
    boxShadow: '0 1px 2px 0 rgba(155, 155, 155, 0.23)',
    border: 'solid 0 #979797',
    padding: '5px 12px !important'
  }
}));

export const ListItem = withStyles(({ palette, spacing }) => ({
  selected: {
    backgroundColor: `${palette.primary.periwinkleThird} !important`
  }
}))(M.ListItem);

export const Text = styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Regular',
  fontSize: 13,
  fontweight: 'normal',
  color: theme.palette.primary.blackMain
}));
export const Title = styled(Text)({
  fontFamily: 'AvenirNext-DemiBold',
});

export const UnreadTitle = styled(Text)({
  fontFamily: 'AvenirNext-Bold',
  fontSize: 13,
  fontWeight: 'bold',
});

export const ReadTitle = styled(Text)({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13,
  fontWeight: 600,
});

export const SectionTitle = styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Bold',
  fontSize: 20,
  fontWeight: 'bold',
  color: theme.palette.primary.textSecondary
}));

export const Time = styled(Text)({
  fontFamily: 'AvenirNext-Regular',
  fontSize: 10
});

export const UnreadTime = styled(Time)({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 10
});

export const Date = styled(Text)(({theme}) => ({
  fontSize: 13,
  fontFamily: 'AvenirNext-MediumItalic',
  fontweight: 'normal',
  color: theme.palette.primary.blackMain,
  verticalAlign: 'middle'
}));

export const Section = styled(M.Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));


export const ShipmentLabel = styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-Regular',
  fontSize: 10,
  padding: '3px 5px',
  fontWeight: 600,
  fontStyle: 'italic',
  borderRadius: 5,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  color: theme.palette.primary.blackMain,
  background: theme.palette.primary.cream
}));

export const UnreadCounter = styled(M.Box)(({theme}) => ({
  width: 'auto',
  display: 'inline',
  backgroundColor: theme.palette.primary.periwinkleSecondary,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  color: theme.palette.primary.main,
  padding: '3px 10px',
  fontFamily: 'AvenirNext-Bold',
  fontSize: 8,
  fontWeight: 'bold',
  verticalAlign: 'middle'
}));

export default makeStyles(theme => ({
  root: {
    maxWidth: '100%',
    backgroundColor: theme.palette.background.list,
    direction: 'ltr',
    height: '100%',
    overflow: 'hidden'
  },
  title: {
    fontFamily: 'AvenirNext-Bold',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#393060',
    verticalAlign: 'middle'
  },
  lists: {
    maxWidth: '100%'
  },
  listItem: {
    gutters: {
      display: 'flex'
    }
  },
  row: {},
  item: {
    backgroundColor: 'transparent'
  },
  totalUnviewed: {
    backgroundColor: '#6c62f5',
    shadowColor: 'rgba(204, 204, 204, 0.35)',
    padding: '3px 5px',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    display: 'inline-block'
  }
}))

export const dotStyle = {
  width: 10,
  height: 10,
  fontSize: 0,
  display: 'inline-block',
  color: 'transparent',
  borderRadius: '50%',
  backgroundColor: '#6c62f5',
  shadowColor: 'rgba(204, 204, 204, 0.35)',
  shadowOffset: {
    width: 0,
    height: 1
  },
  shadowRadius: 2,
  shadowOpacity: 1,
  boxSizing: 'border-box',
  verticalAlign: 'middle'
};
export const counterDotStyle = {
  width: 22,
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 9,
  fontWeight: 'bold',
  display: 'inline-block',
  color: '#ffffff',
  borderRadius: 10,
  padding: '2px 6px',
  backgroundColor: '#6c62f5',
  shadowColor: 'rgba(204, 204, 204, 0.35)',
  shadowOffset: {
    width: 0,
    height: 1
  },
  shadowRadius: 2,
  shadowOpacity: 1,
  boxSizing: 'border-box',
  textAlign: 'center',
  verticalAlign: 'middle'
};

export const EmptyText = M.styled(M.Typography)(({theme}) => ({
  textAlign: 'center'
}));