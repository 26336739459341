import _ from 'lodash';
import {Box, styled, Typography} from "@material-ui/core";

export const Container = styled(Box)({});

export const TitleModal = styled(Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Bold',
  fontSize: 18,
  fontWeight: 'bold',
  letterSpacing: -0.09,
  textAlign: 'center',
  color: theme.palette.primary.blackMain,
  marginBottom: theme.spacing(2)
}));

export const Text = styled(Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13,
  fontWeight: 600,
  color: theme.palette.primary.gray
}));

export const Text_1 = styled(Typography)(({theme, color}) => ({
  fontFamily: 'AvenirNext-MediumItalic',
  fontSize: 8,
  fontWeight: 500,
  fontStyle: 'italic',
  lineHeight: 1.3,
  margin: '3px 0 0',
  color: color || '#4a4a4a'
}));

export const Text_2 = styled(Typography)(({theme, color}) => ({
  fontFamily: 'AvenirNext-MediumItalic',
  fontSize: 10,
  fontWeight: 500,
  fontStyle: 'italic',
  margin: '3px 0 0',
  color: color || theme.palette.primary.blackMain
}));

export const SubTitleModal = styled(Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13,
  fontWeight: 600,
  letterSpacing: -0.07,
  textAlign: 'center',
  color: '#707070'
}));

export const Counter = styled(Text)(({theme}) => ({
  position: 'absolute',
  top: 0,
  right: 5,
  fontSize: 10
}));

export const ResolvedContainer = styled(Box)(({theme}) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));