import React, { useRef } from 'react';
import Box from '@material-ui/core/Box';
import { useRecoilState } from 'recoil';
import { get } from 'lodash';
import produce from 'immer';

import * as S from './styles';
import AxlInput from '../AxlInput';
import AxlButton from '../Button';

import { updateDropOffLocation } from '../../repositories/Api';
import { conversationState } from '../../containers/ChatBoxContainer/states';

export default function DropoffAccessCodeEditForm({ closePopup }) {
  const [conversation, setConversation] = useRecoilState(conversationState);
  const accessCode = get(conversation, 'shipment.dropoff_access_code', 'N/A');
  const instruction = get(conversation, 'shipment.dropoff_additional_instruction', 'N/A');
  const shipmentId = get(conversation, 'shipment.id', null);
  const accessCodeRef = useRef(accessCode);
  const instructionRef = useRef(instruction);
  return (
    <S.Container>
      <S.Title>{`ACCESS CODE/ ADDITIONAL INSTRUCTIONS`}</S.Title>
      <form noValidate autoComplete="off">
        <S.Label>{`Access Code`}</S.Label>
        <AxlInput color={`secondary`} placeholder={accessCode} inputRef={accessCodeRef} />
        <S.Label>{`Additional Instructions`}</S.Label>
        <AxlInput color={`secondary`} multiline rows={3} inputRef={instructionRef} placeholder={instruction} />
        <Box textAlign={'right'} paddingTop={3}>
          <AxlButton
            bgcolor={'primary.main'}
            color={'primary.gray'}
            onClick={closePopup}
            variant="outlined"
          >{`CANCEL`}</AxlButton>
          <AxlButton
            bgcolor={'primary.green'}
            color={'primary.main'}
            variant="contained"
            disableElevation
            onClick={() => {
              const data = {
                dropoff_access_code: accessCodeRef.current.value,
                dropoff_additional_instruction: instructionRef.current.value,
              };
              if (shipmentId) {
                updateDropOffLocation(shipmentId, data).then((res) => {
                  if (res.ok || res.status === 200) {
                    setConversation(
                      produce(conversation, (draft) => {
                        draft.shipment.dropoff_access_code = accessCodeRef.current.value;
                        draft.shipment.dropoff_additional_instruction = instructionRef.current.value;
                      }),
                    );
                    closePopup();
                  }
                });
              }
            }}
          >{`SAVE`}</AxlButton>
        </Box>
      </form>
    </S.Container>
  );
}
