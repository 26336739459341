import { createMuiTheme } from '@material-ui/core/styles';
import {placeholder} from "./pseudo";
const theme = createMuiTheme();

// Global Colors
const AxlColors = {
  ['dark']: '#2a2444',
  ['slate-grey']: '#5e5c78',
  ['greyish-brown']: '#4a4a4a',
  ['light-periwinkle']: '#dae2ec',
  ['apple-green']: '#76c520',
  ['light-grey-blue']: '#a1b1c2',
  ['periwinkle']: '#887fff',
  ['greyblue']: '#8192a7',
  ['very-light-pink']: '#d5d5d5',
  ['very-light-pink']: '#bfbfbf',
  ['pale-grey']: '#fcfcff',
  ['black-20']: '#887fff33',
  ['dark']: '#0f0c1b',
  ['brown-grey']: '#838383',
  ['dark']: '#13101f',
  ['pale-grey']: '#f6f5f8',
  ['light-periwinkle']: '#ccc8ff',
  ['brownish-grey']: '#626262',
  ['periwinkle-20']: '#887fff33',
  ['pale-grey']: '#eceef7',
}

// Colors
export const colors = {
  main: '#FFF',
  whiteTwo: '#fafafa',
  blueEyes: "#f4f3ff",
  black: '#000000',
  black35: 'rgba(0,0,0,0.35)',
  blackSecondary: '#5a5a5a',
  blackThird: '#2a2444',
  textSecondary: '#393060',
  gray: '#8d8d8d',
  gray400: '#cccccc66',
  grayMain: '#CCCCCC',
  graySecond: '#9b9b9b',
  grayThird: '#96979a',
  grayFourth: '#f7f7f7',
  grayFifth: '#eaeaea',
  graySixth: '#eaeaea',
  graySeventh: '#979797',
  grayEighth: '#f9f9f9',
  grayNinth: '#707070',
  grayTenth: '#a9a9a9',
  grayEleventh: '#f4f4f4',
  grayTwelfth: '#aeaeae',
  grayThirteenth: '#e4e4e4',
  grayFourteenth: '#f6f6f6',
  periwinkle: '#887fff',
  periwinkleSecondary: '#6c62f5',
  periwinkleThird: '#aca6f5',
  periwinkleFourth: '#e6e5fe',
  red: '#d63031',
  redSecond: '#d0021b',
  redThird: '#ffdfe3',
  green: '#4abc4e',
  greenSecondary: '#edffed',
  greenThird: '#75c31e',
  greenFourth: '#5eb661',
  cream: '#fff0cf',
  link: '#4a90e2',
  blackMain: '#4a4a4a',
  blackdrop: '#4a4a4a59',
  blueLight: '#41376b',
  blueNight: '#5c558e',
  transparent: '#00000000',
  yellow: '#f2f307',
  yellowSecondary: '#f0a323',
  yellowDark: '#f5a623',
  brownNight: '#5e5b78',
  ...AxlColors
}

// Palette
const palette = {
  primary: colors,
  secondary: {
    main: '#2a2444',
    subsidiary: '#ccccc'
  },
  third: {
    main: '#6c62f5'
  },
  text: {
    primary: '#4f4c75',
    secondary: '#6c62f5',
    third: '#4a4a4a',
    select: '#737273'
  },
  background: {
    paper: "#FFF",
    list: "#f4f3ff"
  },
  color: AxlColors
};

//Typography
const typography = {
  fontFamily: 'AvenirNext',
  h1: {},
  h2: {},
  h3: {
    fontFamily: 'AvenirNext-DemiBold',
    fontSize: 26,
    fontWeight: 600,
    color: '#4a4a4a'
  },
  h4: {
    fontFamily: 'AvenirNext-DemiBold',
    fontSize: 16,
    fontWeight: 600,
    color: '#5e5b78'
  },
  h5: {},
  h6: {},
  body1: {
    fontFamily: 'AvenirNext-Medium',
    fontSize: 13.5,
    fontWeight: 500,
    color: '#393060',
    lineHeight: 1.3
  },
  body2: {
    fontFamily: 'AvenirNext-Medium',
    fontSize: 10,
    fontWeight: 500
  },
  subtitle1: {
    lineHeight: '1.3em'
  },
  button: {}
};

// eslint-disable-next-line import/prefer-default-export
export const lightTheme = createMuiTheme({
  palette: palette,
  typography: typography,
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: colors.blackdrop
      },
      invisible: {}
    },
    MuiButton: {
      root: {
        borderRadius: 3
      },
      label: {
        fontFamily: 'AvenirNext-DemiBold',
        fontSize: 14,
        fontWeight: 600,
        textTransform: 'none',
        whiteSpace: 'nowrap'
      }
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: colors.gray,
      },
    },
    MuiLinearProgress: {
      root: {
        height: 1,
      },
      colorPrimary: {
        color: colors.gray,
        backgroundColor: colors.graySecond,
      },
      barColorPrimary: {
        backgroundColor: colors.periwinkle,
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.graySeventh,
        '&$checked': {
          color: colors.graySeventh,
        }
      },
      // label: {
      //   color: colors.blueEyes
      // },
      colorPrimary: {
        color: colors.gray,
        '&$checked': {
          color: colors.gray,
        }
      },
      colorSecondary: {
        color: colors.grayMain,
        '&$checked': {
          color: colors.periwinkle,
        }
      },
    },
    MuiFormControlLabel: {
      label: {
        color: colors.graySeventh,
      }
    },
    MuiInputBase: {
      root: {
        borderRadius: 3,
        border: `1px solid ${colors.grayMain}`,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      input: {
        ...placeholder({
          fontFamily: 'AvenirNext-Medium',
          fontSize: 10,
          fontWeight: 500,
          color: colors.blackSecondary,
          opacity: 1,
        })
      },
      inputMultiline: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        ...placeholder({
          fontFamily: 'AvenirNext-Medium',
          fontSize: 10,
          fontWeight: 500,
          color: colors.blackSecondary,
          opacity: 1,
        })
      },
      colorSecondary: {
        backgroundColor: colors.whiteTwo
      }
    }
  },
});
