import * as M from '@material-ui/core';
import _ from 'lodash';
import AxlButton from "../Button";

export const Container = M.styled(M.Box)(({theme}) => ({}));

export const Avatar = M.styled(M.Avatar)(({theme, ...props}) => ({
  width: 25,
  height: 25,
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 15,
  fontWeight: 600,
  lineHeight: 25,
  color: theme.palette.primary['greyish-brown'],
  backgroundColor: _.get(theme.palette, props.bgcolor, theme.palette.primary.main),
  margin: theme.spacing(0.1875)
}))

export const DotArg = M.styled('span')(({theme}) => ({
  display: 'inline-block',
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 23,
  fontWeight: 600,
  lineHeight: '20px',
  color: theme.palette.primary.blueEyes,
  margin: theme.spacing(0.1875)
}));

export const CallArguments = M.styled(M.Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end'
}));

export const Text = M.styled(M.Typography)(({theme}) => ({
  fontFamily: "AvenirNext-DemiBold",
  fontWeight: 600,
  color: theme.palette.primary.main,
  fontSize: 13,
  marginBottom: theme.spacing(1)
}));

export const Text_1 = M.styled(Text)(({theme}) => ({
  fontSize: 12,
  fontWeight: 'normal',
  fontFamily: 'AvenirNext-Medium',
}));

export const Text_2 = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13,
  fontWeight: 500,
  fontStyle: 'italic',
  letterSpacing: -0.09,
  color: theme.palette.primary.main
}));

export const Text_3 = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-MediumItalic',
  fontSize: 11,
  fontWeight: 500,
  fontStyle: 'italic',
  letterSpacing: -0.09,
  color: theme.palette.primary.main
}));

export const Text_4 = M.styled(Text)(({theme}) => ({
  fontSize: 11,
  fontWeight: 'normal',
  fontFamily: 'AvenirNext-DemiBold',
  color: theme.palette.primary.brownNight,
  margin: 0
}));

export const Tilte = M.styled(Text)(({theme}) => ({
  fontSize: 16,
  fontWeight: 'bold',
  fontFamily: "AvenirNext-Bold",
  marginBottom: theme.spacing(2)
}));

export const Label = M.styled(Text)(({theme}) => ({
  display: 'inline',
  marginRight: theme.spacing(1),
  marginBottom: 0
}));

export const Divider = M.styled(M.Divider)(({theme}) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
}))

export const CollapseButton = M.withStyles(({palette, spacing}) => ({
  label: {
    color: palette.primary.blueEyes,
    textDecoration: 'underline',
    fontFamily: 'AvenirNext-Medium',
    fontSize: 13,
    fontWeight: 500,
  }
}))(AxlButton)