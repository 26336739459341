import * as M from '@material-ui/core';
import _ from 'lodash';
import AxlButton from "../Button";
import React from "react";

export const Container = M.styled(M.Box)(({theme}) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
}));
export const Title = M.styled(M.Box)(({theme}) => ({
  fontFamily: 'AvenirNext-Bold',
  fontSize: 18,
  fontWeight: 'bold',
  letterSpacing: -0.22,
  color: theme.palette.primary['greyish-brown'],
  marginBottom: theme.spacing(2),
}));
export const SearchContainer = M.styled(M.Box)(({theme}) => ({}));
export const TableList = M.styled(M.Box)(({theme}) => ({}));
export const Controls = M.styled(M.Box)(({theme}) => ({}));
export const Text = M.styled(M.Typography)(({theme}) => ({}));
export const TextControl = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: -0.16,
  textAlign: 'center',
  color: theme.palette.primary.grayTenth,
}));
export const TextRematch = M.styled(TextControl)(({theme}) => ({
  color: theme.palette.primary.main,
}));

