import React, { useEffect, useState } from 'react';
import * as M from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { conversationState, stopsState } from '../../containers/ChatBoxContainer/states';
import DeliveryHistoryList from '../DeliveryHistoryList';
import { getConversationHistory } from '../../repositories/HttpRepository';

export default function DeliveryHistoryContainer() {
  const stops = useRecoilValue(stopsState);
  const conversation = useRecoilValue(conversationState);
  const { shipment } = conversation;
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getConversationHistory(conversation.id).then((resp) => {
      if (resp.ok) {
        setEvents(resp.data);
        setIsLoading(false);
      }
    });
  }, []);

  return !isLoading ? (
    <DeliveryHistoryList events={events} shipment={shipment} stops={stops} />
  ) : (
    <M.Box width={1} height={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <M.CircularProgress size={25} thickness={1} />
    </M.Box>
  );
}
