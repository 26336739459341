import * as M from '@material-ui/core';
import _ from 'lodash';
import AxlButton from "../Button";
import React from "react";

export const Container = M.styled(M.Box)(({theme}) => ({}));

export const ShipmentHistoryContainer = M.styled(M.Box)(({theme}) => ({
  width: 450,
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: 650,
  maxHeight: 'calc(100vh - 150px)',
}));

export const Title = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 28,
  fontWeight: 600,
  color: theme.palette.primary['greyish-brown'],
  margin: '5px 0 10px'
}));

export const SectionTitle = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 15,
  fontWeight: 600,
  color: theme.palette.primary.blackSecondary
}));

export const PopupTitle = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Bold',
  fontSize: 18,
  fontWeight: 'bold',
  letterSpacing: -0.22,
  color: theme.palette.primary['greyish-brown'],
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const ShipmentIdLable = M.styled(`span`)(({theme}) => ({
  borderRadius: 8,
  backgroundColor: theme.palette.primary.cream,
  fontFamily: 'AvenirNext-DemiBoldItalic',
  fontSize: 13,
  fontWeight: 600,
  fontStyle: 'italic',
  color: theme.palette.primary['greyish-brown'],
  verticalAlign: 'middle',
  padding: '3px 5px'
}));

export const Text = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 13,
  color: theme.palette.primary.blackSecondary,
  margin: '3px 0 5px'
}));

export const Text_1 = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBoldItalic',
  fontSize: 11,
  fontWeight: 600,
  fontStyle: 'italic',
  color: theme.palette.primary['greyish-brown']
}));

export const Text_2 = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13,
  fontWeight: 500,
  fontStyle: 'italic',
  letterSpacing: -0.09,
  color: theme.palette.primary.blackSecondary,
  wordBreak: 'break-all',
}));

export const Text_3 = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-MediumItalic',
  fontSize: 11,
  fontWeight: 500,
  fontStyle: 'italic',
  letterSpacing: -0.09,
  color: theme.palette.primary.blackSecondary,
  wordBreak: 'break-all',
}));

export const Text_4 = M.styled(Text)(({theme}) => ({
  color: theme.palette.primary.periwinkle,
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 10,
  margin: 0,
}));

export const ImageCounter = M.styled(Text)(({theme}) => ({
  position: 'relative',
  zIndex: 1,
}));

export const CustomerName = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 1,
  color: theme.palette.primary['greyish-brown']
}));

export const ShipmentStatus = M.styled(M.Typography)(({theme, color}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13,
  color: color || theme.palette.primary.blackSecondary,
  margin: '3px 0 5px'
}));

export const Label = M.styled(Text)(({theme}) => ({
  fontSize: 13,
  color: theme.palette.primary.graySecond,
  margin: '7px 0 5px'
}));

export const TrackingLink = M.styled(`a`)(({theme}) => ({
  textDecoration: 'underline',
  fontWeight: 500,
  color: theme.palette.primary.blackSecondary
}));

export const B = M.styled('b')(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold'
}));

export const P = M.styled(Text)(({theme}) => ({}));

export const Button = M.withStyles(({palette, spacing}) => ({
  label: {
    fontSize: 12
  }
}))(({children, ...props}) => (<AxlButton {...props} variant={'outlined'} color={'primary.brownNight'} padding={`3px 10px`} spacing={0.6875}>{children}</AxlButton>));

export const TextRequired = M.styled('span')(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBoldItalic',
  fontSize: 11,
  fontWeight: 600,
  fontStyle: 'italic',
  letterSpacing: -0.09,
  textAlign: 'center',
  color: theme.palette.primary.yellowDark
}));

export const ImagePreview = M.styled(M.Box)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  width: 75,
  height: 100,
  border: `1px solid ${theme.palette.primary.grayMain}`,
  borderRadius: 3,
  margin: '5px auto 7px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
}));

export const ImagePreviewShow = M.styled('img')(({theme}) => ({
  width: 'auto',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
}));

export const ImagePreviewRequired = M.styled(ImagePreview)(({theme}) => ({
  border: `1px solid ${theme.palette.primary.yellowDark}`,
}));

export const LinkView = M.styled('span')(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 11,
  fontWeight: 500,
  color: theme.palette.primary.link,
  textDecoration: 'underline',
  textAlign: 'center',
  cursor: 'pointer'
}));

export const DriverAvatar = M.styled(M.Box)(({theme}) => ({
  width: 75,
  height: 100,
  backgroundColor: theme.palette.primary.grayMain,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const DividerContainer = M.styled(M.Box)(({theme}) => ({
  height: '100%',
  padding: '25px 0 30px',
  boxSizing: 'border-box',
}));