import Sarus from '@anephenix/sarus';

class Websocket {
  constructor() {
    this.connected = false;
    this.ws = new Sarus({
      url: process.env.REACT_APP_WS,
      eventListeners: {
        open: [
          (e) => {
            this.connected = true;
            // console.log('Connection opened', e);
            if (this.topics) {
              this.waitForConnection(
                () => {
                  try {
                    this.topics.forEach((t) => {
                      this._send({ topic: t, namespace: 'cs', type: 'SUBSCRIBE' });
                    });
                  } catch (error) {
                    console.log('error', error);
                  }
                },
                1000,
                e.target,
              );
            }
          },
        ],
        message: [(e) => this.handleMessage(this, e)],
        close: [(e) => { this.connected = false }],
        error: [(e) => console.log('Error:', e)],
      },
    });
    this.handlers = {};
    this.topics = [];
  }

  waitForConnection(callback, interval, target) {
    if (target && target.readyState === 1) {
      callback();
    } else {
      // optional: implement backoff for interval here
      setTimeout(() => {
        this.waitForConnection(callback, interval);
      }, interval);
    }
  }

  handleMessage(that, e) {
    const result = JSON.parse(e.data);
    console.log('on message', result);
    const handlers = this.handlers[result.topic];
    if (!handlers) return;
    handlers.forEach((handler) => handler(result.message));

  }

  _send(msg) {
    if (!this.connected) return;
    this.ws.send(JSON.stringify(msg));
  }

  subscribe(topic, handler) {
    this._send({ topic, namespace: 'cs', type: 'SUBSCRIBE' });
    if (this.topics.indexOf(topic) === -1) {
      this.topics.push(topic);
    }
    if (!handler) return;
    if (!this.handlers[topic]) {
      this.handlers[topic] = [];
    }
    this.handlers[topic].push(handler);
  }

  unsubscribe(topic, handler) {
    this._send({ topic, namespace: 'cs', type: 'UNSUBSCRIBE' });
    if (!handler) return;
    if (!this.handlers[topic]) return;
    const ind = this.handlers[topic].indexOf(handler);
    if (ind >= 0) this.handlers[topic].splice(ind, 1);
  }

  sendMessage(topic, msg) {
    this._send({
      topic,
      namespace: 'cs',
      message: msg,
    });
  }

  closed() {
    this.ws.disconnect();
  }
}

export default Websocket;
