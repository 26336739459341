import { atom, selector } from 'recoil';

export const searchInputState = atom({
  key: 'search_input_state',
  default: '',
});
export const searchConversationState = atom({
  key: 'search_conversation_state',
  default: {
    isSearching: false,
    data: {},
  },
});

export const isSearchingState = selector({
  key: 'is_searching_state',
  get: ({ get }) => {
    const { isSearching } = get(searchConversationState);
    return isSearching;
  },
});

export const activeSearchState = selector({
  key: 'active_search_state',
  get: ({ get }) => {
    const {
      data: { active },
    } = get(searchConversationState);
    return active;
  },
});
export const pastSearchState = selector({
  key: 'past_search_state',
  get: ({ get }) => {
    const {
      data: { past },
    } = get(searchConversationState);
    return past;
  },
});
export const futureSearchState = selector({
  key: 'future_search_state',
  get: ({ get }) => {
    const {
      data: { future },
    } = get(searchConversationState);
    return future;
  },
});
