import React, {useEffect, useState} from 'react';
import * as M from '@material-ui/core';
import {Container, ListContainer, Title, ListItem, Text_1, Text_2, Text, AddressContainer} from "./styles";
import AxlSelect from "../AxlSelect";
import HistorySearch from "../HistorySearch";
import DeliveryHistoryList from "../DeliveryHistoryList";
import CallHistoryList from "../CallHistoryList";
import AddressHistoryList from "../AddressHistoryList";
import {AxlSimpleTab, AxlSimpleTabs} from "../AxlTab";
import {AxlBox} from "../AxlBox";
import DeliveryHistoryContainer from "../DeliveryHistoryContainer";
import CSCallLogWrapper from '../CSCallLogWrapper';
import CSAddressHistoryWrapper from '../CSAddressHistoryWrapper';

export default function HistoriesComponent() {
  const [historyOption, setHistoryOption] = useState(0);

  const handleChange = (event, newValue) => {
    setHistoryOption(newValue);
  };

  return(<Container>
      <AxlSimpleTabs
        theme={`light`}
        variant={`fullWidth`}
        value={historyOption}
        onChange={handleChange}
        tabs={[
          {label: "Shipment History", bgActiveColor: 'primary.main'},
          {label: "Call History"},
          {label: "Address History"},
        ]}/>
    <M.Box height={1} style={{overflow: 'hidden'}}>
      {historyOption === 0 && <DeliveryHistoryContainer />}
      {historyOption === 1 && <CSCallLogWrapper />}
      {historyOption === 2 && <CSAddressHistoryWrapper />}
    </M.Box>
  </Container>);
}