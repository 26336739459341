import React from 'react';
import * as M from '@material-ui/core';
import _ from 'lodash';

export const Container = M.styled(M.Box)(({theme}) => ({
  position: 'relative',
}));
export const Line = M.styled(M.Box)(({theme, color = 'primary.blackSecondary'}) => ({
  width: 1,
  height: 'calc(100% - 7px)',
  position: 'absolute',
  top: 0,
  left: 3,
  margin: '7px auto 0',
  // backgroundColor: theme.palette.primary.blackSecondary,
  borderLeft: `1px dotted ${_.get(theme.palette, color)}`,
  boxSizing: 'border-box',
}));
export const Dot = M.styled(M.Box)(({theme, color = 'primary.blackSecondary'}) => ({
  width: 7,
  height: 7,
  borderRadius: '50%',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  margin: '7px auto 0',
  backgroundColor: _.get(theme.palette, color),
}));
export const Item = M.styled(M.Box)(({theme}) => ({
  position: 'relative',
  paddingLeft: 14
}));