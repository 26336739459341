import React, { Component, Fragment } from 'react'
import styles from "./shipmentHistoryStyles";

const object_types = {
    DR: 'Driver',
    AS: 'Assignment',
    SH: 'Shipment',
    ST: 'Stop',
    US: 'User',
    CL: 'Client',
}

class EventObject extends Component {
    
    render() {
        const { obj } = this.props
        if (!obj)
            return <span></span>
        const { uid } = obj
        const type = uid ? object_types[uid.split("\_")[0]] : ''
        const object_id = uid.split("\_")[1]
        const name = obj.attributes && obj.attributes.name ? obj.attributes.name : ''
        if (type === 'Driver')
            return <Fragment><span>
                {type} <strong style={styles.strong}>{name}</strong> [{object_id}]
            </span>
            </Fragment>
        return <span>
            {type} <strong style={styles.strong}>{name}</strong> [{object_id}]
        </span>
    }
}

export default EventObject;