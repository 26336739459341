import React from 'react';
import * as M from '@material-ui/core';

export const Box = M.styled(M.Box)(({theme}) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));
export const BoxDetail = M.styled(M.Box)(({theme}) => ({
  padding: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.primary.grayFourth
}));
export const Text = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.text.third,
  lineHeight: 1.3
}));
export const Title = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 15,
}));
export const B = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
}));
export const Label = M.styled(Text)(({theme}) => ({
  color: theme.palette.primary.graySecond,
  margin: '5px 0 3px'
}));
export const ShipmentTime = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13
}));
export const ShipmentId = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBoldItalic',
  fontSize: 8,
  padding: '1px 3px',
  backgroundColor: theme.palette.primary.cream,
  display: 'inline-block',
  margin: '0 5px',
  verticalAlign: 'middle'
}));
export const ShipmentStatus = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBoldItalic',
  fontSize: 10,
  fontWeight: 600,
  fontStyle: 'italic',
  color: theme.palette.primary.black,
  textAlign: 'right'
}));
export const TrackingLink = M.styled(M.Link)(({theme}) => ({
  fontSize: 11,
  fontWeight: 500,
  color: theme.palette.primary.link,
  textDecoration: 'underline'
}));
export const PinLink = M.styled(TrackingLink)(({theme}) => ({
  fontSize: 10,
  verticalAlign: 'top',
  cursor: 'pointer',
}));
export const ClientAvatar = M.styled(M.Box)(({theme}) => ({
  width: 32,
  height: 32,
  // backgroundColor: theme.palette.primary.main
}));
export const ShipmentImgs = M.styled(M.Box)(({theme}) => ({
  width: 28,
  height: '100%',
  backgroundColor: theme.palette.primary.black35,
  cursor: 'pointer'
}));
export const ModalPreviewerContainer = M.styled(M.Modal)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
export const ModalPreviewer = M.styled(M.Box)(({theme}) => ({}));