import React from 'react';
import * as M from '@material-ui/core';
import * as S from './styles';
import {B, Container, Dot, Text, Label, PhoneIco, NameCall, PhoneList, Line, ItemContainer} from "./styles";
import moment from "moment-timezone";
import items from './data';
import AxlTimeLine from "../AxlTimeLine";
import _ from 'lodash';

export default function CallHistoryList({events = [], disabledInfo = false, loading, ...props}) {

  const { customer } = props;

  return(<Container height={1}>
    {!disabledInfo && <M.Box bgcolor={'primary.grayFourth'} padding={2}>
      <M.Box>
        <Text component={'span'}>{`Customer: `}</Text>
        <Text component={'span'}><S.B>{`${customer.name}`}</S.B></Text>
      </M.Box>
      <M.Box>
        <Text component={'span'}>{`Phone: `}</Text>
        <Text component={'span'}><S.B>{`${customer.phone_number}`}</S.B></Text>
      </M.Box>
    </M.Box>}
    {events.length ? <M.Box height={1} style={{padding: '0 10px', overflowY: 'scroll'}}>
      <PhoneList marginTop={2}>
        <Line/>
        {events.map((item, index) => <Item key={index} item={item} />)}
      </PhoneList>
    </M.Box> : loading ? <M.Box width={1} height={200} maxHeight={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <M.CircularProgress size={75} thickness={1} color={'secondary'} />
    </M.Box> : <M.Box width={1} height={100} maxHeight={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>{`No Logs`}</M.Box>}
  </Container>);
}

function Item({item}) {
  const CallNoteList = function(notes) {
    return(notes.map((note, id) => <div key={id}>
      <S.Text_2>{note.content}</S.Text_2>
      <S.Text_3>{`${moment(note.ts).format("MM/DD/YYYY HH:mm")} - by ${_.get(note, 'subject_user.username', '-')}`}</S.Text_3>
    </div>));
  };

  const shipmentId = item.attr ? item.attr.shipment_id : (item.call_args && item.call_args.split('|')[1] || null);
  const isExactMatch = item.call_args && item.attr && item.call_args.indexOf(item.attr.shipment_id) !== -1;
  const isBestguess = item.call_args && item.attr && item.call_args.indexOf(item.attr.shipment_id) === -1;
  const isNoguess = item.attr && !item.attr.shipment_id;
  const agent = item.agent_user_info ? item.agent_user_info.nickname ? item.agent_user_info.nickname : [item.agent_user_info.first_name, item.agent_user_info.last_name].join(" ") : item.call_agent_id;
  const isThis = isBestguess ? `Best guess, Manual Match to shipment ID ${shipmentId} by ${agent}` : isNoguess ? `No guess, Manual Match to shipment ID ${shipmentId} by ${agent}` : isExactMatch ? `Exact match by shipment ID ${shipmentId}` : '-';

  return(<ItemContainer container direction={'row'} alignContent={'center'} spacing={1}>
    <M.Grid item><PhoneIco /></M.Grid>
    <M.Grid xs item>
      <M.Grid container spacing={2}>
        <M.Grid item xs><NameCall>{`Incoming Call`}</NameCall></M.Grid>
        <M.Grid item><S.Date variant={'body1'}>{`${moment(item.start_ts).format('MM/DD/YYYY HH:mmA')} - {call start/end time}`}</S.Date></M.Grid>
      </M.Grid>
      <Text>{`+ ${isThis}`}</Text>
      <Text>{`+ Call from: `}<S.B>{item.call_from}</S.B></Text>
      <Text>{`+ Call session: `}<S.B>{item.id}</S.B></Text>
      <Text>{`+ Handler: `}<S.B>{agent}</S.B></Text>
      {(item.notes && !!item.notes.length) && <M.Grid container spacing={1}>
        <M.Grid item><Text>{`+ Note:`}</Text></M.Grid>
        <M.Grid item xs>
          <AxlTimeLine items={CallNoteList(item.notes)} />
        </M.Grid>
      </M.Grid>}
    </M.Grid>
  </ItemContainer>);
}