import * as S from "../CallCenterShipmentDetail/formStyles";
import AxlInput from "../AxlInput";
import * as M from "@material-ui/core";
import AxlButton from "../Button";
import React, {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {CallSessionState} from "../../states/callCenterStates";
import api from "../../repositories/Api";
import {useRecoilState} from "recoil/dist";

export function CallNoteEditForm({onClose = () => {}}) {
  const [text, setText] = useState('');
  const [sending, setSending] = useState(false);
  const [callSessionState, setCallSessionState] = useRecoilState(CallSessionState);

  function onChange(e) {
    setText(e.target.value)
  }

  useEffect(() => {

  }, []);

  const saveNote = (text) => {
    if(!text) return;
    const sessionId = callSessionState.id || null;

    setSending(true);

    api.patch(`/call_center/${sessionId}/remark`, {remark: text}).then(res => {
      if(res.status === 200) {
        setCallSessionState(res.data);
      }

      setSending(false);
      onClose();
    })
  }

  return(<S.Container>
    <S.Title>{`EDIT CALL NOTE`}</S.Title>
    <AxlInput color={`secondary`} multiline rows={7} placeholder={`{user_last_call_note}`} onChange={e => onChange(e)} />
    <M.Box textAlign={'right'} paddingTop={3}>
      <AxlButton onClick={onClose} padding={'4px 15px'} spacing={0.5} bgcolor={'primary.main'} color={'primary.gray'} variant="outlined">{`Cancel`}</AxlButton>
      <AxlButton
        loading={sending}
        disabled={sending}
        padding={'5px 15px'}
        spacing={0.5}
        bgcolor={'primary.greenThird'}
        color={'primary.main'}
        variant="contained"
        disableElevation
        onClick={() => saveNote(text)}>{`Save`}</AxlButton>
    </M.Box>
  </S.Container>);
}