import React, {useEffect, useState} from 'react';
import * as M from '@material-ui/core';
import * as S from './styles';
import {mainTheme} from "./styles";
import FilterTableList from "../FilterTableList";
import DropdownTableList from "../AxlDropdown";
import {useRecoilState} from "recoil/dist";
import {NoGuessResultFilters, RematchShipmentSelector} from "../../states/callCenterStates";
import _ from 'lodash';
import moment from "moment-timezone";
import ClientFilterTableList from "../ClientFilterTableList";
import api from "../../repositories/Api";

export default function RematchTableList({theme = mainTheme, ...props}) {
  const [items, setItems] = useState([]);
  const [shipmentRematch, setShipmetRematch] = useRecoilState(RematchShipmentSelector);
  const [clients, setClients] = useState([]);
  const [isClientLoading, setClientLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useRecoilState(NoGuessResultFilters);

  const selectItem = (item) => {
    if(Object.values(item).length) {
      if(shipmentRematch && item.shipment.id === shipmentRematch.shipment.id) {
        setShipmetRematch(null);
      } else {
        setShipmetRematch(item);
      }
    }
  };

  const loadClients = () => {
    if(!clients.length) {
      setClientLoading(true);
      api.get(`clients?size=200`).then(res => {
        if(res.status === 200 && res.data) {
          let clients = res.data.map(client => ({
            name: client.name,
            value: client.id
          }));
          setClients(clients);
        }
        setClientLoading(false);
      })
    }
  };

  const onFilterByClient = (clients) => {
    const q = {
      filters: {
        ...(clients.length ? {client_id: clients.map(client => client.value).join(",")} : {})
      }
    };
    const filters = _.assign({}, searchFilter, q);
    console.log(q)
    setSearchFilter(filters)
  }

  useEffect(() => {
    if(props.items && props.items.length === 5) {
      setItems(props.items);
    } else {
      const arr = Array.from(Array(5 - props.items.length), () => ({}));
      var items = props.items;
          items = items.concat(arr);
          setItems(items);
    }
  }, [props.items]);

  return(<M.ThemeProvider theme={theme}>
    <div>
      <M.TableContainer>
        <M.Table>
          <M.TableHead>
            <M.TableRow>
              <M.TableCell width={65}>
                <DropdownTableList
                  onOpen={loadClients}
                  trigger={<div> {`CLIENT`} <S.IconArrowDropDown fontSize={'small'} /> </div>}>
                  {!isClientLoading ? (!!clients.length ? <ClientFilterTableList multiple onChange={onFilterByClient} items={clients} /> : <M.Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={200} height={75}>{`No Clients`}</M.Box>) :
                    <M.Box width={200} height={75} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      <M.CircularProgress color={'inherit'} size={50} thickness={0.5} />
                    </M.Box>}
                </DropdownTableList>
              </M.TableCell>
              <M.TableCell>{`CUSTOMER NAME`}</M.TableCell>
              <M.TableCell>{`PHONE NUMBER`}</M.TableCell>
              <M.TableCell>{`DELIVERY ADDRESS`}</M.TableCell>
              <M.TableCell width={85}>{`DELIVERY DATE`}</M.TableCell>
            </M.TableRow>
          </M.TableHead>
          <M.TableBody>
            {items.length ? items.map((item, id) => <M.TableRow
              key={id}
              hover={shipmentRematch && item}
              onClick={() => selectItem(item)}
              selected={shipmentRematch && shipmentRematch.shipment && item && item.shipment && shipmentRematch.shipment.id === item.shipment.id}>
              <M.TableCell>
                {item.client ?
                  item.client.logo_url ?
                    <M.Avatar src={item.client.logo_url} styles={{width: 78, height: 78}} /> :
                    <M.Avatar styles={{width: 78, height: 78}}>{item.client.name && item.client.name.split()[0] || 'Avatar'}</M.Avatar> :
                  '-'
                }
              </M.TableCell>
              <M.TableCell>{_.get(item, 'shipment.customer.name', '-')}</M.TableCell>
              <M.TableCell>{_.get(item, 'shipment.customer.phone_number', '-')}</M.TableCell>
              <M.TableCell>
                {
                  (item.shipment && item.shipment.dropoff_address) ?
                    `${_.get(item, 'shipment.dropoff_address.street', '')}, ${_.get(item, 'shipment.dropoff_address.city', '')}, ${_.get(item, 'shipment.dropoff_address.state', '')}, ${_.get(item, 'shipment.dropoff_address.zipcode', '')}` :
                    '-'
                }
              </M.TableCell>
              <M.TableCell>{item.shipment && item.shipment.dropoff_latest_ts && moment(item.shipment.dropoff_latest_ts).format('MM/DD/YYYY') || '-'}</M.TableCell>
            </M.TableRow>) : <EmptyTable/>}
          </M.TableBody>
        </M.Table>
      </M.TableContainer>
    </div>
  </M.ThemeProvider>)
}

export function EmptyTable() {
  return Array.from(Array(5), (item, id) => <M.TableRow>
    {Array.from(Array(5), (item, id) => <M.TableCell>{`-`}</M.TableCell>)}
  </M.TableRow>)
}