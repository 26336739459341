import React from 'react';
import * as M from '@material-ui/core';

export const Container = M.styled(M.Box)(({theme}) => ({
  width: 450,
  maxWidth: '100%'
}));

export const Text = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.text.third
}));

export const Label = M.styled(Text)(({theme}) => ({
  fontSize: 13,
  color: theme.palette.primary.blackSecondary,
  margin: '15px 0 5px',
  display: 'inline-block',
  width: 80
}));

export const Title = M.styled(Text)(({theme}) => ({
  fontSize: 18,
  color: theme.palette.text.third,
  margin: '5px 0 10px',
  fontFamily: 'AvenirNext-DemiBold',
  fontWeight: 'bold',
  letterSpacing: -0.09,
}));

export const SaveText = M.styled(Text)(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: 11,
}));

export const CancelText = M.styled(Text)(({theme}) => ({
  color: theme.palette.primary.gray,
  fontSize: 11,
}));
