import moment from 'moment';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'just now',
    ss: '%d seconds',
    m: '1 min',
    mm: '%d mins',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    w: 'a week',
    ww: '%d weeks',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

export function diffTimeToNow(ts) {
  if (ts) return moment(ts).fromNow(true);
  return '';
}

export function covertTimestampToDateFriendly (ts) {
  const minisecs  = Date.parse(new Date()) - ts;
  const secs  = Math.round(minisecs / 1000);
  const mins  = Math.round(secs / 60);
  const hrs   = Math.round(secs / 3600);
  const days   = Math.round(secs / 86400);
  const weeks   = Math.round(secs / 604800);
  const months   = Math.round(secs / 2592000);
  const years   = Math.round(secs / 31536000);
  // console.log(`secs: ${secs}, mins: ${mins}, hrs: ${hrs}, days: ${days}, weeks: ${weeks}, months: ${months}, years: ${years}`)
  const time = secs < 1 ? ('just now') : (
    mins < 1 ? (`${secs} secs`) : (
      hrs < 1 ? (`${mins} mins`) : (
        days < 1 ? (`${hrs} hrs`) : (
          days < 7 ? (`${days} days`) : (
            weeks < 4 ? (`${weeks} weeks`) : (
              years < 365 ? (`${months} months`) : (`${years} years`)
            )
          )
        )
      )
    )
  );

  return time;
}