import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import produce from 'immer';
import { isEmpty } from 'lodash';
import { AssignmentStatus } from '../../constants/assignment';
import * as M from '@material-ui/core';
import * as S from './styles';

import ChatList from '../../components/ChatList';
import { getListAttending, getListReply, getListViewOnly } from '../../repositories/HttpRepository';
// State
import { useWebSocketHook } from '../../utils/websocket-hook';
import { activeListState, attendingState, lengthActiveState } from './states';
import {
  activeSearchState,
  pastSearchState,
  futureSearchState,
  isSearchingState,
} from '../../states/SearchConversation';
import { conversationState } from '../ChatBoxContainer/states';

const PAGE_SIZE = 20;

const markViewMessage = (list, setList, id) => {
  const index = list.findIndex((item) => item.shipmentId === id);
  if (index > -1 && list[index].unViewedMessage > 0) {
    setList(
      produce(list, (draftState) => {
        draftState[index].unViewedMessage = 0;
      }),
    );
  }
};
const SearchComponent = ({ activeList, pastList, futureList, goToChat }) => {
  return (
    <S.SectionContainer>
      {futureList.length > 0 && (
        <ChatList title={`FUTURE`} type={'SEARCH'} data={futureList} url={``} goToChat={goToChat} />
      )}
      {activeList.length > 0 && (
        <ChatList title={`ACTIVE`} type={'SEARCH'} data={activeList} url={``} goToChat={goToChat} />
      )}
      {pastList.length > 0 && <ChatList title={`PAST`} type={'SEARCH'} data={pastList} url={``} goToChat={goToChat} />}
      {isEmpty(futureList) && isEmpty(activeList) && isEmpty(pastList) && (
        <div style={{ textAlign: 'center' }}>No result</div>
      )}
    </S.SectionContainer>
  );
};
const ChatListContainer = ({ ws }) => {
  const history = useHistory();

  const [conversation, setConversation] = useRecoilState(conversationState);
  // const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(0);

  const [activeList, setActiveList] = useRecoilState(activeListState);

  const [attending, setAttending] = useRecoilState(attendingState);
  const [totalReply, setTotalReply] = useState(0);
  const activeListLength = useRecoilValue(lengthActiveState);
  const [isShowLoadMore, setIsShowLoadMore] = useState(false);

  // search
  const activeSearchData = useRecoilValue(activeSearchState);
  const pastSearchData = useRecoilValue(pastSearchState);
  const futureSearchData = useRecoilValue(futureSearchState);
  const isSearchingData = useRecoilValue(isSearchingState);

  useEffect(() => {
    getListAttending().then((resp) => {
      if (resp.ok) {
        setAttending(resp.data);
      }
    });
    getListReply({ page: 0, size: PAGE_SIZE }).then((resp) => {
      if (resp.ok) {
        const { total, items } = resp.data;
        setTotalReply(total);
        const replyListLength = items.length;
        if (replyListLength === total) {
          getListViewOnly({ page: 0, size: PAGE_SIZE }).then((respView) => {
            if (respView.ok) {
              const viewOnlyList = respView.data.items;
              const finalList = items.concat(viewOnlyList);
              setActiveList(finalList);
              if (viewOnlyList.length < respView.data.total) {
                setIsShowLoadMore(true);
              }
            }
          });
        } else {
          // need to query reply more
          setActiveList(items);
          setIsShowLoadMore(true);
        }
        setPageNumber(1);
      }
    });
  }, []);

  const loadMore = () => {
    if (activeListLength < totalReply) {
      getListReply({ page: pageNumber, size: PAGE_SIZE }).then((resp) => {
        if (resp.ok) {
          setActiveList(activeList.concat(resp.data.items));
          setPageNumber(pageNumber + 1);
          if (activeListLength < totalReply) {
            setIsShowLoadMore(true);
          } else {
            setIsShowLoadMore(false);
          }
        }
      });
    } else if (activeListLength === totalReply) {
      getListViewOnly({ page: 0, size: PAGE_SIZE }).then((resp) => {
        if (resp.ok) {
          setActiveList(activeList.concat(resp.data.items));
          setPageNumber(1);
        }
      });
    } else {
      getListViewOnly({ page: pageNumber, size: PAGE_SIZE }).then((resp) => {
        if (resp.ok) {
          const newList = activeList.concat(resp.data.items);
          setActiveList(newList);
          setPageNumber(pageNumber + 1);
          if (newList.length < resp.data.total) {
            setIsShowLoadMore(true);
          } else {
            setIsShowLoadMore(false);
          }
        }
      });
    }
  };

  const goToChat = (id, type) => {
    history.push(`/messages/${id}`);
    if (type === 'ATTENDING') {
      markViewMessage(attending, setAttending, id);
    } else if (type === 'ACTIVE') {
      markViewMessage(activeList, setActiveList, id);
    }
  };

  const conversationUpdater = (msg) => {
    const data = JSON.parse(msg);
    const pathName = history?.location?.pathname.split('/messages/');
    if (pathName.length > 1) {
      const pathId = pathName[1];
      if (pathId === data.ref_id) {
        setConversation(
          produce(conversation, (draftState) => {
            draftState.isViewOnly = data.status !== 'OPEN';
            draftState.shipmentStatus = data.ref_status;
            switch (data.ref_status) {
              case 'DROPOFF_FAILED':
                draftState.assignmentStatus = AssignmentStatus.FAILED;
                break;
              case 'DROPOFF_SUCCEEDED':
                draftState.assignmentStatus = AssignmentStatus.SUCCEEDED;
                break;
              case 'NO_ACTIVE':
                draftState.assignmentStatus = AssignmentStatus.DEACTIVATED;
                break;
              case 'ASSIGNMENT_COMPLETED':
                draftState.assignmentStatus = AssignmentStatus.DEACTIVATED;
                break;
              default:
                draftState.assignmentStatus = AssignmentStatus.ACTIVATED;
                break;
            }
          }),
        );
      }
    }
    const index = attending.findIndex((item) => item.id === data.id);
    if (index > -1) {
      if (data.status === 'ACTIVE') {
        setAttending(
          produce(attending, (draftState) => {
            draftState.splice(index, 1);
          }),
        );
        const objAttending = attending[index];
        const newActive = {
          id: objAttending.id,
          name: objAttending.name,
          ts: 0,
          unViewedMessage: 0,
        };
        setActiveList(
          produce(activeList, (draftState) => {
            draftState.push(objAttending);
          }),
        );
      } else if (data.status === 'CLOSED') {
        setAttending(
          produce(attending, (draftState) => {
            draftState.splice(index, 1);
          }),
        );
      }
    } else {
      // for bad feedback click GotIt
      const indexActive = activeList.findIndex((item) => item.id === data.id);
      if (indexActive > -1 && data.status === 'CLOSED') {
        setActiveList(
          produce(activeList, (draftState) => {
            draftState.splice(indexActive, 1);
          }),
        );
      }
    }
  };
  useWebSocketHook('SHIPMENT_STATUS_UPDATER', conversationUpdater, ws);

  if (isSearchingData)
    return (
      <SearchComponent
        activeList={activeSearchData}
        goToChat={goToChat}
        pastList={pastSearchData}
        futureList={futureSearchData}
      />
    );
  return (
    <S.SectionContainer>
      <S.SectionAttending>
        <ChatList
          title={`ATTENDING`}
          type={`ATTENDING`}
          data={attending}
          url={``}
          goToChat={goToChat}
          isShowLoadMore={false}
        />
      </S.SectionAttending>
      <S.SectionActive>
        <ChatList
          autoSize
          title={`ACTIVE`}
          type={`ACTIVE`}
          data={activeList}
          url={``}
          loadMore={loadMore}
          goToChat={goToChat}
          isShowLoadMore={isShowLoadMore}
        />
      </S.SectionActive>
    </S.SectionContainer>
  );
};
export default React.memo(ChatListContainer);
