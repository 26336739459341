import React from 'react';
import * as M from '@material-ui/core';

export const Container = M.styled(M.Box)(({theme}) => ({
  width: 350,
  margin: 'auto',
  '&:focus': {
    outline: 'none',
  }
}));
export const ContainerInner = M.styled(M.Box)(({theme}) => ({
  marginBottom: theme.spacing(3),
  '&:focus': {
    outline: 'none',
  }
}));
export const ContainerImage = M.styled(M.Box)(({theme}) => ({
  textAlign: 'center',
  '&:focus': {
    outline: 'none',
  }
}));
export const Image = M.styled('img')(({theme}) => ({
  margin: 'auto',
  maxWidth: '100%',
  maxHeight: '100%',
}));
export const ImagePreview = M.styled(M.Box)(({theme}) => ({
  // width: '55px !important',
  height: 80,
  margin: 'auto',
  // paddingLeft: theme.spacing(2),
  // paddingRight: theme.spacing(2),
  // background: theme.palette.primary.grayFourth,
  // display: 'block !important',
  '&:focus': {
    outline: 'none',
  }
}));
export const ContainerBottom = M.styled(M.Box)(({theme}) => ({}));
export const Text = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 11,
  fontWeight: 500,
  color: theme.palette.text.third
}));
export const SendButton = M.styled(M.Button)(({theme}) => ({}));
export const NavBar = M.styled(M.Box)(({theme}) => ({}));