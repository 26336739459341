import React from 'react';
import * as M from '@material-ui/core';

export const Container = M.styled(M.Box)(theme => ({
  height: '100%',
  width: 388,
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  padding: 0,
  boxSizing: 'border-box',
  boxShadow: '-1px 1px 5px 2px rgba(141, 141, 141, 0.24)',
  margin: '0 0 0 1px',
  borderLeft: 'solid 1px #dddddd',
  borderTop: 'solid 1px #dddddd',
}));

export const Title = M.styled(M.Typography)({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 18,
  fontWeight: 600,
  color: '#4a4a4a',
  padding: '0 20px'
});

export const Text = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 11,
  fontWeight: 600,
  textAlign: 'right',
  color: theme.palette.text.select
}));
export const AddressContainer = M.styled(M.Box)(({theme}) => ({
  padding: theme.spacing(2),
}));