import { create } from 'apisauce';

export const api = create({
  baseURL: process.env.REACT_APP_API_DASHBOARD_ROOT,
  withCredentials: true,
});

export const getUser = () => api.get(`/me`);
export const getListAttending = (params) => api.get(`/cs/attending`, params);
export const getListReply = (params) => api.get(`/cs/replyable`, params);
export const getListViewOnly = (params) => api.get(`/cs/active`, params);
export const getMessages = (params, id) => api.get(`/cs/messages/${id}`, params);
export const addMessages = (data, id) => api.post(`/cs/messages/${id}`, data);
export const markReadMessages = (id) => api.put(`/cs/messages/${id}/read`);
export const resolveConversation = (id) => api.post(`/cs/messages/${id}/resolve`);
export const sendNote = (data, id) => api.post(`/cs/messages/${id}/note`, data);

export const getConversationInfo = (id) => api.get(`/cs/messages/${id}/info`);
export const getConversationMember = (id) => api.get(`/cs/messages/${id}/member`);
export const getConversationHistory = (id) => api.get(`/cs/messages/${id}/history`);

export const searchConversation = (keyword) => api.get(`/cs/search?q=${keyword}&size=1000`);
export const gotItFeedBack = (id) => api.post(`/cs/messages/${id}/feedback`);

// SMS
export const putSMS = (id, params) => api.post(`/cs/messages/${id}/link`, params);
