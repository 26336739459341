import React, {useEffect, useState} from 'react';
import * as M from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import HistoryIcon from '@material-ui/icons/History';
import AxlButton from '../Button';
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import * as S from "./styles";
import AxlModal from "../AxlModal";
import DropoffStreetEditForm from "../DropoffStreetEditForm";
import LeaveNoteForm from "../LeaveNoteForm";
import AxlInput from "../AxlInput";
import {TOOLTIP_TITLE} from "../../constants/title";
import {SHIPMENT_STATUS_TO_COLORS} from "../../constants/shipment";
import HistoryImage from '../../assets/images/reopen-chat.png';
import HistoryImage2x from '../../assets/images/reopen-chat@2x.png';
import HistoryImage3x from '../../assets/images/reopen-chat@3x.png';
import AxlConfirmation from "../AxlConfirmation";

export default function ChatControl({handleControl = () => {}, avaiableSearch = false,  ...props}) {
  const [isClose, setClose] = useState(false);
  const { conversation, resolveConversation, sendNote } = props;
  const { shipmentStatus, driverName, uids } = conversation;
  const isShowButton = uids.some((uid) => uid.includes('US'));

  const close = () => setClose(true);

  const open = () => setClose(false);

  return (<S.Container padding={1}>
    <M.Grid container wrap={'nowrap'} direction={'row'} justify={`flex-end`} alignItems={'center'}>
      <M.Grid item>
        <M.Box padding={1}>
          <S.Text_1 component={`p`} align={`right`}>{`STATUS:`}</S.Text_1>
          <S.Text_2 component={`p`} align={`right`} color={SHIPMENT_STATUS_TO_COLORS()[shipmentStatus]}>{shipmentStatus}</S.Text_2>
        </M.Box>
      </M.Grid>
      <M.Grid item container wrap={'nowrap'}>
        {avaiableSearch && <AxlButton spacing={'0 3px'} bgcolor={'primary.whiteTwo'} icon variant="outlined" tooltip={{title: TOOLTIP_TITLE['JOIN']}}>
          <S.Text>{`Join`}</S.Text>
        </AxlButton>}
        {isShowButton && <>
          <AxlButton spacing={'0 3px'} onClick={() => handleControl('MEMBER')} icon variant="outlined" tooltip={{title: TOOLTIP_TITLE['ACTIVE_PARTICIPANTS']}}>
            <img src={`/assets/images/svg/member.svg`} height={16} />
            <S.Counter>{uids.length}</S.Counter>
          </AxlButton>
          {/* <AxlButton spacing={'0 3px'}
            onClick={() => handleControl('FILE')}
            icon variant="outlined" tooltip={{title: TOOLTIP_TITLE['SHARE_FILE']}}><img src={`/assets/images/copy.png`} height={16}/></AxlButton> */}
          </>
        }
        <AxlButton spacing={'0 3px'}
          onClick={() => handleControl('SHIPMENT_HISTORY')}
          icon variant="outlined" tooltip={{title: TOOLTIP_TITLE['SHIPMENT_HISTORY']}}>
          <img src={HistoryImage} srcSet={`${HistoryImage} 1x, ${HistoryImage2x} 2x, ${HistoryImage3x} 3x`} height={16} />
        </AxlButton>
        <AxlButton spacing={'0 3px'}
          onClick={() => handleControl('SHIPMENT_DETAIL')}
          variant="outlined" tooltip={{title: TOOLTIP_TITLE['SHIPMENT_DETAIL']}}>
          <S.Text>{`Shipment details`}</S.Text>
        </AxlButton>
        {isShowButton &&
          <>
            <AxlModal isClose={isClose} trigger={<AxlButton onClick={open} spacing={'0 3px'} icon bgcolor={'primary.whiteTwo'} variant="outlined" tooltip={{title: TOOLTIP_TITLE['LEAVE_NOTE']}}><img src={`/assets/images/svg/edit.svg`} height={16} /></AxlButton>}>
              <LeaveNoteForm onClose={close} driverName={driverName} sendNote={sendNote} />
            </AxlModal>
            <AxlModal isClose={isClose} trigger={<AxlButton onClick={open} spacing={'0 3px'} bgcolor={'primary.whiteTwo'} variant="outlined" tooltip={{title: TOOLTIP_TITLE['RESOLVE']}}>
              <S.Text>{`Resolve`}</S.Text>
            </AxlButton>}>
              <AxlConfirmation
                subTitle={`Are you sure you want to resolve this chat?`}
                closeText={`NO`}
                doText={`YES, resolve this chat`}
                controlTextAlign={`right`}
                onClose={close} onDo={resolveConversation} />
            </AxlModal>
          </>
        }
      </M.Grid>
    </M.Grid>
  </S.Container>);
}
