import React, { useState, useEffect } from 'react';
import * as M from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { get } from 'lodash/fp';
import { Container, Search } from './styles';
import { historySearchState } from '../DeliveryHistoryList/state';
import { useDebounce } from '../../utils/event-hook';

const searchHandler = (event, input) => {
  if (event.date?.includes(input)) {
    return true;
  }

  if(event?.object?.uid?.includes(input) || event?.ref?.uid?.includes(input) || event?.rel?.uid?.includes(input)) {
    return true;
  }

  if(event?.state?.status?.includes(input)) {
    return true;
  }
  if (event?.location?.geolocation?.latitude || event?.location?.geolocation?.longitude) {
    const lat = event?.location?.geolocation?.latitude;
    const lng = event?.location?.geolocation?.longitude;
    if(lat == input || lat.toFixed(6) == input || lng == input || lng.toFixed(6) == input) {
      return true;
    }
  }

  if(event?.subject?.attributes?.name?.includes(input)){
    return true;
  }

  return false;
};
export default function HistorySearch({ eventsResult }) {
  const [searchResult, setSearchResult] = useRecoilState(historySearchState);
  const [searchInput, setSearchInput] = useState();

  const debounceSearchInput = useDebounce(searchInput, 500);
  useEffect(() => {
    if (debounceSearchInput) {
      const newResult = eventsResult.filter(event => searchHandler(event, debounceSearchInput));
      if(newResult.length > 0) {
        setSearchResult(newResult)
      }
    } else {
      setSearchResult([])
    }
  }, [debounceSearchInput]);

  return (
    <Container>
      <Search
        fullWidth
        placeholder="Search…"
        classes={{}}
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e) => setSearchInput(e.target.value)}
      />
    </Container>
  );
}
