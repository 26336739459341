import React, { useEffect, useCallback, useRef } from 'react';
import * as M from '@material-ui/core';
import { get } from 'lodash/fp';
import produce from 'immer';
import { useHistory } from 'react-router-dom';

import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { Switch, Route, useParams } from 'react-router-dom';

import { useEventListener } from '../../utils/event-hook';
import { useWebSocketHook } from '../../utils/websocket-hook';

// Style
import useStyle, { Container } from './styles';
import ChatListContainer from '../ChatListContainer';

// state
import { attendingState, activeListState } from '../ChatListContainer/states';
import { messagesState, conversationState } from '../ChatBoxContainer/states';
import { userState } from '../../atoms';

// Components
import SearchComponent from '../../components/SearchInput';
import EmptyChat from '../../components/EmptyChat';
import ChatBoxContainer from '../ChatBoxContainer';

// api
import { markReadMessages } from '../../repositories/HttpRepository';
import WebSocket from '../../repositories/Websocket';

const OverviewContainer = (props) => {
  // states
  const [attending, setAttending] = useRecoilState(attendingState);
  const [activeList, setActiveList] = useRecoilState(activeListState);
  const [messages, setMessages] = useRecoilState(messagesState);
  const [conversation, setConversation] = useRecoilState(conversationState);
  const user = useRecoilValue(userState);

  const history = useHistory();
  const ws = useRef(null);
  useEffect(() => {
    ws.current = new WebSocket();
  }, []);

  const messageUpdatedHandler = (history, data, listActive, listAttending, messages, conversation) => {
    let isNeedUpdateCount = true;
    // for room message
    const pathName = history?.location?.pathname.split('/messages/');
    if (pathName.length > 1) {
      const pathId = pathName[1];
      if (pathId === data.conversation.ref_id) {
        const msg = {
          type: data.type,
          body: data.body,
          sender: {
            name: data.senderName,
            uid: data.senderUID,
          },
          sentAt: data.sentAt,
          attributes: data.attributes
        };
        const messagesAfter = produce(messages, (draftState) => {
          draftState.unshift(msg);
        });
        markReadMessages(pathId);
        setMessages(messagesAfter);
        isNeedUpdateCount = false;
        setConversation(
          produce(conversation, (draftState) => {
            draftState.uids = data.conversation.uids;
          }),
        );
      }
    }
    // for list
    const index = listActive.findIndex((item) => item.id === data.conversation_id);
    if (index > -1) {
      // bring from active to attending
      if (data.senderUID.includes('US')) {
        setActiveList(listActive =>
          produce(listActive, (draftState) => {
            draftState.splice(index, 1);
          }),
        );
        setAttending(
          produce(listAttending, (draftState) => {
            const currentConversation = { ...listActive[index], ts: Number(data.sentAt) };
            draftState.unshift(currentConversation);
          }),
        );
      } else {
        // remove in list
        if (data?.conversation.status === 'CLOSED') {
          setActiveList((listActive) =>
            produce(listActive, (draftState) => {
              draftState.splice(index, 1);
            }),
          );
        } else {
          let ts = 0;
          let count = 0;
          if (data?.conversation.status === 'OPEN') {
            ts = Number(data.sentAt);
            count = 1;
          }
          setActiveList((listActive) =>
            produce(listActive, (draftState) => {
              if (isNeedUpdateCount) draftState[index].unViewedMessage = count;
              draftState[index].ts = ts;
            }),
          );
        }
      }
    } else {
      const attendingIndex = listAttending.findIndex((item) => item.id === data.conversation_id);
      if (attendingIndex > -1) {
        setAttending(
          produce(listAttending, (draftState) => {
            if (isNeedUpdateCount) draftState[attendingIndex].unViewedMessage += 1;
            draftState[attendingIndex].ts = Number(data.sentAt);
          }),
        );
      } else {
        //  need to add new conversation
        const { conversation } = data
        if (conversation) {
          if (conversation.status === 'OPEN') {
            const newConversation = {
              id: conversation.id,
              name: conversation.customer_name,
              shipmentId: conversation.ref_id,
              ts: new Date(conversation._updated).getTime(),
              unViewedMessage: 1,
            };
            setActiveList((listActive) =>
              produce(listActive, (draftState) => {
                draftState.unshift(newConversation);
              }),
            );
          } else if (conversation.status === 'ACTIVE') {
            const newConversation = {
              id: conversation.id,
              name: conversation.customer_name,
              shipmentId: conversation.ref_id,
            };
            setActiveList((listActive) =>
              produce(listActive, (draftState) => {
                draftState.push(newConversation);
              }),
            );
          }
        }
      }
    }
  };
  const handleMessage = useCallback(
    (msg) => {
      const data = JSON.parse(msg);
      console.log('socket data', data);
      messageUpdatedHandler(history, data, activeList, attending, messages, conversation);
    },
    [activeList, attending, messages, conversation],
  );
  useWebSocketHook('CUSTOMER_SUPPORT_NEW_MESSAGE', handleMessage, ws.current);
  const classes = useStyle();
  return (
    <Container>
      <M.Grid container direction={`row`} wrap={`nowrap`} alignItems={`stretch`} classes={{ root: classes.container }}>
        <M.Box width={300} minWidth={300}>
          <M.Box bgcolor={`primary.blueEyes`} height={1} borderRight={`1px solid #CCC`}>
            <M.Grid container wrap={`nowrap`} alignItems={`stretch`} direction={`column`} style={{ height: '100%' }}>
              <SearchComponent />
              <M.Grid item xs={12} className={classes.innerSection}>
                <M.Box className={classes.inner}>
                  <ChatListContainer ws={ws.current} />
                </M.Box>
              </M.Grid>
            </M.Grid>
          </M.Box>
        </M.Box>
        <M.Grid item xs style={{ overflow: 'hidden' }}>
          <M.Box bgcolor={`primary.main`} height={1}>
            <Switch>
              <Route path="/messages/:id" render={() => <ChatBoxContainer />} />
              <Route exact path="/" render={() => <EmptyChat />} />
            </Switch>
          </M.Box>
        </M.Grid>
      </M.Grid>
    </Container>
  );
};
export default OverviewContainer;
