import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useRecoilState } from 'recoil';

import { Container, ListContainer, Title, ListItem, Text_1, Text_2, Avatar } from './styles';
import { titleCase } from '../../utils/display';

import { memberConversationState } from '../../containers/ChatBoxContainer/states';
import { getConversationMember } from '../../repositories/HttpRepository';

export default function ActiveParticipants({ conversationId }) {
  const [member, setMember] = useRecoilState(memberConversationState);
  useEffect(() => {
    getConversationMember(conversationId).then((resp) => {
      if (resp.ok) {
        setMember(resp.data);
      }
    });
  }, [conversationId]);

  return (
    <Container>
      <Title>{`Active participants in Chat`}</Title>
      <Box height={1}>
        <ListContainer>
          {!member ||
            (member.length === 0 && (
              <Box width={1} height={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <CircularProgress size={25} thickness={1} />
              </Box>
            ))}
          {member.length > 0 &&
            member.map(({ uid, name, avatar, role }, i) => (
              <ListItem key={uid} button>
                <Grid container direction={'row'} alignItems={'center'} spacing={2}>
                  <Box>
                    <Avatar alt={name} src={avatar || '/assets/images/svg/member.svg'} />
                  </Box>
                  <Grid item>
                    <Text_1>{titleCase(name)}</Text_1>
                  </Grid>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Text_2>{`- ${role}`}</Text_2>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
        </ListContainer>
      </Box>
    </Container>
  );
}
