import React, { useRef } from 'react';
import Box from '@material-ui/core/Box';
import produce from 'immer';
import * as S from './styles';
import AxlInput from '../AxlInput';
import AxlButton from '../Button';

import { saveShipmentNotes, updateShipmentNotes, getShipmentNotes } from '../../repositories/Api';

export default function ShipmentNoteEditForm({ shipmentId, list, closePopup, user, setShipmentNotes }) {
  const lastNote = list.find((note) => note.subject.uid === `US_${user?.user?.id}`);
  const contentRef = useRef(lastNote);
  return (
    <S.Container>
      <S.Title>{`SHIPMENT NOTE`}</S.Title>
      <form noValidate autoComplete="off">
        <AxlInput color={`secondary`} inputRef={contentRef} multiline rows={7} placeholder={lastNote?.content || 'Add shipment note here..'} />
        <Box textAlign={'right'} paddingTop={3}>
          <AxlButton
            bgcolor={'primary.main'}
            color={'primary.gray'}
            variant="outlined"
            onClick={closePopup}
          >{`CANCEL`}</AxlButton>
          <AxlButton
            onClick={() => {
              if (lastNote) {
                updateShipmentNotes(shipmentId, contentRef.current.value).then((res) => {
                  if (res.ok || res.status === 200) {
                    getShipmentNotes(shipmentId).then((resp) => {
                      setShipmentNotes(resp.data);
                    });
                  }
                });
              } else {
                saveShipmentNotes(shipmentId, contentRef.current.value).then((res) => {
                  if (res.ok || res.status === 200) {
                    getShipmentNotes(shipmentId).then((resp) => {
                      setShipmentNotes(resp.data);
                    });
                  }
                });
              }
              closePopup();
            }}
            bgcolor={'primary.green'}
            color={'primary.main'}
            variant="contained"
            disableElevation
          >{`SAVE`}</AxlButton>
        </Box>
      </form>
    </S.Container>
  );
}
