import React, {useState} from 'react';
import * as M from '@material-ui/core';
import * as S from './styles';
import AxlInput from "../AxlInput";
import AxlCheckbox from "../AxlCheckbox";
import _ from 'lodash';
import {FixedSizeList} from "react-window";

export default function ClientFilterTableList({onChange = () => {}, multiple = false, ...props}) {
  // Local states
  const [items, setItems] = useState(props.items || []);
  const [checkedItems, setCheckedItems] = useState([]);
  const [searchItems, setSearchItems] = useState(props.items);

  const onChangeCheckbox = (item) => {
    let _items = checkedItems;
    const isExist = !!_.filter(checkedItems, i => i.value === item.value).length;
    if(multiple) {
      if(isExist) {
        _items = _items.filter(i => i.value !== item.value);
        setCheckedItems(_items);
      } else {
        _items = _items.concat(item);
        setCheckedItems(_items);
      }
      onChange(_items);
    } else {
      if(isExist) {
        _items = _items.filter(i => i.value !== item.value);
      } else {
        _items = [item];
      }
      setCheckedItems(_items);
      onChange(_items);
    }
  };

  const onSearch = ({ target: { value } }) => {
    const search = _.debounce((v) => {
      const newItems = _.filter(items, (item) => item.name.toLowerCase().indexOf(v.toLowerCase()) !== -1);
      setSearchItems(newItems);
    }, 300);

    search(value);
  };

  const data = {
    multiple: multiple,
    items: searchItems,
    onChange: onChangeCheckbox,
    checkedItems: checkedItems,
    setCheckedItems: setCheckedItems,
  }

  return <S.Container>
    <S.MenuItem><AxlInput onChange={onSearch} spacing={0} placeholder={`Search by agent name...`} /></S.MenuItem>
    <FixedSizeList
      height={500}
      width={300}
      itemSize={50}
      itemCount={searchItems.length}
      itemData={data}>{renderRow}</FixedSizeList>
  </S.Container>;
}

function renderRow(props) {
  const { index, data, style } = props;
  const {items, onChange = () => {}, checkedItems, setCheckedItems = () => {}} = data;

  return <S.MenuItem style={style} key={index}>
    <AxlCheckbox
      checked={!!_.filter(checkedItems, i => i.value === items[index].value ).length}
      color={'default'}
      label={items[index].name}
      value={items[index].value}
      onChange={() => onChange(items[index])}
    />
  </S.MenuItem>
}