export const DROPOFF_TYPE = {
  CLOCK_IN: 'CLOCK_IN',
  CLOCK_OUT: 'CLOCK_OUT',
  PICK_UP: 'PICK_UP',
  DROP_OFF: 'DROP_OFF',
}

export const DROPOFF_POD_TYPE = {
  signature: 'signature',
  picture: 'picture',
  idscan: 'idscan',
}