import * as M from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import {lightTheme as theme} from '../../themes/index';

export const Tabs = M.withStyles(({ palette, spacing }) => ({
  root: {
    background: palette.primary.grayMain
  },
  scroller: {},
  flexContainer: {},
  indicator: {
    display: 'none'
  },
}))(M.Tabs);

export const Tab = M.withStyles(({ palette, spacing }) => ({
  root: {
  },
  selected: {
    background: palette.primary.periwinkleSecondary
  },
  wrapper: {},
  textColorInherit: {
    flex: 1,
    minWidth: 'auto',
    fontFamily: 'AvenirNext-Medium',
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: -0.12,
    color: palette.primary.main,
    textTransform: 'none',
    opacity: 1
  },
}))(M.Tab);

export const SimpleTabs = M.withStyles({})(({bgcolor, bgActiveColor, ...props}) => (
  <Tabs {...props} />
));

export const lightTheme = M.createMuiTheme({
  overrides: {
    MuiTabs: {
      root: {
        backgroundColor: theme.palette.primary.grayMain,
      },
      vertical: {},
      flexContainer: {},
      flexContainerVertical: {},
      centered: {},
      scroller: {},
      fixed: {},
      scrollable: {},
      scrollButtons: {},
      scrollButtonsDesktop: {},
      indicator: {
        backgroundColor: theme.palette.primary.transparent,
      },
    },
    MuiTab: {
      root: {
        [theme.breakpoints.up('sm')]: {
          minWidth: 120,
        }
      },
      selected: {
        backgroundColor: theme.palette.primary.periwinkle,
      },
      textColorInherit: {
        opacity: 1,
        color: theme.palette.primary.main,
        fontSize: 13,
        lineHeight: 1.3,
        textTransform: 'none',
        '&$selected': {
          backgroundColor: theme.palette.primary.periwinkle,
        },
      },
      textColorPrimary: {},
      textColorSecondary: {},
    }
  },
});

export const mainTheme = M.createMuiTheme({
  overrides: {
    MuiTabs: {
      root: {
        backgroundColor: theme.palette.primary.grayMain,
      },
      vertical: {},
      flexContainer: {},
      flexContainerVertical: {},
      centered: {},
      scroller: {},
      fixed: {},
      scrollable: {},
      scrollButtons: {},
      scrollButtonsDesktop: {},
      indicator: {
        backgroundColor: theme.palette.primary.transparent,
      },
    },
    MuiTab: {
      root: {
        [theme.breakpoints.up('sm')]: {
          minWidth: 120,
        }
      },
      selected: {
        backgroundColor: theme.palette.primary.greyblue,
      },
      textColorInherit: {
        opacity: 1,
        color: theme.palette.primary.main,
        fontSize: 13,
        textTransform: 'none',
        fontFamily: 'AvenirNext-Medium',
        lineHeight: 1.3,
        '&$selected': {
          backgroundColor: theme.palette.primary.greyblue,
        },
      },
      textColorPrimary: {},
      textColorSecondary: {},
    }
  },
});

export const outlineTheme = M.createMuiTheme({
  overrides: {
    MuiTabs: {
      root: {
        backgroundColor: theme.palette.primary.transparent,
      },
      vertical: {},
      flexContainer: {
        display: 'block',
      },
      flexContainerVertical: {},
      centered: {},
      scroller: {},
      fixed: {},
      scrollable: {},
      scrollButtons: {},
      scrollButtonsDesktop: {},
      indicator: {
        height: 4,
        backgroundColor: theme.palette.primary.periwinkle,
      },
    },
    MuiTab: {
      root: {
        [theme.breakpoints.up('sm')]: {
          minWidth: 'auto',
        }
      },
      selected: {
        backgroundColor: theme.palette.primary.transparent,
      },
      textColorInherit: {
        opacity: 1,
        color: theme.palette.primary.blackSecondary,
        fontSize: 13,
        textTransform: 'none',
        fontFamily: 'AvenirNext-DemiBold',
        fontWeight: 600,
        lineHeight: 1.3,
        '&$selected': {
          backgroundColor: theme.palette.primary.transparent,
        },
      },
      textColorPrimary: {},
      textColorSecondary: {},
    }
  },
});
