import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import CallHistoryList from '../CallHistoryList';
import api from '../../repositories/Api';
import { conversationState } from '../../containers/ChatBoxContainer/states';

export default function CSCallLogWrapper() {
  const conversation = useRecoilValue(conversationState);
  const { shipment, customer } = conversation;
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [events, setEvents] = useState({});

  useEffect(() => {
    // Load outbound event of shipment and filter call logs
    setLoadingEvents(true);
    if (shipment) {
      api.get(`/call_center/by_shipment/${shipment.id}`).then((res) => {
        if (res.status === 200 && res.data && res.data.items) {
          setEvents(res.data.items);
        }
        setLoadingEvents(false);
      });
    }
  }, [shipment]);

  return <CallHistoryList loading={loadingEvents} events={events} customer={customer} />;
}
