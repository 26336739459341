import React, { useState, useCallback, useEffect } from 'react';
import * as M from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';

import ChatControl from '../ChatControl';
import ChatInfo from '../ChatInfo';
import ListBoxChat from '../ListBoxChat';
import FormChat from '../ChatForm';
import { InnerScroll, Scroll, SeenText } from './styles';
import ActiveParticipants from '../ActiveParticipants';
import HistoriesComponent from '../HistoriesComponent';
import ImagePreviewer from '../ImagePreviewer';
import ShipmentDetail from '../ShipmentDetail';
import LeaveNoteForm from '../LeaveNoteForm';

import { conversationState } from '../../containers/ChatBoxContainer/states';
import {
  ShipmentSelectedState,
} from '../../states/callCenterStates';
import { dropOffPODState } from '../../states/ShipmentDetail';

// import { resolveConversation } from '../../repositories/HttpRepository';

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    height: '100%',
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
  controls: {
    display: 'flex',
    alignContent: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    overflowX: 'scroll',
  },
  lists: {},
}));

const ChatBox = (props) => {
  const [selector, setSelector] = useState({});
  const { listMsg, sendMessage, isViewOnly, checkToLoadMore, resolveFnc, sendNote } = props;
  const classes = useStyles();
  const conversation = useRecoilValue(conversationState);
  const setShipmentSelected = useSetRecoilState(ShipmentSelectedState);
  const setDropOffPOD = useSetRecoilState(dropOffPODState);
  const [isSeen, setIsSeen] = useState(false);

  const location = useLocation()

  // reset when change conversation
  useEffect(
    () => {
      setSelector({});
      setShipmentSelected(null);
      setDropOffPOD([])
    },
    [location]
  )
  const handleControl = (name) => {
    setSelector({ [name]: !selector[name] });
  };
  
  useEffect(() => {
    if(conversation.driverUID) {
       setIsSeen(listMsg[0]?.readBy?.includes(conversation.driverUID));
    } else {
      setIsSeen(false);
    }
  }, [listMsg, conversation]);
  return (
    <M.Box height={1}>
      <M.Grid container className={classes.container}>
        <M.Grid item container direction={`row`} wrap={'nowrap'}>
          <Scroll>
            <InnerScroll>
              <ChatInfo conversation={conversation} />
              <M.Grid xs item />
              <ChatControl
                handleControl={handleControl}
                status={conversation.shipmentStatus}
                conversation={conversation}
                resolveConversation={resolveFnc}
                sendNote={sendNote}
              />
            </InnerScroll>
          </Scroll>
        </M.Grid>
        <M.Grid xs={12} item container direction={'row'} style={{ overflow: 'hidden' }}>
          <M.Grid xs item container direction={`column`} wrap={`nowrap`} style={{ height: '100%' }}>
            <M.Grid xs={12} item style={{ overflow: 'hidden' }}>
              <ListBoxChat listMsg={listMsg} checkToLoadMore={checkToLoadMore} />
            </M.Grid>
            {isSeen && <M.Box padding={1} paddingBottom={0}><SeenText>{`Seen by driver`}</SeenText></M.Box>}
            {!isViewOnly && <FormChat sendMessage={sendMessage} />}
          </M.Grid>
          <M.Grid style={{ height: '100%' }}>
            {selector['MEMBER'] && <ActiveParticipants conversationId={conversation.id} />}
            {selector['SHIPMENT_HISTORY'] && <HistoriesComponent />}
            {selector['LEAVE_NOTE'] && <LeaveNoteForm />}
            {selector['SHIPMENT_DETAIL'] && <ShipmentDetail />}
          </M.Grid>
        </M.Grid>
      </M.Grid>
    </M.Box>
  );
};
export default React.memo(ChatBox);
