import React, {useEffect, useRef, useState} from 'react';
import * as M from '@material-ui/core';
import * as S from './styles';
import AxlButton from "../Button";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearIcon from '@material-ui/icons/Clear';
import data from './demoData';
import moment from "moment-timezone";
import {SHIPMENT_STATUS_TO_COLORS, SHIPMENT_STATUS_TO_SHORT_STATUS} from "../../constants/shipment";
import {useRecoilState} from "recoil/dist";
import {
  BestGuessResultByDateFilters, BestGuessResultByDateSelector,
  BestGuessResultFilters,
  CallSessionState,
  RematchShipmentSelector,
  ShipmentSelectedState
} from "../../states/callCenterStates";
import api from "../../repositories/Api";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import _ from 'lodash';
import AxlModal from "../AxlModal";
import AxlConfirmation from "../AxlConfirmation";

export default function ShipmentListResult({shipments = [], sorting, ...props}) {
  // Local states/refs
  const dateRefs = useRef();
  const [selectedDate, handleDateChange] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [rematching, setRematching] = useState({});
  const [isClose, setClose] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  // Global states
  const [shipmentSelected, setShipmentSelected] = useRecoilState(ShipmentSelectedState);
  const [callSession, setCallSesison] = useRecoilState(CallSessionState);
  const [searchByDateFilter, setSearchByDateFilter] = useRecoilState(BestGuessResultByDateFilters);
  const [searchFilter, setSearchFilter] = useRecoilState(BestGuessResultFilters);

  const selectRow = (item) => {
    if((item && item.shipment) && item.shipment.id === selectedItem) {
      setSelectedItem(null);
      setShipmentSelected(null);
    } else {
      setSelectedItem((item && item.shipment) ? item.shipment.id : null);
      setShipmentSelected(item.shipment);
    }
  }

  const rematch = (item) => {
    if(!item || !callSession) return;

    const params = {
      attr: {
        ...(item.shipment ? {shipment_id: item.shipment.id} : {}),
        ...(item.shipment ? {assignment_id: item.shipment.assignment_id} : {}),
        ...(item.driver ? {driver_id: item.driver.id} : {}),
      }
    };

    setRematching({[item.shipment.id]: true});
    api.patch(`call_center/${callSession.id}/attr`, params).then(res => {
      if(res.status === 200 && res.data) {
        setCallSesison(res.data);
      }
      setRematching({[item.shipment.id]: false});
    })
  };

  const selectDate = (e) => {
    const day = moment(e).startOf('day').utc()
    const q = {
      loadmore: false,
      filters: {
        from: 0,
        filters: {
          dropoff_earliest_ts: `gte_${day.format()},lt_${day.add(1, 'days').format()}`,
        }
      }
    };
    const filters = _.merge({}, searchByDateFilter, q);
    setSearchByDateFilter(filters);
    handleDateChange(e);
  }

  const resetDateTime = () => {
    const q = {
      loadmore: false,
      filters: {
        from: 0,
        filters: {
          dropoff_earliest_ts: null,
        }
      }
    };
    const filters = _.assign({}, searchByDateFilter, _.omit(searchByDateFilter, ['filters.filters.dropoff_earliest_ts']));
    setSearchByDateFilter(filters);
    handleDateChange(null);
  }

  return(<S.Container>
    <M.Grid container alignItems={'center'}>
      <M.Grid item xs><S.SectionTitle>{`Shipment List`}</S.SectionTitle></M.Grid>
      <M.Grid item>
        <M.Grid container alignItems={'center'}>
          <S.FilterLabel component={'span'}>{`Filter by`}</S.FilterLabel>
          <AxlButton padding={'0px'} spacing={0} spacing={0} onClick={() => setIsOpen(true)}>
            <ArrowDropDownIcon fontSize={'small'} />
            <S.ButtonDateText>{selectedDate ? moment(selectedDate).format('DD/MM/YYYY') : `Date`}</S.ButtonDateText>
          </AxlButton>
          <DatePicker
            variant="inline"
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            label="Open me from button"
            format="d MMM yyyy"
            value={selectedDate}
            onChange={selectDate}
            style={{overflow: 'hidden', width: 0, height: 0}}
          />
          <M.Box mx={0.5}>
            <M.IconButton
              disabled={!selectedDate}
              onClick={resetDateTime}
              size={'small'}>
              <M.Tooltip title={'Clear filter'}><ClearIcon fontSize={'small'} /></M.Tooltip>
            </M.IconButton>
          </M.Box>
        </M.Grid>
      </M.Grid>
    </M.Grid>
    {
      sorting ?
        <M.Box width={1} height={500} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <M.CircularProgress color={'secondary'} size={75} thickness={0.5} />
        </M.Box> :
      shipments.length ?
        <S.ScrollInner maxHeight={1200}>
      {shipments.map((item, index) => <S.Row key={index} active={item.shipment && item.shipment.id === selectedItem} onClick={() => selectRow(item)}>
        <M.Box display={"flex"} alignItems={'flex-start'}>
          <M.Box py={1}><S.Logo src={item.client.logo_url} /></M.Box>
          <M.Box flex={1} p={2}>
            <S.Label>{`Delivery Date:`}</S.Label>
            <S.Text>{`${moment(item.shipment.dropoff_earliest_ts).format('MM/DD/YYYY - [HH:mm')} - ${moment(item.shipment.dropoff_latest_ts).format('HH:mm')}]`}</S.Text>
            <S.Label>{`Drop-off Address:`}</S.Label>
            <S.Text>{`${item.shipment.dropoff_address.city}, ${item.shipment.dropoff_address.state}, ${item.shipment.dropoff_address.zipcode}`}</S.Text>
          </M.Box>
          <M.Box flex={1} p={2}>
            <S.Label>{`Delivery Status:`}</S.Label>
            <S.Text color={SHIPMENT_STATUS_TO_COLORS()[item.shipment.status]}>
              {(item.dropoff && item.dropoff.status) ? item.dropoff.status : (SHIPMENT_STATUS_TO_SHORT_STATUS[item.shipment.status] || '-')}
            </S.Text>
            <S.Label>{`Actual Drop-off Time:`}</S.Label>
            <S.Text>{`${(item.dropoff && item.dropoff.actual_departure_ts) ? moment(item.dropoff.actual_departure_ts).format('MM/DD/YYYY - HH:mmA') : '_'}`}</S.Text>
          </M.Box>
          <M.Box flex={1} p={2}>
            <S.Label>{`Shipment ID:`}</S.Label>
            <S.Text>{item.shipment.id}</S.Text>
            <S.Label>{`Shipment Label:`}</S.Label>
            <S.Text>{item.label ? item.label.driver_label : '_'}</S.Text>
          </M.Box>
          <M.Box alignSelf={'center'} onClick={(e) => {e.stopPropagation()}}>
            {item && item.shipment && <AxlModal
              isClose={isClose['CLOSE_MATCH_CALL']}
              onRendered={() => setClose({['CLOSE_MATCH_CALL']: false})}
              trigger={<AxlButton
                padding={'3px 10px'}
                spacing={0}
                bgcolor={`primary.periwinkle`}><S.ButtonText>{`Match call`}</S.ButtonText></AxlButton>}>
              <AxlConfirmation
                loading={rematching && rematching[item.shipment.id]}
                disabled={rematching && rematching[item.shipment.id]}
                subTitle={"Are you sure to match this call session to selected shipment ?"}
                onClose={() => setClose({['CLOSE_MATCH_CALL']: true})} closeText={'Cancel'}
                onDo={(e) => rematch(item)} doText={'Match'} />
            </AxlModal>}
          </M.Box>
        </M.Box>
      </S.Row>)}
    </S.ScrollInner> :
        <M.Box width={1} height={500} display={'flex'} justifyContent={'center'} alignItems={'center'}>{`No Results`}</M.Box>
    }
  </S.Container>);
}