/* eslint-disable prefer-arrow-callback */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import * as M from '@material-ui/core';
import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';

import useStyles, { buttonStyled, UnreadTime, Time, Text, Title } from './styles';
import { covertTimestampToDateFriendly, diffTimeToNow } from '../../utils/timeformat';
import { titleCase } from '../../utils/display';

import AxlButton from '../Button';
import * as S from './styles';

function renderRow(props) {
  const { index, style, data } = props;
  const item = data.topics[index];
  if (item.name === '') return null;

  const selected = data.params === item.shipmentId;
  return (
    <S.ListItem
      selected={selected}
      button
      style={style}
      key={index}
      onClick={() => {
        data.goToChat(item.shipmentId, data.title);
        diffTimeToNow(item.ts);
      }}
    >
      <M.Grid container direction={'row'}>
        <M.Grid item xs={8}>
          {item.unViewedMessage && data.type === 'ATTENDING' ? <S.UnreadTitle>{titleCase(item.name)}</S.UnreadTitle> : <S.ReadTitle>{titleCase(item.name)}</S.ReadTitle>}
        </M.Grid>
        <M.Grid item xs={4}>
          <M.Grid container justify={'flex-start'} alignItems={`center`}>
            <M.Box width={25} textAlign={'center'}>
              <M.Typography
                style={item.unViewedMessage ? (data.type === 'ATTENDING' ? S.counterDotStyle : S.dotStyle) : {}}
                component={`span`}
              >
                {!!item.unViewedMessage && (item.unViewedMessage > 9 ? '9+' : item.unViewedMessage)}
              </M.Typography>
            </M.Box>
            <M.Grid item xs>
              {item.unViewedMessage && data.type === 'ATTENDING' ? (
                <UnreadTime align={'right'} variant={`body2`} component={`div`}>
                  {diffTimeToNow(item.ts)}
                </UnreadTime>
              ) : (
                <Time align={'right'} variant={`body2`} component={`div`}>
                  {diffTimeToNow(item.ts)}
                </Time>
              )}
            </M.Grid>
          </M.Grid>
        </M.Grid>
      </M.Grid>
    </S.ListItem>
  );
}

function renderSearchRow(props) {
  const { index, style, data } = props;
  const item = data.topics[index];
  const {customer_name, delivery_date, shipmentId, status, avatar} = item;
  const selected = data.params === shipmentId;
  return (
    <S.ListItem
      selected={selected}
      button
      style={style}
      key={index}
      onClick={() => {
        data.goToChat(shipmentId, data.title);
      }}
    >
      <M.Box>
        <Title>
          {customer_name}
          <S.ShipmentLabel component={'span'}>{shipmentId}</S.ShipmentLabel>
        </Title>
        <M.Grid container alignItems={'center'} spacing={1}>
          <M.Grid item>
            <M.Avatar src={avatar} style={{ width: 16, height: 16 }} />
          </M.Grid>
          <M.Grid item xs>
            <S.Date component={'span'}>{moment(delivery_date).format(`(MM/DD/YYYY)`)}</S.Date>
          </M.Grid>
        </M.Grid>
      </M.Box>
    </S.ListItem>
  );
}

renderRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  type: PropTypes.object.isRequired,
};

export default React.memo(function ChatList(props) {
  const classes = useStyles();
  const [params, setParams] = useState('');
  const { type, title, data, loadMore, goToChat, isShowLoadMore } = props;
  const buttonStyle = buttonStyled();
  let dataList = data;
  // if (title === 'ATTENDING') {
  dataList = data.slice().sort((a, b) => {
    if (a.ts === b.ts) {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    }
    return b.ts - a.ts;
  });
  // }
  const sections = {
    title,
    type,
    topics: dataList,
    goToChat,
    params,
  };
  const totalUnview = useMemo(() => {
    if (title === 'ATTENDING') {
      return data.filter((message) => message.unViewedMessage > 0).length;
    }
  }, [data]);
  const rowHeight = ['SEARCH'].indexOf(type) !== -1 ? 56 : 46;
  const isSearch = ['SEARCH'].indexOf(type) !== -1;
  const location = useLocation();
  useEffect(() => {
    const pathname = location.pathname.split('/messages/').splice(-1, 1)[0];
    setParams(pathname);
  }, [location]);
  return (
    <S.Container>
      <div className={classes.root} style={{ height: '100%' }}>
        <S.Section>
          <S.SectionTitle component={`span`}>{title}</S.SectionTitle>
          {!!totalUnview && <S.UnreadCounter>{`${totalUnview} Unread Chats `}</S.UnreadCounter>}
        </S.Section>
        {!props.autoSize ? <FixedSizeList
          height={sections.topics.length <= 6 ? sections.topics.length * rowHeight : (window.innerHeight - 200)/2 - 60}
          width={`100%`}
          itemSize={rowHeight}
          itemCount={sections.topics.length}
          itemData={sections}>
          {isSearch ? renderSearchRow : renderRow}
        </FixedSizeList> : <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            height={height - 60}
            width={width}
            itemSize={rowHeight}
            itemCount={sections.topics.length}
            itemData={sections}
          >
            {isSearch ? renderSearchRow : renderRow}
          </FixedSizeList>
        )}
      </AutoSizer>}
      </div>
      {isShowLoadMore && (
        <M.Box textAlign={'center'} padding={2}>
          <AxlButton classes={buttonStyle} onClick={loadMore} bgcolor={'primary'}>{`Load more +`}</AxlButton>
        </M.Box>
      )}
    </S.Container>
  );
});
