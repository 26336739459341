import * as M from '@material-ui/core';

export const Container = M.styled(M.Box)(({theme}) => ({
  position: 'relative',
  paddingLeft: 0
}));
export const DotLine = M.styled(M.Box)(({theme}) => ({
  position: 'absolute',
  borderLeft: '1px dotted #000',
  width: '1px',
  left: 2,
  top: 0,
  height: 'calc(100% - 15px)',
  boxSizing: 'border-box',
  display: 'block',
  marginTop: '15px',
}));
export const Notes = M.styled(M.Box)(({theme}) => ({

}));
export const Note = M.styled(M.Box)(({theme}) => ({
  position: 'relative',
  paddingLeft: 15,
  marginBottom: 5,
  '&::before': {
    content: '""',
    display: 'inline-block',
    width: 5,
    height: 5,
    borderRadius: '50%',
    background: theme.palette.primary.blackSecondary,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    margin: 'auto 0'
  }
}));
export const Text = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 13,
  letterSpacing: -0.04,
  color: theme.palette.primary.blackSecondary,
  marginBottom: 5
}));
export const Title = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
}));
export const Desc = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-MediumItalic',
  fontSize: 11
}));