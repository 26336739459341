import * as M from '@material-ui/core';
import AxlCheckbox from "../AxlCheckbox";

export const Container = M.styled(M.Box)(({theme}) => ({}));
export const MenuItem = M.withStyles(({palette, spacing}) => ({
  root: {
    borderBottom: `1px solid ${palette.primary.grayMain}`,
    '&:nth-last-child(1)': {
      borderBottom: 'none',
    }
  }
}))(M.MenuItem);
export const Checkbox = M.withStyles(({palette, spacing}) => ({
  colorSecondary: {
    padding: spacing(0.5),
    paddingLeft: spacing(1),
    paddingRight: spacing(1)
  }
}))(AxlCheckbox);