import React, {useEffect, useState} from 'react';
import * as M from '@material-ui/core';
import * as S from "./styles";
import {AxlTimeLineStick} from "../AxlTimeLine";
import api from "../../repositories/Api";
import {useRecoilState, useRecoilValue} from "recoil/dist";
import {ShipmentState, EventOutboundSelectedState} from "../../states/callCenterStates";
import {translateEvents} from "../../utils/display";
import {EVENT_STATUS_TO_COLORS} from "../../constants/event";
import {VERBIAGE} from "../../constants/verbiage";
import moment from 'moment-timezone';
import {userState} from "../../atoms";
import _ from 'lodash';

export default function ShipmentTrackingEvents(props) {
  const shipmentSelected = useRecoilValue(ShipmentState);
  const [events, setEvents] = useRecoilState(EventOutboundSelectedState);
  const currentUser = useRecoilValue(userState);

  useEffect(() => {
    if(shipmentSelected && shipmentSelected.id) {
      api.get(`/shipments/${shipmentSelected.id}/outbound-events`).then(res => {
        if(res.status === 200 && res.data) {
          setEvents(res.data);
        }
      });
    }
  }, [shipmentSelected]);

  const translatedEvents = events ? translateEvents(events, shipmentSelected) : [];

  const rerenderEvents = (events, shipment, client) => {
    if(!events || !events.length) return [];

    return events.map(event => {
      const text = renderText(event, shipment, client);
      if(!event.signal || !text) return;

      return ({
        // active: event.signal,
        ts: event.ts,
        content: text,
        bgcolor: EVENT_STATUS_TO_COLORS()[event.signal] || EVENT_STATUS_TO_COLORS()['CREATED'],
      })
    });
  }

  const renderText = (event, shipment, client) => {
    if (!VERBIAGE[event.convertedSignal] || !shipment || !client) return null;

    const timezone = shipment && shipment.timezone ? shipment.timezone : 'America/Los_Angeles';

    VERBIAGE[event.convertedSignal].dropoff_latest = shipment.dropoff_latest_ts ? moment(shipment.dropoff_latest_ts).tz(timezone).format('hh:mm A [on] MM/DD/YYYY') : 'N/A';
    VERBIAGE[event.convertedSignal].dropoff_predicted_date = shipment.dropoff_latest_ts ? moment(shipment.dropoff_latest_ts).add(event.eta ? event.eta : 0).tz(timezone).format('hh:mm A [on] MM/DD/YYYY') : 'N/A';
    VERBIAGE[event.convertedSignal].dropoff_latest_hours = shipment.dropoff_latest_ts ? moment(shipment.dropoff_latest_ts).tz(timezone).format('hh:mm A [on] MM/DD/YYYY') : 'N/A';
    VERBIAGE[event.convertedSignal].eta_in_minutes = event.eta ? Math.round((event.eta || 0)/60000) : 'N/A';
    VERBIAGE[event.convertedSignal].client_company = _.defaultTo(client.company, 'N/A');
    VERBIAGE[event.convertedSignal].support_email = _.defaultTo(client.support_email, 'N/A');
    VERBIAGE[event.convertedSignal].eta_in_time = event.eta && event.ts ? moment(event.eta + event.ts).tz(timezone).format('hh:mm A'):
      moment(shipment.dropoff_latest_ts).tz(timezone).format('hh:mm A [on] MM/DD/YYYY');

    return VERBIAGE[event.convertedSignal].description;
  };

  const items = rerenderEvents(translatedEvents, shipmentSelected, currentUser).filter(e => e);

  return(<M.Box height={1} style={{overflowY: 'scroll'}}>
    <AxlTimeLineStick color={true} items={items} />
  </M.Box>);
}