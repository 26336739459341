import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {},
  username: {
    fontSize: 14,
  },
  title: {
    flexGrow: 1,
    marginLeft: 10,
    fontFamily: 'AvenirNext',
    color: '#4a4a4a',
    fontSize: 20,
    textTransform: 'uppercase',
  },
  secondAppBar: {
    height: 50,
    position: 'relative',
  },
  link: {
    marginLeft: 30,
    textDecoration: 'none',
    padding: '10px 30px',
    fontFamily: 'AvenirNext',
    fontSize: 12.5,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#ffffff',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'centenr',
    borderBottom: '5.5px solid transparent',
    '&.active': {
      borderBottom: '5.5px solid #fff',
      fontWeight: 'bold',
      color: '#f4f4f4',
    },
  },
}));