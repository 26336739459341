import React from 'react';
import * as M from '@material-ui/core';

export const Container = M.styled(M.Box)(theme => ({
  height: '100%',
  width: 388,
  maxWidth: '100%',
  padding: '25px 0',
  boxSizing: 'border-box',
  boxShadow: '-1px 1px 5px 2px rgba(141, 141, 141, 0.24)',
  margin: '0 0 0 1px',
  borderLeft: 'solid 1px #dddddd',
  borderTop: 'solid 1px #dddddd',
}));

export const Title = M.styled(M.Typography)({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 18,
  fontWeight: 600,
  color: '#4a4a4a',
  padding: '0 20px'
});

export const ListContainer = M.styled(M.List)({
  height: '100%',
  paddingBottom: 15,
  boxSizing: 'border-box',
});
export const ListInnerContainer = M.styled(M.List)({});
export const ListItem = M.styled(M.ListItem)({
  padding: '16px 25px'
});
export const Text_1 = M.styled(M.Typography)({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 16,
  fontWeight: 500,
  color: '#4a4a4a'
});
export const Text_2 = M.styled(M.Typography)({});
export const Avatar = M.styled(M.Avatar)({
  width: 30,
  height: 30
});