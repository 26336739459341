import _ from "lodash";
import moment from "moment-timezone";
import api from "../repositories/Api";

const SHOWING_STOP_TYPES = ['DROP_OFF', 'RETURN'];

export function processAssignmentDetail(assignmentDetail) {
  let shipmentMap = {}
  let shipmentLabelMap = {}
  let clientMap = {};
  let stopMap = {};

  assignmentDetail.shipments.forEach(s => {
    shipmentMap[s.id] = s;
  });

  assignmentDetail.shipmentLabels.forEach(s => {
    shipmentLabelMap[s.shipment_id] = s;
  });

  assignmentDetail.clients.forEach(s => {
    clientMap[s.id] = s;
  });

  // if (assignmentDetail.driver && this.driverCache) {
  //   this.driverCache[assignmentDetail.driver.id] = assignmentDetail.driver
  // }

  assignmentDetail.stops.forEach(s => {
    stopMap[s.id] = s;
  });

  // calculate bbox. Will be part of assignment in the future
  let lats = assignmentDetail.shipments.map(s => s.dropoff_address.lat)
  let lngs = assignmentDetail.shipments.map(s => s.dropoff_address.lng)
  if (assignmentDetail.assignment.logistic_type === 'ON_DEMAND') {
    lats = lats.concat(assignmentDetail.shipments.map(s => s.pickup_address.lat))
    lngs = lngs.concat(assignmentDetail.shipments.map(s => s.pickup_address.lng))
  }
  assignmentDetail.bbox = [[_.min(lngs), _.min(lats)], [_.max(lngs), _.max(lats)]]

  assignmentDetail.stops = assignmentDetail.stops.map(s => {
    let stop = Object.assign({}, s);
    stop.shipment = shipmentMap[stop.shipment_id];
    stop.label = shipmentLabelMap[stop.shipment_id];

    //@TODO: move DROP_OFF into constant
    if (SHOWING_STOP_TYPES && SHOWING_STOP_TYPES.indexOf(stop.type) >= 0) {
      stop.corresponding_stop = stop.corresponding_stop_id && stopMap[stop.corresponding_stop_id] ? stopMap[stop.corresponding_stop_id] : null;
      if (!stop.corresponding_stop) {
        if (stop.type !== 'PICK_UP') {
          let pickups = assignmentDetail.stops.filter((s) => s.shipment_id === stop.shipment_id && s.type === 'PICK_UP');
          if (pickups.length > 0) {
            stop.corresponding_stop = pickups[0]
            stop.attributes = {
              is_reattempt: stop.type === 'DROP_OFF' ? 'true' : 'false',
              can_discard:  (stop.status !== 'SUCCEEDED' && stop.status !== 'FAILED').toString()
            }
          }
        }
      }
    }

    if (stop.shipment)
      stop.client = clientMap[stop.shipment.client_id];

    return stop;
  });
  assignmentDetail.completable = assignmentDetail.assignment && assignmentDetail.assignment.driver_id &&
    assignmentDetail.assignment.status !== 'COMPLETED' && _.every(assignmentDetail.stops
      .filter(s => !s._deleted)
      .filter(s => ['PICK_UP', 'DROP_OFF'].indexOf(s.type) >= 0).map(s => ['FAILED', 'SUCCEEDED', 'DISCARDED'].indexOf(s.status) >= 0))
  assignmentDetail.isCompleted = assignmentDetail.assignment && assignmentDetail.assignment.status === 'COMPLETED'

  // assignmentDetail.locations = [];

  return assignmentDetail;
}

export function getDriverLocation(a) {
  let assignmentDetail = Object.assign({}, a);

  if (!a.assignment.driver_id) {
    return;
  }
  api.get(`/drivers/${a.assignment.driver_id}/location`)
    .then(response => {
      if (response.status === 200) {
        return response.data.length > 0 ? response.data[0] : null;
      } else {
        return null
      }
    })
    .then((l) => {
      if (!l) return;
      if (l.assignment_id !== a.assignment.id) return
      if (moment(l.timestamp).unix() * 1000 < Date.now() - 1200000) {
        return null;
      } else {
        return l;
      }
    })
}