import React, { useEffect, useRef, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import produce from 'immer';
import { debounce, isEmpty } from 'lodash';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { userState } from '../../atoms';
import {
  getMessages,
  markReadMessages,
  getConversationInfo,
  addMessages,
  resolveConversation,
  sendNote,
} from '../../repositories/HttpRepository';
// states
import {
  conversationState,
  messagesState,
  isViewOnlyState,
  messagesQueryState,
  smallerThanTotalState,
  pageMessageState,
} from './states';
import { activeListState, attendingState } from '../ChatListContainer/states';

import ChatBox from '../../components/ChatBox';

const ChatBoxContainer = () => {
  const { id } = useParams();
  // state
  const [conversation, setConversation] = useRecoilState(conversationState);

  const [messages, setMessages] = useRecoilState(messagesState);
  const [page, setPage] = useRecoilState(pageMessageState);
  const setMessageQuery = useSetRecoilState(messagesQueryState);
  const isViewOnly = useRecoilValue(isViewOnlyState);
  const isListSmallerThanTotal = useRecoilValue(smallerThanTotalState);
  const [activeList, setActiveList] = useRecoilState(activeListState);
  const [attending, setAttending] = useRecoilState(attendingState);
  const user = useRecoilValue(userState);

  const history = useHistory();

  const scrollTopList = useRef(0);

  useEffect(() => {
    getMessages({}, id).then((resp) => {
      if (resp.ok) {
        setMessageQuery(resp.data);
      }
    });

    markReadMessages(id).then((resp) => {
      if (resp.ok) {
        // console.log('mark message', resp);
      }
    });

    getConversationInfo(id).then((resp) => {
      if (resp.ok) {
        setConversation({ id, ...resp.data });
      }
    });
  }, [id]);

  const sendNoteFnc = (inputRef, cb = () => {}) => {
    if (id && !isEmpty(inputRef.current.value)) {
      sendNote({ body: inputRef.current.value }, id).then((resp) => {
        if (resp.ok) {
          const messagesAfter = produce(messages, (draftState) => {
            draftState.unshift(resp.data);
          });
          setMessages(messagesAfter);
          // update attending list
          const index = attending.findIndex((item) => item.id === id);
          if (index > -1) {
            setAttending(
              produce(attending, (draftState) => {
                draftState[index].ts = Number(resp.data.sendAt);
              }),
            );
          }
        }
        if (cb) cb(resp);
      });
    }
  };
  const sendMessage = (inputRef) => {
    if (id && !isEmpty(inputRef.current.value)) {
      addMessages({ body: inputRef.current.value }, id).then((resp) => {
        if (resp.ok) {
          const messagesAfter = produce(messages, (draftState) => {
            draftState.unshift(resp.data);
          });
          inputRef.current.value = '';
          setMessages(messagesAfter);
        }
      });
    }
  };

  const checkToLoadMore = debounce((listRef) => {
    const { current } = listRef;
    if (current.scrollTop <= 70 && isListSmallerThanTotal) {
      getMessages({ page: page + 1 }, id).then((resp) => {
        if (resp.ok) {
          scrollTopList.current = current.scrollHeight;
          const { data } = resp;
          setPage(data.page);
          const messagesAfter = produce(messages, (draftState) => {
            draftState.push(...data.items);
          });
          setMessages(messagesAfter);
          debounce(() => {
            current.scrollTop = current.scrollHeight - scrollTopList.current - 35;
          }, 20)();
        }
      });
    }
  }, 100);

  const resolveFnc = useCallback(() => {
    resolveConversation(id).then((resp) => {
      if (resp.ok) {
        const index = attending.findIndex((item) => item.id === id);
        if (index > -1) {
          if (resp.data.status === 'ACTIVE') {
            const objAttending = attending[index];
            const newActive = {
              id: objAttending.id,
              name: objAttending.name,
              ts: 0,
              unViewedMessage: 0,
            };
            setActiveList(
              produce(activeList, (draftState) => {
                draftState.push(newActive);
              }),
            );
          }
          setAttending(
            produce(attending, (draftState) => {
              draftState.splice(index, 1);
            }),
          );
        }
        history.push('/');
      }
    });
  }, [id]);

  return (
    <ChatBox
      listMsg={messages}
      sendNote={sendNoteFnc}
      sendMessage={sendMessage}
      isViewOnly={isViewOnly}
      checkToLoadMore={checkToLoadMore}
      resolveFnc={resolveFnc}
    />
  );
};
export default React.memo(ChatBoxContainer);
