import React, {Component, useEffect, useRef, useState} from "react";
import * as M from '@material-ui/core';
import Slider from "react-slick";
import * as S from "./styles";
import AxlButton from "../Button";
import {useRecoilState, useRecoilValue} from "recoil/dist";
import {
  CurrentSearchShipmentSelector, ShipmentSelectedState
} from "../../states/callCenterStates";
import api from "../../repositories/Api";
import moment from "moment-timezone";
import {copyToClipboard, copyToLocation} from "../../utils/display";
import AxlConfirmation from "../AxlConfirmation";
import AxlModal from "../AxlModal";
import _ from 'lodash';
import AxlMap from "../AxlMap";
import {putSMS} from "../../repositories/HttpRepository";

export default function AddressHistoryPinPreview({trigger}) {
  // Local states/refs
  const [closeConfirmSendPhoto, setCloseConfirmSendPhoto] = useState(true);
  // const [pods, setPods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [curr, setCurr] = useState(0);
  const [copied, setCopied] = useState(false);
  const slider = useRef(null);
  // Global states
  const [currentShipmentSearch, setCurrentShipmentSearch] = useRecoilState(CurrentSearchShipmentSelector);
  const {dropoff, shipment, pods} = currentShipmentSearch;

  const sendToCustomer = (shipmentId, pod) => {
    if(!(pod || pod && (pod.event || pod.event.location || pod.event.location.geolocation))) return;

    setLoading(true);
    const url = `https://www.google.com/maps/search/?api=1&query=${_.get(pod, 'event.location.geolocation.latitude')}, ${_.get(pod, 'event.location.geolocation.longitude')}`;

    // copyToLocation(pod.event.location.geolocation);
    putSMS(shipmentId, {"body": url}).then(res => {
      setLoading(true);
    });
  };

  // Display
  const pod_TS = _.get(pods[0], 'event.ts');

  return (
    <S.Container>
      <S.ContainerInner>
        <M.Box>
          <M.Box mt={2} minHeight={200} height={200} bgcolor={'primary.whiteTwo'}>
            {!!(shipment || pods.length) && <AxlMap
              pods={pods}
              shipment={shipment} />}
          </M.Box>
          <M.Box bgcolor={'primary.blackdrop'} px={0.5} py={1} textAlign={'center'}>
            <S.Text component={'span'}>{`${moment(pod_TS).format('MM/DD/YYYY')}`}</S.Text>
            <S.HL component={'span'}>{`${_.get(shipment, 'id', '-')}`}</S.HL>
          </M.Box>
        </M.Box>
        <M.Box height={200} marginTop={2}>
          <M.Grid container alignItems={'center'}>
            <M.Grid item xs></M.Grid>
            <M.Grid item>
              <AxlModal isClose={closeConfirmSendPhoto}
                onRendered={() => setCloseConfirmSendPhoto(false)}
                trigger={<AxlButton
                variant="contained"
                color="primary.main"
                padding={'5px 15px'}
                noRadius
                disableElevation
                spacing={0}
                bgcolor={'primary.greenFourth'}>{`Send Pin to Customer`}</AxlButton>}>
                <AxlConfirmation
                  loading={loading}
                  subTitle={"Are you sure to send customer this address history photo?"}
                  onClose={() => setCloseConfirmSendPhoto(true)} closeText={'Cancel'}
                  onDo={() => {
                    setCloseConfirmSendPhoto(true);
                    sendToCustomer(_.get(shipment, 'id'), pods[0])
                  }} doText={'Send'}/>
              </AxlModal>
            </M.Grid>
          </M.Grid>
        </M.Box>
        <M.Box>
          <M.Grid container alignItems={'center'}>
            <M.Grid item><S.BackButton onClick={trigger}>{`Back`}</S.BackButton></M.Grid>
            <M.Grid item xs></M.Grid>
          </M.Grid>
        </M.Box>
      </S.ContainerInner>
    </S.Container>
  );
}