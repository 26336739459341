import { atom, selector } from 'recoil';
import { DROPOFF_POD_TYPE } from '../constants/stop';

export const dropOffPODState = atom({
  key: 'SD_drop_off_POD',
  default: [],
});

export const dropOffPODResultState = selector({
  key: 'SD_drop_off_state',
  get: ({ get }) => {
    const podList = get(dropOffPODState);
    if (podList && podList.length > 0) {
      return {
        pictures: podList.filter((pod) => pod.type === DROPOFF_POD_TYPE.picture),
        signatures: podList.filter((pod) => pod.type === DROPOFF_POD_TYPE.signature),
        idscans: podList.filter((pod) => pod.type === DROPOFF_POD_TYPE.idscan),
      };
    }
    return {
      pictures: [],
      signatures: [],
      idscans: [],
    };
  },
});
