import * as M from '@material-ui/core';

export const Container = M.styled(M.Box)(({theme}) => ({}));
export const Link = M.styled('a')(({theme}) => ({
  padding: '8px 20px',
  color: 'rgb(255, 255, 255)',
  backgroundColor: 'rgb(129, 146, 167)',
  minWidth: '80px',
  textDecoration: 'none',
  display: 'inline-flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  placeContent: 'center',
  lineHeight: '1.3em',
  borderRadius: 3,
  width: '100%',
  fontSize: '14px',
  fontFamily: 'AvenirNext-DemiBold',
  fontWeight: '600',
  whiteSpace: 'nowrap',
  textTransform: 'none',
}));