import React, {useEffect, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';

function joinClassnames(...classnames) {
  return classnames.filter((i) => i).join(' ');
}

const NavLink = (props) => {
  const location = useLocation();
  const [classNameLink, setClassNameLink] = useState('');
  const { className: classNameProp, otherActive, to, ...rest } = props;
  const { pathname } = location;
  let isActive = false;

  useEffect(() => {
    if (to !== '/') {
      isActive = !!pathname.match(to);
    } else if (pathname === to) {
      isActive = true;
    } else {
      isActive = !!pathname.match(otherActive);
    }
    const className = isActive ? joinClassnames(classNameProp, 'active') : classNameProp;
    setClassNameLink(className);

  }, [pathname, to, otherActive]);

  const newProps = {
    className: classNameLink,
    to,
    ...rest,
  };

  return <Link {...newProps} />;
};

export default NavLink;
