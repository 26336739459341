import * as M from '@material-ui/core';
import {colors} from "../../themes";

export const mainTheme = M.createMuiTheme({
  overrides: {
    MuiCheckbox: {
      root: {
        fontSize: 20,
        color: colors.grayMain,
        '&$checked': {
          color: colors.periwinkle,
        }
      },
      // label: {
      //   color: colors.blueEyes
      // },
      colorPrimary: {
        color: colors.gray,
        '&$checked': {
          color: colors.gray,
        }
      },
      colorSecondary: {
        color: colors.grayMain,
        '&$checked': {
          color: colors.periwinkle,
        }
      },
    },
    MuiFormControlLabel: {
      label: {
        color: colors.graySeventh,
        fontFamily: 'AvenirNext-Medium',
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.3,
      },
      root: {
        width: '100%',
        display: 'block',
      }
    },
  }
})