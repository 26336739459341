import React, {useEffect, useState} from 'react';
import * as M from '@material-ui/core';
import CallHistoryList from "../CallHistoryList";
import {useRecoilValue} from "recoil";
import {
  ShipmentSelectedState, EventCallLogsStateSelector
} from "../../states/callCenterStates";
import api from "../../repositories/Api";
import {useRecoilState} from "recoil/dist";

export default function CallLogsContainer() {
  // Local states
  const [loadingEvents, setLoadingEvents] = useState(false);
  // Global states
  const shipmentSelected = useRecoilValue(ShipmentSelectedState);
  const [events, setEvents] = useRecoilState(EventCallLogsStateSelector);

  useEffect(() => {
    // Load outbound event of shipment and filter call logs
    setLoadingEvents(true);
    if(shipmentSelected) {
      api.get(`/call_center/by_shipment/${shipmentSelected.id}`).then(res => {
        if(res.status === 200 && res.data && res.data.items) {
          setEvents(res.data.items);
        }
        setLoadingEvents(false);
      });
    }
  }, [shipmentSelected]);

  return <CallHistoryList loading={loadingEvents} events={events} disabledInfo />
}