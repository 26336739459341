import React from 'react';
import { Container } from '@material-ui/core';
import SearchList from '../../components/SearchList';


export default function SearchContainer() {
  return (
    <Container maxWidth={false}>
      <SearchList />
    </Container>
  );
}
