import React from 'react';
import moment from 'moment';
import * as S from './styles';

export default function ShipmentNote({ list }) {
  return (
    <S.Container>
      <S.DotLine />
      <S.Notes>
        {list.map((note, index) => (
          <S.Note key={index}>
            <S.Title>{note.content}</S.Title>
            <S.Desc>
              {moment(note.ts).format('M/D/YYYY H:mm')} - by {note?.subject?.attributes?.username || ''}
            </S.Desc>
          </S.Note>
        ))}
      </S.Notes>
    </S.Container>
  );
}
