import { atom, selector } from 'recoil';
import produce from 'immer';
import { DROPOFF_TYPE } from '../../constants/stop';

export const messagesQueryState = atom({
  key: 'messages_query_state',
  default: {
    total: 0,
    size: 0,
    page: 0,
    items: [],
  },
});

export const messagesState = selector({
  key: 'messages_state',
  get: ({ get }) => {
    const { items } = get(messagesQueryState);
    return items;
  },
  set: ({ get, set }, messages) => {
    const messageState = get(messagesQueryState);
    set(
      messagesQueryState,
      produce(messageState, (draftState) => {
        draftState.items = messages;
      }),
    );
  },
});

export const smallerThanTotalState = selector({
  key: 'smaller_than_message_state',
  get: ({ get }) => {
    const { total } = get(messagesQueryState);
    const messages = get(messagesState);
    return messages?.length < total;
  },
});

export const pageMessageState = selector({
  key: 'page_message_state',
  get: ({ get }) => {
    const { page } = get(messagesQueryState);
    return page;
  },
  set: ({ get, set }, page) => {
    const messageState = get(messagesQueryState);
    set(
      messagesQueryState,
      produce(messageState, (draftState) => {
        draftState.page = page;
      }),
    );
  },
});

export const conversationState = atom({
  key: 'conversation_state',
  default: {
    id: '',
    conversationId: '',
    customerName: '',
    assignmentStatus: 'CREATED',
    driverName: '',
    avatar: '/logo.svg',
    isViewOnly: false,
    uids: [],
    shipmentStatus: 'ACTIVATED',
    shipmentLabel: '',
    shipment: {},
    stops: [],
    shipments: [],
    shipmentLabels: [],
    driverUID: '',
  },
});

export const stopsState = selector({
  key: 'stops_state',
  get: ({ get }) => {
    const { stops, shipments, shipmentLabels } = get(conversationState);
    if (!stops) {
      return [];
    }
    const newResult = stops.map((stop) => ({
      ...stop,
      shipment: shipments.find((s) => s.id === stop.shipment_id),
      label: shipmentLabels.find((s) => s.shipment_id === stop.shipment_id),
    }));
    return newResult;
  },
});

export const dropOffShipmentState = selector({
  key: 'drop_off_shipment_state',
  get: ({ get }) => {
    const { stops, shipment } = get(conversationState);
    return stops && stops.filter((stop) => stop.shipment_id === shipment.id && stop.type === DROPOFF_TYPE.DROP_OFF).pop();
  },
});

export const memberConversationState = atom({
  key: 'member_conversation_state',
  default: [],
});

export const isViewOnlyState = selector({
  key: 'view_only_state',
  get: ({ get }) => {
    const { isViewOnly } = get(conversationState);
    return isViewOnly;
  },
});

export const getShipmentState = selector({
  key: 'get_shipment_state',
  get: ({ get }) => {
    const { shipment } = get(conversationState);
    return shipment;
  },
});
