import React from 'react';
import * as M from '@material-ui/core';

export const Container = M.styled(M.Grid)(theme => ({
  height: '100%',
  overflow: 'hidden'
}));

export const Inner = M.styled(M.Grid)(theme => ({
  overflow: 'hidden',
  height: '100%',
}));

export const ButtonText = M.styled(M.Typography)(theme => ({
  fontSize: 12
}));
