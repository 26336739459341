import * as M from '@material-ui/core';

export const Container = M.styled(M.Box)(({theme}) => ({
  backgroundColor: theme.palette.primary.grayThirteenth,
}));

export const Controler = M.styled(M.Box)(({theme}) => ({}));
export const Main = M.styled(M.Box)(({theme}) => ({
  overflow: 'hidden',
  position: 'relative'
}));

export const LeftSide = M.styled(`div`)(({theme}) => ({
  width: '65%',
  float: 'left',
  boxSizing: 'border-box',
  padding: '0 10px',
}));

export const RightSide = M.styled(`div`)(({theme}) => ({
  width: '35%',
  height: '100%',
  float: 'left',
  boxSizing: 'border-box',
  padding: '0 10px',
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column'
}));

export const MapContainer = M.styled(M.Box)(({theme}) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.whiteTwo,
}));

export const Controls = M.styled(M.Box)(({theme}) => ({
  position: 'absolute',
  width: '100%',
  bottom: 0,
  padding: theme.spacing(1),
  zIndex: 2,
  boxSizing: 'border-box',
  textAlign: 'right',
}));

export const Text = M.styled(M.Typography)(({theme}) => ({
  fontSize: 10,
}));
