import * as M from '@material-ui/core';

export const SectionContainer = M.styled(`div`)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}));
export const SectionAttending = M.styled(`div`)(({theme}) => ({
  minHeight: 40,
  maxHeight: "50%"
}));
export const SectionActive = M.styled(`div`)(({theme}) => ({
  flex: 1
}));