import React, { useState, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import * as M from '@material-ui/core';
import { useDebounce } from '../../utils/event-hook';
import { searchConversation } from '../../repositories/HttpRepository';
import { searchConversationState } from '../../states/SearchConversation';
import AxlButton from '../Button';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: '16px 0',
  },
  form: {
    display: 'flex',
    width: '100%',
  },
  root: {
    display: 'flex',
    width: '100%',
    borderRadius: 3,
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    marginRight: '10px',
  },
  input: {
    flex: 1,
    fontFamily: 'AvenirNext',
    fontSize: 15,
    color: '#aeaeae',
    border: 'none',
    '& .MuiInputBase-input::placeholder': {
      fontSize: 14,
    },
  },
  iconButton: {
    padding: '7px 5px',
    color: '#cccccc',
  },
}));

export default function SearchInput({ searchAction }) {
  const classes = useStyles();
  const searchInputRef = useRef('');

  return (
    <M.Box className={classes.container}>
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          searchAction(searchInputRef.current.value);
        }}
      >
        <M.Paper className={classes.root}>
          <IconButton className={classes.iconButton} aria-label="menu">
            <SearchIcon fontSize={`small`} />
          </IconButton>
          <M.InputBase
            className={classes.input}
            inputRef={searchInputRef}
            placeholder="Search By Shipment ID, tracking code, customer name, delivery address, ..."
          />
        </M.Paper>
        <AxlButton
          type="submit"
          spacing={0}
          bgcolor={'primary.periwinkle'}
          color={'primary.main'}
          minWidth={'104px'}
        >{`SEARCH`}</AxlButton>
      </form>
    </M.Box>
  );
}
