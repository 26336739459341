import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import * as M from '@material-ui/core';
import { useDebounce } from '../../utils/event-hook';
import { searchConversation } from '../../repositories/HttpRepository';
import { searchConversationState } from '../../states/SearchConversation';
import {CallSessionListSelector, CallSessionListFilters} from "../../states/callCenterStates";
import AxlInput from "../AxlInput";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: 20,
    backgroundColor: '#ffffff',
    border: 'solid 0.3px #CCC',
    boxShadow: 'none',
  },
  input: {
    flex: 1,
    fontFamily: 'AvenirNext',
    fontSize: 15,
    color: '#aeaeae',
    border: 'none',
  },
  iconButton: {
    padding: '7px 5px',
    color: '#cccccc',
  },
}));

export default function CallSessionSearch({loading}) {
  const classes = useStyles();
  const [searchInput, setSearchInput] = useState();
  const [searchFilters, setSearchFilters] = useRecoilState(CallSessionListFilters);
  const [callSessionList, setCallSessionList] = useRecoilState(CallSessionListSelector);
  const debounceSearchInput = useDebounce(searchInput, 500);

  const onEnter = (e) => {
    if(e.key === 'Enter') {
      const filters = Object.assign({}, searchFilters, {q: e.target.value || ''});
      setSearchFilters(filters);
    }
  }

  const onSearch = (e) => {
    const filters = Object.assign({}, searchFilters, {q: e.target.value || ''});
    setSearchFilters(filters);
  }

  const onChange = (e) => {
    setSearchInput(e.target.value);
  }

  const clearSearch = () => {
    const filters = Object.assign({}, searchFilters, {q: ''});
    setSearchInput('');
    setSearchFilters(filters);
  }

  return (
    <M.Box>
      <M.Paper className={classes.root}>
        <IconButton onClick={(e) => onSearch(e)} className={classes.iconButton} aria-label="menu">
          <SearchIcon fontSize={`small`} />
        </IconButton>
        <M.InputBase
          value={searchInput}
          className={classes.input}
          placeholder="Search by customer name, shipment ID, delivery address..."
          onChange={onChange}
          onKeyDown={(e) => onEnter(e)}
        />
        {!loading ? <IconButton color="primary" className={classes.iconButton} aria-label="directions">
          <ClearIcon fontSize={`small`} onClick={clearSearch} />
        </IconButton> : <IconButton color="primary" className={classes.iconButton} aria-label="directions">
          <M.CircularProgress color={'secondary'} size={20} thickness={0.5} />
        </IconButton>}
      </M.Paper>
    </M.Box>
  );
}
