import React, {Component, useEffect, useRef, useState} from "react";
import * as M from '@material-ui/core';
import Slider from "react-slick";
import * as S from "./styles";
import AxlButton from "../Button";
import {useRecoilState, useRecoilValue} from "recoil/dist";
import {CurrentSearchShipmentSelector} from "../../states/callCenterStates";
import api from "../../repositories/Api";
import moment from "moment-timezone";
import {copyToClipboard} from "../../utils/display";
import AxlConfirmation from "../AxlConfirmation";
import AxlModal from "../AxlModal";
import _ from 'lodash';

export default function AddressHistoryImagePreview({trigger}) {
  // Local states/refs
  const [closeConfirmSendPhoto, setCloseConfirmSendPhoto] = useState(true);
  const [images, setImages] = useState([]);
  const [curr, setCurr] = useState(0);
  const [copied, setCopied] = useState(false);
  const slider = useRef(null);
  // Global states
  const [currentShipmentSearch, setCurrentShipmentSearch] = useRecoilState(CurrentSearchShipmentSelector);
  const {pod, dropoff, client, shipment} = currentShipmentSearch;

  function next() {
    slider.current.slickNext();
  }

  function previous() {
    slider.current.slickPrev();
  }

  const sendToCustomer = (text) => {
    copyToClipboard(text);
    setCopied(true)
  }

  useEffect(() => {
    if(dropoff) {
      api.get(`stops/${dropoff.id}/pod`).then(res => {
        if(res.status === 200 && res.data && res.data.length) {
          const imgs = res.data.filter(pic => pic.type === 'picture');
          setImages(imgs);
        }
      });
    }
  }, [dropoff]);

  return (
    <S.Container>
      <S.ContainerInner>
        <S.Controls>
          {(curr !== 0) && <S.PrevControl onClick={previous}>←</S.PrevControl>}
          {(curr !== images.length - 1) && <S.NextControl onClick={next}>→</S.NextControl>}
        </S.Controls>
        <Slider
          slidesToShow={1}
          slidesToScroll={1}
          arrows={false}
          infinite={false}
          ref={slider}
          afterChange={(current, next) => setCurr(current)}
        >
          {images.map((image, index) => <S.ContainerImage key={index}>
            <S.Inner bgcolor={'primary.grayFifth'}>
              <M.Box width={1} height={450} maxHeight={1} style={{margin: 'auto'}} textAlign={'center'}>
                {image.url && <S.Image src={image.url} />}
              </M.Box>
              <S.ContainerBottom bgcolor={'primary.graySeventh'} px={1} py={0.5}>
                <S.DescText>{`${_.get(shipment, 'customer.name', '-')} - ${moment(image._created).format('MM/DD/YYYY @H:mmA')}`}</S.DescText>
              </S.ContainerBottom>
            </S.Inner>
            <M.Box marginTop={2}>
              <M.Grid container alignItems={'center'}>
                <M.Grid item><S.BackButton onClick={trigger}>{`Back`}</S.BackButton></M.Grid>
                <M.Grid item xs></M.Grid>
                <M.Grid item>
                  <AxlModal isClose={closeConfirmSendPhoto}
                            onRendered={() => setCloseConfirmSendPhoto(false)}
                            trigger={<AxlButton
                            variant="contained"
                            color="primary.main"
                            bgcolor={'primary.green'}>{`Send Photo to Customer`}</AxlButton>}>
                    <AxlConfirmation
                      subTitle={"Are you sure to send customer this address history photo?"}
                      onClose={() => setCloseConfirmSendPhoto(true)} closeText={'Cancel'}
                      onDo={() => {
                        setCloseConfirmSendPhoto(true);
                        copyToClipboard(image.url || image.data)
                      }} doText={'Send'}/>
                  </AxlModal>
                </M.Grid>
              </M.Grid>
            </M.Box>
          </S.ContainerImage>)}
        </Slider>
      </S.ContainerInner>
    </S.Container>
  );
}