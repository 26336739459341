import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Paper, Typography, ButtonBase, Avatar } from '@material-ui/core';
import * as M from '@material-ui/core';
import * as S from './styles';
import moment from 'moment';
import { AssignmentStatus } from '../../constants/assignment';
import { titleCase } from '../../utils/display';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 'none',
  },
  paper: {},
  image: {
    width: 46,
    height: 46,
  },
  avatar: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    height: '100%',
    border: 'solid 3px #e4e4e4',
    boxSizing: 'border-box',
  },
  img: {
    padding: 5,
    boxSizing: 'border-box',
  },
  shipmentIdLable: {
    fontFamily: 'AvenirNext-DemiBoldItalic',
    fontSize: 10,
    fontWeight: 600,
    fontStyle: 'italic',
    color: '#4a4a4a',
    borderRadius: 4,
    backgroundColor: '#fff0cf',
    padding: '3px 5px',
    margin: '0px 7px',
  },
}));

export default function ChatInfo(props) {
  const classes = useStyles();
  const { conversation } = props;
  const statusMapColor = {
    [AssignmentStatus.DEACTIVATED]: '#e4e4e4',
    [AssignmentStatus.ACTIVATED]: '#6c62f5',
    [AssignmentStatus.SUCCEEDED]: '#4abc4e',
    [AssignmentStatus.FAILED]: '#d0021b',
  };

  const { customer, assignmentStatus, driverName, avatar, shipmentLabel, shipment } = conversation;
  let customerName = '';
  if (customer) {
    customerName = customer.name;
  }
  const displayName = shipmentLabel ? `${shipmentLabel}: ${titleCase(customerName)}` : titleCase(customerName);
  if(!shipment) return false;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Grid container>
          <M.Grid item>
            <M.Box className={classes.image} padding={1}>
              <M.Avatar
                classes={{ root: classes.avatar, img: classes.img }}
                alt="complex"
                src={avatar || '/logo.svg'}
                style={{ borderColor: statusMapColor[assignmentStatus] }}
              />
            </M.Box>
          </M.Grid>
          <Grid item xs>
            <M.Box padding={1}>
              <M.Grid container direction={`row`} alignItems={'center'}>
                <M.Grid item>
                  <S.Title variant="h4">{displayName}</S.Title>
                </M.Grid>
                <M.Grid item>
                  <S.ShipmentIdLabel variant="subtitle1">{shipment.id}</S.ShipmentIdLabel>
                </M.Grid>
              </M.Grid>
              <S.Text variant="body1">{`Delivery date: ${moment(shipment.dropoff_earliest_ts).format('MM/DD/YYYY')}`}</S.Text>
              <S.Text variant="body1">{`Driver: ${driverName || ''}`}</S.Text>
            </M.Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
