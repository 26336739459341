import React from 'react';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  ButtonBase,
  ListItemText,
  ListItemIcon,
  ListItem,
  Divider,
  SwipeableDrawer,
  Drawer,
  List
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
// styles
import useStyles from './styles';
import userEvent from "@testing-library/user-event";

export default function AxlDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => {
    const links = [
      {label: 'Admin Dashboard', value: 'admin', path: '#'},
      {label: 'Warehouse App', value: 'warehouse', path: '#'},
      {label: 'Finance App', value: 'finance', path: '#'},
      {label: 'Dispatch App', value: 'dispatch', path: '#'}
    ];
    return (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          {links.map((link, index) => (
            <div key={index}>
              <ListItem button key={index}>
                <ListItemText>
                  <FiberManualRecordIcon style={{fontSize: 10, marginRight: 10, color: link.value === 'dispatch' ? "#2a2444" : "transparent"}} />
                  <Link target="_blank" href="#" color="inherit">{link.label}</Link>
                </ListItemText>
              </ListItem>
              <Divider light />
            </div>
          ))}
        </List>
      </div>
    )};

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <MenuIcon aria-label="drawer" onClick={toggleDrawer(anchor, true)} />
          <Drawer
            classes={{paperAnchorLeft: classes.paperAnchorLeft}}
            anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}