import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import * as M from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  title: {
    fontFamily: 'AvenirNext-Medium',
    fontSize: 13,
    fontWeight: 500,
    color: '#737273'
  },
  clickable: {
    cursor: 'pointer'
  }
}));

export default function EmptyChat() {
  const classes = useStyles();

  const _oppenTopic = () => {
    console.log('open')
  }

  return(<M.Box height={1} className={classes.clickable} onClick={_oppenTopic}>
    <M.Grid container direction={'row'} alignContent={'center'} justify={'center'} className={classes.root}>
      <M.Grid item>
        <M.Typography variant={`h3`} component={`h1`} className={classes.title}>{`Select a chat to start`}</M.Typography>
      </M.Grid>
    </M.Grid>
  </M.Box>);
}