import * as M from '@material-ui/core';
import _ from 'lodash';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

export const Table = M.styled(M.Table)(({theme}) => ({
  minWidth: 960,
}));

export const TableHead = M.styled(M.TableHead)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.primary.gray,
  backgroundColor: theme.palette.primary.main,
}));

export const TableRow = M.styled(M.TableRow)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.primary.blackSecondary,
  cursor: 'pointer',
  backgroundColor: theme.palette.primary.main,
  '&:hover > td': {
    backgroundColor: theme.palette.primary.blueEyes
  },
  '&:nth-last-child(1) > td': {
    borderBottom: 'none'
  }
}));

export const TableCell = M.styled(M.TableCell)(({theme, bgcolor}) => ({
  padding: theme.spacing(2),
  color: 'inherit',
  font: 'inherit',
  backgroundColor: _.get(theme.palette, bgcolor, theme.palette.primary.main),
  borderBottom: `1px solid ${theme.palette.primary.grayMain}`,
}));

export const Text = M.styled(M.Typography)(({theme, color}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 10.5,
  fontWeight: 500,
  color: color || theme.palette.primary.graySecond
}));

export const HeaderButton = M.styled(M.IconButton)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 10.5,
  fontWeight: 500,
  padding: theme.spacing(0),
  color: theme.palette.primary.graySecond
}));

export const IconArrowDropDown = M.styled(ArrowDropDownIcon)(({theme}) => ({
  verticalAlign: 'middle',
  cursor: 'pointer'
}));

export const IconArrowDropUp = M.styled(ArrowDropUpIcon)(({theme}) => ({
  verticalAlign: 'middle',
  cursor: 'pointer'
}));

export const Container = M.styled(M.Box)(({theme}) => ({}));

export const Avatar = M.styled(M.Avatar)(({theme}) => ({
  width: 25,
  height: 25
}));

export const PaginationContainer = M.styled(M.Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '5px 0 0',
}));