import React, {useEffect, useState} from 'react';
import * as M from '@material-ui/core';
import * as S from './styles';
import {mainTheme} from "./styles";
import FilterTableList from "../FilterTableList";
import DropdownTableList from "../AxlDropdown";
import {useRecoilState} from "recoil/dist";
import {RematchShipmentSelector} from "../../states/callCenterStates";
import _ from 'lodash';
import moment from "moment-timezone";
import ClientFilterTableList from "../ClientFilterTableList";
import api from "../../repositories/Api";

export default function AxlTable({theme = mainTheme, ...props}) {
  const [items, setItems] = useState([]);
  const [shipmentRematch, setShipmetRematch] = useRecoilState(RematchShipmentSelector);

  const selectItem = (item) => {
    if(Object.values(item).length) {
      if(shipmentRematch && item.shipment.id === shipmentRematch.shipment.id) {
        setShipmetRematch(null);
      } else {
        setShipmetRematch(item);
      }
    }
  };

  useEffect(() => {
    if(props.items && props.items.length === 5) {
      setItems(props.items);
    } else {
      const arr = Array.from(Array(5 - props.items.length), () => ({}));
      var items = props.items;
          items = items.concat(arr);
          setItems(items);
    }
  }, [props.items]);

  return(<M.ThemeProvider theme={theme}>
    <div>
      <M.TableContainer>
        <M.Table>
          <M.TableHead>
            <M.TableRow>
              <M.TableCell width={65}>
                <DropdownTableList trigger={<div> {`CLIENT`} <S.IconArrowDropDown fontSize={'small'} /> </div>}>
                  <FilterTableList />
                </DropdownTableList>
              </M.TableCell>
              <M.TableCell>{`CUSTOMER NAME`}</M.TableCell>
              <M.TableCell>{`PHONE NUMBER`}</M.TableCell>
              <M.TableCell>{`DELIVERY ADDRESS`}</M.TableCell>
              <M.TableCell width={85}>{`DELIVERY DATE`}</M.TableCell>
            </M.TableRow>
          </M.TableHead>
          <M.TableBody>
            {items.length ? items.map((item, id) => <M.TableRow
              key={id}
              hover={shipmentRematch && item}
              onClick={() => selectItem(item)}
              selected={shipmentRematch && shipmentRematch.shipment && item && item.shipment && shipmentRematch.shipment.id === item.shipment.id}>
              <M.TableCell>
                {item.client ?
                  item.client.logo_url ?
                    <M.Avatar src={item.client.logo_url} styles={{width: 78, height: 78}} /> :
                    <M.Avatar styles={{width: 78, height: 78}}>{item.client.name && item.client.name.split()[0] || 'Avatar'}</M.Avatar> :
                  '-'
                }
              </M.TableCell>
              <M.TableCell>{_.get(item, 'shipment.customer.name', '-')}</M.TableCell>
              <M.TableCell>{_.get(item, 'shipment.customer.phone_number', '-')}</M.TableCell>
              <M.TableCell>
                {
                  (item.shipment && item.shipment.dropoff_address) ?
                    `${_.get(item, 'shipment.dropoff_address.street', '')}, ${_.get(item, 'shipment.dropoff_address.city', '')}, ${_.get(item, 'shipment.dropoff_address.state', '')}, ${_.get(item, 'shipment.dropoff_address.zipcode', '')}` :
                    '-'
                }
              </M.TableCell>
              <M.TableCell>{item.shipment && item.shipment.dropoff_latest_ts && moment(item.shipment.dropoff_latest_ts).format('MM/DD/YYYY') || '-'}</M.TableCell>
            </M.TableRow>) : <EmptyTable/>}
          </M.TableBody>
        </M.Table>
      </M.TableContainer>
    </div>
  </M.ThemeProvider>)
}

export function EmptyTable({rows = 5, columns = 5}) {
  return Array.from(Array(rows), (item, id) => <M.TableRow>
    {Array.from(Array(columns), (item, id) => <M.TableCell>{`-`}</M.TableCell>)}
  </M.TableRow>)
}