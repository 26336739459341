import * as M from "@material-ui/core";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

export const Container = M.styled(M.Box)(({theme}) => ({
  height: 'calc(100vh - 146px)',
  boxSizing: 'border-box',
  overflow: 'hidden',
  border: `1px solid ${theme.palette.primary.grayMain}`,
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column'
}));

export const ReportProblemIco = M.styled(ReportProblemIcon)(({theme}) => ({
  fontSize: 13,
  color: theme.palette.primary.redSecond
}));

export const Text = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 16,
  color: theme.palette.primary.blackSecondary,
}));

export const Title = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-Bold',
  fontSize: 18,
  color: theme.palette.primary.blueNight,
}));

export const ButtonDateText = M.styled(Text)(({theme}) => ({
  fontSize: 11,
  margin: 0,
}));

export const FilterLabel = M.styled(Text)(({theme}) => ({
  fontSize: 13,
  margin: '0 5px',
}));

export const NoResultsContainer = M.styled(M.Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
}))