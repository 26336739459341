import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import * as M from '@material-ui/core';
import { useDebounce } from '../../utils/event-hook';
import { searchConversation } from '../../repositories/HttpRepository';
import { searchConversationState } from '../../states/SearchConversation';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: 3,
    backgroundColor: '#ffffff',
    border: 'solid 0.3px #CCC',
    boxShadow: 'none',
  },
  input: {
    flex: 1,
    fontFamily: 'AvenirNext',
    fontSize: 15,
    color: '#aeaeae',
    border: 'none',
  },
  iconButton: {
    padding: '7px 5px',
    color: '#cccccc',
  },
}));

export default function SearchComponent() {
  const classes = useStyles();
  const [searchInput, setSearchInput] = useState();
  const [searchResult, setSearchResult] = useRecoilState(searchConversationState);
  const debounceSearchInput = useDebounce(searchInput, 500);
  useEffect(() => {
    if (debounceSearchInput) {
      console.log('useEffect', debounceSearchInput);
      searchConversation(searchInput).then((resp) => {
        if (resp.ok) {
          setSearchResult({ isSearching: true, data: resp.data });
        }
      });
    } else {
      setSearchResult({ isSearching: false, data: {} });
    }
  }, [debounceSearchInput]);

  return (
    <M.Box padding={2}>
      <M.Paper className={classes.root}>
        <IconButton className={classes.iconButton} aria-label="menu">
          <SearchIcon fontSize={`small`} />
        </IconButton>
        <M.InputBase
          value={searchInput}
          className={classes.input}
          placeholder="customer name, shipment ID"
          inputProps={{ 'aria-label': 'search google maps' }}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <IconButton color="primary" className={classes.iconButton} aria-label="directions">
          <ClearIcon fontSize={`small`} onClick={() => setSearchInput('')} />
        </IconButton>
      </M.Paper>
    </M.Box>
  );
}
