import React, { useEffect, useState } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { Box } from '@material-ui/core';
// states
import { conversationState } from '../../containers/ChatBoxContainer/states';
import {
  ShipmentSelectedState,
  AssignmentSelectedState,
} from '../../states/callCenterStates';
import api, {getAssignment, getAssignmentLocation} from '../../repositories/Api';

import { AddressHistoryPopup } from '../AddressHistoryList';

const CSAddressHistoryWrapper = () => {
  const conversation = useRecoilValue(conversationState);
  const setAssignmentSelected = useSetRecoilState(AssignmentSelectedState);
  const setShipmentSelected = useSetRecoilState(ShipmentSelectedState);
  useEffect(() => {
    if (conversation) {
      const { shipment } = conversation;
      setShipmentSelected(shipment);
      getAssignment(shipment.assignment_id).then((res) => {
        if (res.status === 200) {
          const assignment = res.data;
          getAssignmentLocation(shipment.assignment_id).then((res) => {
            if (res.status === 200 && res.data) {
              const assignmentLocations = {
                ...assignment,
                locations: res.data,
                // driverLocation: getDriverLocation(assignment),
              };
              setAssignmentSelected(assignmentLocations);
            }
          });
        }
      });
    }
  }, [conversation]);

  return <Box padding={2}><AddressHistoryPopup /> </Box>;
};
export default CSAddressHistoryWrapper;
