import { makeStyles } from '@material-ui/core/styles';

export default makeStyles( (theme) => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  paperAnchorLeft: {
    top: 64,
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#f4f3ff',
  }
}));