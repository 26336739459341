import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { get, defaultTo } from 'lodash';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import Link from '@material-ui/core/Link';

import { ReactComponent as EditIcon } from '../../assets/images/svg/edit.svg';
import { ReactComponent as CameraIcon } from '../../assets/images/svg/camera.svg';

import { ColorStatus, TableRowCustom, Cell, Col, Row, IconBtn, Text } from './styles';

const useStyles = makeStyles(() => ({
  head: {
    backgroundColor: '#b7b2f5',
    border: '5px solid #e4e4e4',
    borderBottomWidth: '10px',
  },
  w30: {
    width: '30%',
  },
  tBody: {
    backgroundColor: '#fff',
  },
  textRight: {
    textAlign: 'right',
    zIndex: 1,
  },
  leftIcon: {
    color: '#b7b2f5',
    fontSize: '14px',
    marginRight: '10px',
  },
  textBold: {
    fontFamily: 'AvenirNext-DemiBold',
  },
  trackingCode: { color: '#9b9b9b' },
  icon: { width: '14px' },
  avatar: {
    width: '62px',
    height: '62px',
  },
}));

const SearchRow = ({ data, setPopupData, clickRow }) => {
  const { shipment, client, pod, doc, dropoff, driver } = data;

  const status =
    dropoff && dropoff.status
      ? dropoff.status
      : shipment && shipment.inbound_status && shipment.inbound_status === 'MISSING'
      ? shipment.inbound_status
      : shipment.status;

  const classes = useStyles();
  return (
    <TableRowCustom hover tabIndex={-1} key={shipment.id} onClick={() => clickRow(shipment.id)}>
      <Cell component="th" id={1} scope="row" padding="none" className="first">
        <Grid container spacing={2}>
          <Col className="flex1 padding0">
            <Avatar variant="square" src={client.logo_url} className={classes.avatar} />
          </Col>
          <Col>
            <div className={classes.textRight}>{shipment.id}</div>
            <div className={classes.textRight}>
              {moment(shipment.dropoff_earliest_ts).format('MM/DD/YY hh:mm A')} -
              {moment(shipment.dropoff_latest_ts).format('hh:mm A')}
            </div>
          </Col>
        </Grid>
      </Cell>
      <Cell>
        <Grid container spacing={2}>
          <Col className="flex1">
            <Row className={classes.textBold}>
              <PersonIcon className={classes.leftIcon} />
              {shipment.customer.name}
            </Row>
            <Row className={classes.textBold}>
              <HomeIcon className={classes.leftIcon} />
              {shipment.dropoff_address.street}, {shipment.dropoff_address.city}, {shipment.dropoff_address.state}{' '}
              {shipment.dropoff_address.zipcode}
            </Row>
          </Col>
          <Col className="start">
            <Link
              target="_blank"
              href={`${process.env.REACT_APP_TRACKING_CODE_URL}/${get(shipment, 'tracking_code', '')}`}
              className={classes.trackingCode}
            >
              {get(shipment, 'tracking_code', '')}
            </Link>
          </Col>
        </Grid>
      </Cell>
      <Cell>
        <Grid container spacing={2}>
          <Col className="flex1">
            <Row className={classes.textBold}>
              <PersonIcon className={classes.leftIcon} />
              {get(doc, 'driverName', '-')}
            </Row>
            <Row className={classes.textBold}>
              <PhoneRoundedIcon className={classes.leftIcon} />
              {get(driver, 'phone_number', '-')}
            </Row>
          </Col>
          <Col className="start">
            <Text>{get(doc, 'label', '-')}</Text>
          </Col>
        </Grid>
      </Cell>
      <Cell className="rounded" style={{ color: ColorStatus[status] }}>
        <Grid container spacing={2}>
          <Col className="start flex1">
            <div style={{ color: ColorStatus[status] }}>{status}</div>
            <Text style={{ marginTop: '10px' }}> {get(dropoff, 'remark', '-')}</Text>
          </Col>
          <Col>
            {dropoff && dropoff.actual_departure_ts ? (
              <div style={{ color: ColorStatus[status] }}>@{moment(dropoff.actual_departure_ts).format('hh:mm A')}</div>
            ) : (
              '-'
            )}
            <div className={classes.textRight}>
              <IconBtn
                size="small"
                disabled={!pod.signatures || pod.signatures.length === 0}
                onClick={() => {
                  console.log('hello');
                  setPopupData(pod.signatures);
                }}
              >
                <EditIcon className={classes.icon} />
              </IconBtn>
              <IconBtn
                size="small"
                disabled={!pod.images || pod.images.length === 0}
                onClick={(e) => {
                  e.stopPropagation();
                  setPopupData(pod.images);
                  console.log('hello', pod.images);
                }}
              >
                <CameraIcon className={classes.icon} />
              </IconBtn>
            </div>
          </Col>
        </Grid>
      </Cell>
    </TableRowCustom>
  );
};
export default React.memo(SearchRow);
