import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as M from '@material-ui/core';
import * as S from './styles';
import DropdownTableList from "../AxlDropdown";
import AxlInput from "../AxlInput";
import AxlCheckbox from "../AxlCheckbox";
import FilterTableList from "../FilterTableList";
import {useRecoilState} from "recoil/dist";
import {
  CallSessionListFilters,
  CallSessionListParams,
  CallSessionListSelector,
  ClientListSelector,
  DispatcherListSelector
} from "../../states/callCenterStates";
import moment from "moment-timezone";
import { useHistory } from 'react-router-dom';
import {SHIPMENT_STATUS_TO_COLORS} from "../../constants/shipment";
import AxlPagination from "../AxlPagination";
import Pagination from "@material-ui/lab/Pagination";
import ClientFilterTableList from "../ClientFilterTableList";
import _ from "lodash";
import {EmptyTable} from "../AxlTable";

const useStyles = makeStyles({
  table: {
    minWidth: 960,
  },
});

function createData(call_session, call_from, call_to, call_args, time, handle_by, customer_name, client, shipment_id, tracking_status) {
  return { call_session, call_from, call_to, call_args, time, handle_by, customer_name, client, shipment_id, tracking_status };
}

export default function CallHistoryTableList({loading, clientLoading}) {
  const history = useHistory();
  const classes = useStyles();
  // Local state
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  // Global state
  const [callSessionList, setCallSessionList] = useRecoilState(CallSessionListSelector);
  const [searchFilters, setSearchFilters] = useRecoilState(CallSessionListFilters);
  const [clientList, setClientList] = useRecoilState(ClientListSelector);
  const [dispatcherList, setDispatcherList] = useRecoilState(DispatcherListSelector);
  const [callSessionListBody, setCallSessionListBody] = useRecoilState(CallSessionListParams);
  // Variables
  const rows = callSessionList.items.length && callSessionList.items.map(r => createData(
    r?.external_id || null,
    r?.call_from || null,
    r?.call_to || null,
    r?.call_args || null,
    r?.start_ts ? moment(r?.start_ts).tz(r?.agent_user_info?.timezone || 'America/Los_Angeles').format('MM/DD/YYYY HH:mm') : '-',
    r?.agent_user_info?.nickname || ((r?.agent_user_info?.first_name && r?.agent_user_info?.last_name) && [r?.agent_user_info?.first_name, r?.agent_user_info?.last_name].join(" ")) || '-',
    r?.attr?.customer_name || '-',
    r?.attr?.client_logo_url || '-',
    r?.attr?.shipment_id || '-',
    r?.attr?.shipment_status || null
  )) || [];

  const handleChangePagination = (object, number) => {
    let params = new URLSearchParams();
    const filters = Object.assign({}, searchFilters, {page_number: number});
    Object.keys(filters).map(t => {
      params.append(t, filters[t]);
    });
    setSearchFilters(filters)
  };

  const onFilterByClient = (clients) => {
    const q = {
      ...(clients.length ? {client_ids: clients.map(client => client.value)} : {client_ids: []})
    };
    const filters = _.assign({}, callSessionListBody, q);
    setCallSessionListBody(filters);
  };
  const onFilterByHandler = (handlers) => {
    const q = {
      ...(handlers.length ? {call_agent_ids: handlers.map(handler => handler.value)} : {call_agent_ids: []})
    };
    const filters = _.assign({}, callSessionListBody, q);
    setCallSessionListBody(filters);
  };

  const selectItem = (item) => {
    if(item) {
      const params = new URLSearchParams();
            if(item.call_to || item.call_to !== 'undefined') params.append('call_to', item.call_to);
            if(item.call_from || item.call_from !== 'undefined') params.append('call_from', item.call_from);
            if(item.call_args || item.call_args !== 'undefined') params.append('call_args', item.call_args);
            if(item.call_session || item.call_session !== 'undefined') params.append('call_session_id', item.call_session);

      window.open(`/call-center/results?${params}`);
    }
  };

  return (
    <M.Box bgcolor={'primary.main'} height={1} style={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
      <M.TableContainer height={1} component={M.Box} bgcolor={'primary.main'}>
        <S.Table stickyHeader aria-label="Call History Table List">
          <S.TableHead>
            <M.TableRow>
              <S.TableCell width={250} bgcolor={'primary.blueEyes'}>{`Call session`}</S.TableCell>
              <S.TableCell bgcolor={'primary.blueEyes'}>{`Time`}</S.TableCell>
              <S.TableCell bgcolor={'primary.blueEyes'}>
                <DropdownTableList trigger={<S.HeaderButton> {`Handled by`} <S.IconArrowDropDown fontSize={'small'} /></S.HeaderButton>}>
                  {!!dispatcherList.length && <ClientFilterTableList multiple onChange={onFilterByHandler} items={dispatcherList} />}
                </DropdownTableList>
              </S.TableCell>
              <S.TableCell width={120}>{`Customer Name`}</S.TableCell>
              <S.TableCell>
                <DropdownTableList trigger={<S.HeaderButton> {`Client`} <S.IconArrowDropDown fontSize={'small'} /></S.HeaderButton>}>
                  {!clientLoading ? (!!clientList.length ? <ClientFilterTableList multiple onChange={onFilterByClient} items={clientList} /> : <M.Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={200} height={75}>{`No Clients`}</M.Box>) :
                    <M.Box width={200} height={75} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      <M.CircularProgress color={'inherit'} size={50} thickness={0.5} />
                    </M.Box>}
                </DropdownTableList>
              </S.TableCell>
              <S.TableCell>{`Shipment ID`}</S.TableCell>
              <S.TableCell>{`Tracking Status`}</S.TableCell>
            </M.TableRow>
          </S.TableHead>
          <M.TableBody>
            {rows.length ? rows.map((row) => (
              <S.TableRow
                onClick={() => selectItem(row)}
                key={row.name}>
                <S.TableCell bgcolor={'primary.blueEyes'} component="td" scope="row">{row.call_session}</S.TableCell>
                <S.TableCell bgcolor={'primary.blueEyes'} component="td" scope="row">{row.time}</S.TableCell>
                <S.TableCell bgcolor={'primary.blueEyes'} component="td" scope="row">{row.handle_by}</S.TableCell>
                <S.TableCell component="td" scope="row">{row.customer_name}</S.TableCell>
                <S.TableCell component="td" scope="row"><S.Avatar src={row.client} /></S.TableCell>
                <S.TableCell component="td" scope="row">{row.shipment_id}</S.TableCell>
                <S.TableCell component="td" scope="row">
                  <S.Text color={SHIPMENT_STATUS_TO_COLORS()[row.tracking_status]}>{row.tracking_status || '-'}</S.Text>
                </S.TableCell>
              </S.TableRow>
            )) : <EmptyTable rows={Math.round((window.outerHeight - 300)/ 65)} columns={7} />}
          </M.TableBody>
        </S.Table>
      </M.TableContainer>
      {!!callSessionList.count && <S.PaginationContainer>
        <Pagination
          page={searchFilters.page_number}
          onChange={handleChangePagination}
          count={Math.ceil(callSessionList.count/searchFilters.page_size)} />
      </S.PaginationContainer>}
    </M.Box>
  );
}
