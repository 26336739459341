import { atom, selector, selectorFamily } from 'recoil';
import _ from 'lodash';
import {DROPOFF_TYPE} from "../constants/stop";
import {processAssignmentDetail} from "../utils/assignment";

// List call

export const CallSessionListFilters = atom({
  key: 'callSessionListFilters',
  default: {
    page_number: 1,
    page_size: 12,
  },
});

export const CallSessionListParams = atom({
  key: 'callSessionListParams',
  default:{
    "statuses": [],
    "client_ids": [],
    "call_agent_ids": [],
    "categories": []
  }
});

export const CallSessionListParamsSelector = selector({
  key: 'callSessionListParamsSelector',
  get: ({get}) => get(CallSessionListParams),
  set: ({get, set}, newValue) => set(CallSessionListParams, newValue),
})

export const CallSessionListState = atom({
  key: 'callSessionListState',
  default: {
    count: 0,
    items: [],
    page: 1,
    size: 12,
  },
});

export const CallSessionListSelector = selector({
  key: 'callSessionListSelector',
  get: ({get}) => get(CallSessionListState),
  set: ({get, set}, newValue) => set(CallSessionListState, newValue),
});

// Call Session

export const CallSessionState = atom({
  key: 'callSessionState',
  default: {},
});

// Assignment current
export const AssignmentState = atom({
  key: 'assignmentState',
  defaul: null,
});

export const AssignmentSelectedState = selector({
  key: 'assignmentSelectedState',
  get: ({get}) => get(AssignmentState),
  set: ({get, set}, newAssignmentState) => {
    const assignment = processAssignmentDetail(newAssignmentState);
    set(AssignmentState, assignment);
  }
});

export const AssignmentNoteState = atom({
  key: 'assignmentNoteState',
  defaul: null,
});

export const AssignmentNoteSelectedState = selector({
  key: 'assignmentNoteSelectedState',
  get: ({get}) => get(AssignmentNoteState),
  set: ({get, set}, newAssignmentNoteState) => set(AssignmentNoteState, newAssignmentNoteState)
});

// Shipment current
export const ShipmentState = atom({
  key: 'shipmentState',
  defaul: null,
});

export const ShipmentSelectedState = selector({
  key: 'shipmentSelectedState',
  get: ({get}) => get(ShipmentState),
  set: ({get, set}, newShipmentState) => set(ShipmentState, newShipmentState)
});

export const ShipmentLabelState = atom({
  key: 'shipmentLabelState',
  defaul: null,
});

export const ShipmentLabelSelectedState = selector({
  key: 'shipmentLabelSelectedState',
  get: ({get}) => {
    const assignment = get(AssignmentState);
    const shipmentSelected = get(ShipmentSelectedState);
    if(assignment && shipmentSelected) {
      return assignment.shipmentLabels.filter(s => s.shipment_id === shipmentSelected.id)[0];
    } else return null;
  },
  set: ({get, set}, newValue) => set(ShipmentLabelState, newValue)
});

export const ShipmentNoteState = atom({
  key: 'shipmentNoteState',
  defaul: null,
});

export const ShipmentNoteSelectedState = selector({
  key: 'shipmentNoteSelectedState',
  get: ({get}) => get(ShipmentNoteState),
  set: ({get, set}, newShipmentNoteState) => set(ShipmentNoteState, newShipmentNoteState)
});

export const CustomerShipmentState = atom({
  key: 'customerShipmentState',
  defaul: null,
});

export const CustomerShipmentSelectedState = selector({
  key: 'customerShipmentSelectedState',
  get: ({get}) => get(CustomerShipmentState),
  set: ({get, set}, newValue) => set(CustomerShipmentState, newValue)
});

// Search Shipment

export const ShipmentSearchResultState = atom({
  key: 'shipmentSearchResultState',
  defaul: [],
});

export const ShipmentSearchResultSelector = selector({
  key: 'shipmentSearchResultSelector',
  get: ({get}) => get(ShipmentSearchResultState),
  set: ({get, set}, newValue) => set(ShipmentSearchResultState, newValue)
});


// Client current
export const ClientState = atom({
  key: 'clientState',
  default: null,
});

export const ClientSelectedState = selector({
  key: 'clientSelectedState',
  get: ({get}) => get(ClientState),
  set: async ({get, set}, newSClientState) => {
    set(ClientState, newSClientState);
  }
});

// Client list

export const ClientListState = atom({
  key: 'clientListState',
  default: [],
});

export const ClientListSelector = selector({
  key: 'clientListSelector',
  get: ({get}) => get(ClientListState),
  set: async ({get, set}, newValue) => {
    set(ClientListState, newValue);
  }
});

// Dispatcher list

export const DispatcherListState = atom({
  key: 'dispatcherListState',
  default: [],
});

export const DispatcherListSelector = selector({
  key: 'dispatcherListSelector',
  get: ({get}) => get(DispatcherListState),
  set: async ({get, set}, newValue) => {
    set(DispatcherListState, newValue);
  }
});

// Clients of Assignment list
export const AssignmentClientState = atom({
  key: 'assignmentClientState',
  default: null,
});

export const AssignmentClientSelector = selector({
  key: 'assignmentClientSelector',
  get: ({get}) => {
    const assignment = get(AssignmentState)
    const clients = (assignment && assignment.clients && assignment.clients.length) && assignment.clients || [];

    return clients;
  },
  set: async ({get, set}, newValue) => set(AssignmentClientState, newValue)
});

// Stop current
export const DropoffState = atom({
  key: 'dropoffState',
  default: null,
});

export const DropoffSelectedState = selector({
  key: 'dropoffSelectedState',
  get: ({get}) => {
    const assignment = get(AssignmentState);
    const shipment = get(ShipmentState);

    return (assignment && shipment) && assignment.stops.filter(stop => (stop.shipment_id === shipment.id) && (stop.type === DROPOFF_TYPE.DROP_OFF)).pop() || null;
  },
  set: async ({get, set}, newDropoffState) => {
    set(DropoffState, newDropoffState);
  }
});

// Stops
export const DropoffsState = atom({
  key: 'dropoffsState',
  default: [],
});

export const DropoffsSelectedState = selector({
  key: 'dropoffsSelectedState',
  get: ({get}) => {
    let stops = [];
    // Attachment shipment/label to on each stop.
    const assignment = get(AssignmentState);
    const shipments = assignment && assignment.shipments;
    const shipmentLabels = assignment && assignment.shipmentLabels;
    stops = (assignment && shipments.length && shipmentLabels.length) && assignment.stops.map(stop => {
      // check dropoff type
      if([DROPOFF_TYPE.DROP_OFF, DROPOFF_TYPE.PICK_UP].indexOf(stop.type) !== -1) {
        const shipmentSelected = shipments.filter(shipment => shipment.id === stop.shipment_id).pop();
        const shipmentLabelSelected = shipmentLabels.filter(shipment => shipment.dropoff_stop_id === stop.id).pop();
        return({
          ...stop,
          ...(!!shipmentSelected ? {shipment: shipmentSelected} : {}),
          ...(!!shipmentLabelSelected ? {
            label: {
              driver_label: shipmentLabelSelected.driver_label || 0
            },
          } : {})
        });
      }
    }).filter(s => (s && s.shipment)) || [];

    return stops;
  },
  set: async ({get, set}, newDropoffsState) => {
    set(DropoffsState, newDropoffsState);
  }
});

// POD of current shipment
export const DropoffPODState = atom({
  key: 'dropoffPODState',
  default: [],
});

export const DropoffPODSelectedState = selector({
  key: 'dropoffPODSelectedState',
  get: ({get}) => get(DropoffPODState),
  set: async ({get, set}, newValue) => {
    set(DropoffPODState, newValue);
  }
});

// POD of pin

export const CurrentSearchShipmentState = atom({
  key: 'currentSearchShipmentState',
  default: []
});

export const CurrentSearchShipmentSelector = selector({
  key: 'currentSearchShipmentSelector',
  get: ({get}) => get(CurrentSearchShipmentState),
  set: ({get, set}, newValue) => set(CurrentSearchShipmentState, newValue)
})

// Driver
export const DriverState = atom({
  key: 'driverState',
  default: null,
});

export const DriverSelectedState = selector({
  key: 'driverSelectedState',
  get: ({get}) => {
    const assignment = get(AssignmentState);
    if(assignment && assignment.driver) {
      return assignment.driver;
    }
  },
  set: ({get, set}, newDriverState) => set(DriverState, newDriverState)
});

// Event
export const EventOutboundState = atom({
  key: 'eventOutboundState',
  default: [],
});

export const EventOutboundSelectedState = selector({
  key: 'eventOutboundSelectedState',
  get: ({get}) => get(EventOutboundState),
  set: ({get, set}, newEventOutboundState) => set(EventOutboundState, newEventOutboundState)
});

// Event for call logs

export const EventCallLogsState = atom({
  key: 'eventCallLogsState',
  default: [],
});

export const EventCallLogsStateSelector = selector({
  key: 'eventOutboundSelectedState',
  get: ({get}) => get(EventCallLogsState),
  set: ({get, set}, newValue) => set(EventCallLogsState, newValue)
});

// Dropoff Edit Form
export const DropoffEditFormState = atom({
  key: 'dropoffEditFormState',
  default: null,
});

export const DropoffEditFormSelector = selector({
  key: 'dropoffEditFormSelector',
  get: ({get}) => {
    return Object.assign({}, get(ShipmentState), get(DropoffEditFormState))
  },
  set: ({get, set}, newValue) => set(DropoffEditFormState, newValue)
});

// Shipment Note Edit Form
export const ShipmentNoteEditFormState = atom({
  key: 'shipmentNoteEditFormState',
  default: null,
});

export const ShipmentNoteEditFormSelector = selector({
  key: 'shipmentNoteEditFormSelector',
  get: ({get}) => Object.assign({}, get(ShipmentNoteState) && get(ShipmentNoteState)[0] || {}, get(ShipmentNoteEditFormState)),
  set: ({get, set}, newValue) => set(ShipmentNoteEditFormState, newValue)
});

export const AssignmentNoteEditFormState = atom({
  key: 'assignmentNoteEditFormState',
  default: null,
});

export const AssignmentNoteEditFormSelector = selector({
  key: 'assignmentNoteEditFormSelector',
  get: ({get}) => Object.assign({}, get(AssignmentNoteState) && get(AssignmentNoteState)[0] || {}, get(AssignmentNoteEditFormState)),
  set: ({get, set}, newValue) => set(AssignmentNoteEditFormState, newValue)
});

// Rematch shipment

export const RematchShipmentState = atom({
  key: 'rematchShipmentState',
  default: null,
});

export const RematchShipmentSelector = selector({
  key: 'rematchShipmentSelector',
  get: ({get}) => get(RematchShipmentState),
  set: ({get, set}, newValue) => set(RematchShipmentState, newValue)
});

// Conversation

export const ConversationState = atom({
  key: 'conversationState',
  default: null,
});

export const ConverstationSelector = selector({
  key: 'converstationSelector',
  get: ({get}) => get(ConversationState),
  set: ({get, set}, newValue) => set(ConversationState, newValue)
});

// Best Guess Results

export const BestGuessResultFilters = atom({
  key: 'bestGuessResultFilters',
  default: {
    loadmore: false,
    filters: {
      "from": 0,
      "size": 5,
      "q": "",
      "filters": {},
      "sorts": [
        "-dropoff_earliest_ts"
      ]
    }
  },
});

export const BestGuessResultState = atom({
  key: 'bestGuessResultState',
  default: {
    from: 0,
    size: 0,
    total: 0,
    results: []
  },
});

export const BestGuessResultSelector = selector({
  key: 'bestGuessResultSelector',
  get: ({get}) => get(BestGuessResultState),
  set: ({get, set}, newValue) => set(BestGuessResultState, newValue)
});

export const BestGuessResultByDateFilters = atom({
  key: 'bestGuessResultByDateFilters',
  default: BestGuessResultFilters,
});

export const BestGuessResultByDateState = atom({
  key: 'bestGuessResultByDateState',
  default: BestGuessResultState,
});

export const BestGuessResultByDateSelector = selector({
  key: 'bestGuessResultByDateSelector',
  get: ({get}) => get(BestGuessResultByDateState),
  set: ({get, set}, newValue) => set(BestGuessResultByDateState, newValue)
});

// No Guess

export const NoGuessResultFilters = atom({
  key: 'noGuessResultFilters',
  default: {
    "from": 0,
    "size": 5,
    "q": "",
    "filters": {},
    "sorts": [
      "-dropoff_earliest_ts"
    ]
  },
});

export const NoGuessResultState = atom({
  key: 'noGuessResultState',
  default: null,
});

export const NoGuessResultSelector = selector({
  key: 'noGuessResultSelector',
  get: ({get}) => get(NoGuessResultState),
  set: ({get, set}, newValue) => set(NoGuessResultState, newValue)
});

export const NoGuessSearchResultState = atom({
  key: 'noGuessSearchResultState',
  default: [],
});

export const NoGuessSearchResultSeletor = selector({
  key: 'noGuessSearchResultSeletor',
  get: ({get}) => get(NoGuessSearchResultState),
  set: ({get, set}, newValue) => set(NoGuessSearchResultState, newValue)
});
