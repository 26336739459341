import { create } from 'apisauce';

const api = create({
  baseURL: process.env.REACT_APP_API_DATAORCH_ROOT,
  withCredentials: true,
});

export const getShipment = (id) => api.get(`shipments/${id}?customer_request_included=true`);
export const getClient = (id) => api.get(`clients/${id}`);
export const updateDropOffAddress = (id, data) => api.put(`/shipments/${id}/dropoff-address`, data);
export const updateDropOffNote = (id, data) => api.put(`/shipments/${id}/dropoff-note`, data);
export const updateDropOffLocation = (id, data) => api.put(`/shipments/${id}/dropoff-location-info`, data);
export const getShipmentNotes = (id) => api.get(`/notes/SH_${id}`);
export const updateShipmentNotes = (id, data) => api.put(`/notes/SH_${id}`, data);
export const saveShipmentNotes = (id, data) => api.post(`/notes/SH_${id}`, data);
export const getAssignmentNotes = (id) => api.get(`/notes/AS_${id}`);
export const updateAssignmentNotes = (id, data) => api.put(`/notes/AS_${id}`, data);
export const saveAssignmentNotes = (id, data) => api.post(`/notes/AS_${id}`, data);
export const getDropOffPOD = (id) => api.get(`stops/${id}/pod`);
export const getAssignment = (id) => api.get(`/assignments/${id}/detail?show_soft_deleted=true`);
export const getAssignmentLocation = (id) => api.get(`/assignments/${id}/locations`);
export const searchShipment = (params) => api.post('/shipments/search', params);
export const getFeedback = (id) => api.get(`/stops/${id}/get-feedback`);
export const addFeedback = (id, params) => api.post(`/stops/${id}/add-feedback`, params);

export default api;
