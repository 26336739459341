import * as M from '@material-ui/core';

export const Container = M.styled(M.Box)(({theme}) => ({
  width: 450,
  maxWidth: '100%',
}));
export const Inner = M.styled(M.Box)(({theme}) => ({}));
export const GroupControl = M.styled(M.Box)(({theme}) => ({
  marginBottom: theme.spacing(1.5),
}));
export const Controls = M.styled(M.Box)(({theme}) => ({}));

export const Text = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 14,
  letterSpacing: -0.22,
  color: theme.palette.primary.blackSecondary,
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(1.5),
  wordBreak: 'break-all',
}));

export const Text_2 = M.styled(Text)(({theme}) => ({
  marginBottom: 0,
}));

export const Title = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-Bold',
  fontSize: 18,
  fontWeight: 'bold',
  letterSpacing: -0.22,
  color: theme.palette.primary['greyish-brown'],
  marginBottom: theme.spacing(2),
}));

export const SectionTitle = M.styled(Title)(({theme}) => ({
  fontSize: 15,
  fontFamily: 'AvenirNext-DemiBold',
  color: theme.palette.primary['greyish-brown'],
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
}));

export const Label = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13,
  color: theme.palette.primary.grayNinth,
  marginBottom: theme.spacing(1),
}));

export const Label_1 = M.styled(Label)(({theme}) => ({
  fontSize: 11,
}));

export const Label_2 = M.styled(Label)(({theme}) => ({
  fontSize: 15,
  color: theme.palette.primary.graySecond,
}));


