import React, {useEffect, useState} from 'react';
import * as M from '@material-ui/core';
import * as S from './styles';
import {mainTheme} from "./styles";
import FilterTableList from "../FilterTableList";
import DropdownTableList from "../AxlDropdown";
import {useRecoilState} from "recoil/dist";
import {
  RematchShipmentSelector, ShipmentSelectedState,
  CallSessionState, NoGuessResultSelector, BestGuessResultByDateFilters, NoGuessResultFilters
} from "../../states/callCenterStates";
import _ from 'lodash';
import moment from "moment-timezone";
import api from "../../repositories/Api";
import AxlButton from "../Button";
import AxlModal from "../AxlModal";
import AxlConfirmation from "../AxlConfirmation";
import ClientFilterTableList from "../ClientFilterTableList";

export default function NoGuessTableList({theme = mainTheme, loading = false,  ...props}) {
  // Local states
  const [items, setItems] = useState([]);
  const [rematching, setRematching] = useState({});
  const [isClose, setClose] = useState({});
  const [clients, setClients] = useState([]);
  const [isClientLoading, setClientLoading] = useState(false);
  // Global states
  const [callSession, setCallSesison] = useRecoilState(CallSessionState);
  const [shipmentRematch, setShipmetRematch] = useRecoilState(RematchShipmentSelector);
  const [shipmentSelected, setShipmentSelected] = useRecoilState(ShipmentSelectedState);
  const [shipmentNoGuess, setShipmentNoGuess] = useRecoilState(NoGuessResultSelector);
  const [searchFilter, setSearchFilter] = useRecoilState(NoGuessResultFilters);

  const selectItem = (item) => {
    if(Object.values(item).length) {
      if(shipmentRematch && item.shipment.id === shipmentRematch.shipment.id) {
        setShipmetRematch(null);
        setShipmentSelected(null);
        setShipmentNoGuess(null);
      } else {
        setShipmetRematch(item);
        setShipmentSelected(item.shipment);
        setShipmentNoGuess(item.shipment);
      }
    }
  };

  const rematch = (item) => {
    if(!(item && item.shipment) || !callSession) return;

    const params = {
      attr: {
        ...(item.shipment ? {shipment_id: item.shipment.id} : {}),
        ...(item.shipment ? {assignment_id: item.shipment.assignment_id} : {}),
        ...(item.driver ? {driver_id: item.driver.id} : {}),
      }
    };
    setRematching({[item.shipment.id]: true});
    api.patch(`call_center/${callSession.id}/attr`, params).then(res => {
      if(res.status === 200 && res.data) {
        setCallSesison(res.data);
      }
      setRematching({[item.shipment.id]: false});
    })
  };

  const loadClients = () => {
    if(!clients.length) {
      setClientLoading(true);
      api.get(`clients?size=200`).then(res => {
        if(res.status === 200 && res.data) {
          let clients = res.data.map(client => ({
            name: client.name,
            value: client.id
          }));
          setClients(res.data);
        }
        setClientLoading(false);
      })
    }
  };

  const onFilterByClient = (clients) => {
    const q = {
      filters: {
      ...(clients.length ? {client_id: clients.map(client => client.id).join(",")} : {})
      }
    };
    const filters = _.assign({}, searchFilter, q);
    setSearchFilter(filters)
  }

  useEffect(() => {
    if(props.items && props.items.length === 5) {
      setItems(props.items);
    } else {
      const arr = Array.from(Array(8 - props.items.length), () => ({}));
      var items = props.items;
          items = items.concat(arr);
          setItems(items);
    }
  }, [props.items]);

  return(<M.ThemeProvider theme={theme}>
    <M.Box position={'relative'} mn={2}>
      <M.TableContainer>
        <M.Table>
          <M.TableHead>
            <M.TableRow>
              <M.TableCell width={65}>
                <DropdownTableList
                  onOpen={loadClients}
                  trigger={<div> {`CLIENT`} <S.IconArrowDropDown fontSize={'small'} /> </div>}>
                  {!isClientLoading ? (!!clients.length ? <ClientFilterTableList multiple onChange={onFilterByClient} items={clients} /> : <M.Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={200} height={75}>{`No Clients`}</M.Box>) :
                    <M.Box width={200} height={75} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      <M.CircularProgress color={'inherit'} size={50} thickness={0.5} />
                    </M.Box>}
                </DropdownTableList>
              </M.TableCell>
              <M.TableCell>{`CUSTOMER NAME`}</M.TableCell>
              <M.TableCell width={120}>{`PHONE NUMBER`}</M.TableCell>
              <M.TableCell>{`DELIVERY ADDRESS`}</M.TableCell>
              <M.TableCell width={85}>{`DELIVERY DATE`}</M.TableCell>
              <M.TableCell width={120} />
            </M.TableRow>
          </M.TableHead>
          <M.TableBody>
            {items.length ? items.map((item, id) => <M.TableRow
              key={id}
              hover={shipmentRematch && item}
              onClick={() => selectItem(item)}
              selected={shipmentRematch && shipmentRematch.shipment && item && item.shipment && shipmentRematch.shipment.id === item.shipment.id}>
              <M.TableCell>
                {item.client ?
                  item.client.logo_url ?
                    <M.Avatar src={item.client.logo_url} styles={{width: 78, height: 78}} /> :
                    <M.Avatar styles={{width: 78, height: 78}}>{item.client.name && item.client.name.split()[0] || 'Avatar'}</M.Avatar> :
                  '-'
                }
              </M.TableCell>
              <M.TableCell>{_.get(item, 'shipment.customer.name', '-')}</M.TableCell>
              <M.TableCell>{_.get(item, 'shipment.customer.phone_number', '-')}</M.TableCell>
              <M.TableCell>
                {
                  (item.shipment && item.shipment.dropoff_address) ?
                    `${_.get(item, 'shipment.dropoff_address.street', '')}, ${_.get(item, 'shipment.dropoff_address.city', '')}, ${_.get(item, 'shipment.dropoff_address.state', '')}, ${_.get(item, 'shipment.dropoff_address.zipcode', '')}` :
                    '-'
                }
              </M.TableCell>
              <M.TableCell>{item.shipment && item.shipment.dropoff_latest_ts && moment(item.shipment.dropoff_latest_ts).format('MM/DD/YYYY') || '-'}</M.TableCell>
              <M.TableCell>
                {item && item.shipment && <AxlModal
                  isClose={isClose['CLOSE_MATCH_CALL']}
                  onRendered={() => setClose({['CLOSE_MATCH_CALL']: false})}
                  trigger={<AxlButton
                    padding={'5px 20px'}
                    spacing={1}
                    bgcolor={'primary.periwinkle'}
                    color={'primary.main'}
                    variant="contained"
                    disableElevation><S.TextRematch>{`Match call`}</S.TextRematch></AxlButton>}>
                  <AxlConfirmation
                    loading={rematching[item.shipment.id]} disabled={rematching[item.shipment.id]}
                    subTitle={"Are you sure to match this call session to selected shipment ?"}
                    onClose={() => setClose({['CLOSE_MATCH_CALL']: true})} closeText={'Cancel'}
                    onDo={() => rematch(item)} doText={'Match'} />
                </AxlModal>}
              </M.TableCell>
            </M.TableRow>) : <EmptyTable/>}
          </M.TableBody>
        </M.Table>
      </M.TableContainer>
      {loading && <S.Overlay>
        <M.CircularProgress size={75} thickness={0.5} color={'inherit'} />
      </S.Overlay>}
    </M.Box>
  </M.ThemeProvider>)
}

export function EmptyTable() {
  return Array.from(Array(8), (item, id) => <M.TableRow>
    {Array.from(Array(5), (item, id) => <M.TableCell>{``}</M.TableCell>)}
  </M.TableRow>)
}