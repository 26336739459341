import React, {useState} from 'react';
import Rating from 'react-rating';
import { AxlPanel, AxlCheckbox, AxlTextArea, AxlButton } from 'axl-reactjs-ui';
import {IconButton, Box} from "@material-ui/core";
import {ThumbDown as ThumbDownIcon, ThumbUp as ThumbUpIcon} from "@material-ui/icons";

import styles, * as E from './styles';
import _ from "lodash";

export function ShipmentFeedback(props) {
  const [data, setData] = useState(props.data || {});
  const tagValues = [
    "Late due to Driver",
    "Inapropriate behavior",
    "Confirmed delivery",
    "Damaged - AxleHire",
    "Instructions not followed",
    "Delivered to wrong address",
    "Unconfirmed delivery"
  ];

  const handleAddFeedBack = (e) => {
    const {addFeedback, selectedStop, toggleFeedback, addingFeedback} = props;

    addFeedback(data);
  };

  const getField = (name, defautValue) => {
    return _.get(data, name, defautValue);
  };

  const handlerRating = (name) => (value) => {
    let _data = _.assign({}, data);
    _.set(_data, name, value);
    setData(_data);
  };

  const arrayContains = (name, value) => {
    return _.get(data, name, []).includes(value);
  };

  const handlerTextarea = (event) => {
    if (event.target && event.target.name && event.target.value !== undefined) {
      let _data = _.assign({}, data);
      const v = event.target.name.endsWith('variants') ? event.target.value.split(',') : event.target.value;
      _.set(_data, event.target.name, v);
      setData(_data);
    }
  };

  const handlerCheckboxes = (event) => {
    const currentValue = getField(event.target.name, []);
    let _data = _.assign({}, data);
    if (event.target.checked === true && !currentValue.includes(event.target.value)) {
      currentValue.push(event.target.value);
      _.set(_data, event.target.name, currentValue)
    } else if (event.target.checked === false && currentValue.includes(event.target.value)) {
      _.remove(currentValue, function(str) {
        return str === event.target.value;
      });
      _.set(_data, event.target.name, currentValue);
    }
    setData(_data);
  };

  // Display
  const thumb = getField('thumb', null);

  return <E.Container>
    <E.Inner>
      <E.Row>
        <E.Label>{`Rate:`}</E.Label>
        <E.Content>
          {/*<Rating initialRating={getField('rating', 0)} onChange={handlerRating('rating')} emptySymbol={<img src="/assets/images/star-periwinkle-empty.png" />} fullSymbol={<img src="/assets/images/star-periwinkle-full.png" />} />*/}
          <Box component="span" px={1}>
            <IconButton size="small" onClick={() => handlerRating('thumb')(true)}>
              <ThumbUpIcon fontSize="small" style={{color: !!thumb ? '#4abc4e' : undefined}} />
            </IconButton>
          </Box>
          <Box component="span" px={1}>
            <IconButton size="small" onClick={() => handlerRating('thumb')(false)}>
              <ThumbDownIcon fontSize="small" style={{color: thumb === false ? '#d0021b' : undefined}} />
            </IconButton>
          </Box>
        </E.Content>
      </E.Row>
      <E.Row>
        <E.Label>{`Tags:`}</E.Label>
        <E.Content>
          <AxlPanel>
            <AxlPanel.Row>
              <AxlPanel.Col>
                {tagValues.slice(0, 4).map((v, i) => <E.Control key={i}>
                  <AxlCheckbox key={i} checked={arrayContains("tags", v)} onChange={handlerCheckboxes} name='tags' value={v} theme={`main`} color={`white`}>{v}</AxlCheckbox>
                </E.Control>)}
              </AxlPanel.Col>
              <AxlPanel.Col>
                {tagValues.slice(4).map((v, i) => <E.Control key={i} >
                  <AxlCheckbox checked={arrayContains("tags", v)} onChange={handlerCheckboxes} name='tags' value={v} theme={`main`} color={`white`}>{v}</AxlCheckbox>
                </E.Control>)}
              </AxlPanel.Col>
            </AxlPanel.Row>
          </AxlPanel>
        </E.Content>
      </E.Row>
      <E.Row>
        <E.Label>{`Comment:`}</E.Label>
        <E.Content>
          <AxlTextArea onChange={handlerTextarea} name='comment' value={getField('comment', '')} placeholder={`Add comment...`} style={styles.textarea} fluid />
        </E.Content>
      </E.Row>
      <E.Row>
        <E.Label />
        <E.Content>
          <AxlButton loading={props.addingFeedback} onClick={handleAddFeedBack} disabled={thumb === null} compact bg={`bluish`}>{`Add Feedback`}</AxlButton>
          <AxlButton onClick={() => props.onClose && props.onClose() } compact bg={`borBluish`}>{`Cancel`}</AxlButton>
        </E.Content>
      </E.Row>
    </E.Inner>
  </E.Container>
}
