import React, {useState} from 'react';
import * as M from '@material-ui/core';
import * as S from './styles';
import AxlButton from "../Button";
import {TOOLTIP_TITLE} from "../../constants/title";
import LeaveNoteForm from "../LeaveNoteForm";
import AxlModal from "../AxlModal";
import AxlConfirmation from "../AxlConfirmation";
import {CancelShipmentConfirmation, LeaveCallNoteForm} from './forms';
import RematchToOther from "../RematchToOther";
import {useRecoilValue} from "recoil";
import {AssignmentState, CallSessionState, ShipmentSelectedState} from "../../states/callCenterStates";
import api from "../../repositories/Api";
import {userState} from "../../atoms";
import {useRecoilState} from "recoil/dist";
import moment from "moment-timezone";

export default function CallCenterControls(props) {
  const {matchCall} = props;
  // Local states
  const [isClose, setClose] = useState({});
  const [isRematchFormClose, setRematchFormClose] = useState(false);
  const [isCloseSession, setCloseSession] = useState(false);
  const [isCloseNoteForm, setCloseNoteForm] = useState(false);
  // Global states
  const user = useRecoilValue(userState);
  const [callSession, setCallSession] = useRecoilState(CallSessionState);
  const [shipmentSelected, setShipmentSelected] = useRecoilState(ShipmentSelectedState);
  const assignmentSelected = useRecoilValue(AssignmentState);
  // Variable
  const DEFINED = {
    UNKNOWN_DRIVER: 1,
    UNKNOWN_RECIPIENT: 2,
    DRIVER: 8,
    RECIPIENT: 9
  }
  const urlParams = new URLSearchParams(window.location.search);
  const callArgs = callArgsHandle(urlParams.get('call_args'));
  const {call_args, attr} = callSession;
  const agrs = callArgsHandle(call_args) || callArgs;
  const shipmentIdFromSession = (attr && attr.shipment_id) ?
    parseInt(attr.shipment_id) :
    (agrs && [DEFINED.RECIPIENT, DEFINED.UNKNOWN_RECIPIENT].indexOf(agrs[0]) !== -1 && agrs[1]  ||
      (agrs && [DEFINED.RECIPIENT, DEFINED.UNKNOWN_RECIPIENT].indexOf(agrs[0]) !== -1 && agrs[1]) || null);

  const close = () => setClose(true);

  const open = () => setClose(false);

  const closeSession = (callSession) => {
    if(!callSession) return;
    setCloseSession(true);

    api.put(`callcenter/${callSession.id}/close`).then(res => {
      setCloseSession(false);
      setClose(true);
      if(res.status === 200 && res.data) {
        setCallSession(res.data)
      }
    });
  };

  function callArgsHandle(value) {
    if(!value) return null;

    return value.split("|").map(a => parseInt(a));
  }

  // Display
  const isShowCancel = !!callSession?.attr?.shipment_id && !!shipmentSelected && !(shipmentSelected.is_cancelled || shipmentSelected.status === 'DROPOFF_SUCCEEDED');
  const isEdit = !!(callSession.notes && user.user && callSession.notes.filter(note => note.subject_user && note.subject_user.id === user.user.id).length);
  const isShowRematch = callSession && callSession.status !== 'CLOSED' && shipmentIdFromSession;
  const isShowNote = !(callSession.notes && callSession.notes.length) || !isEdit;
  const isShowClose = (callSession && callSession.status !== 'CLOSED');

  return(<S.Container>
    <M.Grid container direction={'row'} justify={'flex-end'}>
      {assignmentSelected && <M.Box display={'flex'} alignItems={'center'}>
        <S.Link href={`${process.env.REACT_APP_DISPATCH_URL}/routes/${moment(assignmentSelected.assignment.predicted_end_ts).format('YYYY-MM-DD')}/all/all/${assignmentSelected.assignment.id}`} target={`_blank`}>{`View in Dispatch`}</S.Link>
      </M.Box>}
      {isShowCancel && <AxlModal
        isClose={isClose['CANCEL_SHIPMENT']}
        onRendered={() => setClose({['CANCEL_SHIPMENT']: false})}
        trigger={<AxlButton minWidth={180} padding={"8px 30px"} variant={'text'} bgcolor={'primary.yellowSecondary'} color={'primary.main'}>{`Customer Requests Cancel`}</AxlButton>}>
        {!isClose['CANCEL_SHIPMENT_CONFIRMATION'] ? <AxlConfirmation
          loading={isCloseSession} disabled={isCloseSession}
          subTitle={"Are you sure to cancel this shipment ?"}
          onClose={() => setClose({['CANCEL_SHIPMENT']: true})} closeText={'NO'}
          doProps={{bgcolor: 'primary.yellowSecondary', color: 'primary.main', padding: '6px 30px'}}
          onDo={() => setClose({['CANCEL_SHIPMENT_CONFIRMATION']: true})} doText={'Yes, cancel shipment'} /> :
        <CancelShipmentConfirmation
          onClose={() => setClose({['CANCEL_SHIPMENT']: true})} />}
      </AxlModal>}
      {isShowRematch && <AxlModal
        isClose={isClose['REMATCH']}
        onRendered={() => setClose({['REMATCH']: false})}
        trigger={<AxlButton padding={"8px 20px"} variant={'text'} bgcolor={'primary.greyblue'} color={'primary.main'}>{`Rematch call to another shipment`}</AxlButton>}>
        <RematchToOther onClose={() => setClose({['REMATCH']: true})} />
      </AxlModal>}
      {isShowNote && <AxlModal
        isClose={isClose['LEAVE_NOTE']}
        onRendered={() => setClose({['LEAVE_NOTE']: false})}
        trigger={<AxlButton padding={"8px 20px"} variant={'text'} bgcolor={'primary.greyblue'} color={'primary.main'}>{`Leave Call Note`}</AxlButton>}>
        <LeaveCallNoteForm onClose={() => setClose({['LEAVE_NOTE']: true})} />
      </AxlModal>}
      {isShowClose && <AxlModal
        isClose={isClose['CLOSE_CALL']}
        onRendered={() => setClose({['CLOSE_CALL']: false})}
        trigger={<AxlButton padding={"8px 30px"} variant={'text'} bgcolor={'primary.periwinkleSecondary'} color={'primary.main'}>{`Close Call`}</AxlButton>}>
        <AxlConfirmation
          loading={isCloseSession} disabled={isCloseSession}
          subTitle={"Are you sure to close this call session?"}
          onClose={() => setClose({['CLOSE_CALL']: true})} closeText={'Cancel'}
          onDo={() => closeSession(callSession)} doText={'Close'} />
      </AxlModal>}
    </M.Grid>
  </S.Container>);
}
