import * as M from '@material-ui/core';

export const Container = M.styled(M.Box)(({theme}) => ({
  position: 'relative'
}));
export const Menu = M.withStyles(({palette, spacing}) => ({
  root: {},
  list: {
    padding: 0
  }
}))(M.Menu);