import React from 'react';
import * as M from '@material-ui/core';

export const Container = M.styled(M.Box)(({theme}) => ({}));

export const BottomText = M.styled(M.Box)(({theme}) => ({}));

export const Controls = M.styled(M.Box)(({theme}) => ({}));

export const Image = M.styled(M.Box)(({theme}) => ({}));

export const Text = M.styled(M.Typography)(({theme}) => ({}));
