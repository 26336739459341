import React, {useEffect, useState} from 'react';
import * as M from '@material-ui/core';
import * as S from "./styles";
import {api as db_api, getConversationInfo, getMessages, markReadMessages} from "../../repositories/HttpRepository";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {messagesQueryState, messagesState} from "../../containers/ChatBoxContainer/states";
import ListBoxChat from "../ListBoxChat";
import AxlButton from "../Button";
import {ConverstationSelector, ShipmentSelectedState} from "../../states/callCenterStates";
import { useHistory } from "react-router-dom";

export default function ShipmentChatLog(props) {
  // Local states
  const [loadMsgs, setLoadMsgs] = useState(false);
  // Global states
  const setMessageQuery = useSetRecoilState(messagesQueryState);
  const [messages, setMessages] = useRecoilState(messagesState);
  const shipmentSelected = useRecoilValue(ShipmentSelectedState);
  const [conversation, setConversation] = useRecoilState(ConverstationSelector);
  // Variables
  const history = useHistory();

  useEffect(() => {
    if(shipmentSelected) {
      setLoadMsgs(true);
      getMessages({shipment: true}, shipmentSelected.id).then((res) => {
        if (res.status === 200 && res.data) {
          setMessageQuery(res.data);
          db_api.get(`cs/messages/${shipmentSelected.id}/info?shipment=true`).then(res => {
            if(res.status === 200 && res.data) {
              setConversation(res.data);
            }
          })
        }
        setLoadMsgs(false);
      });
    }
  }, [shipmentSelected]);

  return(<S.Container container wrap={'nowrap'} direction={'column'}>
    <S.Inner item>
      {loadMsgs ? <M.Box width={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <M.CircularProgress color={'secondary'} size={50} thickness={0.5} />
      </M.Box> : (messages && !!messages.length) ? <ListBoxChat listMsg={messages} checkToLoadMore={() => {}} /> : <M.Box py={3} textAlign={'center'}>{`No message`}</M.Box>}
    </S.Inner>
    {conversation && <M.Box textAlign={'center'} py={1}><AxlButton
      variant={`outlined`}
      padding={`1px 7px`}
      color={`primary.brownNight`}
      bgcolor={conversation ? 'primary.main' : 'primary.graySixth'}
      disabled={!conversation}
      onClick={() => history.push(`/messages/${shipmentSelected.id}`)}>
      <S.ButtonText>{`Go to Chat`}</S.ButtonText>
    </AxlButton></M.Box>}
  </S.Container>);
}