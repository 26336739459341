import React from 'react';
import * as M from '@material-ui/core';

export const Container = M.styled(M.Box)(({theme}) => ({
  width: 450,
  maxWidth: '100%',
  margin: 'auto',
  '&:focus': {
    outline: 'none',
  }
}));
export const ContainerInner = M.styled(M.Box)(({theme}) => ({
  // marginBottom: theme.spacing(3),
  '&:focus': {
    outline: 'none',
  }
}));
export const ContainerImage = M.styled(M.Box)(({theme}) => ({
  textAlign: 'center',
  '&:focus': {
    outline: 'none',
  }
}));
export const Image = M.styled('img')(({theme}) => ({
  margin: 'auto',
  width: 'auto',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '100%',
}));
export const ImagePreview = M.styled(M.Box)(({theme}) => ({
  // width: '55px !important',
  height: 80,
  margin: 'auto',
  // paddingLeft: theme.spacing(2),
  // paddingRight: theme.spacing(2),
  // background: theme.palette.primary.grayFourth,
  // display: 'block !important',
  '&:focus': {
    outline: 'none',
  }
}));
export const ContainerBottom = M.styled(M.Box)(({theme}) => ({
  position: 'absolute',
  left: 0,
  bottom: 0,
  backgroundColor: theme.palette.primary.grayThird,
  width: '100%',
  boxSizing: 'border-box',
}));
export const Text = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 13,
  fontWeight: 500,
  color: theme.palette.text.third
}));
export const HL = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 10,
  fontWeight: 500,
  color: theme.palette.primary['greyish-brown'],
  backgroundColor: theme.palette.primary.cream,
  padding: '1px 5px',
  borderRadius: 5,
  verticalAlign: 'center',
  margin: '0 7px',
}));
export const ButtonText = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 11,
  fontWeight: 500,
  color: theme.palette.primary.main,
}));
export const DescText = M.styled(Text)(({theme}) => ({
  fontSize: 10,
  textAlign: 'left',
  color: theme.palette.primary['greyish-brown'],
}));
export const Inner = M.styled(M.Box)(({theme}) => ({
  position: 'relative'
}));
export const Controls = M.styled(M.Box)(({theme}) => ({}));
export const Control = M.styled(M.Box)(({theme}) => ({
  width: 30,
  height: 30,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.grayMain,
  color: theme.palette.primary.gray,
  position: 'absolute',
  top: 0,
  bottom: 0,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,
  cursor: 'pointer'
}));
export const NextControl = M.styled(Control)(({theme}) => ({
  right: 30
}));
export const PrevControl = M.styled(Control)(({theme}) => ({
  left: 30
}));
export const BackButton = M.styled(M.Typography)(({theme}) => ({
  color: theme.palette.primary.link,
  fontFamily: 'AvenirNext-Medium',
  fontSize: 13,
  fontWeight: 500,
  textDecoration: 'underline',
  cursor: 'pointer',
}));
