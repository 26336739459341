import React, { useEffect, Fragment, useState } from 'react';
import * as M from '@material-ui/core';
import * as S from './shipmentHistoryStyles';
import styles, {
  convertStatusToColor,
  Container,
  Inner,
  List,
  Item,
  Circle,
  Date,
  Text,
  RemarkText,
} from './shipmentHistoryStyles';
import { useRecoilValue } from 'recoil';
import moment from 'moment-timezone';
import AxlSelect from '../AxlSelect';
import HistorySearch from '../HistorySearch';
import MapCanvas from '../MapCanvas';
import _ from 'lodash';
import EventObject from './event';

import { historySearchState } from '../DeliveryHistoryList/state';
import AxlMap from "../AxlMap";

export default function DeliveryHistoryList({shipment, stops, events, enableMap = true}) {
  const eventSearchResult = useRecoilValue(historySearchState);
  let date = '';
  let eventToDisplay = events;
  if (eventSearchResult.length > 0) {
    eventToDisplay = eventSearchResult;
  }

  const eventsResult = _.sortBy(eventToDisplay, (e) => e.ts).flatMap((e) => {
    const d = moment(e.ts).format('dddd M/D/YYYY').toUpperCase();
    if (d === date) return [e];
    date = d;
    return [{ signal: 'DATE', date: d }, e];
  });

  return (<S.ContainerList>
    {enableMap && <M.Box m={2} height={200} bgcolor={'primary.whiteTwo'}>
      <MapCanvas stops={stops} shipment={shipment} />
    </M.Box>}
    <M.Box mx={2}>
      <HistorySearch eventsResult={eventsResult} />
    </M.Box>
    <S.ContainerListInner padding={2} my={2}>
      <S.DotLine />
      {eventsResult.map((e, i) => {
        if (e.signal === 'DATE')
          return (
            <Item key={i}>
              <Circle style={{ backgroundColor: convertStatusToColor()[e.state && e.state.status || 'STARTED']}} />
              <Text>{e.date}</Text>
            </Item>
          );
        if (e.action === 'sms') return eventSMS({ e });

        if (e.category === 'SHIPMENT') {
          if (e.type === 'INBOUND') return eventInbound({ e });
          if (e.action === 'update_status') return eventUpdateStatus({ e });
          if (e.action === 'un-route') return eventUnroute({ e });
          if (e.type === 'MODIFIER') return eventModifyShipment({ e });
          return (
            <Item key={e.id}>
              <Circle style={{ backgroundColor: convertStatusToColor()[e.action] }} />
              <Text>
                <div>
                  <EventObject obj={e.subject} /> {e.action} <EventObject obj={e.object} />
                </div>
              </Text>
              <Date>{moment(e.ts).format('hh:mm A')}</Date>
            </Item>
          );
        }
        if (e.category === 'STOP' && e.type === 'OUTBOUND') {
          return eventStopOutboundUpdate({ e });
        }
        if (e.category === 'STOP' && e.type === 'POD') {
          return eventStopPOD({ e });
        }
        if (e.category === 'STOP' && e.type === 'MODIFIER') return eventStopModify({ e });
        return <div key={i}></div>;
      })}
    </S.ContainerListInner>
  </S.ContainerList>);
}

function eventSMS(props) {
  const { e } = props;
  return (
    <Item key={e.id}>
      <Circle style={{ backgroundColor: convertStatusToColor()['STARTED'] }} />
      <Text>
        <div>
          Send SMS -{' '}
          {e.fact && e.fact.sms_type
            ? e.fact.sms_type.replace('RECIPIENT', '').replace(/__/g, ' ').replace(/_/g, '-').toLowerCase()
            : null}
        </div>
        <div style={{ color: 'brown' }}>
          <small>{e.evidence.text}</small>
        </div>
      </Text>
      <Date>{moment(e.ts).format('hh:mm A')}</Date>
    </Item>
  );
}

function eventInbound(props) {
  const { e } = props;
  const { status } = styles;
  return (
    <Item key={e.id}>
      <Circle style={{ backgroundColor: '#259' }} />
      <Text>
        <div>
          {(e.action !== 'update-inbound' || !e.state) && (
            <Fragment>
              <EventObject obj={e.subject} /> {e.action} <EventObject obj={e.object} />
            </Fragment>
          )}
          {e.action === 'update-inbound' && e.state && (
            <Fragment>
              <EventObject obj={e.subject} /> Update Inbound Status of <EventObject obj={e.object} /> to{' '}
              <b style={styles.strong}>{e.state.inbound_status}</b>
              {e.state.indbound_notes ? <RemarkText>{e.state.indbound_notes}</RemarkText> : ''}
            </Fragment>
          )}
        </div>
        {e.event && e.event.location && e.event.location.geolocation && (
          <div>
            At location [ {e.event.location.geolocation.latitude.toFixed(6)}, {e.event.location.geolocation.longitude.toFixed(6)} ]{' '}
            <a
              target="_blank"
              href={`https://www.google.com/maps/search/?api=1&query=${e.event.location.geolocation.latitude.toFixed(
                6,
              )},${e.event.location.geolocation.longitude.toFixed(6)}`}
            >
              Google Map
            </a>
          </div>
        )}
      </Text>
      <Date>{moment(e.ts).format('hh:mm A')}</Date>
    </Item>
  );
}

function eventUpdateStatus(props) {
  const { e } = props;
  const { status } = styles;
  return (
    <Item key={e.id}>
      <Circle style={{ backgroundColor: convertStatusToColor()[e.state.status] }} />
      <Text>
        <div>
          <EventObject obj={e.subject} /> update <EventObject obj={e.object} /> status to{' '}
          <b style={styles.strong}>{e.state.status}</b>
          {e.state.remark ? <RemarkText>{e.state.remark}</RemarkText> : ''}
        </div>
      </Text>
      <Date>{moment(e.ts).format('hh:mm A')}</Date>
    </Item>
  );
}

function eventUnroute(props) {
  const { e } = props;
  const { status } = styles;
  return (
    <Item key={e.id}>
      <Circle style={{ backgroundColor: convertStatusToColor()['STARTED'] }} />
      <Text>
        <div>
          <EventObject obj={e.subject} /> un-route <EventObject obj={e.object} /> from <EventObject obj={e.ref} />, with
          label <b style={styles.strong}>{e.fact.label}</b>
        </div>
      </Text>
      <Date>{moment(e.ts).format('hh:mm A')}</Date>
    </Item>
  );
}

function eventStopOutboundUpdate(props) {
  const { e } = props;
  const { status } = styles;

  let latitude = null,
    longitude = null;
  if (e.event && e.event.location && e.event.location.geolocation) {
    latitude = e.event.location.geolocation.latitude.toFixed(6);
    longitude = e.event.location.geolocation.longitude.toFixed(6);
  }
  return (
    <Item key={e.id}>
      <Circle style={{ backgroundColor: convertStatusToColor()[e.state.status] }} />
      <Text>
        <div>
          <EventObject obj={e.subject} /> {e.action.replace('_', ' ')} <EventObject obj={e.object} />
          {e.action !== 'reattempt' && (
            <span>
              {' '}
              status to <b style={styles.strong}>{e.state.status}</b>
            </span>
          )}
          {e.action === 'reattempt' && <span> from Stop {e.fact.previous_stop_id} </span>}
          {e.state.remark ? <RemarkText>{e.state.remark}</RemarkText> : ''}
        </div>
        {latitude && (
          <div style={styles.updateStopWrap}>
            At location
            <div
              style={styles.coordinatesWrap}
              // onMouseEnter={() => { locationStore.updateLocation([longitude, latitude])}}
              // onMouseLeave={() => { locationStore.updateLocation(null) }}
            >
              [ {latitude}, {longitude} ]
            </div>{' '}
            <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}>
              Google Map
            </a>
          </div>
        )}
      </Text>
      <Date>{moment(e.ts).format('hh:mm A')}</Date>
    </Item>
  );
}

function eventStopModify(props) {
  const { e } = props;
  const { status } = styles;
  if (!e.state) return <div></div>;
  const keys = Object.keys(e.state);
  return (
    <Item key={e.id}>
      <Circle style={{ backgroundColor: convertStatusToColor()[e.state.status] }} />
      <Text>
        <div>
          <EventObject obj={e.subject} /> {e.action.replace('_', ' ')} <EventObject obj={e.object} />
        </div>
        {keys.map((key) => (
          <div key={key}>
            <span>+</span> <b style={styles.strong}>{key}</b> <span style={{ color: '#822' }}>&#64;</span>{' '}
            <RemarkText style={{ display: 'inline' }}>{e.state[key]}</RemarkText>
          </div>
        ))}
      </Text>
      <Date>{moment(e.ts).format('hh:mm A')}</Date>
    </Item>
  );
}

function eventStopPOD(props) {
  const { e } = props;
  const { status } = styles;
  return (
    <Item key={e.id}>
      <Circle style={{ backgroundColor: convertStatusToColor()[e.action] }} />
      <Text>
        <div>
          <EventObject obj={e.subject} /> upload {e.action.replace('_', ' ')} for <EventObject obj={e.object} />
          {e.state.remark ? <RemarkText>{e.state.remark}</RemarkText> : ''}
        </div>
        {e.event && e.event.location && e.event.location.geolocation && (
          <div>
            At location [ {e.event.location.geolocation.latitude.toFixed(6)}, {e.event.location.geolocation.longitude.toFixed(6)} ]{' '}
            <a
              target="_blank"
              href={`https://www.google.com/maps/search/?api=1&query=${e.event.location.geolocation.latitude.toFixed(
                6,
              )},${e.event.location.geolocation.longitude.toFixed(6)}`}
            >
              Google Map
            </a>
          </div>
        )}
      </Text>
      <Date>{moment(e.ts).format('hh:mm A')}</Date>
    </Item>
  );
}

function eventModifyShipment(props) {
  const { e } = props;
  const { status } = styles;
  const fields = e.evidence && e.evidence.fields ? e.evidence.fields.split(',') : [];
  const withValueFields = fields.filter((f) => e.state && e.state[f]);
  // console.log(fields, withValueFields)
  if (withValueFields.length < 1) return <div></div>;
  return (
    <Item key={e.id}>
      <Circle style={{ backgroundColor: '#ddd' }} />
      <Text>
        <div>
          <EventObject obj={e.subject} /> update <EventObject obj={e.object} /> set
        </div>
        {withValueFields.map((field) => (
          <div>
            <span>+</span> <b style={styles.strong}>{field.replace('.', ' ').replace('_', ' ')}</b>{' '}
            <span style={{ color: '#822' }}>&#64;</span>{' '}
            <RemarkText style={{ display: 'inline' }}>{e.state[field]}</RemarkText>
          </div>
        ))}
      </Text>
      <Date>{moment(e.ts).format('hh:mm A')}</Date>
    </Item>
  );
}
