import React, {useEffect, useState} from 'react';
import * as M from '@material-ui/core';
import * as S from './styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AxlButton from "../Button";
import AxlTimeLine from "../AxlTimeLine";
import moment from "moment-timezone";
import AxlModal from "../AxlModal";
import {CallNoteEditForm} from "./forms";
import _ from 'lodash';
import {useRecoilState, useRecoilValue} from "recoil";
import {
  doSearch,
  SessionCallCenter,
  noteSelector,
  shipmentIdSelectedStatus
} from "../../containers/CallCenterResults/states";
import {CallSessionState} from "../../states/callCenterStates";
import {userState} from "../../atoms";

export default function CallCenterInfo({showcase, ...props}) {
  const user = useRecoilValue(userState);
  const [shipmentIdSelected, setShipmentIdSelected] = useRecoilState(shipmentIdSelectedStatus)
  const [shipments, searchShipments] = useRecoilState(doSearch);
  const [open, doOpen] = useState(false);
  const [isClose, setClose] = useState({});
  const [isCloseCallNoteForm, setCloseCallNoteForm] = useState(false);
  const callSession = useRecoilValue(CallSessionState);
  // Variables
  const urlParams = new URLSearchParams(window.location.search);
  const callFrom = urlParams.get('call_from') || '-';
  const callTo = urlParams.get('call_from') || '-';
  const callArgs = callArgsHandle(urlParams.get('call_args'));
  const callStatusMapToText = {
    'OPEN': 'ACTIVE',
    'CLOSED': 'CLOSED',
  };
  const isEdit = !!(callSession.notes && user.user && callSession.notes.filter(note => note.subject_user && note.subject_user.id === user.user.id).length);
  //Call session
  const notes = callSession.notes || [];
  const {raw_call_from, call_from, call_to, external_id, call_args, attr} = callSession;
  const args = call_args && call_args.split("|") || callArgs || [];
  const staffName = callSession.agent_user_info && (
                      callSession.agent_user_info.nickname ||
                      ((callSession.agent_user_info.first_name && callSession.agent_user_info.first_name) &&
                      [callSession.agent_user_info.first_name, callSession.agent_user_info.last_name].join(" "))
                    ) || '-';
  const shipmentId = attr ? attr.shipment_id : (args[1] || null);
  const isThis = showcase === 'EXACT' ?
    `Exact match by shipment ID ${shipmentId} (call from Recipient App)` :
    showcase === 'BEST_GUESS' ?
      `Best Guess result by call from number ${call_from || callFrom} (call from Recipient App)` :
      `No match by call from number ${call_from || callFrom} (call from Recipient App)`;

  function callArgsHandle(value) {
    if(!value) return null;

    return value.split("|");
  }

  function collapsed() {
    doOpen(!open);
  }

  const closeModal = (name = '') => setClose({[name]: true});

  const openModal = (name) => setClose({[name]: false});

  const noteHistoriesRender = function(notes) {
    if(!notes.length) return [];

    return(_.orderBy(notes, ['ts'], ['asc']).map((note, id) => <div key={note.id}>
      <S.Text_2>{note.content}</S.Text_2>
      <S.Text_3>{`${moment(note.ts).format("MM/DD/YYYY HH:mm")} - by ${_.get(note, 'subject_user_info.nickname', note.subject_user.username)}`}</S.Text_3>
    </div>));
  };

  return(<M.Box bgcolor={'primary.blueNight'} padding={2}>
    <M.Grid container>
      <M.Grid item xs>
        <S.Tilte>{`CALL INFO`}</S.Tilte>
      </M.Grid>
      <M.Grid item>
        <S.Text_1>{`Call Status: `}<S.Text component={'span'}>{callStatusMapToText[callSession.status] || '-'}</S.Text></S.Text_1>
      </M.Grid>
    </M.Grid>
    <S.Text>{isThis}</S.Text>
    <>
      <M.Grid container direction={'row'}>
        <M.Grid item xs><S.Text><S.Label>{`Call session ID:`}</S.Label>{_.defaultTo(callSession.id, '-')}</S.Text></M.Grid>
        <div><S.CollapseButton padding={0} onClick={collapsed}>{`Show ${open ? 'less' : 'more'}`}{open ? <ExpandLessIcon fontSize={'small'} /> : <ExpandMoreIcon fontSize={'small'} />}</S.CollapseButton></div>
      </M.Grid>
    </>
    {!!notes.length && <M.Box>
      <S.Divider/>
      <M.Grid container direction={'row'}>
        <S.Label>{`Call note:`}</S.Label>
        <M.Grid item xs>
          <AxlTimeLine color={'primary.main'} items={noteHistoriesRender(notes)} />
        </M.Grid>
        {isEdit && <AxlModal
          onRendered={() => openModal('CALL_NOTE_FORM')}
          isClose={isClose['CALL_NOTE_FORM']}
          isOpen={false}
          trigger={<AxlButton padding={'4px 10px'} variant={'contained'} bgcolor={'primary.main'}><S.Text_4>{`Edit`}</S.Text_4></AxlButton>}>
            <CallNoteEditForm onClose={() => closeModal('CALL_NOTE_FORM')}/>
        </AxlModal>}
      </M.Grid>
    </M.Box>}
    {open && <>
      <S.Divider/>
      <M.Grid container direction={'row'}>
        <M.Grid item xs>
          <S.Text><S.Label>{`Call from:`}</S.Label>{callSession.call_from || callFrom}</S.Text>
          <S.Text><S.Label>{`Call to: `}</S.Label>{callSession.call_to || callTo}</S.Text>
          <div>
            <M.Grid container direction={'row'} alignItems={'center'}>
              <S.Label>{`Call arguments:`}</S.Label>
              <M.Grid item xs>
                <S.CallArguments>
                  <S.Avatar bgcolor={'primary.yellow'}>{args[0]}</S.Avatar>
                  <S.DotArg>{`.`}</S.DotArg>
                  {!!args[1] && args[1].split("").map(arg => <S.Avatar>{arg}</S.Avatar>)}
                </S.CallArguments>
              </M.Grid>
            </M.Grid>
          </div>
        </M.Grid>
          <M.Grid item xs>
          <S.Text>{`Call region: ${callSession.call_regions || '-'}`}</S.Text>
          <S.Text>{`Call time: ${callSession.start_ts ? moment(callSession.start_ts).tz(_.get(callSession, 'agent_user_info.timezone', 'America/Los_Angeles')).format('MM/DD/YYYY @ hh:mm') : '-'}`}</S.Text>
          <S.Text>{`Handler: ${staffName}`}</S.Text>
        </M.Grid>
      </M.Grid>
      <S.Divider/>
    </>}
  </M.Box>);
}