export const shipments = [
  {
    id: 69609699,
    dropoff_latest_ts: "2020-06-30T19:58:00.000Z",
    status: "DROPOFF_SUCCEEDED",
    tracking_code: "8l6fyfbspbpuqymq",
    customer: {
      name: "Lorraine Dummy",
      phone_number: 3108170029,
      email: "lorraine@dummy.com"
    },
    dropoff_address: {
      street2: "Floor 2",
      street: "1 Federal Street",
      city: " San Francisco",
      state: "CA",
      zipcode: 94107,
      lat: 37.783966,
      lng: -122.391111
    },
  },
  {
    id: 69609699,
    dropoff_latest_ts: "2020-06-30T19:58:00.000Z",
    status: "DROPOFF_FAILED",
    tracking_code: "8l6fyfbspbpuqymq",
    customer: {
      name: "Lorraine Dummy",
      phone_number: 3108170029,
      email: "lorraine@dummy.com"
    },
    dropoff_address: {
      street2: "Floor 2",
      street: "1 Federal Street",
      city: " San Francisco",
      state: "CA",
      zipcode: 94107,
      lat: 37.783966,
      lng: -122.391111
    },
  },
  {
    id: 69609699,
    dropoff_latest_ts: "2020-06-30T19:58:00.000Z",
    status: "DROPOFF_SUCCEEDED",
    tracking_code: "8l6fyfbspbpuqymq",
    customer: {
      name: "Lorraine Dummy",
      phone_number: 3108170029,
      email: "lorraine@dummy.com"
    },
    dropoff_address: {
      street2: "Floor 2",
      street: "1 Federal Street",
      city: " San Francisco",
      state: "CA",
      zipcode: 94107,
      lat: 37.783966,
      lng: -122.391111
    },
  },
  {
    id: 69609699,
    dropoff_latest_ts: "2020-06-30T19:58:00.000Z",
    status: "DROPOFF_SUCCEEDED",
    tracking_code: "8l6fyfbspbpuqymq",
    customer: {
      name: "Lorraine Dummy",
      phone_number: 3108170029,
      email: "lorraine@dummy.com"
    },
    dropoff_address: {
      street2: "Floor 2",
      street: "1 Federal Street",
      city: " San Francisco",
      state: "CA",
      zipcode: 94107,
      lat: 37.783966,
      lng: -122.391111
    },
  },
  {
    id: 69609699,
    dropoff_latest_ts: "2020-06-30T19:58:00.000Z",
    status: "DROPOFF_SUCCEEDED",
    tracking_code: "8l6fyfbspbpuqymq",
    customer: {
      name: "Lorraine Dummy",
      phone_number: 3108170029,
      email: "lorraine@dummy.com"
    },
    dropoff_address: {
      street2: "Floor 2",
      street: "1 Federal Street",
      city: " San Francisco",
      state: "CA",
      zipcode: 94107,
      lat: 37.783966,
      lng: -122.391111
    },
  },
  {
    id: 69609699,
    dropoff_latest_ts: "2020-06-30T19:58:00.000Z",
    status: "DROPOFF_SUCCEEDED",
    tracking_code: "8l6fyfbspbpuqymq",
    customer: {
      name: "Lorraine Dummy",
      phone_number: 3108170029,
      email: "lorraine@dummy.com"
    },
    dropoff_address: {
      street2: "Floor 2",
      street: "1 Federal Street",
      city: " San Francisco",
      state: "CA",
      zipcode: 94107,
      lat: 37.783966,
      lng: -122.391111
    },
  },
];
export default {
  assignment: {
    "assignment":{
      "_created":"2020-07-09T03:45:26.836Z",
      "_updated":"2020-07-09T03:59:47.375Z",
      "_deleted":false,
      "_etag":"d2d01662-66d5-40fe-98d2-65522617888e",
      "id":186670,
      "driver_id":915,
      "solution_id":11645,
      "vehicle_id":158,
      "courier_id":8,
      "sequence_id":1,
      "status":"IN_PROGRESS",
      "number_of_stops":7,
      "deploy_cost":1234567,
      "mileage_cost":17870,
      "aggregate_cost":1252446.7044444445,
      "travel_time":2196,
      "service_time":0,
      "aggregate_time":2766,
      "travel_distance":30743.56469490181,
      "predicted_start_ts":"2020-07-10T16:05:46.000Z",
      "predicted_latest_start_ts":"2020-07-10T17:50:24.000Z",
      "predicted_end_ts":"2020-07-10T16:42:22.000Z",
      "logistic_type":"NEXT_DAYS",
      "is_active":true,
      "is_labeled":true,
      "shipment_count":4,
      "region_code":"SFO",
      "timezone":"America/Los_Angeles",
      "client_ids":[
        1
      ],
      "label":"AC",
      "code":"yx8zd",
      "is_selected":true
    },
    "stops":[
      {
        "_created":"2020-07-09T03:45:26.858Z",
        "_updated":"2020-07-09T03:58:51.404Z",
        "_deleted":false,
        "_etag":"7a767c83-33e5-464f-9173-b8def46c6dae",
        "id":9792690,
        "status":"PENDING",
        "shipment_id":0,
        "sequence_id":0,
        "type":"CLOCK_IN",
        "predicted_arrival_ts":"2020-07-10T16:05:46.000Z",
        "predicted_departure_ts":"2020-07-10T16:05:46.000Z",
        "address":"{\"street2\":\"Hall A\",\"street\":\"2320 Warring Street\",\"city\":\"Berkeley\",\"state\":\"CA\",\"zipcode\":\"94704\",\"lat\":37.868488,\"lng\":-122.251042}"
      },
      {
        "_created":"2020-07-09T03:45:11.967Z",
        "_updated":"2020-07-09T03:59:47.366Z",
        "_deleted":false,
        "_etag":"d2274987-9c4e-4dfd-9711-b6d201342c8f",
        "id":9792678,
        "status":"SUCCEEDED",
        "corresponding_stop_id":9792685,
        "shipment_id":4822493,
        "sequence_id":40000,
        "type":"PICK_UP",
        "predicted_arrival_ts":"2020-07-10T16:05:46.000Z",
        "predicted_departure_ts":"2020-07-10T16:05:46.000Z",
        "actual_departure_ts":"2020-07-09T03:59:48.000Z",
        "actual_arrival_ts":"2020-07-09T03:59:14.000Z",
        "travel_distance_from_previous_stop":0,
        "travel_time_from_previous_stop":0,
        "service_time":0,
        "location":{
          "latitude":37.868488,
          "longitude":-122.251042
        },
        "client_id":1,
        "remark":"",
        "reason":"",
        "shipment":{
          "_created":"2020-07-09T03:44:34.185Z",
          "_updated":"2020-07-09T04:00:27.393Z",
          "id":4822493,
          "internal_id":"",
          "client_id":1,
          "job_id":7230,
          "workload":1,
          "customer":{
            "name":"Rashad Dummy",
            "phone_number":"3108170029",
            "email":"rashad@dummy.com"
          },
          "status":"CANCELLED_AFTER_PICKUP",
          "pickup_address":{
            "street2":"Hall A",
            "street":"2320 Warring Street",
            "city":"Berkeley",
            "state":"CA",
            "zipcode":"94704",
            "lat":37.868488,
            "lng":-122.251042
          },
          "origin_pickup_address":{

          },
          "dropoff_address":{
            "street2":"Detached Unit C",
            "street":"1218 Treat Avenue",
            "city":" San Francisco",
            "state":"CA",
            "zipcode":"94110",
            "lat":37.750623,
            "lng":-122.413104
          },
          "dropoff_geocoder_id":"ff7d7a97-c7d8-471b-9b6e-2405667e8696",
          "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
          "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
          "dropoff_note":"Place on the patio table",
          "id_required":true,
          "assignment_id":186670,
          "batch_key":{
            "earliest":"2020-07-10",
            "latest":"2020-07-10",
            "client_id":1,
            "region_code":"SFO"
          },
          "region_code":"SFO",
          "timezone":"America/Los_Angeles",
          "service_level":"NEXT_DAY",
          "tracking_code":"hzc9l5tn6e78czmq",
          "is_cancelled":true,
          "dropoff_access_code":"012",
          "dropoff_uncharted":false
        },
        "label":{
          "id":87982,
          "shipment_id":4822493,
          "pickup_stop_id":9792678,
          "dropoff_stop_id":9792685,
          "driver_label":"AA-4"
        },
        "client":{
          "name":"Skydeck",
          "email":"thuan+5@gojitsu.com",
          "company":"Skydeck Test",
          "timezone":"America/Los_Angeles",
          "id":1,
          "user_id":5,
          "phone_number":"+15108593068",
          "support_email":"support@gojitsu.com",
          "support_phone_number":"+12001003000",
          "twitter_id":"",
          "facebook_id":"123",
          "logo_url":"https://ah-staging-delivery.s3.us-west-2.amazonaws.com/clients/1/1571825403979-logo.png",
          "label_settings":{
            "version":{
              "PACKING":20,
              "PACKING_PLUS_SHIPPING":20
            }
          },
          "output_order_reserved":false,
          "twilio_enabled":true,
          "warehousing_enabled":true
        }
      },
      {
        "_created":"2020-07-09T03:45:11.967Z",
        "_updated":"2020-07-09T03:59:47.381Z",
        "_deleted":false,
        "_etag":"2b53a306-7015-4f2a-8379-c3b4050ffef8",
        "id":9792679,
        "status":"SUCCEEDED",
        "corresponding_stop_id":9792686,
        "shipment_id":4822494,
        "sequence_id":50000,
        "type":"PICK_UP",
        "predicted_arrival_ts":"2020-07-10T16:05:46.000Z",
        "predicted_departure_ts":"2020-07-10T16:05:46.000Z",
        "actual_departure_ts":"2020-07-09T03:59:48.000Z",
        "actual_arrival_ts":"2020-07-09T03:59:14.000Z",
        "travel_distance_from_previous_stop":0,
        "travel_time_from_previous_stop":0,
        "service_time":0,
        "location":{
          "latitude":37.868488,
          "longitude":-122.251042
        },
        "client_id":1,
        "remark":"",
        "reason":"",
        "shipment":{
          "_created":"2020-07-09T03:44:34.194Z",
          "_updated":"2020-07-09T04:15:40.595Z",
          "id":4822494,
          "internal_id":"",
          "client_id":1,
          "job_id":7230,
          "workload":1,
          "customer":{
            "name":"Eva Dummy",
            "phone_number":"3108170029",
            "email":"eva@dummy.com"
          },
          "status":"CANCELLED_AFTER_PICKUP",
          "pickup_address":{
            "street2":"Hall A",
            "street":"2320 Warring Street",
            "city":"Berkeley",
            "state":"CA",
            "zipcode":"94704",
            "lat":37.868488,
            "lng":-122.251042
          },
          "origin_pickup_address":{

          },
          "dropoff_address":{
            "street2":"Room 105",
            "street":"30 Jersey Street",
            "city":" San Francisco",
            "state":"CA",
            "zipcode":"94114",
            "lat":37.751235,
            "lng":-122.425782
          },
          "dropoff_geocoder_id":"7cba4a2a-9c85-466e-a38b-012752c7d4b8",
          "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
          "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
          "dropoff_note":"My house is hard to find, call me when you arrive",
          "id_required":true,
          "assignment_id":186670,
          "batch_key":{
            "earliest":"2020-07-10",
            "latest":"2020-07-10",
            "client_id":1,
            "region_code":"SFO"
          },
          "region_code":"SFO",
          "timezone":"America/Los_Angeles",
          "service_level":"NEXT_DAY",
          "tracking_code":"wbrtdgutk6k8cyuu",
          "is_cancelled":true,
          "dropoff_access_code":"123",
          "dropoff_uncharted":true
        },
        "label":{
          "id":87983,
          "shipment_id":4822494,
          "pickup_stop_id":9792679,
          "dropoff_stop_id":9792686,
          "driver_label":"AA-5"
        },
        "client":{
          "name":"Skydeck",
          "email":"thuan+5@gojitsu.com",
          "company":"Skydeck Test",
          "timezone":"America/Los_Angeles",
          "id":1,
          "user_id":5,
          "phone_number":"+15108593068",
          "support_email":"support@gojitsu.com",
          "support_phone_number":"+12001003000",
          "twitter_id":"",
          "facebook_id":"123",
          "logo_url":"https://ah-staging-delivery.s3.us-west-2.amazonaws.com/clients/1/1571825403979-logo.png",
          "label_settings":{
            "version":{
              "PACKING":20,
              "PACKING_PLUS_SHIPPING":20
            }
          },
          "output_order_reserved":false,
          "twilio_enabled":true,
          "warehousing_enabled":true
        }
      },
      {
        "_created":"2020-07-09T03:45:11.967Z",
        "_updated":"2020-07-09T03:59:47.351Z",
        "_deleted":false,
        "_etag":"b03bd3b4-5167-4c86-b4b5-9adf68ced329",
        "id":9792680,
        "status":"SUCCEEDED",
        "corresponding_stop_id":9792687,
        "shipment_id":4822495,
        "sequence_id":60000,
        "type":"PICK_UP",
        "predicted_arrival_ts":"2020-07-10T16:05:46.000Z",
        "predicted_departure_ts":"2020-07-10T16:05:46.000Z",
        "actual_departure_ts":"2020-07-09T03:59:48.000Z",
        "actual_arrival_ts":"2020-07-09T03:59:14.000Z",
        "travel_distance_from_previous_stop":0,
        "travel_time_from_previous_stop":0,
        "service_time":0,
        "location":{
          "latitude":37.868488,
          "longitude":-122.251042
        },
        "client_id":1,
        "remark":"",
        "reason":"",
        "shipment":{
          "_created":"2020-07-09T03:44:34.205Z",
          "_updated":"2020-07-09T04:37:50.203Z",
          "id":4822495,
          "internal_id":"",
          "client_id":1,
          "job_id":7230,
          "workload":1,
          "customer":{
            "name":"David Dummy",
            "phone_number":"3108170029",
            "email":"david@dummy.com"
          },
          "status":"DROPOFF_FAILED",
          "pickup_address":{
            "street2":"Hall A",
            "street":"2320 Warring Street",
            "city":"Berkeley",
            "state":"CA",
            "zipcode":"94704",
            "lat":37.868488,
            "lng":-122.251042
          },
          "origin_pickup_address":{

          },
          "dropoff_address":{
            "street2":"Ceiling",
            "street":"1066 Guerrero Street",
            "city":" San Francisco",
            "state":"CA",
            "zipcode":"94110",
            "lat":37.754134,
            "lng":-122.423429
          },
          "dropoff_geocoder_id":"b789cc47-db0b-4cbd-b697-389504706359",
          "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
          "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
          "dropoff_note":"Baby inside, leave the box quietly",
          "id_required":true,
          "assignment_id":186670,
          "batch_key":{
            "earliest":"2020-07-10",
            "latest":"2020-07-10",
            "client_id":1,
            "region_code":"SFO"
          },
          "region_code":"SFO",
          "timezone":"America/Los_Angeles",
          "service_level":"NEXT_DAY",
          "tracking_code":"yxaq2y6ljyh768j5",
          "is_cancelled":false,
          "dropoff_uncharted":true
        },
        "label":{
          "id":87984,
          "shipment_id":4822495,
          "pickup_stop_id":9792680,
          "dropoff_stop_id":9792687,
          "driver_label":"AA-6"
        },
        "client":{
          "name":"Skydeck",
          "email":"thuan+5@gojitsu.com",
          "company":"Skydeck Test",
          "timezone":"America/Los_Angeles",
          "id":1,
          "user_id":5,
          "phone_number":"+15108593068",
          "support_email":"support@gojitsu.com",
          "support_phone_number":"+12001003000",
          "twitter_id":"",
          "facebook_id":"123",
          "logo_url":"https://ah-staging-delivery.s3.us-west-2.amazonaws.com/clients/1/1571825403979-logo.png",
          "label_settings":{
            "version":{
              "PACKING":20,
              "PACKING_PLUS_SHIPPING":20
            }
          },
          "output_order_reserved":false,
          "twilio_enabled":true,
          "warehousing_enabled":true
        }
      },
      {
        "_created":"2020-07-09T03:45:11.967Z",
        "_updated":"2020-07-09T03:59:47.336Z",
        "_deleted":false,
        "_etag":"89584c51-b05b-4ae9-8833-106e20f5b235",
        "id":9792681,
        "status":"SUCCEEDED",
        "corresponding_stop_id":9792688,
        "shipment_id":4822489,
        "sequence_id":70000,
        "type":"PICK_UP",
        "predicted_arrival_ts":"2020-07-10T16:05:46.000Z",
        "predicted_departure_ts":"2020-07-10T16:05:46.000Z",
        "actual_departure_ts":"2020-07-09T03:59:48.000Z",
        "actual_arrival_ts":"2020-07-09T03:59:14.000Z",
        "travel_distance_from_previous_stop":0,
        "travel_time_from_previous_stop":0,
        "service_time":0,
        "location":{
          "latitude":37.868488,
          "longitude":-122.251042
        },
        "client_id":1,
        "remark":"",
        "reason":"",
        "shipment":{
          "_created":"2020-07-09T03:44:34.149Z",
          "_updated":"2020-07-09T03:59:47.625Z",
          "id":4822489,
          "internal_id":"",
          "client_id":1,
          "job_id":7230,
          "workload":1,
          "customer":{
            "name":"Pamela Dummy",
            "phone_number":"3108170029",
            "email":"pamela@dummy.com"
          },
          "status":"PICKUP_SUCCEEDED",
          "pickup_address":{
            "street2":"Hall A",
            "street":"2320 Warring Street",
            "city":"Berkeley",
            "state":"CA",
            "zipcode":"94704",
            "lat":37.868488,
            "lng":-122.251042
          },
          "origin_pickup_address":{

          },
          "dropoff_address":{
            "street2":"Apartment 1",
            "street":"1790 Jackson Street",
            "city":" San Francisco",
            "state":"CA",
            "zipcode":"94109",
            "lat":37.793998,
            "lng":-122.424509
          },
          "dropoff_geocoder_id":"5ce298d5-0f3d-413e-a71c-2718c55bcfc0",
          "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
          "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
          "dropoff_note":"Do not ring the bell",
          "id_required":true,
          "assignment_id":186670,
          "batch_key":{
            "earliest":"2020-07-10",
            "latest":"2020-07-10",
            "client_id":1,
            "region_code":"SFO"
          },
          "region_code":"SFO",
          "timezone":"America/Los_Angeles",
          "service_level":"NEXT_DAY",
          "tracking_code":"z3njrddleqhsv7ma",
          "is_cancelled":false,
          "dropoff_access_code":"23412",
          "dropoff_uncharted":true
        },
        "label":{
          "id":87985,
          "shipment_id":4822489,
          "pickup_stop_id":9792681,
          "dropoff_stop_id":9792688,
          "driver_label":"AA-7"
        },
        "client":{
          "name":"Skydeck",
          "email":"thuan+5@gojitsu.com",
          "company":"Skydeck Test",
          "timezone":"America/Los_Angeles",
          "id":1,
          "user_id":5,
          "phone_number":"+15108593068",
          "support_email":"support@gojitsu.com",
          "support_phone_number":"+12001003000",
          "twitter_id":"",
          "facebook_id":"123",
          "logo_url":"https://ah-staging-delivery.s3.us-west-2.amazonaws.com/clients/1/1571825403979-logo.png",
          "label_settings":{
            "version":{
              "PACKING":20,
              "PACKING_PLUS_SHIPPING":20
            }
          },
          "output_order_reserved":false,
          "twilio_enabled":true,
          "warehousing_enabled":true
        }
      },
      {
        "_created":"2020-07-09T03:45:11.967Z",
        "_updated":"2020-07-09T03:59:48.878Z",
        "_deleted":false,
        "_etag":"a82efa4a-5d76-4b66-bf06-3d47cec721bd",
        "id":9792685,
        "status":"EN_ROUTE",
        "corresponding_stop_id":9792678,
        "shipment_id":4822493,
        "sequence_id":110000,
        "type":"DROP_OFF",
        "predicted_arrival_ts":"2020-07-10T16:30:45.000Z",
        "predicted_departure_ts":"2020-07-10T16:30:45.000Z",
        "travel_distance_from_previous_stop":24137.850803803314,
        "travel_time_from_previous_stop":1499,
        "service_time":0,
        "location":{
          "latitude":37.750623,
          "longitude":-122.413104
        },
        "client_id":1,
        "remark":"",
        "reason":"",
        "shipment":{
          "_created":"2020-07-09T03:44:34.185Z",
          "_updated":"2020-07-09T04:00:27.393Z",
          "id":4822493,
          "internal_id":"",
          "client_id":1,
          "job_id":7230,
          "workload":1,
          "customer":{
            "name":"Rashad Dummy",
            "phone_number":"3108170029",
            "email":"rashad@dummy.com"
          },
          "status":"CANCELLED_AFTER_PICKUP",
          "pickup_address":{
            "street2":"Hall A",
            "street":"2320 Warring Street",
            "city":"Berkeley",
            "state":"CA",
            "zipcode":"94704",
            "lat":37.868488,
            "lng":-122.251042
          },
          "origin_pickup_address":{

          },
          "dropoff_address":{
            "street2":"Detached Unit C",
            "street":"1218 Treat Avenue",
            "city":" San Francisco",
            "state":"CA",
            "zipcode":"94110",
            "lat":37.750623,
            "lng":-122.413104
          },
          "dropoff_geocoder_id":"ff7d7a97-c7d8-471b-9b6e-2405667e8696",
          "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
          "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
          "dropoff_note":"Place on the patio table",
          "id_required":true,
          "assignment_id":186670,
          "batch_key":{
            "earliest":"2020-07-10",
            "latest":"2020-07-10",
            "client_id":1,
            "region_code":"SFO"
          },
          "region_code":"SFO",
          "timezone":"America/Los_Angeles",
          "service_level":"NEXT_DAY",
          "tracking_code":"hzc9l5tn6e78czmq",
          "is_cancelled":true,
          "dropoff_access_code":"012",
          "dropoff_uncharted":false
        },
        "label":{
          "id":87982,
          "shipment_id":4822493,
          "pickup_stop_id":9792678,
          "dropoff_stop_id":9792685,
          "driver_label":"AA-4"
        },
        "corresponding_stop":{
          "_created":"2020-07-09T03:45:11.967Z",
          "_updated":"2020-07-09T03:59:47.366Z",
          "_deleted":false,
          "_etag":"d2274987-9c4e-4dfd-9711-b6d201342c8f",
          "id":9792678,
          "status":"SUCCEEDED",
          "corresponding_stop_id":9792685,
          "shipment_id":4822493,
          "sequence_id":40000,
          "type":"PICK_UP",
          "predicted_arrival_ts":"2020-07-10T16:05:46.000Z",
          "predicted_departure_ts":"2020-07-10T16:05:46.000Z",
          "actual_departure_ts":"2020-07-09T03:59:48.000Z",
          "actual_arrival_ts":"2020-07-09T03:59:14.000Z",
          "travel_distance_from_previous_stop":0,
          "travel_time_from_previous_stop":0,
          "service_time":0,
          "location":{
            "latitude":37.868488,
            "longitude":-122.251042
          },
          "client_id":1,
          "remark":"",
          "reason":"",
          "shipment":{
            "_created":"2020-07-09T03:44:34.185Z",
            "_updated":"2020-07-09T04:00:27.393Z",
            "id":4822493,
            "internal_id":"",
            "client_id":1,
            "job_id":7230,
            "workload":1,
            "customer":{
              "name":"Rashad Dummy",
              "phone_number":"3108170029",
              "email":"rashad@dummy.com"
            },
            "status":"CANCELLED_AFTER_PICKUP",
            "pickup_address":{
              "street2":"Hall A",
              "street":"2320 Warring Street",
              "city":"Berkeley",
              "state":"CA",
              "zipcode":"94704",
              "lat":37.868488,
              "lng":-122.251042
            },
            "origin_pickup_address":{

            },
            "dropoff_address":{
              "street2":"Detached Unit C",
              "street":"1218 Treat Avenue",
              "city":" San Francisco",
              "state":"CA",
              "zipcode":"94110",
              "lat":37.750623,
              "lng":-122.413104
            },
            "dropoff_geocoder_id":"ff7d7a97-c7d8-471b-9b6e-2405667e8696",
            "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
            "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
            "dropoff_note":"Place on the patio table",
            "id_required":true,
            "assignment_id":186670,
            "batch_key":{
              "earliest":"2020-07-10",
              "latest":"2020-07-10",
              "client_id":1,
              "region_code":"SFO"
            },
            "region_code":"SFO",
            "timezone":"America/Los_Angeles",
            "service_level":"NEXT_DAY",
            "tracking_code":"hzc9l5tn6e78czmq",
            "is_cancelled":true,
            "dropoff_access_code":"012",
            "dropoff_uncharted":false
          },
          "label":{
            "id":87982,
            "shipment_id":4822493,
            "pickup_stop_id":9792678,
            "dropoff_stop_id":9792685,
            "driver_label":"AA-4"
          },
          "client":{
            "name":"Skydeck",
            "email":"thuan+5@gojitsu.com",
            "company":"Skydeck Test",
            "timezone":"America/Los_Angeles",
            "id":1,
            "user_id":5,
            "phone_number":"+15108593068",
            "support_email":"support@gojitsu.com",
            "support_phone_number":"+12001003000",
            "twitter_id":"",
            "facebook_id":"123",
            "logo_url":"https://ah-staging-delivery.s3.us-west-2.amazonaws.com/clients/1/1571825403979-logo.png",
            "label_settings":{
              "version":{
                "PACKING":20,
                "PACKING_PLUS_SHIPPING":20
              }
            },
            "output_order_reserved":false,
            "twilio_enabled":true,
            "warehousing_enabled":true
          }
        },
        "client":{
          "name":"Skydeck",
          "email":"thuan+5@gojitsu.com",
          "company":"Skydeck Test",
          "timezone":"America/Los_Angeles",
          "id":1,
          "user_id":5,
          "phone_number":"+15108593068",
          "support_email":"support@gojitsu.com",
          "support_phone_number":"+12001003000",
          "twitter_id":"",
          "facebook_id":"123",
          "logo_url":"https://ah-staging-delivery.s3.us-west-2.amazonaws.com/clients/1/1571825403979-logo.png",
          "label_settings":{
            "version":{
              "PACKING":20,
              "PACKING_PLUS_SHIPPING":20
            }
          },
          "output_order_reserved":false,
          "twilio_enabled":true,
          "warehousing_enabled":true
        }
      },
      {
        "_created":"2020-07-09T03:45:11.967Z",
        "_updated":"2020-07-09T04:15:24.200Z",
        "_deleted":false,
        "_etag":"1a02d64e-35e0-4cf9-89ae-4c9f9b6d21c1",
        "id":9792686,
        "status":"EN_ROUTE",
        "corresponding_stop_id":9792679,
        "shipment_id":4822494,
        "sequence_id":120000,
        "type":"DROP_OFF",
        "predicted_arrival_ts":"2020-07-10T16:33:08.000Z",
        "predicted_departure_ts":"2020-07-10T16:33:08.000Z",
        "travel_distance_from_previous_stop":1389.8286268267166,
        "travel_time_from_previous_stop":143,
        "service_time":0,
        "location":{
          "latitude":37.751235,
          "longitude":-122.425782
        },
        "client_id":1,
        "remark":"",
        "reason":"",
        "shipment":{
          "_created":"2020-07-09T03:44:34.194Z",
          "_updated":"2020-07-09T04:15:40.595Z",
          "id":4822494,
          "internal_id":"",
          "client_id":1,
          "job_id":7230,
          "workload":1,
          "customer":{
            "name":"Eva Dummy",
            "phone_number":"3108170029",
            "email":"eva@dummy.com"
          },
          "status":"CANCELLED_AFTER_PICKUP",
          "pickup_address":{
            "street2":"Hall A",
            "street":"2320 Warring Street",
            "city":"Berkeley",
            "state":"CA",
            "zipcode":"94704",
            "lat":37.868488,
            "lng":-122.251042
          },
          "origin_pickup_address":{

          },
          "dropoff_address":{
            "street2":"Room 105",
            "street":"30 Jersey Street",
            "city":" San Francisco",
            "state":"CA",
            "zipcode":"94114",
            "lat":37.751235,
            "lng":-122.425782
          },
          "dropoff_geocoder_id":"7cba4a2a-9c85-466e-a38b-012752c7d4b8",
          "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
          "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
          "dropoff_note":"My house is hard to find, call me when you arrive",
          "id_required":true,
          "assignment_id":186670,
          "batch_key":{
            "earliest":"2020-07-10",
            "latest":"2020-07-10",
            "client_id":1,
            "region_code":"SFO"
          },
          "region_code":"SFO",
          "timezone":"America/Los_Angeles",
          "service_level":"NEXT_DAY",
          "tracking_code":"wbrtdgutk6k8cyuu",
          "is_cancelled":true,
          "dropoff_access_code":"123",
          "dropoff_uncharted":true
        },
        "label":{
          "id":87983,
          "shipment_id":4822494,
          "pickup_stop_id":9792679,
          "dropoff_stop_id":9792686,
          "driver_label":"AA-5"
        },
        "corresponding_stop":{
          "_created":"2020-07-09T03:45:11.967Z",
          "_updated":"2020-07-09T03:59:47.381Z",
          "_deleted":false,
          "_etag":"2b53a306-7015-4f2a-8379-c3b4050ffef8",
          "id":9792679,
          "status":"SUCCEEDED",
          "corresponding_stop_id":9792686,
          "shipment_id":4822494,
          "sequence_id":50000,
          "type":"PICK_UP",
          "predicted_arrival_ts":"2020-07-10T16:05:46.000Z",
          "predicted_departure_ts":"2020-07-10T16:05:46.000Z",
          "actual_departure_ts":"2020-07-09T03:59:48.000Z",
          "actual_arrival_ts":"2020-07-09T03:59:14.000Z",
          "travel_distance_from_previous_stop":0,
          "travel_time_from_previous_stop":0,
          "service_time":0,
          "location":{
            "latitude":37.868488,
            "longitude":-122.251042
          },
          "client_id":1,
          "remark":"",
          "reason":"",
          "shipment":{
            "_created":"2020-07-09T03:44:34.194Z",
            "_updated":"2020-07-09T04:15:40.595Z",
            "id":4822494,
            "internal_id":"",
            "client_id":1,
            "job_id":7230,
            "workload":1,
            "customer":{
              "name":"Eva Dummy",
              "phone_number":"3108170029",
              "email":"eva@dummy.com"
            },
            "status":"CANCELLED_AFTER_PICKUP",
            "pickup_address":{
              "street2":"Hall A",
              "street":"2320 Warring Street",
              "city":"Berkeley",
              "state":"CA",
              "zipcode":"94704",
              "lat":37.868488,
              "lng":-122.251042
            },
            "origin_pickup_address":{

            },
            "dropoff_address":{
              "street2":"Room 105",
              "street":"30 Jersey Street",
              "city":" San Francisco",
              "state":"CA",
              "zipcode":"94114",
              "lat":37.751235,
              "lng":-122.425782
            },
            "dropoff_geocoder_id":"7cba4a2a-9c85-466e-a38b-012752c7d4b8",
            "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
            "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
            "dropoff_note":"My house is hard to find, call me when you arrive",
            "id_required":true,
            "assignment_id":186670,
            "batch_key":{
              "earliest":"2020-07-10",
              "latest":"2020-07-10",
              "client_id":1,
              "region_code":"SFO"
            },
            "region_code":"SFO",
            "timezone":"America/Los_Angeles",
            "service_level":"NEXT_DAY",
            "tracking_code":"wbrtdgutk6k8cyuu",
            "is_cancelled":true,
            "dropoff_access_code":"123",
            "dropoff_uncharted":true
          },
          "label":{
            "id":87983,
            "shipment_id":4822494,
            "pickup_stop_id":9792679,
            "dropoff_stop_id":9792686,
            "driver_label":"AA-5"
          },
          "client":{
            "name":"Skydeck",
            "email":"thuan+5@gojitsu.com",
            "company":"Skydeck Test",
            "timezone":"America/Los_Angeles",
            "id":1,
            "user_id":5,
            "phone_number":"+15108593068",
            "support_email":"support@gojitsu.com",
            "support_phone_number":"+12001003000",
            "twitter_id":"",
            "facebook_id":"123",
            "logo_url":"https://ah-staging-delivery.s3.us-west-2.amazonaws.com/clients/1/1571825403979-logo.png",
            "label_settings":{
              "version":{
                "PACKING":20,
                "PACKING_PLUS_SHIPPING":20
              }
            },
            "output_order_reserved":false,
            "twilio_enabled":true,
            "warehousing_enabled":true
          }
        },
        "client":{
          "name":"Skydeck",
          "email":"thuan+5@gojitsu.com",
          "company":"Skydeck Test",
          "timezone":"America/Los_Angeles",
          "id":1,
          "user_id":5,
          "phone_number":"+15108593068",
          "support_email":"support@gojitsu.com",
          "support_phone_number":"+12001003000",
          "twitter_id":"",
          "facebook_id":"123",
          "logo_url":"https://ah-staging-delivery.s3.us-west-2.amazonaws.com/clients/1/1571825403979-logo.png",
          "label_settings":{
            "version":{
              "PACKING":20,
              "PACKING_PLUS_SHIPPING":20
            }
          },
          "output_order_reserved":false,
          "twilio_enabled":true,
          "warehousing_enabled":true
        }
      },
      {
        "_created":"2020-07-09T03:45:11.967Z",
        "_updated":"2020-07-09T04:37:49.910Z",
        "_deleted":false,
        "_etag":"27899bc6-dd04-4e65-9311-68c2fe5c34fc",
        "id":9792687,
        "status":"FAILED",
        "corresponding_stop_id":9792680,
        "shipment_id":4822495,
        "sequence_id":130000,
        "type":"DROP_OFF",
        "predicted_arrival_ts":"2020-07-10T16:34:21.000Z",
        "predicted_departure_ts":"2020-07-10T16:34:21.000Z",
        "actual_departure_ts":"2020-07-09T04:37:50.000Z",
        "actual_arrival_ts":"2020-07-09T04:37:07.000Z",
        "travel_distance_from_previous_stop":543.3110852823763,
        "travel_time_from_previous_stop":73,
        "service_time":0,
        "location":{
          "latitude":37.754134,
          "longitude":-122.423429
        },
        "client_id":1,
        "remark":"",
        "reason":"NO_ACCESS_CODE",
        "shipment":{
          "_created":"2020-07-09T03:44:34.205Z",
          "_updated":"2020-07-09T04:37:50.203Z",
          "id":4822495,
          "internal_id":"",
          "client_id":1,
          "job_id":7230,
          "workload":1,
          "customer":{
            "name":"David Dummy",
            "phone_number":"3108170029",
            "email":"david@dummy.com"
          },
          "status":"DROPOFF_FAILED",
          "pickup_address":{
            "street2":"Hall A",
            "street":"2320 Warring Street",
            "city":"Berkeley",
            "state":"CA",
            "zipcode":"94704",
            "lat":37.868488,
            "lng":-122.251042
          },
          "origin_pickup_address":{

          },
          "dropoff_address":{
            "street2":"Ceiling",
            "street":"1066 Guerrero Street",
            "city":" San Francisco",
            "state":"CA",
            "zipcode":"94110",
            "lat":37.754134,
            "lng":-122.423429
          },
          "dropoff_geocoder_id":"b789cc47-db0b-4cbd-b697-389504706359",
          "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
          "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
          "dropoff_note":"Baby inside, leave the box quietly",
          "id_required":true,
          "assignment_id":186670,
          "batch_key":{
            "earliest":"2020-07-10",
            "latest":"2020-07-10",
            "client_id":1,
            "region_code":"SFO"
          },
          "region_code":"SFO",
          "timezone":"America/Los_Angeles",
          "service_level":"NEXT_DAY",
          "tracking_code":"yxaq2y6ljyh768j5",
          "is_cancelled":false,
          "dropoff_uncharted":true
        },
        "label":{
          "id":87984,
          "shipment_id":4822495,
          "pickup_stop_id":9792680,
          "dropoff_stop_id":9792687,
          "driver_label":"AA-6"
        },
        "corresponding_stop":{
          "_created":"2020-07-09T03:45:11.967Z",
          "_updated":"2020-07-09T03:59:47.351Z",
          "_deleted":false,
          "_etag":"b03bd3b4-5167-4c86-b4b5-9adf68ced329",
          "id":9792680,
          "status":"SUCCEEDED",
          "corresponding_stop_id":9792687,
          "shipment_id":4822495,
          "sequence_id":60000,
          "type":"PICK_UP",
          "predicted_arrival_ts":"2020-07-10T16:05:46.000Z",
          "predicted_departure_ts":"2020-07-10T16:05:46.000Z",
          "actual_departure_ts":"2020-07-09T03:59:48.000Z",
          "actual_arrival_ts":"2020-07-09T03:59:14.000Z",
          "travel_distance_from_previous_stop":0,
          "travel_time_from_previous_stop":0,
          "service_time":0,
          "location":{
            "latitude":37.868488,
            "longitude":-122.251042
          },
          "client_id":1,
          "remark":"",
          "reason":"",
          "shipment":{
            "_created":"2020-07-09T03:44:34.205Z",
            "_updated":"2020-07-09T04:37:50.203Z",
            "id":4822495,
            "internal_id":"",
            "client_id":1,
            "job_id":7230,
            "workload":1,
            "customer":{
              "name":"David Dummy",
              "phone_number":"3108170029",
              "email":"david@dummy.com"
            },
            "status":"DROPOFF_FAILED",
            "pickup_address":{
              "street2":"Hall A",
              "street":"2320 Warring Street",
              "city":"Berkeley",
              "state":"CA",
              "zipcode":"94704",
              "lat":37.868488,
              "lng":-122.251042
            },
            "origin_pickup_address":{

            },
            "dropoff_address":{
              "street2":"Ceiling",
              "street":"1066 Guerrero Street",
              "city":" San Francisco",
              "state":"CA",
              "zipcode":"94110",
              "lat":37.754134,
              "lng":-122.423429
            },
            "dropoff_geocoder_id":"b789cc47-db0b-4cbd-b697-389504706359",
            "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
            "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
            "dropoff_note":"Baby inside, leave the box quietly",
            "id_required":true,
            "assignment_id":186670,
            "batch_key":{
              "earliest":"2020-07-10",
              "latest":"2020-07-10",
              "client_id":1,
              "region_code":"SFO"
            },
            "region_code":"SFO",
            "timezone":"America/Los_Angeles",
            "service_level":"NEXT_DAY",
            "tracking_code":"yxaq2y6ljyh768j5",
            "is_cancelled":false,
            "dropoff_uncharted":true
          },
          "label":{
            "id":87984,
            "shipment_id":4822495,
            "pickup_stop_id":9792680,
            "dropoff_stop_id":9792687,
            "driver_label":"AA-6"
          },
          "client":{
            "name":"Skydeck",
            "email":"thuan+5@gojitsu.com",
            "company":"Skydeck Test",
            "timezone":"America/Los_Angeles",
            "id":1,
            "user_id":5,
            "phone_number":"+15108593068",
            "support_email":"support@gojitsu.com",
            "support_phone_number":"+12001003000",
            "twitter_id":"",
            "facebook_id":"123",
            "logo_url":"https://ah-staging-delivery.s3.us-west-2.amazonaws.com/clients/1/1571825403979-logo.png",
            "label_settings":{
              "version":{
                "PACKING":20,
                "PACKING_PLUS_SHIPPING":20
              }
            },
            "output_order_reserved":false,
            "twilio_enabled":true,
            "warehousing_enabled":true
          }
        },
        "client":{
          "name":"Skydeck",
          "email":"thuan+5@gojitsu.com",
          "company":"Skydeck Test",
          "timezone":"America/Los_Angeles",
          "id":1,
          "user_id":5,
          "phone_number":"+15108593068",
          "support_email":"support@gojitsu.com",
          "support_phone_number":"+12001003000",
          "twitter_id":"",
          "facebook_id":"123",
          "logo_url":"https://ah-staging-delivery.s3.us-west-2.amazonaws.com/clients/1/1571825403979-logo.png",
          "label_settings":{
            "version":{
              "PACKING":20,
              "PACKING_PLUS_SHIPPING":20
            }
          },
          "output_order_reserved":false,
          "twilio_enabled":true,
          "warehousing_enabled":true
        }
      },
      {
        "_created":"2020-07-09T04:39:52.873Z",
        "_updated":"2020-07-09T04:39:52.873Z",
        "_deleted":false,
        "_etag":"dd731bc9-7a36-4c37-bc2e-6b2adc078fa5",
        "uid":"7a7b0b64-1857-4c09-9006-b77eed894939",
        "id":9792693,
        "status":"EN_ROUTE",
        "shipment_id":4822495,
        "sequence_id":135000,
        "type":"DROP_OFF",
        "predicted_arrival_ts":"2020-07-10T16:34:21.000Z",
        "predicted_departure_ts":"2020-07-10T16:34:21.000Z",
        "travel_distance_from_previous_stop":0,
        "travel_time_from_previous_stop":0,
        "service_time":0,
        "location":{
          "latitude":37.754134,
          "longitude":-122.423429
        },
        "client_id":1,
        "shipment":{
          "_created":"2020-07-09T03:44:34.205Z",
          "_updated":"2020-07-09T04:37:50.203Z",
          "id":4822495,
          "internal_id":"",
          "client_id":1,
          "job_id":7230,
          "workload":1,
          "customer":{
            "name":"David Dummy",
            "phone_number":"3108170029",
            "email":"david@dummy.com"
          },
          "status":"DROPOFF_FAILED",
          "pickup_address":{
            "street2":"Hall A",
            "street":"2320 Warring Street",
            "city":"Berkeley",
            "state":"CA",
            "zipcode":"94704",
            "lat":37.868488,
            "lng":-122.251042
          },
          "origin_pickup_address":{

          },
          "dropoff_address":{
            "street2":"Ceiling",
            "street":"1066 Guerrero Street",
            "city":" San Francisco",
            "state":"CA",
            "zipcode":"94110",
            "lat":37.754134,
            "lng":-122.423429
          },
          "dropoff_geocoder_id":"b789cc47-db0b-4cbd-b697-389504706359",
          "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
          "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
          "dropoff_note":"Baby inside, leave the box quietly",
          "id_required":true,
          "assignment_id":186670,
          "batch_key":{
            "earliest":"2020-07-10",
            "latest":"2020-07-10",
            "client_id":1,
            "region_code":"SFO"
          },
          "region_code":"SFO",
          "timezone":"America/Los_Angeles",
          "service_level":"NEXT_DAY",
          "tracking_code":"yxaq2y6ljyh768j5",
          "is_cancelled":false,
          "dropoff_uncharted":true
        },
        "label":{
          "id":87984,
          "shipment_id":4822495,
          "pickup_stop_id":9792680,
          "dropoff_stop_id":9792687,
          "driver_label":"AA-6"
        },
        "corresponding_stop":{
          "_created":"2020-07-09T03:45:11.967Z",
          "_updated":"2020-07-09T03:59:47.351Z",
          "_deleted":false,
          "_etag":"b03bd3b4-5167-4c86-b4b5-9adf68ced329",
          "id":9792680,
          "status":"SUCCEEDED",
          "corresponding_stop_id":9792687,
          "shipment_id":4822495,
          "sequence_id":60000,
          "type":"PICK_UP",
          "predicted_arrival_ts":"2020-07-10T16:05:46.000Z",
          "predicted_departure_ts":"2020-07-10T16:05:46.000Z",
          "actual_departure_ts":"2020-07-09T03:59:48.000Z",
          "actual_arrival_ts":"2020-07-09T03:59:14.000Z",
          "travel_distance_from_previous_stop":0,
          "travel_time_from_previous_stop":0,
          "service_time":0,
          "location":{
            "latitude":37.868488,
            "longitude":-122.251042
          },
          "client_id":1,
          "remark":"",
          "reason":"",
          "shipment":{
            "_created":"2020-07-09T03:44:34.205Z",
            "_updated":"2020-07-09T04:37:50.203Z",
            "id":4822495,
            "internal_id":"",
            "client_id":1,
            "job_id":7230,
            "workload":1,
            "customer":{
              "name":"David Dummy",
              "phone_number":"3108170029",
              "email":"david@dummy.com"
            },
            "status":"DROPOFF_FAILED",
            "pickup_address":{
              "street2":"Hall A",
              "street":"2320 Warring Street",
              "city":"Berkeley",
              "state":"CA",
              "zipcode":"94704",
              "lat":37.868488,
              "lng":-122.251042
            },
            "origin_pickup_address":{

            },
            "dropoff_address":{
              "street2":"Ceiling",
              "street":"1066 Guerrero Street",
              "city":" San Francisco",
              "state":"CA",
              "zipcode":"94110",
              "lat":37.754134,
              "lng":-122.423429
            },
            "dropoff_geocoder_id":"b789cc47-db0b-4cbd-b697-389504706359",
            "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
            "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
            "dropoff_note":"Baby inside, leave the box quietly",
            "id_required":true,
            "assignment_id":186670,
            "batch_key":{
              "earliest":"2020-07-10",
              "latest":"2020-07-10",
              "client_id":1,
              "region_code":"SFO"
            },
            "region_code":"SFO",
            "timezone":"America/Los_Angeles",
            "service_level":"NEXT_DAY",
            "tracking_code":"yxaq2y6ljyh768j5",
            "is_cancelled":false,
            "dropoff_uncharted":true
          },
          "label":{
            "id":87984,
            "shipment_id":4822495,
            "pickup_stop_id":9792680,
            "dropoff_stop_id":9792687,
            "driver_label":"AA-6"
          },
          "client":{
            "name":"Skydeck",
            "email":"thuan+5@gojitsu.com",
            "company":"Skydeck Test",
            "timezone":"America/Los_Angeles",
            "id":1,
            "user_id":5,
            "phone_number":"+15108593068",
            "support_email":"support@gojitsu.com",
            "support_phone_number":"+12001003000",
            "twitter_id":"",
            "facebook_id":"123",
            "logo_url":"https://ah-staging-delivery.s3.us-west-2.amazonaws.com/clients/1/1571825403979-logo.png",
            "label_settings":{
              "version":{
                "PACKING":20,
                "PACKING_PLUS_SHIPPING":20
              }
            },
            "output_order_reserved":false,
            "twilio_enabled":true,
            "warehousing_enabled":true
          }
        },
        "attributes":{
          "is_reattempt":"true",
          "can_discard":"true"
        },
        "client":{
          "name":"Skydeck",
          "email":"thuan+5@gojitsu.com",
          "company":"Skydeck Test",
          "timezone":"America/Los_Angeles",
          "id":1,
          "user_id":5,
          "phone_number":"+15108593068",
          "support_email":"support@gojitsu.com",
          "support_phone_number":"+12001003000",
          "twitter_id":"",
          "facebook_id":"123",
          "logo_url":"https://ah-staging-delivery.s3.us-west-2.amazonaws.com/clients/1/1571825403979-logo.png",
          "label_settings":{
            "version":{
              "PACKING":20,
              "PACKING_PLUS_SHIPPING":20
            }
          },
          "output_order_reserved":false,
          "twilio_enabled":true,
          "warehousing_enabled":true
        }
      },
      {
        "_created":"2020-07-09T03:45:11.967Z",
        "_updated":"2020-07-09T03:58:51.404Z",
        "_deleted":false,
        "_etag":"8ce806bd-11a1-414e-9876-d85e964729a2",
        "id":9792688,
        "status":"PENDING",
        "corresponding_stop_id":9792681,
        "shipment_id":4822489,
        "sequence_id":140000,
        "type":"DROP_OFF",
        "predicted_arrival_ts":"2020-07-10T16:42:22.000Z",
        "predicted_departure_ts":"2020-07-10T16:42:22.000Z",
        "travel_distance_from_previous_stop":4672.5741789894,
        "travel_time_from_previous_stop":481,
        "service_time":0,
        "location":{
          "latitude":37.793998,
          "longitude":-122.424509
        },
        "client_id":1,
        "shipment":{
          "_created":"2020-07-09T03:44:34.149Z",
          "_updated":"2020-07-09T03:59:47.625Z",
          "id":4822489,
          "internal_id":"",
          "client_id":1,
          "job_id":7230,
          "workload":1,
          "customer":{
            "name":"Pamela Dummy",
            "phone_number":"3108170029",
            "email":"pamela@dummy.com"
          },
          "status":"PICKUP_SUCCEEDED",
          "pickup_address":{
            "street2":"Hall A",
            "street":"2320 Warring Street",
            "city":"Berkeley",
            "state":"CA",
            "zipcode":"94704",
            "lat":37.868488,
            "lng":-122.251042
          },
          "origin_pickup_address":{

          },
          "dropoff_address":{
            "street2":"Apartment 1",
            "street":"1790 Jackson Street",
            "city":" San Francisco",
            "state":"CA",
            "zipcode":"94109",
            "lat":37.793998,
            "lng":-122.424509
          },
          "dropoff_geocoder_id":"5ce298d5-0f3d-413e-a71c-2718c55bcfc0",
          "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
          "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
          "dropoff_note":"Do not ring the bell",
          "id_required":true,
          "assignment_id":186670,
          "batch_key":{
            "earliest":"2020-07-10",
            "latest":"2020-07-10",
            "client_id":1,
            "region_code":"SFO"
          },
          "region_code":"SFO",
          "timezone":"America/Los_Angeles",
          "service_level":"NEXT_DAY",
          "tracking_code":"z3njrddleqhsv7ma",
          "is_cancelled":false,
          "dropoff_access_code":"23412",
          "dropoff_uncharted":true
        },
        "label":{
          "id":87985,
          "shipment_id":4822489,
          "pickup_stop_id":9792681,
          "dropoff_stop_id":9792688,
          "driver_label":"AA-7"
        },
        "corresponding_stop":{
          "_created":"2020-07-09T03:45:11.967Z",
          "_updated":"2020-07-09T03:59:47.336Z",
          "_deleted":false,
          "_etag":"89584c51-b05b-4ae9-8833-106e20f5b235",
          "id":9792681,
          "status":"SUCCEEDED",
          "corresponding_stop_id":9792688,
          "shipment_id":4822489,
          "sequence_id":70000,
          "type":"PICK_UP",
          "predicted_arrival_ts":"2020-07-10T16:05:46.000Z",
          "predicted_departure_ts":"2020-07-10T16:05:46.000Z",
          "actual_departure_ts":"2020-07-09T03:59:48.000Z",
          "actual_arrival_ts":"2020-07-09T03:59:14.000Z",
          "travel_distance_from_previous_stop":0,
          "travel_time_from_previous_stop":0,
          "service_time":0,
          "location":{
            "latitude":37.868488,
            "longitude":-122.251042
          },
          "client_id":1,
          "remark":"",
          "reason":"",
          "shipment":{
            "_created":"2020-07-09T03:44:34.149Z",
            "_updated":"2020-07-09T03:59:47.625Z",
            "id":4822489,
            "internal_id":"",
            "client_id":1,
            "job_id":7230,
            "workload":1,
            "customer":{
              "name":"Pamela Dummy",
              "phone_number":"3108170029",
              "email":"pamela@dummy.com"
            },
            "status":"PICKUP_SUCCEEDED",
            "pickup_address":{
              "street2":"Hall A",
              "street":"2320 Warring Street",
              "city":"Berkeley",
              "state":"CA",
              "zipcode":"94704",
              "lat":37.868488,
              "lng":-122.251042
            },
            "origin_pickup_address":{

            },
            "dropoff_address":{
              "street2":"Apartment 1",
              "street":"1790 Jackson Street",
              "city":" San Francisco",
              "state":"CA",
              "zipcode":"94109",
              "lat":37.793998,
              "lng":-122.424509
            },
            "dropoff_geocoder_id":"5ce298d5-0f3d-413e-a71c-2718c55bcfc0",
            "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
            "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
            "dropoff_note":"Do not ring the bell",
            "id_required":true,
            "assignment_id":186670,
            "batch_key":{
              "earliest":"2020-07-10",
              "latest":"2020-07-10",
              "client_id":1,
              "region_code":"SFO"
            },
            "region_code":"SFO",
            "timezone":"America/Los_Angeles",
            "service_level":"NEXT_DAY",
            "tracking_code":"z3njrddleqhsv7ma",
            "is_cancelled":false,
            "dropoff_access_code":"23412",
            "dropoff_uncharted":true
          },
          "label":{
            "id":87985,
            "shipment_id":4822489,
            "pickup_stop_id":9792681,
            "dropoff_stop_id":9792688,
            "driver_label":"AA-7"
          },
          "client":{
            "name":"Skydeck",
            "email":"thuan+5@gojitsu.com",
            "company":"Skydeck Test",
            "timezone":"America/Los_Angeles",
            "id":1,
            "user_id":5,
            "phone_number":"+15108593068",
            "support_email":"support@gojitsu.com",
            "support_phone_number":"+12001003000",
            "twitter_id":"",
            "facebook_id":"123",
            "logo_url":"https://ah-staging-delivery.s3.us-west-2.amazonaws.com/clients/1/1571825403979-logo.png",
            "label_settings":{
              "version":{
                "PACKING":20,
                "PACKING_PLUS_SHIPPING":20
              }
            },
            "output_order_reserved":false,
            "twilio_enabled":true,
            "warehousing_enabled":true
          }
        },
        "client":{
          "name":"Skydeck",
          "email":"thuan+5@gojitsu.com",
          "company":"Skydeck Test",
          "timezone":"America/Los_Angeles",
          "id":1,
          "user_id":5,
          "phone_number":"+15108593068",
          "support_email":"support@gojitsu.com",
          "support_phone_number":"+12001003000",
          "twitter_id":"",
          "facebook_id":"123",
          "logo_url":"https://ah-staging-delivery.s3.us-west-2.amazonaws.com/clients/1/1571825403979-logo.png",
          "label_settings":{
            "version":{
              "PACKING":20,
              "PACKING_PLUS_SHIPPING":20
            }
          },
          "output_order_reserved":false,
          "twilio_enabled":true,
          "warehousing_enabled":true
        }
      },
      {
        "_created":"2020-07-09T03:45:26.860Z",
        "_updated":"2020-07-09T03:58:51.404Z",
        "_deleted":false,
        "_etag":"6142e8e4-cad0-40b9-abaa-853d50c71622",
        "id":9792691,
        "status":"PENDING",
        "shipment_id":0,
        "sequence_id":150000,
        "type":"CLOCK_OUT",
        "predicted_arrival_ts":"2020-07-10T16:42:22.000Z",
        "predicted_departure_ts":"2020-07-10T16:42:22.000Z"
      }
    ],
    "shipments":[
      {
        "_created":"2020-07-09T03:44:34.149Z",
        "_updated":"2020-07-09T03:59:47.625Z",
        "id":4822489,
        "internal_id":"",
        "client_id":1,
        "job_id":7230,
        "workload":1,
        "customer":{
          "name":"Pamela Dummy",
          "phone_number":"3108170029",
          "email":"pamela@dummy.com"
        },
        "status":"PICKUP_SUCCEEDED",
        "pickup_address":{
          "street2":"Hall A",
          "street":"2320 Warring Street",
          "city":"Berkeley",
          "state":"CA",
          "zipcode":"94704",
          "lat":37.868488,
          "lng":-122.251042
        },
        "origin_pickup_address":{

        },
        "dropoff_address":{
          "street2":"Apartment 1",
          "street":"1790 Jackson Street",
          "city":" San Francisco",
          "state":"CA",
          "zipcode":"94109",
          "lat":37.793998,
          "lng":-122.424509
        },
        "dropoff_geocoder_id":"5ce298d5-0f3d-413e-a71c-2718c55bcfc0",
        "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
        "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
        "dropoff_note":"Do not ring the bell",
        "id_required":true,
        "assignment_id":186670,
        "batch_key":{
          "earliest":"2020-07-10",
          "latest":"2020-07-10",
          "client_id":1,
          "region_code":"SFO"
        },
        "region_code":"SFO",
        "timezone":"America/Los_Angeles",
        "service_level":"NEXT_DAY",
        "tracking_code":"z3njrddleqhsv7ma",
        "is_cancelled":false,
        "dropoff_access_code":"23412",
        "dropoff_uncharted":true
      },
      {
        "_created":"2020-07-09T03:44:34.185Z",
        "_updated":"2020-07-09T04:00:27.393Z",
        "id":4822493,
        "internal_id":"",
        "client_id":1,
        "job_id":7230,
        "workload":1,
        "customer":{
          "name":"Rashad Dummy",
          "phone_number":"3108170029",
          "email":"rashad@dummy.com"
        },
        "status":"CANCELLED_AFTER_PICKUP",
        "pickup_address":{
          "street2":"Hall A",
          "street":"2320 Warring Street",
          "city":"Berkeley",
          "state":"CA",
          "zipcode":"94704",
          "lat":37.868488,
          "lng":-122.251042
        },
        "origin_pickup_address":{

        },
        "dropoff_address":{
          "street2":"Detached Unit C",
          "street":"1218 Treat Avenue",
          "city":" San Francisco",
          "state":"CA",
          "zipcode":"94110",
          "lat":37.750623,
          "lng":-122.413104
        },
        "dropoff_geocoder_id":"ff7d7a97-c7d8-471b-9b6e-2405667e8696",
        "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
        "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
        "dropoff_note":"Place on the patio table",
        "id_required":true,
        "assignment_id":186670,
        "batch_key":{
          "earliest":"2020-07-10",
          "latest":"2020-07-10",
          "client_id":1,
          "region_code":"SFO"
        },
        "region_code":"SFO",
        "timezone":"America/Los_Angeles",
        "service_level":"NEXT_DAY",
        "tracking_code":"hzc9l5tn6e78czmq",
        "is_cancelled":true,
        "dropoff_access_code":"012",
        "dropoff_uncharted":false
      },
      {
        "_created":"2020-07-09T03:44:34.194Z",
        "_updated":"2020-07-09T04:15:40.595Z",
        "id":4822494,
        "internal_id":"",
        "client_id":1,
        "job_id":7230,
        "workload":1,
        "customer":{
          "name":"Eva Dummy",
          "phone_number":"3108170029",
          "email":"eva@dummy.com"
        },
        "status":"CANCELLED_AFTER_PICKUP",
        "pickup_address":{
          "street2":"Hall A",
          "street":"2320 Warring Street",
          "city":"Berkeley",
          "state":"CA",
          "zipcode":"94704",
          "lat":37.868488,
          "lng":-122.251042
        },
        "origin_pickup_address":{

        },
        "dropoff_address":{
          "street2":"Room 105",
          "street":"30 Jersey Street",
          "city":" San Francisco",
          "state":"CA",
          "zipcode":"94114",
          "lat":37.751235,
          "lng":-122.425782
        },
        "dropoff_geocoder_id":"7cba4a2a-9c85-466e-a38b-012752c7d4b8",
        "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
        "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
        "dropoff_note":"My house is hard to find, call me when you arrive",
        "id_required":true,
        "assignment_id":186670,
        "batch_key":{
          "earliest":"2020-07-10",
          "latest":"2020-07-10",
          "client_id":1,
          "region_code":"SFO"
        },
        "region_code":"SFO",
        "timezone":"America/Los_Angeles",
        "service_level":"NEXT_DAY",
        "tracking_code":"wbrtdgutk6k8cyuu",
        "is_cancelled":true,
        "dropoff_access_code":"123",
        "dropoff_uncharted":true
      },
      {
        "_created":"2020-07-09T03:44:34.205Z",
        "_updated":"2020-07-09T04:37:50.203Z",
        "id":4822495,
        "internal_id":"",
        "client_id":1,
        "job_id":7230,
        "workload":1,
        "customer":{
          "name":"David Dummy",
          "phone_number":"3108170029",
          "email":"david@dummy.com"
        },
        "status":"DROPOFF_FAILED",
        "pickup_address":{
          "street2":"Hall A",
          "street":"2320 Warring Street",
          "city":"Berkeley",
          "state":"CA",
          "zipcode":"94704",
          "lat":37.868488,
          "lng":-122.251042
        },
        "origin_pickup_address":{

        },
        "dropoff_address":{
          "street2":"Ceiling",
          "street":"1066 Guerrero Street",
          "city":" San Francisco",
          "state":"CA",
          "zipcode":"94110",
          "lat":37.754134,
          "lng":-122.423429
        },
        "dropoff_geocoder_id":"b789cc47-db0b-4cbd-b697-389504706359",
        "dropoff_earliest_ts":"2020-07-10T16:27:00.000Z",
        "dropoff_latest_ts":"2020-07-10T18:27:00.000Z",
        "dropoff_note":"Baby inside, leave the box quietly",
        "id_required":true,
        "assignment_id":186670,
        "batch_key":{
          "earliest":"2020-07-10",
          "latest":"2020-07-10",
          "client_id":1,
          "region_code":"SFO"
        },
        "region_code":"SFO",
        "timezone":"America/Los_Angeles",
        "service_level":"NEXT_DAY",
        "tracking_code":"yxaq2y6ljyh768j5",
        "is_cancelled":false,
        "dropoff_uncharted":true
      }
    ],
    "shipmentLabels":[
      {
        "id":87982,
        "shipment_id":4822493,
        "pickup_stop_id":9792678,
        "dropoff_stop_id":9792685,
        "driver_label":"AA-4"
      },
      {
        "id":87983,
        "shipment_id":4822494,
        "pickup_stop_id":9792679,
        "dropoff_stop_id":9792686,
        "driver_label":"AA-5"
      },
      {
        "id":87984,
        "shipment_id":4822495,
        "pickup_stop_id":9792680,
        "dropoff_stop_id":9792687,
        "driver_label":"AA-6"
      },
      {
        "id":87985,
        "shipment_id":4822489,
        "pickup_stop_id":9792681,
        "dropoff_stop_id":9792688,
        "driver_label":"AA-7"
      }
    ],
    "assignmentLabel":{
      "id":8956,
      "code":"yx8zd",
      "token":"e33168a5dcfb439db6510437af40fe50",
      "prefix":"AC",
      "assignment_id":186670
    },
    "clients":[
      {
        "name":"Skydeck",
        "email":"thuan+5@gojitsu.com",
        "company":"Skydeck Test",
        "timezone":"America/Los_Angeles",
        "id":1,
        "user_id":5,
        "phone_number":"+15108593068",
        "support_email":"support@gojitsu.com",
        "support_phone_number":"+12001003000",
        "twitter_id":"",
        "facebook_id":"123",
        "logo_url":"https://ah-staging-delivery.s3.us-west-2.amazonaws.com/clients/1/1571825403979-logo.png",
        "label_settings":{
          "version":{
            "PACKING":20,
            "PACKING_PLUS_SHIPPING":20
          }
        },
        "output_order_reserved":false,
        "twilio_enabled":true,
        "warehousing_enabled":true
      }
    ],
    "extra":{
      "bound":{
        "coordinates":[
          {
            "lat":37.793998,
            "lng":-122.413104
          },
          {
            "lat":37.750623,
            "lng":-122.413104
          },
          {
            "lat":37.750623,
            "lng":-122.425782
          },
          {
            "lat":37.793998,
            "lng":-122.425782
          },
          {
            "lat":37.793998,
            "lng":-122.413104
          }
        ]
      },
      "stats":{
        "1":{
          "DP":4
        }
      },
      "assignment_id":186670,
      "dropoff_status":"PS|PS|PS|PS|DI|DI|DF|D-|DP",
      "path":"kdleFzxcjV{BfnAaQuM??exFvE",
      "full_path":"aecfF~cdiV??????t_Vzs^{BfnAaQuM??exFvE"
    },
    "courier":{
      "id":8,
      "company":"Courier",
      "email":"cc@gojitsu.com",
      "phone_number":"510-510-510",
      "bank_verified":false,
      "user_id":427
    },
    "driver":{
      "id":915,
      "photo":"https://ah-staging-driver.s3.us-west-2.amazonaws.com/driver/profile/915/profile_1584437220.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20200714T171844Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Credential=AKIAUZ4OLYLINHWP35BR%2F20200714%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=a35b483c44ecbcb94c4d3b073f99f28a2126550aa782758394aa012f1f4b613b",
      "first_name":"🙂 Driver Lan",
      "last_name":"06",
      "birthday":"2020-03-16",
      "home_address":{
        "street":"9860 Maple Street",
        "city":"Bellflower",
        "state":"CA",
        "zipcode":"90706"
      },
      "phone_number":"+12223234345",
      "email":"landsp06@gmail.com",
      "driver_license":"123",
      "driver_license_state":"CT",
      "driver_license_expired_date":"2020-03-17",
      "social_security":"553-57-0070",
      "vehicle_type":"Sedan",
      "vehicle_year":2018,
      "vehicle_make":"Bugatti",
      "vehicle_model":"Chiron",
      "vehicle_color":"white",
      "vehicle_license_plate":"123",
      "vehicle_license_plate_state":"AK",
      "bank_account":"11111",
      "bank_routing":"11111",
      "agreement_url":"https://ah-staging-driver.s3.us-west-2.amazonaws.com/driver/profile/1/agreement-915.pdf",
      "agreement_version":3,
      "license_pic_url":"https://ah-staging-driver.s3.us-west-2.amazonaws.com/driver/profile/915/unassign_1585904707977",
      "insurance_card_pic_url":"https://s3-us-west-2.amazonaws.com/ah-staging-driver/driver/profile/915/insurance_card_1584437272.png",
      "background_status":"MANUAL_APPROVED",
      "background_provider":"turning.io",
      "background_decision_ts":"2020-03-16T08:37:24.114Z",
      "status":"BUSY",
      "ssn_verified":true,
      "ssn_verified_by":"423",
      "referred_by":0,
      "user_id":1201
    },
    "driverScore":0,
    "slots":[

    ],
    "pickup_groups":[
      {
        "name":"Inbound Failed",
        "actions":[
          "pending"
        ],
        "shipment_ids":[

        ]
      },
      {
        "name":"Inbound Unconfirmed",
        "actions":[
          "missing",
          "damage",
          "remove"
        ],
        "shipment_ids":[

        ]
      },
      {
        "name":"Pickup Pending",
        "actions":[
          "mis-sorted",
          "damage",
          "remove"
        ],
        "shipment_ids":[

        ]
      },
      {
        "name":"Pickup Failed",
        "actions":[
          "damage",
          "remove"
        ],
        "shipment_ids":[

        ]
      },
      {
        "name":"Pickup Succeeded",
        "actions":[
          "pending",
          "damage",
          "remove"
        ],
        "shipment_ids":[
          4822489,
          4822493,
          4822494
        ]
      },
      {
        "name":"Completed",
        "actions":[

        ],
        "shipment_ids":[
          4822495
        ]
      }
    ],
    "lastUpdate":1594747138009,
    "bbox":[
      [
        -122.425782,
        37.750623
      ],
      [
        -122.413104,
        37.793998
      ]
    ],
    "completable":false,
    "isCompleted":false,
    "locations":[
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T03:59:23.979Z",
        "altitude":-6.1,
        "longitude":105.7983288,
        "driver_id":915,
        "latitude":20.9988823,
        "speed":1,
        "heading":228,
        "accuracy":23,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T03:59:36.551Z",
        "altitude":-6.5,
        "longitude":105.798115,
        "driver_id":915,
        "latitude":20.9987205,
        "speed":2,
        "heading":231,
        "accuracy":38,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T03:59:46.462Z",
        "altitude":-6.5,
        "longitude":105.7990421,
        "driver_id":915,
        "latitude":20.999148,
        "speed":7,
        "heading":64,
        "accuracy":47,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:00:01.586Z",
        "altitude":-6.1,
        "longitude":105.7982488,
        "driver_id":915,
        "latitude":20.9988,
        "speed":0,
        "heading":317,
        "accuracy":17,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:00:11.697Z",
        "altitude":-6.1,
        "longitude":105.7982443,
        "driver_id":915,
        "latitude":20.9988156,
        "speed":0,
        "heading":77,
        "accuracy":20,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:00:27.525Z",
        "altitude":-6.1,
        "longitude":105.798232,
        "driver_id":915,
        "latitude":20.9988132,
        "speed":0,
        "heading":257,
        "accuracy":17,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:00:40.924Z",
        "altitude":-14.7,
        "longitude":105.7982352,
        "driver_id":915,
        "latitude":20.9987795,
        "speed":0,
        "heading":163,
        "accuracy":26,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:00:51.001Z",
        "altitude":-6.1,
        "longitude":105.7982346,
        "driver_id":915,
        "latitude":20.9988059,
        "speed":0,
        "heading":0,
        "accuracy":15,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:01:06.915Z",
        "altitude":-14.7,
        "longitude":105.7982433,
        "driver_id":915,
        "latitude":20.9988098,
        "speed":0,
        "heading":51,
        "accuracy":19,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:01:21.896Z",
        "altitude":-14.7,
        "longitude":105.7982446,
        "driver_id":915,
        "latitude":20.9988116,
        "speed":0,
        "heading":5,
        "accuracy":15,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:01:32.015Z",
        "altitude":-14.7,
        "longitude":105.798245,
        "driver_id":915,
        "latitude":20.9988069,
        "speed":0,
        "heading":174,
        "accuracy":22,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:01:42.127Z",
        "altitude":-14.7,
        "longitude":105.7982446,
        "driver_id":915,
        "latitude":20.9988127,
        "speed":0,
        "heading":357,
        "accuracy":14,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:01:57.428Z",
        "altitude":-14.7,
        "longitude":105.7982449,
        "driver_id":915,
        "latitude":20.9988067,
        "speed":0,
        "heading":177,
        "accuracy":22,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:02:12.419Z",
        "altitude":-14.7,
        "longitude":105.7982441,
        "driver_id":915,
        "latitude":20.998806,
        "speed":0,
        "heading":16,
        "accuracy":20,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:02:22.484Z",
        "altitude":-14.7,
        "longitude":105.798245,
        "driver_id":915,
        "latitude":20.9988055,
        "speed":0,
        "heading":103,
        "accuracy":22,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:02:39.392Z",
        "altitude":-14.7,
        "longitude":105.7982438,
        "driver_id":915,
        "latitude":20.9988051,
        "speed":0,
        "heading":246,
        "accuracy":14,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:02:54.406Z",
        "altitude":-14.7,
        "longitude":105.7982443,
        "driver_id":915,
        "latitude":20.9988079,
        "speed":0,
        "heading":351,
        "accuracy":19,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:03:09.686Z",
        "altitude":-14.7,
        "longitude":105.798243,
        "driver_id":915,
        "latitude":20.9988037,
        "speed":0,
        "heading":196,
        "accuracy":21,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:03:24.644Z",
        "altitude":-14.7,
        "longitude":105.7982439,
        "driver_id":915,
        "latitude":20.9988067,
        "speed":0,
        "heading":332,
        "accuracy":19,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:03:34.750Z",
        "altitude":-14.7,
        "longitude":105.7982437,
        "driver_id":915,
        "latitude":20.9988052,
        "speed":0,
        "heading":190,
        "accuracy":15,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:03:44.848Z",
        "altitude":-14.7,
        "longitude":105.798244,
        "driver_id":915,
        "latitude":20.9988078,
        "speed":0,
        "heading":7,
        "accuracy":19,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:03:54.919Z",
        "altitude":-14.7,
        "longitude":105.7982431,
        "driver_id":915,
        "latitude":20.9988035,
        "speed":0,
        "heading":192,
        "accuracy":21,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:06:20.168Z",
        "altitude":-14.7,
        "longitude":105.7982446,
        "driver_id":915,
        "latitude":20.9988038,
        "speed":0,
        "heading":83,
        "accuracy":23,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:06:31.890Z",
        "altitude":-14.7,
        "longitude":105.7982437,
        "driver_id":915,
        "latitude":20.9988051,
        "speed":0,
        "heading":186,
        "accuracy":15,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:06:47.140Z",
        "altitude":-14.7,
        "longitude":105.7982441,
        "driver_id":915,
        "latitude":20.998808,
        "speed":0,
        "heading":6,
        "accuracy":19,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:07:07.163Z",
        "altitude":-14.7,
        "longitude":105.7982442,
        "driver_id":915,
        "latitude":20.9988069,
        "speed":0,
        "heading":16,
        "accuracy":19,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:07:17.341Z",
        "altitude":-14.7,
        "longitude":105.7982438,
        "driver_id":915,
        "latitude":20.9988053,
        "speed":0,
        "heading":194,
        "accuracy":14,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:07:37.600Z",
        "altitude":-14.7,
        "longitude":105.7982448,
        "driver_id":915,
        "latitude":20.9988051,
        "speed":0,
        "heading":84,
        "accuracy":22,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:07:52.582Z",
        "altitude":-14.7,
        "longitude":105.7982446,
        "driver_id":915,
        "latitude":20.9988131,
        "speed":0,
        "heading":1,
        "accuracy":14,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:08:08.098Z",
        "altitude":-14.7,
        "longitude":105.7982438,
        "driver_id":915,
        "latitude":20.9988058,
        "speed":0,
        "heading":186,
        "accuracy":14,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:08:18.210Z",
        "altitude":-14.7,
        "longitude":105.7982429,
        "driver_id":915,
        "latitude":20.9988023,
        "speed":0,
        "heading":192,
        "accuracy":20,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:08:38.458Z",
        "altitude":-14.7,
        "longitude":105.7982446,
        "driver_id":915,
        "latitude":20.9988062,
        "speed":0,
        "heading":32,
        "accuracy":21,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:08:53.500Z",
        "altitude":-14.7,
        "longitude":105.7982428,
        "driver_id":915,
        "latitude":20.9988026,
        "speed":0,
        "heading":198,
        "accuracy":15,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:09:08.762Z",
        "altitude":-14.7,
        "longitude":105.7982441,
        "driver_id":915,
        "latitude":20.9988084,
        "speed":0,
        "heading":11,
        "accuracy":17,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:09:28.967Z",
        "altitude":-14.7,
        "longitude":105.7982442,
        "driver_id":915,
        "latitude":20.9988064,
        "speed":0,
        "heading":178,
        "accuracy":20,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:09:43.993Z",
        "altitude":-14.7,
        "longitude":105.7982443,
        "driver_id":915,
        "latitude":20.9988089,
        "speed":0,
        "heading":356,
        "accuracy":17,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:09:59.627Z",
        "altitude":-14.7,
        "longitude":105.7982434,
        "driver_id":915,
        "latitude":20.9988047,
        "speed":0,
        "heading":191,
        "accuracy":15,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:10:09.757Z",
        "altitude":-14.7,
        "longitude":105.7982441,
        "driver_id":915,
        "latitude":20.9988061,
        "speed":0,
        "heading":31,
        "accuracy":20,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:10:19.868Z",
        "altitude":-14.7,
        "longitude":105.7982446,
        "driver_id":915,
        "latitude":20.9988129,
        "speed":0,
        "heading":4,
        "accuracy":14,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:10:34.906Z",
        "altitude":-14.7,
        "longitude":105.7982449,
        "driver_id":915,
        "latitude":20.9988055,
        "speed":0,
        "heading":173,
        "accuracy":22,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:10:50.166Z",
        "altitude":-14.7,
        "longitude":105.7982444,
        "driver_id":915,
        "latitude":20.9988086,
        "speed":0,
        "heading":350,
        "accuracy":17,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:11:00.372Z",
        "altitude":-6.1,
        "longitude":105.7982314,
        "driver_id":915,
        "latitude":20.9987928,
        "speed":0,
        "heading":218,
        "accuracy":22,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:11:11.130Z",
        "altitude":-6.1,
        "longitude":105.7982297,
        "driver_id":915,
        "latitude":20.9987865,
        "speed":0,
        "heading":202,
        "accuracy":16,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:14:40.143Z",
        "altitude":-6.1,
        "longitude":105.7982284,
        "driver_id":915,
        "latitude":20.9987862,
        "speed":-1,
        "heading":-1,
        "accuracy":24,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:14:52.457Z",
        "altitude":-6.1,
        "longitude":105.7982288,
        "driver_id":915,
        "latitude":20.9987871,
        "speed":-1,
        "heading":-1,
        "accuracy":23,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:15:02.609Z",
        "altitude":-6.1,
        "longitude":105.7982295,
        "driver_id":915,
        "latitude":20.9987848,
        "speed":0,
        "heading":163,
        "accuracy":19,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:15:13.009Z",
        "altitude":-6.1,
        "longitude":105.7982292,
        "driver_id":915,
        "latitude":20.9987872,
        "speed":0,
        "heading":354,
        "accuracy":22,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:15:24.652Z",
        "altitude":-6.1,
        "longitude":105.7982302,
        "driver_id":915,
        "latitude":20.9987871,
        "speed":0,
        "heading":95,
        "accuracy":16,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:15:38.076Z",
        "altitude":-6.1,
        "longitude":105.7982285,
        "driver_id":915,
        "latitude":20.9987858,
        "speed":0,
        "heading":297,
        "accuracy":24,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:15:48.121Z",
        "altitude":-6.1,
        "longitude":105.7982285,
        "driver_id":915,
        "latitude":20.9987819,
        "speed":0,
        "heading":181,
        "accuracy":22,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:15:58.233Z",
        "altitude":-6.1,
        "longitude":105.7982299,
        "driver_id":915,
        "latitude":20.9987845,
        "speed":0,
        "heading":26,
        "accuracy":17,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:16:08.338Z",
        "altitude":-6.1,
        "longitude":105.7982292,
        "driver_id":915,
        "latitude":20.9987831,
        "speed":0,
        "heading":204,
        "accuracy":21,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:16:23.581Z",
        "altitude":-6.1,
        "longitude":105.7982294,
        "driver_id":915,
        "latitude":20.998789,
        "speed":0,
        "heading":1,
        "accuracy":22,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:16:33.710Z",
        "altitude":-6.1,
        "longitude":105.7982291,
        "driver_id":915,
        "latitude":20.9987838,
        "speed":0,
        "heading":183,
        "accuracy":21,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:16:48.744Z",
        "altitude":-6.1,
        "longitude":105.7982294,
        "driver_id":915,
        "latitude":20.9987833,
        "speed":0,
        "heading":198,
        "accuracy":20,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:17:04.000Z",
        "altitude":-6.1,
        "longitude":105.7982305,
        "driver_id":915,
        "latitude":20.9987929,
        "speed":0,
        "heading":6,
        "accuracy":20,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:17:24.234Z",
        "altitude":-6.1,
        "longitude":105.7982283,
        "driver_id":915,
        "latitude":20.998784,
        "speed":0,
        "heading":195,
        "accuracy":24,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:17:39.289Z",
        "altitude":-6.1,
        "longitude":105.7982287,
        "driver_id":915,
        "latitude":20.9987841,
        "speed":0,
        "heading":357,
        "accuracy":23,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:17:49.356Z",
        "altitude":-6.1,
        "longitude":105.798229,
        "driver_id":915,
        "latitude":20.9987852,
        "speed":0,
        "heading":11,
        "accuracy":22,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:17:59.425Z",
        "altitude":-6.1,
        "longitude":105.7982301,
        "driver_id":915,
        "latitude":20.9987905,
        "speed":0,
        "heading":11,
        "accuracy":21,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:18:09.511Z",
        "altitude":-6.1,
        "longitude":105.7982297,
        "driver_id":915,
        "latitude":20.9987854,
        "speed":0,
        "heading":182,
        "accuracy":19,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:18:19.593Z",
        "altitude":-6.1,
        "longitude":105.79823,
        "driver_id":915,
        "latitude":20.9987847,
        "speed":0,
        "heading":158,
        "accuracy":17,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:18:29.686Z",
        "altitude":-6.1,
        "longitude":105.7982302,
        "driver_id":915,
        "latitude":20.9987853,
        "speed":0,
        "heading":28,
        "accuracy":15,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:18:39.783Z",
        "altitude":-6.1,
        "longitude":105.7982287,
        "driver_id":915,
        "latitude":20.9987861,
        "speed":0,
        "heading":300,
        "accuracy":24,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:18:55.044Z",
        "altitude":-6.1,
        "longitude":105.7982287,
        "driver_id":915,
        "latitude":20.9987812,
        "speed":0,
        "heading":181,
        "accuracy":21,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:19:10.105Z",
        "altitude":-6.1,
        "longitude":105.7982286,
        "driver_id":915,
        "latitude":20.9987847,
        "speed":0,
        "heading":225,
        "accuracy":24,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:19:25.369Z",
        "altitude":-6.1,
        "longitude":105.7982303,
        "driver_id":915,
        "latitude":20.9987931,
        "speed":0,
        "heading":10,
        "accuracy":20,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:19:40.455Z",
        "altitude":-6.1,
        "longitude":105.7982293,
        "driver_id":915,
        "latitude":20.9987845,
        "speed":0,
        "heading":185,
        "accuracy":21,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:31:20.942Z",
        "altitude":-14.8,
        "longitude":105.7982314,
        "driver_id":915,
        "latitude":20.9987815,
        "speed":-1,
        "heading":-1,
        "accuracy":19,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:35:51.508Z",
        "altitude":-14.8,
        "longitude":105.7982389,
        "driver_id":915,
        "latitude":20.9987806,
        "speed":-1,
        "heading":-1,
        "accuracy":19,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:37:04.526Z",
        "altitude":-1,
        "longitude":105.798199,
        "driver_id":915,
        "latitude":20.9988121,
        "speed":-1,
        "heading":-1,
        "accuracy":75,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:37:49.119Z",
        "altitude":97,
        "longitude":105.7988323,
        "driver_id":915,
        "latitude":20.9992206,
        "speed":3,
        "heading":51,
        "accuracy":49,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:39:52.062Z",
        "altitude":-1,
        "longitude":105.7988743,
        "driver_id":915,
        "latitude":20.9992617,
        "speed":-1,
        "heading":-1,
        "accuracy":56,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:42:00.089Z",
        "altitude":-6.1,
        "longitude":105.7981783,
        "driver_id":915,
        "latitude":20.9988325,
        "speed":-1,
        "heading":-1,
        "accuracy":24,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:42:12.461Z",
        "altitude":-6.1,
        "longitude":105.7981604,
        "driver_id":915,
        "latitude":20.9988353,
        "speed":-1,
        "heading":-1,
        "accuracy":25,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:42:32.675Z",
        "altitude":-6.1,
        "longitude":105.7981685,
        "driver_id":915,
        "latitude":20.9988331,
        "speed":0,
        "heading":275,
        "accuracy":27,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:42:47.720Z",
        "altitude":-6.1,
        "longitude":105.7981576,
        "driver_id":915,
        "latitude":20.9988358,
        "speed":0,
        "heading":94,
        "accuracy":25,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:42:57.826Z",
        "altitude":-6.1,
        "longitude":105.7981638,
        "driver_id":915,
        "latitude":20.998834,
        "speed":0,
        "heading":106,
        "accuracy":27,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:43:13.069Z",
        "altitude":-6.1,
        "longitude":105.7981906,
        "driver_id":915,
        "latitude":20.9988438,
        "speed":0,
        "heading":70,
        "accuracy":110,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:43:34.923Z",
        "altitude":-6.1,
        "longitude":105.798187,
        "driver_id":915,
        "latitude":20.9988239,
        "speed":0,
        "heading":178,
        "accuracy":25,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:43:49.976Z",
        "altitude":-6.1,
        "longitude":105.7981731,
        "driver_id":915,
        "latitude":20.998832,
        "speed":0,
        "heading":2,
        "accuracy":27,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:44:05.000Z",
        "altitude":-6.1,
        "longitude":105.7981958,
        "driver_id":915,
        "latitude":20.9988455,
        "speed":0,
        "heading":59,
        "accuracy":37,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:44:17.000Z",
        "altitude":-6.1,
        "longitude":105.7982077,
        "driver_id":915,
        "latitude":20.9988512,
        "speed":0,
        "heading":61,
        "accuracy":30,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:44:30.165Z",
        "altitude":-6.1,
        "longitude":105.7982017,
        "driver_id":915,
        "latitude":20.9988488,
        "speed":0,
        "heading":249,
        "accuracy":42,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:44:53.000Z",
        "altitude":-6.1,
        "longitude":105.7981786,
        "driver_id":915,
        "latitude":20.9988324,
        "speed":0,
        "heading":283,
        "accuracy":47,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:45:05.473Z",
        "altitude":-6.1,
        "longitude":105.7981869,
        "driver_id":915,
        "latitude":20.9988393,
        "speed":0,
        "heading":44,
        "accuracy":81,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:45:15.572Z",
        "altitude":-6.1,
        "longitude":105.7981994,
        "driver_id":915,
        "latitude":20.9988479,
        "speed":0,
        "heading":50,
        "accuracy":63,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:45:34.000Z",
        "altitude":-6.1,
        "longitude":105.7981789,
        "driver_id":915,
        "latitude":20.9988322,
        "speed":0,
        "heading":105,
        "accuracy":43,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:45:46.272Z",
        "altitude":-6.1,
        "longitude":105.7981893,
        "driver_id":915,
        "latitude":20.9988356,
        "speed":0,
        "heading":77,
        "accuracy":92,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:45:57.000Z",
        "altitude":-6.1,
        "longitude":105.7981914,
        "driver_id":915,
        "latitude":20.9988393,
        "speed":0,
        "heading":45,
        "accuracy":60,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:46:08.436Z",
        "altitude":-6.1,
        "longitude":105.7981942,
        "driver_id":915,
        "latitude":20.9988436,
        "speed":0,
        "heading":37,
        "accuracy":81,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:46:27.021Z",
        "altitude":-6.1,
        "longitude":105.7981621,
        "driver_id":915,
        "latitude":20.9988337,
        "speed":0,
        "heading":273,
        "accuracy":25,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:46:41.989Z",
        "altitude":-6.1,
        "longitude":105.7981771,
        "driver_id":915,
        "latitude":20.9988368,
        "speed":0,
        "heading":72,
        "accuracy":123,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:46:52.104Z",
        "altitude":-6.1,
        "longitude":105.7981684,
        "driver_id":915,
        "latitude":20.9988351,
        "speed":0,
        "heading":251,
        "accuracy":26,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T04:47:00.480Z",
        "altitude":-6.1,
        "longitude":105.7981684,
        "driver_id":915,
        "latitude":20.9988351,
        "speed":0,
        "heading":251,
        "accuracy":26,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T05:01:20.081Z",
        "altitude":-14.7,
        "longitude":105.7982371,
        "driver_id":915,
        "latitude":20.9987766,
        "speed":-1,
        "heading":-1,
        "accuracy":15,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T05:16:19.989Z",
        "altitude":-9.2,
        "longitude":105.7982474,
        "driver_id":915,
        "latitude":20.9987859,
        "speed":-1,
        "heading":-1,
        "accuracy":15,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T05:31:20.005Z",
        "altitude":-9.2,
        "longitude":105.798213,
        "driver_id":915,
        "latitude":20.9987597,
        "speed":0,
        "heading":195,
        "accuracy":48,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T05:46:20.045Z",
        "altitude":-6.1,
        "longitude":105.7982008,
        "driver_id":915,
        "latitude":20.998822,
        "speed":-1,
        "heading":-1,
        "accuracy":25,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T06:01:22.861Z",
        "altitude":-6.1,
        "longitude":105.7982406,
        "driver_id":915,
        "latitude":20.9987952,
        "speed":-1,
        "heading":-1,
        "accuracy":19,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T06:16:20.001Z",
        "altitude":-14.8,
        "longitude":105.7982263,
        "driver_id":915,
        "latitude":20.9987778,
        "speed":-1,
        "heading":-1,
        "accuracy":25,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T06:31:19.986Z",
        "altitude":-6.1,
        "longitude":105.7982438,
        "driver_id":915,
        "latitude":20.9987867,
        "speed":-1,
        "heading":-1,
        "accuracy":16,
        "delivery_id":"AS_186670"
      },
      {
        "assignment_id":186670,
        "timestamp":"2020-07-09T06:46:25.941Z",
        "altitude":-6.1,
        "longitude":105.7982403,
        "driver_id":915,
        "latitude":20.9987832,
        "speed":-1,
        "heading":-1,
        "accuracy":14,
        "delivery_id":"AS_186670"
      }
    ]
  },
  locations: null,
  previewShipment: null,
  driverLocation: null,
  // shipment: null,
}