import React from 'react';
import {Tabs, Tab} from './styles';
import * as M from '@material-ui/core';
import {lightTheme, mainTheme, outlineTheme} from './styles';

const mapToTheme = {
  'light': lightTheme,
  'main': mainTheme,
  'outline': outlineTheme,
}

export function AxlSimpleTabs({children, tabs = [], theme = 'light', ...props}) {
  return(<M.ThemeProvider theme={mapToTheme[theme]}>
    <M.Tabs {...props}>
      {tabs.map((props, id) => <M.Tab key={id} {...props} />)}
    </M.Tabs>
  </M.ThemeProvider>);
}

export function AxlSimpleTab({children, ...props}) {
  return(<Tab {...props}>{children}</Tab>);
}