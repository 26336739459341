import * as M from '@material-ui/core';
import React from "react";
import _ from 'lodash';

export const Container = M.styled(M.Box)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const ScrollInner = M.styled(M.Box)(({theme}) => ({
  overflowY: 'scroll',
  padding: '5px 0',
}));

export const Row = M.styled(M.Box)(({theme, active}) => ({
  cursor: 'pointer',
  position: 'relative',
  zIndex: active ? 1 : 0,
  marginBottom: -4,
  borderRadius: active ? 9 : 0,
  padding: active ? '5px 8px 8px' : theme.spacing(1),
  borderTop: active ? `4px solid ${theme.palette.primary.periwinkle}` : `1px solid ${theme.palette.primary.gray}`,
  borderRight: `4px solid ${active ? theme.palette.primary.periwinkle : theme.palette.primary.transparent}`,
  borderBottom: `4px solid ${active ? theme.palette.primary.periwinkle : theme.palette.primary.transparent}`,
  borderLeft: `4px solid ${active ? theme.palette.primary.periwinkle : theme.palette.primary.transparent}`,
  boxShadow: `2px 2px 6px 0 ${active ? 'rgba(155, 155, 155, 0.54)' : 'transparent'}`,
}));

export const Logo = M.styled(M.Avatar)(({theme}) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const Text = M.styled(M.Typography)(({theme, color}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13,
  fontWeight: 600,
  color: color || theme.palette.primary.blackSecondary,
  marginBottom: 5
}));

export const SectionTitle = M.styled(Text)(({theme}) => ({
  fontSize: 13,
  margin: '10px 0 15px',
}));

export const FilterLabel = M.styled(Text)(({theme}) => ({
  fontSize: 13,
  margin: '0 5px',
}));

export const Label = M.styled(Text)(({theme}) => ({
  color: theme.palette.primary.graySecond,
  fontSize: 12,
  fontFamily: 'AvenirNext-Medium',
  margin: '7px 0 3px',
}));

export const ButtonText = M.styled(Text)(({theme}) => ({
  color: '#FFF',
  fontSize: 11,
  margin: 0,
}));
export const ButtonDateText = M.styled(Text)(({theme}) => ({
  fontSize: 11,
  margin: 0,
}));


