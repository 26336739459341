import React from 'react';
import * as M from '@material-ui/core';

export const ListContainer = M.styled(M.Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  boxSizing: 'border-box',
})

export const Box = M.styled(M.Box)(({theme}) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

export const Text = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.text.third,
  lineHeight: 1.3
}));

export const Text_1 = M.styled(Text)(({theme}) => ({
  marginBottom: theme.spacing(0.8)
}));

export const Title = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 15,
}));

export const B = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
}));

export const Label = M.styled(Text)(({theme}) => ({
  color: theme.palette.primary.graySecond,
  margin: '5px 0 3px'
}));

export const PopupContainer = M.styled(M.Box)(({}) => ({
  maxHeight: 'calc(100vh - 200px)',
  width: '550px',
  maxWidth: '100%',
  overflowY: 'hidden',
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}));

export const Scroll = M.styled(M.Box)(({}) => ({
  overflowY: 'scroll',
  flex: 1,
  height: '100%',
}));

export const SliderPopupContainer = M.styled(M.Box)(({}) => ({
  maxHeight: 'calc(100vh - 200px)',
  maxWidth: '550px',
  overflow: 'hidden'
}));

export const PopupTitle = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Bold',
  fontSize: 18,
  fontWeight: 'bold',
  letterSpacing: -0.22,
  color: theme.palette.primary['greyish-brown'],
  marginBottom: theme.spacing(2)
}));
