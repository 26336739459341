import React, { useState, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';

import moment from 'moment-timezone';
import produce from 'immer';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Container from '@material-ui/core/Container';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';

import SearchRow from './SearchRow';
import SearchInput from './SearchInput';
import AxlModal from '../AxlModal';
import ImagePreviewer from '../ImagePreviewer';
import { EmptyTable } from '../AxlTable';
import { searchShipment } from '../../repositories/Api';

// style

import useStyles, { PaginationContainer, TableHeadSearch, SearchPagination } from './styles';

const SearchList = () => {
  const history = useHistory();
  const classes = useStyles();
  const [result, setResult] = useState({ from: 0, results: [], size: 15, total: 0 });
  const [countPage, setCountPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [popupData, setPopupData] = useState(false);
  const params = useRef({
    from: 0,
    size: 15,
    q: '',
    filters: {},
    sorts: ['-dropoff_earliest_ts'],
  });
  //   const [params, setParams] = useState({
  //     from: 0,
  //     size: 15,
  //     q: '',
  //     filters: {},
  //     sorts: ['-dropoff_earliest_ts'],
  //   });
  console.log('render', popupData);
  const handleSearchResult = (input) => {
    setIsLoading(true);
    searchShipment(input).then((resp) => {
      setIsLoading(false);
      if (resp.ok) {
        const { data } = resp;
        setResult(data);
        if (data) {
          const { total, size } = data;
          const count = Math.ceil(total / size);
          console.log('countPage', countPage);
          setCountPage(count);
        }
      }
    });
  };
  useEffect(() => {
    handleSearchResult(params.current);
  }, []);
  const handleChangePage = (e, number) => {
    params.current = produce(params.current, (draft) => {
      draft.from = Math.max(0, number - 1) * draft.size;
    });
    setCurrentPage(number);
    handleSearchResult(params.current);
  };
  const searchAction = (value) => {
    params.current = produce(params.current, (draft) => {
      draft.q = value;
    });
    handleSearchResult(params.current);
  };
  const clickRow = (value) => {
    if (value) {
      history.push(`/search/detail/${value}`);
    }
  };
  return (
    <Box className={classes.boxRoot}>
      <SearchInput searchAction={searchAction} />
      <TableContainer className={classes.tableContainer} component={Box}>
        <Table className={classes.table}>
          <TableHead className={classes.head}>
            <TableRow>
              <TableHeadSearch className="w20">Order Info</TableHeadSearch>
              <TableHeadSearch className="w30">Customer Info</TableHeadSearch>
              <TableHeadSearch className="w20">Driver Info</TableHeadSearch>
              <TableHeadSearch>Status</TableHeadSearch>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tBody}>
            {result.results.length ? (
              result.results.map((data) => {
                return <SearchRow data={data} setPopupData={setPopupData} clickRow={clickRow} />;
              })
            ) : (
              <EmptyTable rows={Math.round((window.outerHeight - 300) / 65)} columns={4} />
            )}
          </TableBody>
          {isLoading && (
            <Box
              className={classes.loadingContainer}
              width={'100%'}
              height={'100%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <CircularProgress size={75} thickness={1} />
            </Box>
          )}
        </Table>
        <Modal
          open={popupData}
          className={classes.modal}
          onClose={() => {
            console.log('close');
            setPopupData(false);
          }}
        >
          {popupData && <ImagePreviewer hideSendBtn images={popupData} textBottomAlign={'center'} />}
        </Modal>
      </TableContainer>
      <PaginationContainer>
        <SearchPagination
          page={currentPage}
          onChange={handleChangePage}
          count={countPage}
          className={classes.pagination}
        />
      </PaginationContainer>
    </Box>
  );
};

export default SearchList;
