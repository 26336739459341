import React from 'react';
import { useTheme } from '@material-ui/core/styles';

export const SHIPMENT_STATUSES = {
  ASSIGNED: 'ASSIGNED',
  CANCELLED_AFTER_PICKUP: 'CANCELLED_AFTER_PICKUP',
  CANCELLED_BEFORE_PICKUP: 'CANCELLED_BEFORE_PICKUP',
  CREATED: 'CREATED',
  DELIVERED: 'DELIVERED',
  DELIVERY_FAILED: 'DELIVERY_FAILED',
  DROPOFF_DELAY: 'DROPOFF_DELAY',
  DROPOFF_EN_ROUTE: 'DROPOFF_EN_ROUTE',
  DROPOFF_FAILED: 'DROPOFF_FAILED',
  DROPOFF_READY: 'DROPOFF_READY',
  DROPOFF_SUCCEEDED: 'SUCCEEDED',
  EN_ROUTE: 'EN_ROUTE',
  GEOCODED: 'GEOCODED',
  GEOCODE_FAILED: 'GEOCODE_FAILED',
  GEOCODE_FAILED_DROPOFF: 'GEOCODE_FAILED_DROPOFF',
  GEOCODE_FAILED_PICKUP: 'GEOCODE_FAILED_PICKUP',
  GEOCODE_FAILED_WITH_SUGGESTIONS: 'GEOCODE_FAILED_WITH_SUGGESTIONS',
  PENDING: 'PENDING',
  PICKUP_DELAY: 'PICKUP_DELAY',
  PICKUP_EN_ROUTE: 'PICKUP_EN_ROUTE',
  PICKUP_FAILED: 'PICKUP_FAILED',
  PICKUP_READY: 'PICKUP_READY',
  PICKUP_SUCCEEDED: 'PICKUP_SUCCEEDED',
  REATTEMPT_DELIVERED: 'REATTEMPT_DELIVERED',
  REATTEMPT_DELIVERY_FAILED: 'REATTEMPT_DELIVERY_FAILED',
  REATTEMPT_EN_ROUTE: 'REATTEMPT_EN_ROUTE',
  REATTEMPTING: 'REATTEMPTING',
  RECEIVED: 'RECEIVED',
  RESCHEDULED: 'RESCHEDULED',
  RETURN_FAILED: 'RETURN_FAILED',
  RETURN_READY: 'RETURN_READY',
  RETURN_SUCCEEDED: 'RETURN_SUCCEEDED',
  UNDELIVERABLE: 'UNDELIVERABLE',
  UNSERVICEABLE: 'UNSERVICEABLE',
};

export const SHIPMENT_STATUS_TO_SHORT_STATUS = {
  ASSIGNED: 'ASSIGNED',
  CANCELLED_AFTER_PICKUP: 'CANCELLED_AFTER_PICKUP',
  CANCELLED_BEFORE_PICKUP: 'CANCELLED_BEFORE_PICKUP',
  CREATED: 'CREATED',
  DELIVERED: 'DELIVERED',
  DELIVERY_FAILED: 'DELIVERY_FAILED',
  DROPOFF_DELAY: 'DROPOFF_DELAY',
  DROPOFF_EN_ROUTE: 'DROPOFF_EN_ROUTE',
  DROPOFF_FAILED: 'FAILED',
  DROPOFF_READY: 'DROPOFF_READY',
  DROPOFF_SUCCEEDED: 'SUCCEEDED',
  EN_ROUTE: 'EN_ROUTE',
  GEOCODED: 'GEOCODED',
  GEOCODE_FAILED: 'GEOCODE_FAILED',
  GEOCODE_FAILED_DROPOFF: 'GEOCODE_FAILED_DROPOFF',
  GEOCODE_FAILED_PICKUP: 'GEOCODE_FAILED_PICKUP',
  GEOCODE_FAILED_WITH_SUGGESTIONS: 'GEOCODE_FAILED_WITH_SUGGESTIONS',
  PENDING: 'PENDING',
  PICKUP_DELAY: 'PICKUP_DELAY',
  PICKUP_EN_ROUTE: 'PICKUP_EN_ROUTE',
  PICKUP_FAILED: 'FAILED',
  PICKUP_READY: 'PICKUP_READY',
  PICKUP_SUCCEEDED: 'PICKUP_SUCCEEDED',
  REATTEMPT_DELIVERED: 'REATTEMPT_DELIVERED',
  REATTEMPT_DELIVERY_FAILED: 'REATTEMPT_DELIVERY_FAILED',
  REATTEMPT_EN_ROUTE: 'REATTEMPT_EN_ROUTE',
  REATTEMPTING: 'REATTEMPTING',
  RECEIVED: 'RECEIVED',
  RESCHEDULED: 'RESCHEDULED',
  RETURN_DAMAGED: 'RETURN_DAMAGED',
  RETURN_DELAY: 'RETURN_DELAY',
  RETURN_EN_ROUTE: 'RETURN_EN_ROUTE',
  RETURN_FAILED: 'RETURN_FAILED',
  RETURN_READY: 'RETURN_READY',
  RETURN_SUCCEEDED: 'RETURN_SUCCEEDED',
  UNDELIVERABLE: 'UNDELIVERABLE',
  UNSERVICEABLE: 'UNSERVICEABLE',
};

export function SHIPMENT_STATUS_TO_COLORS() {
  const theme = useTheme();
  return ({
    CREATED: theme.palette.primary.textMain,
    PENDING: theme.palette.primary.cream,
    SUCCEEDED: theme.palette.primary.green,
    DELIVERED: theme.palette.primary.green,
    DROPOFF_SUCCEEDED: theme.palette.primary.green,
    PICKUP_SUCCEEDED: theme.palette.primary.green,
    DROPOFF_FAILED: theme.palette.primary.redSecond,
    PICKUP_FAILED: theme.palette.primary.redSecond,
    CANCELLED_BEFORE_PICKUP: theme.palette.primary.redSecond,
    CANCELLED_AFTER_PICKUP: theme.palette.primary.redSecond,
  });
}

export const serviceLevelToMap = {
  NEXT_DAY: 'Next Day',
  SAME_DAY: 'Same Day',
}

export const SHIPMENT_OUTBOUND_EVENTS = {
  PROCESSING: 'Processing',
  PROCESSED: 'Processed',
  RECEIVED: 'Received at facility',
  FAILED: 'Failed',
  RETURNED: 'Returned',
  CANCELLED: 'Cancelled',
  OUT_FOR_DELIVERY: 'Out for Delivery',
  REATTEMPTING: 'Re-attempting',
  NEXT_IN_QUEUE: 'Next in Queue',
  UNDELIVERABLE_SH: 'Undeliverable',
  DELIVERED: 'Delivered',
  INFORM_RECIPIENT_PICKUP_SUCCEEDED: 'INFORM_RECIPIENT_PICKUP_SUCCEEDED',
  INFORM_RECIPIENT_PICKUP_FAILED: 'INFORM_RECIPIENT_PICKUP_FAILED',
  INFORM_RECIPIENT_COMING_SOON: 'INFORM_RECIPIENT_COMING_SOON',
  INFORM_RECIPIENT_NEXT_IN_LINE: 'INFORM_RECIPIENT_NEXT_IN_LINE',
  INFORM_RECIPIENT_DROPOFF_SUCCEEDED: 'INFORM_RECIPIENT_DROPOFF_SUCCEEDED',
  INFORM_RECIPIENT_DROPOFF_FAILED: 'INFORM_RECIPIENT_DROPOFF_FAILED',
  NOT_FOUND: 'NOT_FOUND',
  LEAKING: 'LEAKING',
  DAMAGED: 'DAMAGED',
}
