import React, {useEffect, useState} from 'react';
import * as M from '@material-ui/core';
import * as S from "./styles";
import DeliveryHistoryList from "../DeliveryHistoryList";
import CallHistoryList from "../CallHistoryList";
import AddressHistoryList from "../AddressHistoryList";
import {AxlSimpleTab, AxlSimpleTabs} from "../AxlTab";
import {AxlSimpleBox} from "../AxlBox";
import ShipmentTrackingEvents from "../ShipmentTrackingEvents";
import ShipmentChatLog from "../ShipmentChatLog";
import CallLogsContainer from "../CallLogsContainer";

export default function CallCenterShipmentLogs() {
  const [historyOption, setHistoryOption] = useState(0);

  const handleChange = (event, newValue) => {
    setHistoryOption(newValue);
  };

  return(<S.Container square bgcolor={'primary.main'}>
    <AxlSimpleTabs
      theme={`main`}
      variant={`fullWidth`}
      bgcolor={'primary.grayMain'}
      value={historyOption}
      onChange={handleChange}
      tabs={[
        {label: "Tracking events", bgActiveColor: 'primary.main'},
        {label: "Chat Log"},
        {label: "Call Log"},
      ]}>
    </AxlSimpleTabs>
    <S.Inner height={1} bgcolor={'primary.main'}>
      {historyOption === 0 && <ShipmentTrackingEvents/>}
      {historyOption === 1 && <ShipmentChatLog/>}
      {historyOption === 2 && <CallLogsContainer />}
    </S.Inner>
  </S.Container>);
}