import React, {useEffect} from "react";
import _ from "lodash";
import * as S from "./styles";
import * as M from "@material-ui/core";
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ImagePreviewer from "../ImagePreviewer";
import {SHIPMENT_STATUS_TO_COLORS, SHIPMENT_STATUS_TO_SHORT_STATUS} from "../../constants/shipment";
import AddressHistoryImagePreview from "../AddressHistoryImagePreview";
import {useRecoilState, useRecoilValue} from "recoil/dist";
import {AssignmentClientSelector, CurrentSearchShipmentSelector} from "../../states/callCenterStates";

export default function AddressHistory({shipment, client, dropoff, pod = {images: [], signatures: []}, enableImageMode, trigger, triggerPin}) {
  const preventDefault    = (event) => event.preventDefault();
  const trackingCodeName  = _.defaultTo(shipment.tracking_code, null);
  const trackingCodeLink  = _.defaultTo(process.env.REACT_APP_TRACKING_CODE_URL + shipment.tracking_code, '');
  const shipmentColorStatus = _.defaultTo(SHIPMENT_STATUS_TO_COLORS()[shipment.status], SHIPMENT_STATUS_TO_COLORS()['CREATED']);
  const assignmentClient = useRecoilValue(AssignmentClientSelector);
  const [currentShipmentSearch, setCurrentShipmentSearch] = useRecoilState(CurrentSearchShipmentSelector);
  // States
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    if(!enableImageMode) {
      setOpen(true);
    } else {
      trigger({shipment, client, dropoff, pod});
    }
  };

  const handleClose = () => {
    if(!enableImageMode) {
      setOpen(false);
    }
  };

  const handlePinOpen = () => {
    triggerPin({shipment, dropoff});
  }

  const actualTs = dropoff?.actual_departure_ts || dropoff?.actual_arrival_ts || null;

  return(<M.Box maxWidth={1}>
    <M.Grid container>
      <M.Grid item xs={6}>
        <S.ShipmentTime>{actualTs ? moment(actualTs).format('MM/DD/YYYY') : '-'}<S.ShipmentId component={'span'}>{shipment.id}</S.ShipmentId></S.ShipmentTime>
      </M.Grid>
      <M.Grid item xs={6}>
        <S.ShipmentStatus style={{color: SHIPMENT_STATUS_TO_COLORS()[shipment.status]}}>{`${SHIPMENT_STATUS_TO_SHORT_STATUS[shipment.status]} ${actualTs ? moment(actualTs).format('@hh:mmA') : ''}`}</S.ShipmentStatus>
      </M.Grid>
    </M.Grid>
    <S.BoxDetail>
      <M.Grid container direction={'row'} alignItems={'flex-start'} spacing={1}>
        <M.Grid item>
          <S.ClientAvatar><img src={client && client.logo_url || `/logo.svg` } width={'auto'} height={'100%'} /></S.ClientAvatar>
        </M.Grid>
        <M.Grid item xs>
          <S.Title>{shipment.customer && shipment.customer.name}</S.Title>
          <S.Label>{`Reason/remark:`}</S.Label>
          <S.Text>{(dropoff && (dropoff.reason || dropoff.remark)) || '-'}</S.Text>
          <S.Label>{`Drop-off note:`}</S.Label>
          <S.Text>{_.defaultTo(shipment.dropoff_note, '-')}</S.Text>
          <S.Label>{`Access code:`}</S.Label>
          <S.Text>{_.defaultTo(shipment.dropoff_access_code, '-')}</S.Text>
          <S.Label>{`Additional Instructions:`}</S.Label>
          <S.Text>{_.defaultTo(shipment.dropoff_additional_instruction, '-')}</S.Text>
        </M.Grid>
        <M.Grid item>
          {(shipment && shipment.status === 'DROPOFF_SUCCEEDED') && <S.PinLink onClick={handlePinOpen}>{`Pin`}</S.PinLink>}
        </M.Grid>
        <M.Grid item>
          <S.ShipmentImgs onClick={handleOpen}>
            {!!pod.images.length && <img src={pod.images[0].url} width={'100%'} height={'auto'} />}
          </S.ShipmentImgs>
          <S.ModalPreviewerContainer
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 150,
            }}>
            <Fade in={open} timeout={150}>
              <S.ModalPreviewer>
                <ImagePreviewer images={pod.images} />
              </S.ModalPreviewer>
            </Fade>
          </S.ModalPreviewerContainer>
        </M.Grid>
      </M.Grid>
    </S.BoxDetail>
  </M.Box>);
}