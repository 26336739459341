import {Box, styled, Typography} from "@material-ui/core";

export const ResolvedContainer = styled(Box)(({theme}) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  width: 380,
  maxWidth: '100%',
}));

export const TitleModal = styled(Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Bold',
  fontSize: 18,
  fontWeight: 'bold',
  letterSpacing: -0.09,
  textAlign: 'center',
  color: theme.palette.primary.blackMain,
  marginBottom: theme.spacing(2)
}));

export const SubTitleModal = styled(Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13,
  fontWeight: 600,
  letterSpacing: -0.07,
  textAlign: 'center',
  color: '#707070'
}));