import React from "react";
import * as M from "@material-ui/core";
import * as S from "./styles";
import AxlButton from "../Button";


export default function AxlConfirmation({
                                          onClose = () => {},
                                          onDo = () => {},
                                          title = "CONFIRMATION",
                                          subTitle,
                                          closeText = "Cancel",
                                          doText = "Save",
                                          controlTextAlign = 'center',
                                          loading = false,
                                          disabled = false,
                                          ...props
}) {
  const {
    closeProps = {
      padding: '5px 24px',
      bgcolor: 'primary.main',
      color: 'primary.graySecond',
      variant: 'outlined',
    },
    doProps = {
      padding: '6px 25px',
      bgcolor: 'primary.greenThird',
      color: 'primary.main',
      variant: 'contained',
    },
  } = props;

  return(<S.ResolvedContainer>
    <S.TitleModal>{title}</S.TitleModal>
    <S.SubTitleModal>{subTitle}</S.SubTitleModal>
    <M.Box textAlign={controlTextAlign} paddingTop={2}>
      <AxlButton onClick={onClose} {...closeProps}>{closeText}</AxlButton>
      <AxlButton onClick={onDo} disableElevation disabled={disabled} {...doProps}>
        {loading ? <M.CircularProgress size={15} thickness={1} /> : doText}
      </AxlButton>
    </M.Box>
  </S.ResolvedContainer>);
}