import React, { useEffect, useRef, useState } from 'react';
import { Container } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';

import { copyToClipboard, copyToLocation } from '../../utils/display';

// components
import { CallCenterShipmentDetail } from '../CallCenterShipmentDetail';
import AxlButton from '../Button';
import AxlMap from '../AxlMap';
import AxlModal from '../AxlModal';
import { getShipment, getAssignment, getAssignmentLocation } from '../../repositories/Api';
import AxlConfirmation from '../AxlConfirmation';
import CallCenterShipmentLogs from '../CallCenterShipmentLogs';

// states
import {
  ShipmentSelectedState,
  CustomerShipmentSelectedState,
  AssignmentSelectedState,
  DropoffPODSelectedState,
} from '../../states/callCenterStates';

import { LeftSide, Main, RightSide, MapContainer } from './styles';
import { Controls, Text } from '../../containers/CallCenterResults/styles';
import {putSMS} from "../../repositories/HttpRepository";
import _ from "lodash";

export default function SearchDetail() {
  const { id } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [closeConfirmSendPin, setCloseConfirmSendPin] = useState(false);
  const [shipmentSelected, setShipmentSelected] = useRecoilState(ShipmentSelectedState);
  const setCustomerShipmentSelected = useSetRecoilState(CustomerShipmentSelectedState);
  const dropoffPODSelected = useRecoilValue(DropoffPODSelectedState);
  const [assignmentSelected, setAssignmentSelected] = useRecoilState(AssignmentSelectedState);
  const [driverLastLocation, setDriverLastLocation] = useState(null);
  const [picture, setPicture] = useState(null);

  useEffect(() => {
    getShipment(id).then((res) => {
      if (res.status === 200) {
        const { shipment, customerShipment } = res.data;
        if (shipment) {
          setShipmentSelected(shipment);
          const { assignment_id } = shipment;
          if (assignment_id) {
            getAssignment(assignment_id).then((res) => {
              if (res.status === 200) {
                const assignment = res.data;
                getAssignmentLocation(assignment_id).then((res) => {
                  if (res.status === 200 && res.data) {
                    const assignmentLocations = {
                      ...assignment,
                      locations: res.data,
                      // driverLocation: getDriverLocation(assignment),
                    };
                    setAssignmentSelected(assignmentLocations);
                  }
                });
              }
            });
          }
        }
        if (customerShipment) {
          setCustomerShipmentSelected(customerShipment);
        }
      }
    });
  }, [id]);

  useEffect(() => {
    if (assignmentSelected) {
      const { locations, assignment } = assignmentSelected;
      if (locations && locations.length) {
        if (assignment.status && assignment.status === 'IN_PROGRESS') {
          const lastDriverLocation = { ...locations[locations.length - 1] };
          setDriverLastLocation(lastDriverLocation)
          // driverLocationLink.current = `https://www.google.com/maps/search/?api=1&query=${lastDriverLocation.latitude},${lastDriverLocation.longitude}`;
        }
      }
    }
  }, [assignmentSelected]);

  useEffect(() => {
    if (dropoffPODSelected) {
      if (shipmentSelected && shipmentSelected.status === 'DROPOFF_SUCCEEDED') {
        const pictures = dropoffPODSelected.filter((d) => d.type === 'picture');
        if (pictures.length) {
          setPicture(pictures[0]);
        }
      }
    }
  }, [dropoffPODSelected]);

  const sendToCustomer = (shipmentId, pod) => {
    if(!pod || !_.get(pod, 'event.location.geolocation.latitude') || !_.get(pod, 'event.location.geolocation.longitude')) return;

    setLoading(true);
    const url = `https://www.google.com/maps/search/?api=1&query=${_.get(pod, 'event.location.geolocation.latitude')}, ${_.get(pod, 'event.location.geolocation.longitude')}`;

    // copyToLocation(pod.event.location.geolocation);
    putSMS(shipmentId, {"body": url}).then(res => {
      setLoading(true);
    });
  };

  const sendDriverLocationToCustomer = (shipmentId, location) => {
    if(!(location || location && (location.latitude || location.longitude))) return;

    setLoading(true);
    const url = `https://www.google.com/maps/search/?api=1&query=${_.get(location, 'latitude')}, ${_.get(location, 'longitude')}`;

    // copyToLocation(pod.event.location.geolocation);
    putSMS(shipmentId, {"body": url}).then(res => {
      setLoading(true);
    });
  };

  return (
    <Container maxWidth={false}>
      <AxlButton onClick={() => history.push('/search')} padding={'8px 30px'} variant={'text'} bgcolor={'primary.main'} color={'primary.gray'}>
        Back to Search
      </AxlButton>
      <Main>
        <LeftSide>
          <CallCenterShipmentDetail isHideChangeRequest />
        </LeftSide>
        <RightSide>
          <MapContainer mb={2} height={300}>
            <AxlMap
              pods={dropoffPODSelected}
              shipment={shipmentSelected}
              assignment={assignmentSelected}
              locations={assignmentSelected ? assignmentSelected.locations : null}
              driverLocation={assignmentSelected ? assignmentSelected.driverLocation : null}
            />
            <Controls>
              {driverLastLocation && (
                <AxlModal
                  isClose={closeConfirmSendPin}
                  onRendered={() => setCloseConfirmSendPin(false)}
                  trigger={
                    <AxlButton
                      variant={'outlined'}
                      color={'primary.brownNight'}
                      bgcolor={'primary.main'}
                      padding={`3px 10px`}
                      spacing={0.3}
                    >
                      <Text>{`Driver’s current location`}</Text>
                    </AxlButton>
                  }
                >
                  <AxlConfirmation
                    subTitle={'Are you sure to send driver’s current location pin?'}
                    onClose={() => setCloseConfirmSendPin(true)}
                    closeText={'Cancel'}
                    onDo={() => {
                      setCloseConfirmSendPin(true);
                      sendDriverLocationToCustomer(id, driverLastLocation);
                    }}
                    doText={'Send'}
                  />
                </AxlModal>
              )}
              {picture && (
                <AxlModal
                  isClose={closeConfirmSendPin}
                  onRendered={() => setCloseConfirmSendPin(false)}
                  trigger={
                    <AxlButton
                      variant={'outlined'}
                      color={'primary.brownNight'}
                      bgcolor={'primary.main'}
                      padding={`3px 10px`}
                      spacing={0.3}
                    >
                      <Text>{`Send customer dropoff pin`}</Text>
                    </AxlButton>
                  }
                >
                  <AxlConfirmation
                    subTitle={'Are you sure to send customer shipment’s dropoff location pin?'}
                    onClose={() => setCloseConfirmSendPin(true)}
                    closeText={'Cancel'}
                    onDo={() => {
                      setCloseConfirmSendPin(true);
                      sendToCustomer(id, picture);
                    }}
                    doText={'Send'}
                  />
                </AxlModal>
              )}
            </Controls>
          </MapContainer>
          <CallCenterShipmentLogs />
        </RightSide>
      </Main>
    </Container>
  );
}
