import React from 'react';
import * as M from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import {lightTheme} from "../../themes";
import {mainTheme} from "./styles";

const mapToTheme = {
  light: lightTheme,
  main: mainTheme,
};

export default function AxlCheckbox({label, theme = 'main', checked = false, onChange, ...props}) {
  const [state, setState] = React.useState({
    checked: checked
  });

  const handleChange = (event) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    onChange(event);
  };

  return (
      <M.ThemeProvider theme={mapToTheme[theme]}>
        <FormControlLabel
          control={<Checkbox {...props} checked={checked} onChange={handleChange} name="checked" />}
          label={label || ''}
        />
      </M.ThemeProvider>
  );
}