import React, {useEffect, useState} from 'react';
import * as M from '@material-ui/core';
import SearchComponent from "../../components/SearchInput";
import {Container, ReportProblemIco} from "./styles";
import CallHistoryTableList from "../../components/CallHistoryTableList";
import api from "../../repositories/Api";
import {useRecoilState} from "recoil/dist";
import {
  CallSessionListFilters,
  CallSessionListSelector,
  ClientListSelector,
  CallSessionListParamsSelector,
  DispatcherListSelector
} from "../../states/callCenterStates";
import {userState} from "../../atoms";
import CallSessionSearch from "../../components/CallSessionSearch";
import * as S from './styles';
import {AxlSimpleTabs} from "../../components/AxlTab";
import AxlButton from "../../components/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import moment from "moment-timezone";
import {DatePicker} from "@material-ui/pickers";
import ClearIcon from "@material-ui/icons/Clear";
import _ from "lodash";

export default function CallCenterContainer() {
  // Local state
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedDate, handleDateChange] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [isClientLoading, setClientLoading] = useState(false);
  // Global state
  const [user, setUser] = useRecoilState(userState);
  const [callSessionList, setCallSessionList] = useRecoilState(CallSessionListSelector);
  const [searchFilters, setSearchFilters] = useRecoilState(CallSessionListFilters);
  const [clientList, setClientList] = useRecoilState(ClientListSelector);
  const [dispatcherList, setDispatcherList] = useRecoilState(DispatcherListSelector);
  const [callSessionListBody, setCallSessionListBody] = useRecoilState(CallSessionListParamsSelector);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const selectDate = (e) => {
    const startDay = moment(e).startOf('day').valueOf();
    const endDay = moment(e).endOf('day').valueOf();
    const filters = _.assign({}, searchFilters, {
      page_number: 1,
      from_ts: startDay,
      to_ts: endDay,
    });
    setSearchFilters(filters);
    handleDateChange(e);
  }

  const resetDateTime = () => {
    let filters = _.assign({}, searchFilters, {page_number: 1});
                  delete filters.from_ts;
                  delete filters.to_ts;
    setSearchFilters(filters);
    handleDateChange(null);
  };

  const loadDispatcher = () => {
    if(!dispatcherList.length) {
      api.post(`/users/dispatchers`).then(res => {
        if(res.status === 200 && res.data) {
          let dispatchers = res.data.map(dispatcher => ({
            name: dispatcher.username,
            value: dispatcher.id
          }));
          setDispatcherList(dispatchers);
        }
      })
    }
  };

  const loadClients = () => {
    if(!clientList.length) {
      setClientLoading(true);
      api.get(`clients?size=200`).then(res => {
        if(res.status === 200 && res.data) {
          let clients = res.data.map(client => ({
            name: client.name,
            value: client.id
          }));
          setClientList(clients);
        }
        setClientLoading(false);
      })
    }
  };

  const setCallSessionBody = () => {
    if(user?.user?.id) {
      if(tabIndex === 0) {
        setCallSessionListBody({
          "statuses": ["OPEN", "CLOSED"],
          "client_ids": [],
          "call_agent_ids": [user?.user?.id],
          "categories": ["RECIPIENT_CALLER"]
        })
      } else if(tabIndex === 1) {
        setCallSessionListBody({
          "statuses": ["OPEN", "CLOSED"],
          "client_ids": [],
          "call_agent_ids": [],
          "categories": ["RECIPIENT_CALLER"]
        });
      } else if(tabIndex === 2) {
        setCallSessionListBody({
          "statuses": ["ABANDONED"],
          "categories": ["RECIPIENT_CALLER"]
        });
      }
    }
  }

  useEffect(() => {
    // Reset filter when change tab
    const filters = {
      page_number: 1,
      page_size: 12,
    };
    setSearchFilters(filters);
    setCallSessionBody();
    // Load clients
    loadClients();
    loadDispatcher();
  }, [tabIndex]);

  useEffect(() => {
    if(user?.user?.id) {
      let params = new URLSearchParams();
      Object.keys(searchFilters).map(t => {
        params.append(t, searchFilters[t]);
      });
      // Loading
      setLoading(true);
      api.post(`/call_center/all_sessions?${params}`, callSessionListBody).then(res => {
        if(res.status === 200 && res?.data?.items) {
          setCallSessionList(res?.data);
        }
        setLoading(false);
      });
    }
  }, [searchFilters, callSessionListBody]);

  useEffect(() => {
    if(user?.user?.id) {
      const _callSessionListBody = _.assign({}, callSessionListBody, {
        "call_agent_ids": [user?.user?.id],
        "categories": ["RECIPIENT_CALLER"],
        "statuses": ["OPEN", "CLOSED"],
      });
      let params = new URLSearchParams();
      Object.keys(searchFilters).map(t => {
        params.append(t, searchFilters[t]);
      });
      // Loading
      setLoading(true);
      api.post(`/call_center/all_sessions?${params}`, _callSessionListBody).then(res => {
        if(res.status === 200 && res?.data?.items) {
          setCallSessionList(res?.data);
        }
        setLoading(false);
      });
    }
  }, [user]);

  return(<Container>
    <M.Box position={'relative'} zIndex={1}>
      <M.Grid container alignItems={'center'}>
        <M.Grid item xs>
          <S.Title>{`CALL HISTORY`}</S.Title>
        </M.Grid>
        <M.Grid item xs>
          <CallSessionSearch loading={loading} />
        </M.Grid>
      </M.Grid>
      <M.Grid container alignItems={'center'}>
        <M.Grid item xs>
          <M.Box mb={'-2.5px'}>
            <AxlSimpleTabs
              theme={`outline`}
              variant={`standard`}
              bgcolor={'primary.transparent'}
              value={tabIndex}
              onChange={handleChange}
              tabs={[
                {label: "My Calls", bgActiveColor: 'primary.periwinkle'},
                {label: "All Calls"},
                {label: "All Abandoned Calls"},
              ]}/>
          </M.Box>
        </M.Grid>
        <M.Grid item>
          <M.Grid item>
            <M.Grid container alignItems={'center'}>
              <S.FilterLabel component={'span'}>{`Filter by`}</S.FilterLabel>
              <AxlButton icon padding={'0px'} spacing={0} onClick={() => setIsOpen(true)}>
                <ArrowDropDownIcon fontSize={'small'} />
                <S.ButtonDateText>{selectedDate ? moment(selectedDate).format('DD/MM/YYYY') : `Date`}</S.ButtonDateText>
              </AxlButton>
              <DatePicker
                variant="inline"
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                label="Open me from button"
                format="d MMM yyyy"
                value={selectedDate}
                onChange={selectDate}
                style={{overflow: 'hidden', width: 0, height: 0}}
              />
              <M.Box mx={0.5}>
                <M.IconButton
                  disabled={!selectedDate}
                  onClick={resetDateTime}
                  size={'small'}>
                  <M.Tooltip title={'Clear filter'}><ClearIcon fontSize={'small'} /></M.Tooltip>
                </M.IconButton>
              </M.Box>
            </M.Grid>
          </M.Grid>
        </M.Grid>
      </M.Grid>
    </M.Box>
    <M.Box mb={2}>
      {loading ? <M.LinearProgress color="primary" /> : <M.Divider />}
    </M.Box>
    <M.Box height={1} style={{overflow: 'hidden'}}>
      {/*{callSessionList.items.length ?*/}
        <CallHistoryTableList
          loading={loading}
          clientLoading={isClientLoading} />
          {/*: <S.NoResultsContainer>{`No Results`}</S.NoResultsContainer>}*/}
    </M.Box>
  </Container>);
}