import React, {useEffect, useState} from 'react';
import * as M from '@material-ui/core';
import * as S from './formStyles';
import AxlInput from "../AxlInput";
import AxlButton from "../Button";
import {convertDriverLicenseFormat, IDScanTimeFormatNormal} from "../../utils/display";
import moment from "moment-timezone";
import _ from 'lodash';
import {useRecoilValue} from "recoil";
import {
  ShipmentSelectedState,
  DropoffEditFormSelector,
  ShipmentNoteEditFormSelector,
  AssignmentNoteEditFormSelector,
  AssignmentSelectedState,
  ShipmentNoteSelectedState,
  AssignmentNoteSelectedState,
  CallSessionState,
  CustomerEditFormSelector
} from "../../states/callCenterStates";
import {useRecoilState, useSetRecoilState} from "recoil/dist";
import api from "../../repositories/Api";

export function DropoffEditForm({onClose}) {
  // Global states
  const [shipmentSelected, setShipmentSelected] = useRecoilState(ShipmentSelectedState);
  const [dropoffEditStatus, setDropoffEditStatus] = useRecoilState(DropoffEditFormSelector);
  // Local states
  const [saving, setSaving] = useState(false);
  const [isChangeAddress, setChangeAddress] = useState(false);
  const [dropoff, setDropoff] = useState(dropoffEditStatus);

  const onChange = (pathField, text) => {
    if(!dropoffEditStatus) return;
    // Check dropoff note is change to call api
    const value = text.target.value;

    let newObj = Object.assign({}, dropoff);
        _.set(newObj, pathField, value);

    setDropoff(newObj);
  }

  const onChangeAddress = (pathField, text) => {
    if(!dropoffEditStatus) return;

    // Check dropoff address 2 is change to call api
    const value = text.target.value;

    let newObj = Object.assign({}, dropoff);

    let addresses = Object.assign({}, _.get(dropoffEditStatus, 'dropoff_address'));
        _.set(addresses, pathField, value);
        _.set(newObj, 'dropoff_address', addresses);

    setDropoff(newObj);
  }

  const save = () => {
    if(!dropoff || !dropoff.id) return false;

    const dropoffParams = {
      dropoff_access_code: dropoff.dropoff_access_code,
      dropoff_additional_instruction: dropoff.dropoff_additional_instruction,
    };

    const dropoffAddressParams = {
      address: dropoff.dropoff_address,
      note: dropoff.dropoff_note,
    };

    setSaving(true);
    api.put(`shipments/${dropoff.id}/dropoff`, dropoffAddressParams).then(res => {
      if(res.status === 200) {
        if(res.data) {
          setShipmentSelected(res.data);
          api.put(`shipments/${dropoff.id}/dropoff-location-info`, dropoffParams).then(res => {
            if(res.status === 200) {
              if(res.data) {
                setShipmentSelected(res.data);
              }
            }
            setSaving(false);
            onClose();
          });
        }
      }
      onClose();
    })
  }

  return(<S.Container>
    <S.Title>{`EDIT DROPOFF INFO`}</S.Title>
    <S.Label>{`Dropoff Address: `}</S.Label>
    <S.Text>{dropoff && dropoff.dropoff_address &&
    `${dropoff.dropoff_address.street} ${dropoff.dropoff_address.city} ${dropoff.dropoff_address.state}, ${dropoff.dropoff_address.zipcode}`}</S.Text>
    <S.GroupControl>
      <S.Label>{`Adress Line 2:`}</S.Label>
      <AxlInput
        onChange={e => onChangeAddress('street2', e)}
        color={`secondary`}
        placeholder={`Add address here...`}
        value={dropoff && dropoff.dropoff_address ? dropoff.dropoff_address.street2 : ''}/>
    </S.GroupControl>
    <S.GroupControl>
      <S.Label>{`Dropoff Note:`}</S.Label>
      <AxlInput
        onChange={e => onChange('dropoff_note', e)}
        color={`secondary`} multiline rows={7} placeholder={`Add note here...`}
        value={dropoff && dropoff.dropoff_note ? dropoff.dropoff_note : ''}/>
    </S.GroupControl>
    <S.GroupControl>
      <S.Label>{`Access Code:`}</S.Label>
      <AxlInput
        onChange={e => onChange('dropoff_access_code', e)}
        color={`secondary`}
        placeholder={`Add access code here...`}
        value={dropoff && dropoff.dropoff_access_code ? dropoff.dropoff_access_code : ''}/>
    </S.GroupControl>
    <S.GroupControl>
      <S.Label>{`Additional Instruction:`}</S.Label>
      <AxlInput
        onChange={e => onChange('dropoff_additional_instruction', e)}
        color={`secondary`}
        multiline
        rows={7}
        placeholder={`Add intruction here...`}
        value={dropoff && dropoff.dropoff_additional_instruction ? dropoff.dropoff_additional_instruction : ''}/>
    </S.GroupControl>
    <M.Box textAlign={'right'} paddingTop={3}>
      <AxlButton onClick={onClose} padding={'4px 20px'} spacing={0.5} bgcolor={'primary.main'} color={'primary.gray'} variant="outlined">{`Cancel`}</AxlButton>
      <AxlButton padding={'5px 30px'} spacing={0.5} bgcolor={'primary.greenThird'} color={'primary.main'} variant="contained" disableElevation loading={saving} disabled={saving} onClick={save}>{`Save`}</AxlButton>
    </M.Box>
  </S.Container>);
}

export function ShipmentCustomerForm({onClose}) {
  // Global states
  const [shipmentSelected, setShipmentSelected] = useRecoilState(ShipmentSelectedState);
  // Local states
  const [saving, setSaving] = useState(false);
  const [isChangeAddress, setChangeAddress] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(_.get(shipmentSelected, 'customer'));

  const onChange = (pathField, text) => {
    // Check dropoff note is change to call api
    const value = text.target.value;

    let newObj = Object.assign({}, customerInfo);
        _.set(newObj, pathField, value);

    setCustomerInfo(newObj);
  }

  const normalizeFormData = (fd) => {
    const fdClone = _.clone(fd);
    Object.keys(fdClone).forEach(k => {
      if (typeof fdClone[k] === 'string'){
        fdClone[k] = (fdClone[k] && fdClone[k].trim() !== '') ? fdClone[k].trim() : null;
      }
    });
    return fdClone;
  }

  const save = () => {
    setSaving(true);
    api.put(`/shipments/${shipmentSelected.id}/customer`, normalizeFormData(customerInfo)).then((res) => {
      setSaving(false);
      // take some properties
      setShipmentSelected(res.data);
      onClose();
    })
  }

  return(<S.Container>
    <S.Title>{`EDIT CUSTOMER INFO`}</S.Title>
    <S.GroupControl>
      <S.Label>{`Customer Name:`}</S.Label>
      <AxlInput
        onChange={e => onChange('name', e)}
        color={`secondary`} placeholder={`Add name here...`}
        value={_.get(customerInfo, 'name')}/>
    </S.GroupControl>
    <S.GroupControl>
      <S.Label>{`Customer Email:`}</S.Label>
      <AxlInput
        onChange={e => onChange('email', e)}
        color={`secondary`} placeholder={`Add email here...`}
        value={_.get(customerInfo, 'email')}/>
    </S.GroupControl>
    <S.GroupControl>
      <S.Label>{`Customer Phone Number`}</S.Label>
      <AxlInput
        onChange={e => onChange('phone_number', e)}
        color={`secondary`} placeholder={`Add phone number here...`}
        value={_.get(customerInfo, 'phone_number')}/>
    </S.GroupControl>
    <M.Box textAlign={'right'} paddingTop={3}>
      <AxlButton onClick={onClose} padding={'4px 20px'} spacing={0.5} bgcolor={'primary.main'} color={'primary.gray'} variant="outlined">{`Cancel`}</AxlButton>
      <AxlButton padding={'5px 30px'} spacing={0.5} bgcolor={'primary.greenThird'} color={'primary.main'} variant="contained" disableElevation loading={saving} disabled={saving} onClick={save}>{`Save`}</AxlButton>
    </M.Box>
  </S.Container>);
}

export function AddressChangeRequestForm({onClose}) {
  // Global states
  const [shipmentSelected, setShipmentSelected] = useRecoilState(ShipmentSelectedState);
  const [dropoffEditStatus, setDropoffEditStatus] = useRecoilState(DropoffEditFormSelector);
  // Local states
  const [saving, setSaving] = useState(false);
  const [isChangeAddress, setChangeAddress] = useState(false);
  const [dropoff, setDropoff] = useState({});
  const [callSession, setCallSesison] = useRecoilState(CallSessionState);

  const onChange = (pathField, text) => {
    let newObj = Object.assign({}, dropoff);
    if(!text.target.value) {
      _.unset(newObj, pathField);
    } else {
      _.set(newObj, pathField, text.target.value);
    }

    setDropoff(newObj);
  }

  const save = () => {
    if(!(callSession && callSession.id) || !shipmentSelected) return;

    const dropoffParams = {
      ...dropoff,
      "shipment_id": shipmentSelected.id
    };

    setSaving(true);
    api.patch(`/call_center/${callSession.id}/recipient_address_update`, dropoffParams).then(res => {
      if(res.status === 204) {}
      setSaving(false);
      onClose();
    });
  }

  // Display
  const isChange = _.isEqual(dropoff, {}) || _.isEqual(dropoff, {dropoff_address: {}});

  return(<S.Container>
    <S.Title>{`ADDRESS CHANGE REQUEST`}</S.Title>
    <S.SectionTitle>{`Current Dropoff Address:`}</S.SectionTitle>
    {(dropoffEditStatus && dropoffEditStatus.dropoff_address) && <div>
      <S.Text_2>{dropoffEditStatus.dropoff_address.street}</S.Text_2>
      <S.Text_2>{dropoffEditStatus.dropoff_address.street2}</S.Text_2>
      <S.Text_2>{`${dropoffEditStatus.dropoff_address.city} ${dropoffEditStatus.dropoff_address.state}, ${dropoffEditStatus.dropoff_address.zipcode}`}</S.Text_2>
    </div>}
    <S.SectionTitle>{`New Address:`}</S.SectionTitle>
    <S.GroupControl>
      <S.Label>{`Line 1`}</S.Label>
      <AxlInput
        spacing={0}
        onChange={e => onChange('dropoff_address.street', e)}
        color={`secondary`}
        value={dropoff && dropoff.dropoff_address ? dropoff.dropoff_address.street : ''}/>
    </S.GroupControl>
    <S.GroupControl>
      <S.Label>{`Line 2`}</S.Label>
      <AxlInput
        spacing={0}
        onChange={e => onChange('dropoff_address.street2', e)}
        color={`secondary`}
        value={dropoff && dropoff.dropoff_address ? dropoff.dropoff_address.street2 : ''}/>
    </S.GroupControl>
    <M.Grid container spacing={2}>
      <M.Grid xs item>
        <S.GroupControl>
          <S.Label>{`City`}</S.Label>
          <AxlInput
            spacing={0}
            onChange={e => onChange('dropoff_address.city', e)}
            color={`secondary`}
            value={dropoff && dropoff.dropoff_address ? dropoff.dropoff_address.city : ''}/>
        </S.GroupControl>
      </M.Grid>
      <M.Grid xs item>
        <S.GroupControl>
          <S.Label>{`State`}</S.Label>
          <AxlInput
            spacing={0}
            onChange={e => onChange('dropoff_address.state', e)}
            color={`secondary`}
            value={dropoff && dropoff.dropoff_address ? dropoff.dropoff_address.state : ''}/>
        </S.GroupControl>
      </M.Grid>
      <M.Grid xs item>
        <S.GroupControl>
          <S.Label>{`Zipcode`}</S.Label>
          <AxlInput
            spacing={0}
            onChange={e => onChange('dropoff_address.zipcode', e)}
            color={`secondary`}
            value={dropoff && dropoff.dropoff_address ? dropoff.dropoff_address.zipcode : ''}/>
        </S.GroupControl>
      </M.Grid>
    </M.Grid>
    <S.GroupControl>
      <S.Label>{`Dropoff Note:`}</S.Label>
      <AxlInput
        spacing={0}
        onChange={e => onChange('dropoff_note', e)}
        color={`secondary`} multiline rows={7}
        value={dropoff && dropoff.dropoff_note ? dropoff.dropoff_note : ''}/>
    </S.GroupControl>
    <M.Box textAlign={'right'} paddingTop={3}>
      <AxlButton onClick={onClose} padding={'4px 20px'} spacing={0.5} bgcolor={'primary.main'} color={'primary.gray'} variant="outlined">{`Cancel`}</AxlButton>
      <AxlButton
        padding={'5px 30px'}
        spacing={0.5}
        bgcolor={(saving || isChange) ? 'primary.graySixth' : 'primary.greenThird'}
        color={'primary.main'}
        variant="contained"
        disableElevation
        loading={saving}
        disabled={saving || isChange}
        onClick={save}>{`Save`}</AxlButton>
    </M.Box>
  </S.Container>);
}

export function ViewPODIdScan({pod}) {
  const obj = (pod && JSON.parse(JSON.stringify(pod))) || null;
  const data = (obj && obj.data) || null;
  const podInfo = convertDriverLicenseFormat(data);
console.log(obj)
  return(pod ? <S.Container>
    <S.Title>{`ID SCAN`}</S.Title>
    <S.Label_1>{`Time scanned: ${(pod && pod.event && pod.event.ts) ? moment(pod.event.ts).format('MM/DD/YYYY HH:mmA') : '-'}`}</S.Label_1>
    <M.Grid container>
      <M.Grid item xs={6}>
        <S.Label_2>{`Last Name:`}</S.Label_2>
        <S.Text>{podInfo.lastName}</S.Text>
      </M.Grid>
      <M.Grid item xs={6}>
        <S.Label_2>{`First Name:`}</S.Label_2>
        <S.Text>{podInfo.firstName}</S.Text>
      </M.Grid>
    </M.Grid>
    <M.Grid container>
      <M.Grid item xs={6}>
        <S.Label_2>{`DOB:`}</S.Label_2>
        <S.Text>{IDScanTimeFormatNormal(podInfo.dateOfBirth)}</S.Text>
      </M.Grid>
      <M.Grid item xs={6}>
        <S.Label_2>{`Driver License:`}</S.Label_2>
        <S.Text>{podInfo.customerId}</S.Text>
      </M.Grid>
    </M.Grid>
    <M.Grid container>
      <M.Grid item xs={6}>
        <S.Label_2>{`State:`}</S.Label_2>
        <S.Text>{podInfo.state}</S.Text>
      </M.Grid>
      <M.Grid item xs={6}>
        <S.Label_2>{`Expiration:`}</S.Label_2>
        <S.Text>{IDScanTimeFormatNormal(podInfo.expirationDate)}</S.Text>
      </M.Grid>
    </M.Grid>
  </S.Container> : <S.Container width={1} height={300} maxHeight={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
    <M.CircularProgress color={'secondary'} size={50} thickness={0.5} />
  </S.Container>);
}

export function ShipmentNoteForm({onClose}) {
  // Local states
  const [shipmentNote, setShipmentNote] = useState('');
  const [isLoading, setLoading] = useState(false);
  // Global states
  const shipmentSelected = useRecoilValue(ShipmentSelectedState);
  const setShipmentNotes = useSetRecoilState(ShipmentNoteSelectedState);

  const onChange = (e) => {
    setShipmentNote(e.target.value);
  };

  const save = () => {
    if(!shipmentNote) return false;

    if(shipmentSelected) {
      setLoading(true);
      api.post(`notes/SH_${shipmentSelected.id}`, shipmentNote).then(res => {
        setLoading(false);
        if(res.status === 200) {
          api.get(`notes/SH_${shipmentSelected.id}`).then(res => {
            if(res.status === 200 && res.data) {
              setShipmentNotes(res.data)
              onClose();
            }
          })
        }
      });
    }
  };

  return(<S.Container>
    <S.Title>{`ADD SHIPMENT NOTE`}</S.Title>
    <AxlInput
      onChange={(e) => onChange(e)}
      color={`secondary`} multiline rows={7}
      placeholder={`Add note to shipment here...`} />
    <M.Box textAlign={'right'} paddingTop={3}>
      <AxlButton onClick={onClose} padding={'4px 20px'} spacing={0.5} bgcolor={'primary.main'} color={'primary.gray'} variant="outlined">{`Cancel`}</AxlButton>
      <AxlButton
        padding={'5px 30px'}
        spacing={0.5}
        bgcolor={shipmentNote ? 'primary.greenThird' : 'primary.graySixth'}
        color={`primary.main`}
        variant="contained"
        loading={isLoading}
        disabled={isLoading || !shipmentNote}
        disableElevation onClick={save}>{`Save`}</AxlButton>
    </M.Box>
  </S.Container>);
}

export function AssignmentNoteForm({onClose}) {
  // Local states
  const [assignmentNote, setAssignmentNote] = useState('');
  const [isLoading, setLoading] = useState(false);
  // Global states
  const setAssignmentNotes = useSetRecoilState(AssignmentNoteSelectedState);
  const assignmentSelected = useRecoilValue(AssignmentSelectedState);

  const onChange = (e) => {
    setAssignmentNote(e.target.value);
  };

  const save = () => {
    if(!assignmentNote) return false;

    if(assignmentSelected) {
      setLoading(true);
      api.post(`notes/AS_${assignmentSelected.assignment.id}`, assignmentNote).then(res => {
        setLoading(false);
        if(res.status === 200) {
          api.get(`notes/AS_${assignmentSelected.assignment.id}`).then(res => {
            if(res.status === 200 && res.data) {
              setAssignmentNotes(res.data)
              onClose();
            }
          })
        }
      });
    }
  };

  return(<S.Container>
    <S.Title>{`ADD ASSIGNMENT NOTE`}</S.Title>
    <AxlInput
      onChange={(e) => onChange(e)}
      color={`secondary`} multiline rows={7}
      placeholder={`Add note to assignment here...`} />
    <M.Box textAlign={'right'} paddingTop={3}>
      <AxlButton onClick={onClose} padding={'4px 20px'} spacing={0.5} bgcolor={'primary.main'} color={'primary.gray'} variant="outlined">{`Cancel`}</AxlButton>
      <AxlButton
        padding={'5px 30px'}
        spacing={0.5}
        bgcolor={assignmentNote ? 'primary.greenThird' : 'primary.graySixth'}
        color={`primary.main`}
        variant="contained"
        loading={isLoading}
        disabled={isLoading || !assignmentNote}
        disableElevation onClick={save}>{`Save`}</AxlButton>
    </M.Box>
  </S.Container>);
}

export function NotesEditForm({onClose, isShowShipmentNote = true, isShowAssignmentNote = true}) {
  // Local states
  const [shipmentSaving, setShipmentSaving] = useState(false);
  const [assignmentSaving, setAssignmentSaving] = useState(false);
  const [isChangeShipmentNote, setChangeShipmentNote] = useState(false);
  const [isChangeAssignmentNote, setChangeAssignmentNote] = useState(false);
  // Global states
  const shipmentSelected = useRecoilValue(ShipmentSelectedState);
  const assignmentSelected = useRecoilValue(AssignmentSelectedState);
  const [shipmentNoteState, setShipmentNote] = useRecoilState(ShipmentNoteEditFormSelector);
  const [assignmentNoteState, setAssignmentNote] = useRecoilState(AssignmentNoteEditFormSelector);
  const setShipmentNotes = useSetRecoilState(ShipmentNoteSelectedState);
  const setAssignmentNotes = useSetRecoilState(AssignmentNoteSelectedState);

  const onChangeShipment = (e) => {
    setChangeShipmentNote(true);
    setShipmentNote({content: e.target.value});
  }

  const onChangeAssignment = (e) => {
    setChangeAssignmentNote(true);
    setAssignmentNote({content: e.target.value});
  }

  const saveNote = () => {
    if(shipmentSelected && shipmentNoteState && isChangeShipmentNote && isShowShipmentNote) {
      setShipmentSaving(true);
      api.put(`notes/SH_${shipmentSelected.id}`, shipmentNoteState.content || '').then(res => {
        setShipmentSaving(false);
        setChangeShipmentNote(false);
        if(res.status === 201) {
          api.get(`notes/SH_${shipmentSelected.id}`).then(res => {
            if(res.status === 200 && res.data) {
              setShipmentNotes(res.data)
            }
          })
        }
      });
    }

    if(assignmentSelected && assignmentSelected.assignment && assignmentNoteState && isChangeAssignmentNote && isShowAssignmentNote) {
      setAssignmentSaving(true);
      api.put(`notes/AS_${assignmentSelected.assignment.id}`, assignmentNoteState.content || '').then(res => {
        setAssignmentSaving(false);
        setChangeAssignmentNote(false);
        if(res.status === 201) {
          api.get(`notes/AS_${assignmentSelected.assignment.id}`).then(res => {
            if(res.status === 200 && res.data) {
              setAssignmentNotes(res.data)
            }
          })
        }
      });
    }
  }

  useEffect(() => {
    if(!(shipmentSaving || assignmentSaving) && (isChangeShipmentNote || isChangeAssignmentNote)) {
      onClose();
    }
  }, [shipmentSaving, assignmentSaving])

  return(<S.Container>
    <S.Title>{`EDIT NOTE`}</S.Title>
    {isShowShipmentNote && <S.GroupControl>
      <S.Label>{`Shipment Note`}</S.Label>
      <AxlInput
        onChange={e => onChangeShipment(e)}
        value={shipmentNoteState && shipmentNoteState.content || ''}
        color={`secondary`} multiline rows={7} spacing={0}
      placeholder={`{last_own_shipment_note}`} />
    </S.GroupControl>}
    {isShowAssignmentNote && <S.GroupControl>
      <S.Label>{`Assignment Note`}</S.Label>
      <AxlInput
        onChange={e => onChangeAssignment(e)}
        value={assignmentNoteState && assignmentNoteState.content || ''}
        color={`secondary`} multiline rows={7} spacing={0}
        placeholder={`{last_own_assignment_note}`} />
    </S.GroupControl>}
    <M.Box textAlign={'right'} paddingTop={3}>
      <AxlButton onClick={onClose} padding={'4px 20px'} spacing={0.5} bgcolor={'primary.main'} color={'primary.gray'} variant="outlined">{`Cancel`}</AxlButton>
      <AxlButton
        padding={'5px 30px'}
        spacing={0.5}
        bgcolor={(isChangeAssignmentNote || isChangeShipmentNote) ? 'primary.greenThird' : 'primary.graySixth'}
        color={'primary.main'}
        variant="contained"
        disableElevation
        loading={shipmentSaving || assignmentSaving}
        disabled={shipmentSaving || assignmentSaving}
        onClick={saveNote}>{`Save`}</AxlButton>
    </M.Box>
  </S.Container>);
}