import * as M from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const Container = M.styled(M.Grid)(({ theme }) => ({
  height: '100%',
}));

export const HeaderSection = M.styled(M.Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
}));

export const ContainerScroll = M.styled(M.Grid)(({ theme }) => ({
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

export const Scroll = M.styled(M.Box)(({ theme }) => ({
  height: '100%',
  overflowY: 'scroll',
  borderRadius: 0,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  boxSizing: 'border-box',
}));

export const Box = M.styled(M.Box)(({ theme }) => ({
  padding: '7.5px 8.5px',
  borderRadius: 5,
  border: `1px solid ${theme.palette.primary.graySeventh}`,
  backgroundColor: theme.palette.primary.grayFourth,
}));

export const Text = M.styled(M.Typography)(({ theme }) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 13,
  color: theme.palette.primary.blackSecondary,
  margin: '3px 0 5px',
}));
export const TextThumbNail = M.styled(M.Typography)(({ theme }) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 13,
  color: theme.palette.primary.blackSecondary,
  margin: '3px 0 5px',
  position: 'absolute',
}));

export const Text_1 = M.styled(Text)(({ theme }) => ({
  fontFamily: 'AvenirNext-DemiBoldItalic',
  fontSize: 11,
  fontWeight: 600,
  fontStyle: 'italic',
  color: theme.palette.primary['greyish-brown'],
}));

export const Text_2 = M.styled(Text)(({ theme }) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13,
  fontWeight: 500,
  fontStyle: 'italic',
  letterSpacing: -0.09,
  color: theme.palette.primary.blackSecondary,
}));

export const Text_3 = M.styled(Text)(({ theme }) => ({
  fontFamily: 'AvenirNext-MediumItalic',
  fontSize: 11,
  fontWeight: 500,
  fontStyle: 'italic',
  letterSpacing: -0.09,
  color: theme.palette.primary.blackSecondary,
}));

export const Text_4 = M.styled(Text)(({ theme }) => ({
  color: theme.palette.primary.periwinkle,
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 10,
  margin: 0,
}));
export const ClientName = M.styled(Text)(({ theme }) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13,
  marginBottom: 5,
}));

export const TrackingText = M.styled(M.Link)(({ theme }) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.text.third,
  textDecoration: 'underline',
}));

export const ShipmentId = M.styled(Text)(({ theme }) => ({
  fontFamily: 'AvenirNext-DemiBoldItalic',
  fontSize: 10,
  fontWeight: 600,
  fontStyle: 'italic',
  color: theme.palette.text.third,
  borderRadius: 4,
  backgroundColor: theme.palette.primary.cream,
  padding: '3px 5px',
  margin: '0px 7px',
  verticalAlign: 'middle',
}));

export const StatusText = M.styled(M.Typography)(({ theme, color }) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13,
  color: color || theme.palette.primary.blackSecondary,
  margin: '3px 0 5px',
}));

export const SubTitle = M.styled(Text)(({ theme }) => ({
  fontFamily: 'AvenirNext-Bold',
  fontSize: 11,
  marginBottom: theme.spacing(1),
  margin: '7px 0 3px',
}));

export const Label = M.styled(Text)(({ theme }) => ({
  fontSize: 11,
  color: theme.palette.text.select,
  margin: '10px 0 2px',
}));

export const ImagePreview = M.styled(M.Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: 60,
  height: 100,
  border: `1px solid ${theme.palette.primary.grayMain}`,
  borderRadius: 3,
  margin: '5px auto 7px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ImagePreviewRequired = M.styled(ImagePreview)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.yellowDark}`,
  position: 'relative',
}));

export const LinkView = M.styled('span')(({ theme }) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 11,
  fontWeight: 500,
  color: theme.palette.primary.link,
  textDecoration: 'underline',
  textAlign: 'center',
  cursor: 'pointer',
}));

export const DividerContainer = M.styled(M.Box)(({ theme }) => ({
  height: '100%',
  padding: '25px 0 30px',
  boxSizing: 'border-box',
}));

export const TextRequired = M.styled('span')(({ theme }) => ({
  fontFamily: 'AvenirNext-DemiBoldItalic',
  fontSize: 11,
  fontWeight: 600,
  fontStyle: 'italic',
  letterSpacing: -0.09,
  textAlign: 'center',
  color: theme.palette.primary.yellowDark,
}));

export const Image = M.styled('img')(({theme}) => ({
  margin: 'auto',
  maxWidth: '100%',
  maxHeight: '100%',
  opacity: 0.6,
}));
