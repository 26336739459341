import * as S from "../CallCenterShipmentDetail/formStyles";
import AxlInput from "../AxlInput";
import * as M from "@material-ui/core";
import AxlButton from "../Button";
import React, {useState} from "react";
import AxlCheckbox from "../AxlCheckbox";
import {useRecoilState} from "recoil/dist";
import {CallSessionState, CustomerShipmentSelectedState, ShipmentSelectedState} from "../../states/callCenterStates";
import api from "../../repositories/Api";
import {api as db_api} from '../../repositories/HttpRepository';

export function LeaveCallNoteForm({onClose}) {
  // Local states
  const [text, setText] = useState('');
  const [isChecked, setChecked] = useState(false);
  const [sending, setSending] = useState(false);
  // Global states
  const [callSessionState, setCallSessionState] = useRecoilState(CallSessionState);
  // Variables
  const DEFINED = {
    UNKNOWN_DRIVER: 1,
    UNKNOWN_RECIPIENT: 2,
    DRIVER: 8,
    RECIPIENT: 9
  }
  const urlParams = new URLSearchParams(window.location.search);
  const callArgs = callArgsHandle(urlParams.get('call_args'));
  const {call_args, attr} = callSessionState;
  const agrs = callArgsHandle(call_args) || callArgs;
  const shipmentIdFromSession = (attr && attr.shipment_id) ?
    parseInt(attr.shipment_id) :
    ([DEFINED.RECIPIENT, DEFINED.UNKNOWN_RECIPIENT].indexOf(agrs[0]) !== -1 && agrs[1]  ||
      ([DEFINED.RECIPIENT, DEFINED.UNKNOWN_RECIPIENT].indexOf(agrs[0]) !== -1 && agrs[1]) || null);

  function callArgsHandle(value) {
    if(!value) return null;

    return value.split("|").map(a => parseInt(a));
  }

  function onChange(e) {
    setText(e.target.value)
  }

  function onCheckbox(e) {
    setChecked(e.target.checked);
  }

  const saveNote = (text) => {
    if(!text) return;
    const sessionId = callSessionState.id || null;

    setSending(true);

    api.patch(`/call_center/${sessionId}/remark`, {remark: text}).then(res => {
      if(res.status === 200) {
        setCallSessionState(res.data);
        if(isChecked) {
          db_api.post(`/cs/messages/483c0b60-02b4-4d17-9fe1-98eb14389e04/note?shipment=true`, {body: text});
        }
      }

      setSending(false);
      onClose();
    })
  }

  return(<S.Container>
    <S.Title>{`LEAVE CALL NOTE`}</S.Title>
    <AxlInput
      spacing={0}
      color={`secondary`}
      multiline rows={7}
      placeholder={`Add note/summary of the call about issues and solutions after assisting customer...`}
      onChange={e => onChange(e)}/>
    {shipmentIdFromSession &&
      <AxlCheckbox checked={isChecked} color={'default'} label={`Send Note to Driver`} onChange={(e) => onCheckbox(e)} />}
    <M.Box textAlign={'right'} paddingTop={3}>
      <AxlButton onClick={onClose} padding={'4px 15px'} spacing={0.5} bgcolor={'primary.main'} color={'primary.gray'} variant="outlined">{`Cancel`}</AxlButton>
      <AxlButton
        loading={sending}
        disabled={sending || !text}
        padding={'5px 15px'}
        spacing={0.5}
        bgcolor={text ? 'primary.greenThird' : 'primary.graySixth'}
        color={'primary.main'}
        variant="contained"
        disableElevation
        onClick={() => saveNote(text)}>{`Save`}</AxlButton>
    </M.Box>
  </S.Container>);
}

export function CancelShipmentConfirmation({onClose}) {
  // Local states
  const [text, setText] = useState('');
  const [sending, setSending] = useState(false);
  // Global states
  const [shipmentSelected, setShipmentSelected] = useRecoilState(ShipmentSelectedState);
  const [customerShipmentSelected, setCustomerShipmentSelected] = useRecoilState(CustomerShipmentSelectedState);

  function onChange(e) {
    setText(e.target.value)
  }

  const saveNote = (text) => {
    if(!text || !shipmentSelected) return;

    setSending(true);

    api.post(`shipments/${shipmentSelected.id}/cancel`, text).then(res => {
      if(res.status === 202 || res.data) {
        // Reload
        api.get(`shipments/${shipmentSelected.id}?customer_request_included=true`).then(res => {
          if(res.status === 200 && res.data) {
            const {shipment, customerShipment} = res.data;
            if(shipment) {
              setShipmentSelected(shipment);
            }
            if(customerShipment) {
              setCustomerShipmentSelected(customerShipment);
            }
          }
        })
      }

      setSending(false);
      onClose();
    })
  }

  return(<S.Container>
    <S.Title>{`Cancel Shipment Reason`}</S.Title>
    <AxlInput
      color={`secondary`}
      multiline rows={7}
      placeholder={`Add reason here...`}
      onChange={e => onChange(e)}/>
    <M.Box textAlign={'right'} paddingTop={3}>
      <AxlButton onClick={onClose} padding={'4px 15px'} spacing={0.5} bgcolor={'primary.main'} color={'primary.gray'} variant="outlined">{`Cancel`}</AxlButton>
      <AxlButton
        loading={sending}
        disabled={sending || !text}
        padding={'5px 15px'}
        spacing={0.5}
        bgcolor={text ? 'primary.periwinkle' : 'primary.graySixth'}
        color={'primary.main'}
        variant="contained"
        disableElevation
        onClick={() => saveNote(text)}>{`Confirm`}</AxlButton>
    </M.Box>
  </S.Container>);
}