import React, { Component } from "react";
import * as M from '@material-ui/core';
import Slider from "react-slick";
import {Container, ContainerBottom, ContainerImage, ContainerInner, Image, ImagePreview, Text} from "./styles";
import AxlButton from "../Button";
import AxlConfirmation from "../AxlConfirmation";
import AxlModal from "../AxlModal";
import moment from "moment-timezone";
import {copyToClipboard, copyToLocation} from "../../utils/display";
import _ from 'lodash';
import {putSMS} from "../../repositories/HttpRepository";

export default class ImagePreviewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      isClose: true,
      copied: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  open = () => this.setState({isClose: false});

  close = () => {
    this.setState({isClose: true});
  }

  sendToCustomer = (shipmentId, text) => {
    if(!shipmentId || !text) return;
    const {callback = () => {}} = this.props;

    // copyToClipboard(text);
    const newData = `${process.env.REACT_APP_TRACKING_CODE_URL}/showpod/${encodeURIComponent(text)}`
    this.setState({loading: true});
    putSMS(shipmentId, {"body": newData}).then(res => {
      this.setState({loading: false});
      callback();
    });
  }

  render() {
    const {isClose, copied, loading} = this.state;
    const {textBottomAlign, images, hideSendBtn, shipmentId} = this.props;

    return (
      <Container>
        <ContainerInner>
          <Slider
            arrows={false}
            asNavFor={this.state.nav2}
            ref={slider => (this.slider1 = slider)}
          >
            {images.map((image, index) => <ContainerImage key={index}>
              <M.Box bgcolor={'primary.gray'} >
                <M.Box width={320} height={450} bgcolor={'primary.gray'} display={'flex'} alignItems={'center'} justify={'center'} m={'auto'}>
                  <Image src={image.url || image.data} />
                </M.Box>
                <ContainerBottom bgcolor={'primary.main'} padding={1} textAlign={textBottomAlign || 'left'}>
                  <Text>{`Photo taken @ ${(image._created || image.event.ts) ? moment(image._created || image.event.ts).format('MM/DD/YYYY HH:mmA') : '[-]'} - [${_.get(image, 'event.location.geolocation.latitude', '-')} ${_.get(image, 'event.location.geolocation.longitude', '')}]`}</Text>
                </ContainerBottom>
              </M.Box>
              {!hideSendBtn && <M.Box marginTop={2}>
                <AxlModal isClose={isClose}
                          onRendered={this.open}
                          trigger={<AxlButton
                              {...(copied ? ({tooltip: {title: 'copied', placement: 'top-end'}}) : {})}
                              disabled={!image || (image && !(image.data || image.url))}
                              variant="contained"
                              color="primary.main"
                              bgcolor={(!image || (image && !(image.data || image.url))) ? 'primary.graySixth' : 'primary.green'}>{`Send to Customer`}</AxlButton>}>
                  <AxlConfirmation
                    subTitle={"Are you sure to send customer photo?"}
                    onClose={this.close} closeText={'Cancel'}
                    loading={loading}
                    onDo={() => {
                      this.close();
                      this.sendToCustomer(shipmentId, image.url);
                    }} doText={'Send'}/>
                </AxlModal>
              </M.Box>
              }
            </ContainerImage>)}
          </Slider>
        </ContainerInner>
        <Slider
          arrows={false}
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          slidesToShow={(images.length < 5 ? images.length : 5)}
          swipeToSlide={true}
          focusOnSelect={true}
        >
          {images.map((image, index) => <ImagePreview key={index}>
            {/*<Image src={`/assets/images/logo.png`} width={'auto'} height={'auto'} />*/}
            <M.Box width={55} height={82} bgcolor={'primary.gray'} display={'flex'} alignItems={'center'} justify={'center'} m={'auto'}>
              <Image src={image.url || image.data} />
            </M.Box>
          </ImagePreview>)}
        </Slider>
      </Container>
    );
  }
}