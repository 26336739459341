import { atom, selector, selectorFamily } from 'recoil';
import api from '../../repositories/Api';
import {messagesQueryState} from "../ChatBoxContainer/states";

//Todo call session
const CallCenterState = atom({
  key: 'call_center_state',
  default: {
    "raw_call_from": "+19252653785",
    "call_from": "9252653785",
    "call_to": "+18552497447",
    "external_id": "TESTING-NOT-REAL-7669970-1",
    "call_args": "2"
  }
});

export const GetCallSession = selector({
  key: 'get_call_session',
  get: ({get}) => get(CallCenterState),
})

export const SessionCallCenter = selectorFamily({
  key: 'session_call_center',
  get: (query = {}) => async () => {
    const mergedQuery = Object.assign({}, query);
    const res = await api.post(`/call_center`, mergedQuery);
    if (res.error) {
      throw res.error;
    }

    return res.data;
  },
  set: async ({get, set}, query) => {
    set(
      CallCenterState,
      Object.assign({}, get(CallCenterState), query)
    )
  }
});

// Todo Search
const searchFilters = atom({
  key: 'searchFilters',
  default: {
    "from": 0,
    "size": 15,
    "q": "",
    "filters": {},
    "sorts": ["-dropoff_earliest_ts"],
  }
});

const searchResults = atom({
  key: 'searchResults',
  default: {
    "total": 0,
    "from": 0,
    "size": 15,
    "results": [],
  }
});

export const doSearch = selector({
  key: 'doSearch',
  get: ({ get }) => {
    const { results } = get(searchResults);
    return results;
  },
  set: ({get, set}, filters) => {
    const mergedFilters = Object.assign({}, get(searchFilters), filters);
    const res = api.post(`/shipments/search`, mergedFilters);
    if (res.error) {
      throw res.error;
    }

    set(
      searchResults,
      Object.assign({}, get(searchResults), res)
    )
  }
});

// Todo Shipment
const shipmentState = atom({
  key: 'shipmentState',
  default: null
});

export const currentShipment = selector({
  key: 'currentShipment',
  get: ({get}) => get(shipmentState),
  set: async ({get, set}, shipmentId) => {
    const res = await api.get(`/shipments/${shipmentId}`);
    if (res.error) {
      throw res.error;
    }
    set(
      shipmentState,
      res
    )
  }
});

const shipmentsState = atom({
  key: 'shipmentsState',
  default: []
});

const shipmentsFilters = atom({
  key: 'shipmentsFilters',
  default: {
    "from": 0,
    "size": 15,
    "q": "",
    "filters": {},
    "sorts": [
      "-dropoff_earliest_ts"
    ]
  }
});

export const shipmentResults = selector({
  key: 'shipmentResults',
  get: ({get}) => get(shipmentsState),
  set: async ({get, set}, filters) => {
    const mergedFilters = Object.assign({}, get(shipmentsFilters), filters);
    const res = await api.post(`/shipments/search`, mergedFilters);
    if (res.error) {
      throw res.error;
    }

    set(
      searchResults,
      Object.assign({}, get(searchResults), res)
    )
  }
});

//Todo shipment selected
export const shipmentIdSelected = atom({
  key: 'shipmentIdSelected',
  default: null
});

export const shipmentIdSelectedStatus = selector({
  get: ({get}) => get(shipmentIdSelected),
  set: ({get, set}, newShipmentId) => set(shipmentIdSelected, newShipmentId),
});

//Todo Driver
export const driverState = atom({
  key: 'driverState',
  default: {}
});

export const driverFilters = atom({
  key: 'driverFilters',
  default: {
    page: 0,
    size: 20,
    order_by: 'id',
    desc: true,
    q: '6263169879'
  }
});

export const currentDriver = selector({
  key: 'currentDriver',
  get: async ({get}) => get(driverState),
  set: async ({get, set}, filters) => {
    const mergedFilters = Object.assign({}, get(driverFilters), filters);
    const res = await api.get(`/drivers/search`, mergedFilters);
    if (res.error) {
      throw res.error;
    }

    set(
      driverState,
      Object.assign({}, get(driverState), res)
    )
  }
});

//Todo load outbound events
export const getShipmentOutboundEvents = selectorFamily({
  key: 'getShipmentOutboundEvents',
  get: shipmentId => async () => {
    const res = await api.get(`/shipments/${shipmentId}/outbound-events`);
    if (res.error) {
      throw res.error;
    }
    return res;
  }
});

export const shipmentSearch = selector({
  key: 'shipmentSearch',
  get: ({get}) => get(getShipmentOutboundEvents(get(shipmentIdSelected))),
})

// Notes
export const noteStatus = atom({
  key: 'noteStatus',
  default: []
});


export const noteSelector = selector({
  key: 'noteSelector',
  get: async ({get}) => {
    const res = await api.get(`/notes/SH_${get(shipmentIdSelected)}`);
    if (res.error) {
      throw res.error;
    }
    return res.data;
  }
});
