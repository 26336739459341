import React from 'react';
import * as M from '@material-ui/core';

export const Container = M.styled(M.Box)(({theme}) => ({
  width: 450,
  maxWidth: '100%'
}));

export const Text = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Bold',
  fontSize: 13,
  fontWeight: 500,
  color: theme.palette.text.third
}));

export const Label = M.styled(Text)(({theme}) => ({
  fontSize: 11,
  color: theme.palette.text.select,
  margin: '15px 0 5px'
}));

export const Title = M.styled(Text)(({theme}) => ({
  fontSize: 15,
  color: theme.palette.text.select,
  margin: '5px 0',
  fontFamily: 'AvenirNext-DemiBold',
}));