import React, {useEffect, useState} from 'react';
import api from "../../repositories/Api";
import {useRecoilValue} from "recoil";
import {DropoffsSelectedState, ShipmentSelectedState} from "../../states/callCenterStates";
import * as M from '@material-ui/core';
import DeliveryHistoryList from "../DeliveryHistoryList";
import {useRecoilState} from "recoil/dist";

export default function ShipmentEventsContainer({onClose = () => {}}) {
  // Local states
  const [events, setEvents] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(false);
  // Global states
  const shipment = useRecoilValue(ShipmentSelectedState);
  const stops = useRecoilValue(DropoffsSelectedState);

  useEffect(() => {
    // Load outbound event of shipment
    setLoadingEvents(true);
    if(shipment) {
      api.get(`events/shipments/${shipment.id}?ref=true&rel=true`).then(res => {
        if(res.status === 200 && res.data) {
          setEvents(res.data);
        }
        setLoadingEvents(false);
      });
    }
  }, []);

  return (shipment && !loadingEvents ) ?
    events.length ?
      <DeliveryHistoryList
        stops={stops}
        shipment={shipment}
        events={events}
        enableMap={false} /> :
      <M.Box width={1} height={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>{`No History`}</M.Box> :
    <M.Box width={1} height={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <M.CircularProgress size={75} thickness={1} color={'secondary'} />
    </M.Box>
}