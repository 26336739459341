import * as M from '@material-ui/core';

export const Container = M.styled(M.Box)(({theme}) => ({}));
export const Inner = M.styled(`div`)(({theme}) => ({}));
export const List = M.styled(`div`)(({theme}) => ({}));
export const Item = M.styled(`div`)(({theme}) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '7.5px 0 7.5px 20px',
  '&:after': {
    content: '""',
    width: '1px',
    height: '100%',
    display: 'block',
    position: 'absolute',
    top: '20px',
    left: '4px',
    bottom: 0,
    backgroundColor: '#e4e4e4',
  },
  '&:nth-last-child(1)': {
    '&:after': {
      display: 'none'
    }
  }
}));

export const Text = M.styled(`div`)(({theme}) => ({
  flex: 1,
  fontFamily: 'AvenirNext',
  fontSize: '12px',
  color: '#4a4a4a',
}));

export const Circle = M.styled(`div`)(({theme}) => ({
  width: '9px',
  height: '9px',
  display: 'inline-block',
  backgroundColor: '#96979a',
  borderRadius: '50%',
  position: 'absolute',
  top: '12px',
  left: 0,
  margin: 'auto',
  zIndex: 1,
}));

export const Date = M.styled(`div`)(({theme}) => ({
  fontFamily: 'AvenirNext',
  fontSize: '12px',
  color: '#96979a',
}));

export const RemarkText = M.styled(`div`)(({theme}) => ({
  fontFamily: 'AvenirNext-Italic',
  fontSize: '12px',
  color: '#4a4a4a',
}));

export const DotLine = M.styled(M.Box)(({theme}) => ({
  position: 'absolute',
  borderLeft: `1px solid ${theme.palette.primary.grayMain}`,
  width: '1px',
  left: 0,
  top: 0,
  height: 'calc(100% - 15px)',
  boxSizing: 'border-box',
  display: 'block',
  marginTop: '15px',
}));

export const ContainerList = M.styled(M.Box)(({theme, ...props }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
  height: '100%',
}));

export const ContainerListInner = M.styled(M.Box)(({theme, ...props }) => ({
  flex: 1,
  overflowY: 'scroll',
}));

export function convertStatusToColor() {
  const theme = M.useTheme();
  return ({
    'STARTED': '#96979a',
    'CREATED': '#e4e4e4',
    'ASSIGNED': '#e4e4e4',
    'ROUTED': '#fbc04f',
    'PICKUP_READY': '#fbc04f',
    'DROPOFF_READY': '#fbc04f',
    'PICKUP_SUCCEEDED': '#887fff',
    'PICKUPED': '#887fff',
    'SUCCESSED': '#4abc4e',
    'DROPOFF_SUCCEEDED': '#4abc4e',
    'SUCCEEDED': '#4abc4e',
    'RECEIVED_OK': '#297ec8',
    'DROPOFF_FAILED': '#c44',
    'PICKUP_FAILED': '#c44',
    'FAILED': '#c44',
  });
}

export default {
  strong: {
    fontFamily: 'AvenirNext-Bold'
  },
  coordinatesWrap: {
    marginRight: '5px',
    marginLeft: '5px'
  },
  updateStopWrap: {
    display: 'flex',
    flexDirection: 'row'
  }
}