import React from 'react';
import * as M from '@material-ui/core';

export const Container = M.styled(M.Box)({});
export const Search = M.styled(M.InputBase)(({theme}) => ({
  borderRadius: 2.5,
  border: 'solid 0.8px #cccccc',
  backgroundColor: theme.palette.primary.whiteTwo,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  boxSizing: 'border-box',
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13,
  fontWeight: 600,
  color: theme.palette.primary.gray
}));