import React, {useCallback, useEffect, useState} from 'react';
import * as M from '@material-ui/core';
import _ from 'lodash';
import * as S from './styles';
import CallCenterControls from "../../components/CallCenterControls";
import CallCenterInfo from "../../components/CallCenterInfo";
import {CallCenterShipmentDetail} from "../../components/CallCenterShipmentDetail";
import MapCanvas from "../../components/MapCanvas";
import CallCenterShipmentLogs from "../../components/CallCenterShipmentLogs";
import {useRecoilState, useRecoilValue} from "recoil";
import {CallCenterBestGuess} from "../../components/CallCenterShipmentDetail/bestGuess";
import {
  BrowserRouter as Router,
  Switch,
  useLocation, useHistory, useParams
} from "react-router-dom";
import api from "../../repositories/Api";
import {
  CallSessionState,
  ShipmentSelectedState,
  AssignmentSelectedState,
  DropoffsSelectedState,
  DropoffSelectedState,
  CustomerShipmentSelectedState,
  BestGuessResultSelector,
  BestGuessResultFilters,
  NoGuessResultSelector,
  DropoffPODSelectedState,
  BestGuessResultByDateFilters
} from "../../states/callCenterStates";
import AxlMap from "../../components/AxlMap";
import {getDriverLocation} from "../../utils/assignment";
import CallCenterNoGuess from "../../components/CallCenterShipmentDetail/NoGuess";
import AxlButton from "../../components/Button";
import {copyToClipboard, copyToLocation} from "../../utils/display";
import AxlConfirmation from "../../components/AxlConfirmation";
import AxlModal from "../../components/AxlModal";
import {putSMS} from "../../repositories/HttpRepository";
import {Text} from "./styles";

export default function CallCenterResults() {
  // Local states
  const [loading, setLoading] = useState(false);
  const [isBestGuess, setBestGuess] = useState(false);
  const [isBestGuessLoading, setBestGuessLoading] = useState(false);
  const [isBestGuessSorting, setBestGuessSorting] = useState(false);
  const [copied, setCopied] = useState(false);
  const [closeConfirmSendPin, setCloseConfirmSendPin] = useState(false);
  const [driverLastLocation, setDriverLastLocation] = useState(null);
  // Global states
  const [callSession, setCallSesison] = useRecoilState(CallSessionState);
  const [shipmentSelected, setShipmentSelected] = useRecoilState(ShipmentSelectedState);
  const [customerShipmentSelected, setCustomerShipmentSelected] = useRecoilState(CustomerShipmentSelectedState);
  const [assignmentSelected, setAssignmentSelected] = useRecoilState(AssignmentSelectedState);
  const stops = useRecoilValue(DropoffsSelectedState);
  const [bestGuessShipmentResults, setBestGuessShipmentResults] = useRecoilState(BestGuessResultSelector);
  const [searchFilter, setSearchFilter] = useRecoilState(BestGuessResultFilters);
  const [shipmentNoGuess, setShipmentNoGuess] = useRecoilState(NoGuessResultSelector);
  const [dropoffPODSelected, setDropoffPODSelected] = useRecoilState(DropoffPODSelectedState);
  const [searchByDateFilter, setSearchByDateFilter] = useRecoilState(BestGuessResultByDateFilters);
  // Variables
  const history = useHistory();
  // Defined
  const DEFINED = {
    UNKNOWN_DRIVER: 1,
    UNKNOWN_RECIPIENT: 2,
    DRIVER: 8,
    RECIPIENT: 9
  }
  // Variables
  const urlParams = new URLSearchParams(window.location.search);
  const callFrom = urlParams.get('call_from') || '-';
  const callArgs = callArgsHandle(urlParams.get('call_args')) || [9];
  const query = {
    'raw_call_from': urlParams.get('call_from'),
    'call_from': formatPhoneNumber(urlParams.get('call_from')),
    'call_to': urlParams.get('call_to'),
    'external_id': urlParams.get('call_session_id') || urlParams.get('external_id'),
    'call_args': urlParams.get('call_args')
  };
  const {raw_call_from, call_from, call_to, external_id, call_args, attr} = callSession;
  const agrs = callArgsHandle(call_args) || callArgs;
  const shipmentIdFromSession = (attr && attr.shipment_id) ?
    parseInt(attr.shipment_id) :
    ([DEFINED.RECIPIENT, DEFINED.UNKNOWN_RECIPIENT].indexOf(agrs[0]) !== -1 && agrs[1]  || null);

  function formatPhoneNumber(phoneNumber) {
    if(!phoneNumber) return null;

    return phoneNumber.trim().replace(/[^0-9]/gi, '').slice(-10);
  }

  function callArgsHandle(value) {
    if(!value) return null;

    return value.split("|").map(a => parseInt(a));
  }

  useEffect(() => {
    //Initial general session
    api.post('/call_center/', query).then(res => {
      if(res.status === 200) {
        setCallSesison(res.data);
      }
    })
  }, []);

  useEffect(() => {
    if(agrs[0] === DEFINED.DRIVER) {
      // Driver incomming
      if(agrs[1]) {
        // Load driver info
      } else if(query['call_from']) {
        //Driver check phone number
      }
    } else if(agrs[0] === DEFINED.UNKNOWN_DRIVER) {
      // Unknown driver
    } else if([DEFINED.RECIPIENT, DEFINED.UNKNOWN_RECIPIENT].indexOf(agrs[0]) !== -1) {
      // Check shipment id?
      if(shipmentIdFromSession) {
        // Load shipment
        let shipmentId = shipmentIdFromSession;
        // Five9 test
        if(process.env.REACT_APP_FIVE9_TEST_SHIPMENT_IDS) {
          if (agrs[1] === process.env.REACT_APP_FIVE9_TEST_SHIPMENT_IDS) {
            shipmentId = process.env.REACT_APP_FIVE9_REAL_SHIPMENT_IDS;
          }
        }
        api.get(`shipments/${shipmentId}?customer_request_included=true`).then(res => {
          if(res.status === 200) {
            const {shipment, customerShipment} = res.data;
            if(shipment) {
              setShipmentSelected(shipment);
            }
            if(customerShipment) {
              setCustomerShipmentSelected(customerShipment);
            }
            // Load Assignment
            if(shipment && shipment.assignment_id) {
              api.get(`/assignments/${shipment.assignment_id}/detail?show_soft_deleted=true`).then(res => {
                if(res.status === 200) {
                  const assignment = res.data;
                  api.get(`/assignments/${shipment.assignment_id}/locations`).then(res => {
                    if(res.status === 200 && res.data) {
                      const assignmentLocations = {
                        ...assignment,
                        locations: res.data,
                        // driverLocation: getDriverLocation(assignment),
                      };
                      setAssignmentSelected(assignmentLocations);
                    }
                  });
                }
              });
            }
          }
        });
        // Load shipment outbound events
      } else {
        // Load shipment by customer's phone
        const q = {
          filters: {
            q: query['call_from'] || ''
          }
        };
        const bestGuessFilters = _.merge({}, searchFilter, q);
        setSearchByDateFilter(bestGuessFilters);
        // Loading more
        if(bestGuessFilters.loadmore) {
          setBestGuessLoading(true);
        } else {
          setBestGuessSorting(true);
        }
        api.post(`shipments/search`, bestGuessFilters.filters).then(res => {
          if(res.status === 200 && res.data && res.data.results) {
            if(bestGuessFilters.loadmore) {
              const data = _.concat(bestGuessShipmentResults.results, res.data.results);
              const obj = {
                ...res.data,
                results: data
              };
              setBestGuessShipmentResults(obj);
            } else {
              setBestGuessShipmentResults(res.data);
            }
          }
          setBestGuessLoading(false);
          setBestGuessSorting(false);
        })
      }
    }
  }, [searchFilter, callSession]);

  useEffect(() => {
    if(shipmentNoGuess) {
      api.get(`shipments/${shipmentNoGuess.id}?customer_request_included=true`).then(res => {
        if(res.status === 200) {
          const {shipment, customerShipment} = res.data;
          if(shipment) {
            setShipmentSelected(shipment);
          }
          if(customerShipment) {
            setCustomerShipmentSelected(customerShipment);
          }
        }
      });
    }
  }, [shipmentNoGuess]);

  useEffect(() => {
    if (assignmentSelected) {
      const { locations, assignment } = assignmentSelected;
      if (locations && locations.length) {
        // if (assignment.status && assignment.status === 'IN_PROGRESS') {
          const lastDriverLocation = { ...locations[locations.length - 1] };
          setDriverLastLocation(lastDriverLocation)
          // driverLocationLink.current = `https://www.google.com/maps/search/?api=1&query=${lastDriverLocation.latitude},${lastDriverLocation.longitude}`;
        // }
      }
    }
  }, [assignmentSelected]);

  const sendToCustomer = (shipmentId, pod) => {
    if(!pod || !_.get(pod, 'event.location.geolocation.latitude') || !_.get(pod, 'event.location.geolocation.longitude')) return;

    setLoading(true);
    const url = `https://www.google.com/maps/search/?api=1&query=${_.get(pod, 'event.location.geolocation.latitude')}, ${_.get(pod, 'event.location.geolocation.longitude')}`;

    // copyToLocation(pod.event.location.geolocation);
    putSMS(shipmentId, {"body": url}).then(res => {
      setLoading(true);
    });
  };

  const sendDriverLocationToCustomer = (shipmentId, location) => {
    if( !location || (location && !(location.latitude && location.longitude)) ) return;
    setLoading(true);
    const url = `https://www.google.com/maps/search/?api=1&query=${_.get(location, 'latitude')}, ${_.get(location, 'longitude')}`;

    // copyToLocation(pod.event.location.geolocation);
    putSMS(shipmentId, {"body": url}).then(res => {
      setLoading(true);
    });
  };

  // Pods
  const pictures = dropoffPODSelected.filter(d => d.type === 'picture');

  // Last driver's location
  const lastDriverLocation = assignmentSelected && assignmentSelected.locations.length ? {...assignmentSelected.locations[assignmentSelected.locations.length-1]} : null;
  const lastDriverLocationLink = lastDriverLocation ? `https://www.google.com/maps/search/?api=1&query=${lastDriverLocation.latitude},${lastDriverLocation.longitude}` : null;
  const isShowDriverLocationLink = !!(assignmentSelected && assignmentSelected.assignment && assignmentSelected.assignment.status === 'IN_PROGRESS' && lastDriverLocation);
  const isShowSendPin = !!(shipmentSelected && shipmentSelected.status === 'DROPOFF_SUCCEEDED' && pictures.length);
  // show cases
  const isShow_CC_Detail = !!(agrs[1] || shipmentIdFromSession);
  const isShowBestGuess = !isShow_CC_Detail && !!(bestGuessShipmentResults.results && bestGuessShipmentResults.results.length) || !!Object.values(searchFilter.filters.filters).length;
  const isShowNoGuess = !isShow_CC_Detail && !isShowBestGuess;
  const showcase = isShow_CC_Detail ? 'EXACT' : isShowBestGuess ? 'BEST_GUESS' : 'NO_GUESS';

  return(<S.Container>
    <S.Controler mb={2}>
      <CallCenterControls/>
    </S.Controler>
    <S.Main>
      <S.LeftSide>
        <CallCenterInfo showcase={showcase} />
        {isShow_CC_Detail && <CallCenterShipmentDetail/>}
        {isShowBestGuess && <CallCenterBestGuess sorting={isBestGuessSorting} loadingMore={isBestGuessLoading} />}
        {isShowNoGuess && <CallCenterNoGuess />}
      </S.LeftSide>
      <S.RightSide height={1}>
        <S.MapContainer mb={2} height={300}>
          <AxlMap
            pods={dropoffPODSelected}
            shipment={shipmentSelected}
            assignment={assignmentSelected}
            locations={ assignmentSelected ? assignmentSelected.locations : null }
            driverLocation={ assignmentSelected ? assignmentSelected.driverLocation : null }
          />
          <S.Controls>
            {isShowDriverLocationLink && (
              <AxlModal
                isClose={closeConfirmSendPin}
                onRendered={() => setCloseConfirmSendPin(false)}
                trigger={
                  <AxlButton
                    variant={'outlined'}
                    color={'primary.brownNight'}
                    bgcolor={'primary.main'}
                    padding={`3px 10px`}
                    spacing={0.3}
                  >
                    <Text>{`Driver’s current location`}</Text>
                  </AxlButton>
                }
              >
                <AxlConfirmation
                  subTitle={'Are you sure to send driver’s current location pin?'}
                  onClose={() => setCloseConfirmSendPin(true)}
                  closeText={'Cancel'}
                  onDo={() => {
                    setCloseConfirmSendPin(true);
                    sendDriverLocationToCustomer(shipmentIdFromSession, driverLastLocation);
                  }}
                  doText={'Send'}
                />
              </AxlModal>
            )}
            {isShowSendPin && <AxlModal isClose={closeConfirmSendPin}
                      onRendered={() => setCloseConfirmSendPin(false)}
                      trigger={<AxlButton
                        variant={'outlined'}
                        color={'primary.brownNight'}
                        bgcolor={'primary.main'}
                        padding={`3px 10px`}
                        spacing={0.3}>
                        <S.Text>{`Send customer dropoff pin`}</S.Text>
                      </AxlButton>}>
              <AxlConfirmation
                subTitle={"Are you sure to send customer shipment’s dropoff location pin?"}
                onClose={() => setCloseConfirmSendPin(true)} closeText={'Cancel'}
                onDo={() => {
                  setCloseConfirmSendPin(true);
                  sendToCustomer(shipmentIdFromSession, pictures[0]);
                }} doText={'Send'}/>
            </AxlModal>}
          </S.Controls>
        </S.MapContainer>
        <CallCenterShipmentLogs/>
      </S.RightSide>
    </S.Main>
  </S.Container>);
}