import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import * as M from '@material-ui/core';
import MapCanvas from "../MapCanvas";
import demodata, {shipments} from "../AddressHistoryList/data";
import * as S from './styles';
import AddressHistory from "../AddressHistory";
import {useRecoilState} from "recoil";
import {currentShipment} from "../../containers/CallCenterResults/states";
import AddressHistoryImagePreview from "../AddressHistoryImagePreview";
import {
  DropoffsSelectedState,
  ShipmentSelectedState,
  DropoffStopAddressHistoryState,
  AssignmentSelectedState,
  CurrentSearchShipmentSelector
} from "../../states/callCenterStates";
import {useRecoilValue} from "recoil/dist";
import {DROPOFF_TYPE} from "../../constants/stop";
import api from "../../repositories/Api";
import AxlMap from "../AxlMap";
import AddressHistoryPinPreview from "../AddressHistoryPinPreview";


export default function AddressHistoryList() {
  const shipment = useRecoilState(currentShipment)[0];

  return(<S.ListContainer padding={2}>
    <M.Box mb={2}>
      <S.Text><S.B>{`1234 PEMBERTON LN`}</S.B></S.Text>
      <S.Text><S.B>{`FOLSOM CA 95630-7913`}</S.B></S.Text>
    </M.Box>
    <M.Box height={200} bgcolor={'primary.whiteTwo'} mb={2}>
      <MapCanvas shipment={shipment} />
    </M.Box>
    <S.Scroll>
      {shipments.map((shipment, index) => <AddressHistory shipment={shipment} key={index} />)}
    </S.Scroll>
  </S.ListContainer>);
}

export function AddressHistoryPopup() {
  // Local states
  const [imageMode, setImageMode] = useState(false);
  const [pinMode, setPinMode] = useState(false);
  const [searchShipments, setShipments] = useState([]);
  const [pods, setPods] = useState([]);
  const [loadingShipment, setLoadingShipment] = useState(false);
  const [loadingPods, setLoadingPods] = useState(false);
  // Global states
  const stops = useRecoilValue(DropoffsSelectedState);
  const shipmentSelected = useRecoilValue(ShipmentSelectedState);
  const [currentShipmentSearch, setCurrentShipmentSearch] = useRecoilState(CurrentSearchShipmentSelector);
  const [assignmentSelected, setAssignmentSelected] = useRecoilState(AssignmentSelectedState);

  function triggerImage({shipment, client, dropoff, pod}) {
    setImageMode(!imageMode);
    if(pod && pod.images.length) {
      setCurrentShipmentSearch({dropoff, pod, client, shipment});
    }
  }

  function triggerPin({shipment, dropoff}) {
    let currentPods = [];
    setPinMode(!pinMode);
    if(pods.length) {
      currentPods = (dropoff && pods.length) && pods.filter(p => p.stop_id === dropoff.id) || [];
    }
    setCurrentShipmentSearch({dropoff, pods: currentPods, shipment});
  }

  useEffect(() => {
    // Load shipment from address
    if(shipmentSelected) {
      const params = {
        from: 0,
        filters: {},
        size: 5,
        q: `${shipmentSelected.dropoff_address.street.trim()} ${shipmentSelected.dropoff_address.city.trim()} ${shipmentSelected.dropoff_address.state.trim()} ${shipmentSelected.dropoff_address.zipcode.trim()}`,
        sorts: ["-dropoff_earliest_ts"]
      };
      setLoadingShipment(true);
      api.post(`shipments/search`, params).then(res => {
        if(res.status === 200 && res.data && res.data.results && res.data.results.length) {
          setShipments(res.data.results);
          const dropoffIds = res.data.results.filter(s => s.dropoff && ['SUCCEEDED', 'EN_ROUTE'].indexOf(s.dropoff.status) !== -1).map(s => s.dropoff && s.dropoff.id)
          if(dropoffIds && dropoffIds.length) {
            // Load pods
            setLoadingPods(true);
            api.post(`stops/pods`, {'stop_ids': dropoffIds}).then(res => {
              if(res.status === 200 && res.data) {
                setPods(res.data.filter(p => _.get(p, 'event.location.geolocation', false)));
              }
              setLoadingPods(false);
            })
          }
        }
        setLoadingShipment(false);
      })
    }
  }, [shipmentSelected]);

  return(<M.Box>
    {!imageMode ? !pinMode ? <S.PopupContainer>
      <M.Box>
        <S.PopupTitle>{`ADDRESS HISTORY`}</S.PopupTitle>
        {shipmentSelected && <M.Box>
          <S.Text_1>{`Dropoff Address: `}</S.Text_1>
          <S.Text><S.B>{`${shipmentSelected.dropoff_address.street}, ${shipmentSelected.dropoff_address.city}, ${shipmentSelected.dropoff_address.state} ${shipmentSelected.dropoff_address.zipcode}`}</S.B></S.Text>
        </M.Box>}
      </M.Box>

      <M.Box my={2} minHeight={200} height={200} bgcolor={'primary.whiteTwo'}>
        {!!(shipmentSelected || pods.length) && <AxlMap
          pods={pods}
          shipment={shipmentSelected}
          locations={ assignmentSelected ? assignmentSelected.locations : null }
          driverLocation={ assignmentSelected ? assignmentSelected.driverLocation : null }
        />}
      </M.Box>
      {!loadingShipment ? <S.Scroll>
        {!!searchShipments.length && searchShipments.map((props, index) => <AddressHistory key={index} {...props} enableImageMode
          trigger={triggerImage} triggerPin={triggerPin} />)}
      </S.Scroll>  : <M.Box width={1} height={300} maxHeight={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <M.CircularProgress color={'secondary'} size={50} thickness={0.5} />
      </M.Box>}
    </S.PopupContainer> :
    <div>
      <M.Box>
        <S.PopupTitle>{`ADDRESS HISTORY`}</S.PopupTitle>
      </M.Box>
      <AddressHistoryPinPreview trigger={triggerPin} />
    </div> :
    <div>
      <M.Box>
        <S.PopupTitle>{`ADDRESS HISTORY`}</S.PopupTitle>
      </M.Box>
      <AddressHistoryImagePreview trigger={triggerImage} />
    </div>}
  </M.Box>);
}
