import {Box, styled} from "@material-ui/core";
import * as M from '@material-ui/core';
import AxlButton from "../Button";

export const FormChatContainer = styled(Box)({
  borderTop: '1px solid #CCCCCC'
});

export const SendButton = M.withStyles(({palette, spacing}) => ({
  label: {
    fontFamily: 'AvenirNext-Bold',
    fontSize: 12.5,
    fontWeight: 'bold',
  }
}))(AxlButton)