import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { AppBar, Toolbar, Typography, IconButton, MenuItem, Menu, Box, Grid } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

import useStyles from './styles';

import { userState } from '../../atoms';
import logo from '../../logo.svg';
import NavLink from './NavLink';
import AxlDrawer from "../Drawer";

const menus = [
  { label: 'Customer Support', link: '/', otherActive: '/messages' },
  { label: 'Search', link: '/search' },
  { label: 'Call Center', link: '/call-center' },
];

export default function Header() {
  const user = useRecoilValue(userState);

  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    window.location.href = `${process.env.REACT_APP_OAUTH_URL}/login?logout=true&next=${encodeURI(
      window.location.href,
    )}`;
  };

  return (
    <div className={classes.root}>
      <AppBar color="default" position="static">
        <Toolbar style={{minHeight: 50}}>
          <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer">
            <AxlDrawer />
          </IconButton>
          <Box>
            <Link to={'/'}>
              <img alt="Jitsu logo" height="50px" src={logo} style={{display: "block"}} />
            </Link>
          </Box>
          <Typography variant="h6" className={classes.title}>Customer Support</Typography>
          {user.user && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
                <span className={classes.username}>{user.user.name}</span>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <AppBar className={classes.secondAppBar} color="secondary">
        <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
          {menus.map((menu) => (
            <NavLink
              location={history.location}
              key={menu.link}
              className={classes.link}
              to={menu.link}
              otherActive={menu.otherActive}
            >
              {menu.label}
            </NavLink>
          ))}
        </Grid>
      </AppBar>
    </div>
  );
}
