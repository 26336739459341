import React from 'react';
import moment from "moment-timezone";
import * as M from '@material-ui/core';
import * as S from './styles';
import * as S1 from './stickStyles';

export default function AxlTimeLine({items = [], color, ...props}) {
  return(<S.Container>
    <S.Line color={color} />
    {items.map((item) => (
      <S.Item>
        <S.Dot color={color}/>
        {item}
      </S.Item>
    ))}
  </S.Container>);
}

export function AxlTimeLineStick({items, ...props}) {
  return(<S1.Container>
    {items.map(({ts, ...item}, index) => (
      <S1.Item key={index}>
        <S1.DateContainer>
          <S1.Date>{moment(ts).format(`MMM DD`)}</S1.Date>
          <S1.Time>{moment(ts).format(`HH:mm A`)}</S1.Time>
        </S1.DateContainer>
        <S1.StickContainer className={index === items.length-1 ? 'last' : ''}>
          <S1.Stick bgcolor={item.bgcolor} />
        </S1.StickContainer>
        <S1.Content>
          <S1.Text active={index === 0}>{item.content}</S1.Text>
        </S1.Content>
      </S1.Item>
    ))}
  </S1.Container>);
}