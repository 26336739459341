import React, {useEffect, useState} from 'react';
import * as S from './noGuessStyles';
import AxlInput from "../AxlInput";
import * as M from '@material-ui/core';
import AxlButton from "../Button";
import {variantModal} from "../../themes/modal";
import AxlTable from "../AxlTable";
import {useRecoilState} from "recoil/dist";
import {
  CallSessionState,
  NoGuessResultFilters, NoGuessSearchResultSeletor,
  RematchShipmentSelector,
  ShipmentSearchResultSelector
} from "../../states/callCenterStates";
import api from "../../repositories/Api";
import NoGuessTableList from "../NoGuessTableList";

export default function CallCenterNoGuess() {
  // Local states
  const [text, setText] = useState('');
  const [onKey, setOnKey] = useState(false);
  const [isSearching, setSearching] = useState(false);
  // Global states
  const [shipmentResults, setShipmentResults] = useRecoilState(NoGuessSearchResultSeletor);
  const [searchFilter, setSearchFilter] = useRecoilState(NoGuessResultFilters);

  const onChange = (e) => {
    setText(e.target.value);
  }

  const onSearch = (e) => {
    if(e.key === 'Enter' && !!text) {
      setOnKey(true);
    }
  }

  useEffect(() => {
    if(!!text || onKey) {
      const params = Object.assign({}, searchFilter, {q: text});
      setSearching(true);
      api.post(`/shipments/search`, params).then(res => {
        if(res.status === 200 && res.data) {
          setShipmentResults(res.data.results);
        }
        // Reset
        setOnKey(false);
        setSearching(false);
      })
    }
  }, [searchFilter.filters, onKey])

  return(<S.Container>
    <S.SearchContainer my={2}>
      <AxlInput
        onChange={(e) => onChange(e)}
        onKeyDown={(e) => onSearch(e)}
        theme={'main'} spacing={0} autoFocus fullWidth
        placeholder={`Search shipment by customer info, tracking code...`} />
    </S.SearchContainer>
    <M.TableContainer>
      <NoGuessTableList items={shipmentResults} loading={isSearching} />
    </M.TableContainer>
  </S.Container>);
}
