import * as M from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

export const Container = M.styled(M.Box)(({theme}) => ({
  height: 'calc(100vh - 146px)',
  boxSizing: 'border-box',
  overflow: 'hidden',
  border: `1px solid ${theme.palette.primary.grayMain}`
}));

export default makeStyles(theme => ({
  root: {
    height: 'calc(100vh - 146px)',
    boxSizing: 'border-box',
    overflow: 'hidden'
  },
  container: {
    height: '100%'
  },
  gridRoot: {},
  zeroMinWidth: {
    width: 300
  },
  inner: {
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    direction: 'rtl'
  },
  innerSection: {
    overflow: 'hidden'
  }
}))