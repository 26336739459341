import React from 'react';
import * as M from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';

export const Container = M.styled(M.Box)({
  display: 'flex',
  flexDirection: 'column'
});
export const ItemContainer = M.styled(M.Grid)({
  zIndex: 1,
  position: 'relative',
  marginBottom: 10
});
export const Dot = M.styled(M.Box)(({theme, ...rest}) => ({
  width: 8,
  height: 8,
  marginTop: 5,
  borderRadius: '50%'
}));
export const Text = M.styled(M.Typography)(({theme}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.text.select,
  marginBottom: 5
}));
export const NameCall = M.styled(Text)(({theme}) => ({
  fontSize: 14,
  fontWeight: 500,
}));
export const B = M.styled('b')(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.text.select,
}));
export const Date = M.styled(B)(({theme}) => ({
  fontSize: 10
}));
export const Label = M.styled(B)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
}));
export const PhoneIco = M.styled(PhoneIcon)(({theme}) => ({
  fontSize: 13,
  backgroundColor: '#f4f5f4',
  border: `1px solid ${theme.palette.primary.green}`,
  borderRadius: '50%',
  color: theme.palette.primary.green,
  padding: 2,
  fontWeight: 'bold'
}));
export const PhoneList = M.styled(M.Box)(({theme}) => ({
  position: 'relative',
  zIndex: 1,
}));
export const Line = M.styled(M.Box)(({theme}) => ({
  position: 'absolute',
  left: 8,
  width: 2,
  height: 'calc(100% - 20px)',
  margin: '20px 0 0',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.primary.grayFifth
}));

export const Text_1 = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBoldItalic',
  fontSize: 11,
  fontWeight: 600,
  fontStyle: 'italic',
  color: theme.palette.primary['greyish-brown']
}));

export const Text_2 = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13,
  fontWeight: 500,
  fontStyle: 'italic',
  letterSpacing: -0.09,
  color: theme.palette.primary.blackSecondary
}));

export const Text_3 = M.styled(Text)(({theme}) => ({
  fontFamily: 'AvenirNext-MediumItalic',
  fontSize: 11,
  fontWeight: 500,
  fontStyle: 'italic',
  letterSpacing: -0.09,
  color: theme.palette.primary.blackSecondary
}));