import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { useRecoilValue, useRecoilState } from 'recoil';
import { get } from 'lodash';
import { AxlBox, AxlSimpleBox, AxlBorderBox } from '../AxlBox';
import * as S from './styles';

import { SHIPMENT_STATUS_TO_COLORS } from '../../constants/shipment';

// component
import AxlModal from '../AxlModal';
import DropoffStreetEditForm from '../DropoffStreetEditForm';
import DropoffNotesEditForm from '../DropoffNotesEditForm';
import DropoffAccessCodeEditForm from '../DropoffAccessCodeEditForm';
import LeaveNoteForm from '../LeaveNoteForm';
import ShipmentNote from '../ShipmentNote';
import ShipmentNoteEditForm from '../ShipmentNoteEditForm';
import AssignmentNoteEditForm from '../AssignmentNoteEditForm';
import ImagePreviewer from '../ImagePreviewer';
import { ViewPODIdScan } from '../CallCenterShipmentDetail/forms';

import { conversationState, dropOffShipmentState } from '../../containers/ChatBoxContainer/states';
import { userState } from '../../atoms';
import { dropOffPODResultState, dropOffPODState } from '../../states/ShipmentDetail';
import { ClientSelectedState } from '../../states/callCenterStates';
import { getShipmentNotes, getAssignmentNotes, getDropOffPOD, getClient } from '../../repositories/Api';

export default function ShipmentDetail() {
  const [dropOffPod, setDropOffPod] = useRecoilState(dropOffPODState);
  const conversation = useRecoilValue(conversationState);
  const user = useRecoilValue(userState);
  const dropOff = useRecoilValue(dropOffShipmentState);
  const { shipment, avatar, customer, assignment } = conversation;
  const [isClosePopup, setIsClosePopup] = useState(false);
  const [shipmentNotes, setShipmentNotes] = useState([]);
  const [assignmentNotes, setAssignmentNotes] = useState([]);
  const dropOffPodResult = useRecoilValue(dropOffPODResultState);
  const { pictures, signatures, idscans } = dropOffPodResult;
  const [clientSelected, setClientSelected] = useRecoilState(ClientSelectedState);

  useEffect(() => {
    if (shipment.id) {
      getShipmentNotes(shipment.id).then((res) => {
        if (res.status === 200 && res.data) {
          setShipmentNotes(res.data);
        }
      });
      getAssignmentNotes(shipment.id).then((res) => {
        if (res.status === 200 && res.data) {
          setAssignmentNotes(res.data);
        }
      });
      getClient(shipment.client_id).then(res => {
        if (res.status === 200 && res.data) {
          setClientSelected(res.data);
        }
      })
    }
  }, [shipment]);

  useEffect(() => {
    if (dropOff && dropOff.id) {
      getDropOffPOD(dropOff.id).then((resp) => {
        if (resp.ok) {
          setDropOffPod(resp.data);
        }
      });
    } else {
      setDropOffPod([]);
    }
  }, [dropOff]);

  return (
    <AxlBox noscroll>
      <S.Container wrap={'nowrap'} container direction={'column'} alignItems={'flex-start'}>
        <S.HeaderSection>
          <Box padding={'0 15px'}>
            <Grid container direction={'row'} spacing={1}>
              <Grid item>
                <img src={avatar || '/logo.svg'} width={40} height={40} />
              </Grid>
              <Grid item xs>
                <S.ClientName>
                  {get(clientSelected, 'name', '-')}
                  <S.ShipmentId component={'span'}>{get(shipment, 'id', '')}</S.ShipmentId>
                </S.ClientName>
                <S.Text>
                  {`Tracking code: `}
                  <S.TrackingText
                    target={'_blank'}
                    href={`${process.env.REACT_APP_TRACKING_CODE_URL}/${get(shipment, 'tracking_code', '')}`}
                  >
                    {get(shipment, 'tracking_code', '')}
                  </S.TrackingText>
                </S.Text>
              </Grid>
              <Grid item>
                <S.Text>{`STATUS:`}</S.Text>
                <S.StatusText color={SHIPMENT_STATUS_TO_COLORS()[get(shipment, 'status', 'CREATED')]}>
                  {get(shipment, 'status', 'CREATED')}
                </S.StatusText>
              </Grid>
            </Grid>
          </Box>
        </S.HeaderSection>
        <S.ContainerScroll item>
          <S.Scroll>
            <S.SubTitle>{`Customer Info:`}</S.SubTitle>
            <AxlSimpleBox padding={2} marginBottom={1}>
              <Box>
                <S.Label>{`Name:`}</S.Label>
                <S.Text>{get(customer, 'name', '')}</S.Text>
                <Box>
                  <Grid container direction={'row'}>
                    <Grid item xs>
                      <S.Label>{`Phone:`}</S.Label>
                      <S.Text>{get(customer, 'phone_number', '')}</S.Text>
                    </Grid>
                    <Grid item xs>
                      <S.Label>{`Email:`}</S.Label>
                      <S.Text>{get(customer, 'email', '')}</S.Text>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </AxlSimpleBox>
            <S.SubTitle>{`Proof of Delivery:`}</S.SubTitle>
            <AxlSimpleBox padding={2} marginBottom={1}>
              <Box>
                <Grid container spacing={2}>
                  <AxlModal
                    disabled={!pictures.length}
                    bgcolor={'primary.transparent'}
                    isOpen={false}
                    trigger={
                      <Grid item>
                        <S.Text_1>
                          {`Photo `}
                          {shipment && shipment.delivery_proof_photo_required && (
                            <S.TextRequired>{`(required)`}</S.TextRequired>
                          )}
                        </S.Text_1>
                        <S.ImagePreviewRequired>
                          <S.Image src={pictures[0]?.url || ''}/>
                          <S.TextThumbNail>{`(${pictures.length})`}</S.TextThumbNail>
                        </S.ImagePreviewRequired>
                        <Box textAlign={'center'}>
                          <S.LinkView>{`View`}</S.LinkView>
                        </Box>
                      </Grid>
                    }
                  >
                    <ImagePreviewer shipmentId={shipment.id} images={pictures} textBottomAlign={'center'} />
                  </AxlModal>
                  <Grid item>
                    <S.DividerContainer>
                      <Divider orientation={'vertical'} />
                    </S.DividerContainer>
                  </Grid>
                  <AxlModal
                    disabled={!signatures.length}
                    isOpen={false}
                    bgcolor={'primary.transparent'}
                    trigger={
                      <Grid item>
                        <S.Text_1>
                          {`Signature `}
                          {shipment && shipment.signature_required && <S.TextRequired>{`(required)`}</S.TextRequired>}
                        </S.Text_1>
                        <S.ImagePreviewRequired>
                          <S.Image src={signatures[0]?.data || ''} />
                          <S.TextThumbNail>{`(${signatures.length})`}</S.TextThumbNail>
                        </S.ImagePreviewRequired>
                        <Box textAlign={'center'}>
                          <S.LinkView>{`View`}</S.LinkView>
                        </Box>
                      </Grid>
                    }
                  >
                    <ImagePreviewer images={signatures} textBottomAlign={'center'} />
                  </AxlModal>
                  <Grid item>
                    <S.DividerContainer>
                      <Divider orientation={'vertical'} />
                    </S.DividerContainer>
                  </Grid>
                  <AxlModal
                    disabled={!idscans.length}
                    isOpen={false}
                    trigger={
                      <Grid item>
                        <S.Text_1>
                          {`ID Scan `}
                          {shipment && shipment.id_required && <S.TextRequired>{`(required)`}</S.TextRequired>}
                        </S.Text_1>
                        <S.ImagePreview>
                          <S.Text>{`(${idscans.length})`}</S.Text>
                        </S.ImagePreview>
                        <Box textAlign={'center'}>
                          <S.LinkView>{`View`}</S.LinkView>
                        </Box>
                      </Grid>
                    }
                  >
                    <ViewPODIdScan pod={idscans[0]} />
                  </AxlModal>
                </Grid>
              </Box>
            </AxlSimpleBox>
            <S.SubTitle>{`Dropoff Info:`}</S.SubTitle>
            <AxlSimpleBox padding={2} marginBottom={1}>
              <Box>
                <Grid container direction={'row'} alignItems={'center'}>
                  <Grid item xs={11}>
                    <S.Label>{`Address:`}</S.Label>
                    <S.Text>{get(shipment, 'dropoff_address.street', '')}</S.Text>
                    <S.Text>{get(shipment, 'dropoff_address.street2', '')}</S.Text>
                  </Grid>
                  <Grid item xs={1}>
                    <AxlModal
                      isClose={isClosePopup}
                      onRendered={() => setIsClosePopup(false)}
                      trigger={
                        <IconButton>
                          <img src={`/assets/images/svg/edit.svg`} width={15} height={15} />
                        </IconButton>
                      }
                    >
                      <DropoffStreetEditForm shipmentId={shipment.id} closePopup={() => setIsClosePopup(true)} />
                    </AxlModal>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <S.Label>{`Dropoff remark:`}</S.Label>
                <AxlBorderBox>
                  <Grid container direction={'row'} alignItems={'center'}>
                    <Grid item xs={11}>
                      <S.Text>{get(dropOff, 'remark', 'N/A')}</S.Text>
                    </Grid>
                  </Grid>
                </AxlBorderBox>
                <S.Label>{`Dropoff notes:`}</S.Label>
                <Grid container direction={'row'} alignItems={'center'}>
                  <Grid item xs={11}>
                    <AxlBorderBox>
                      <S.Text>{get(shipment, 'dropoff_note', '-')}</S.Text>
                    </AxlBorderBox>
                  </Grid>
                  <Grid item xs={1}>
                    <AxlModal
                      isClose={isClosePopup}
                      onRendered={() => setIsClosePopup(false)}
                      trigger={
                        <IconButton>
                          <img src={`/assets/images/svg/edit.svg`} width={15} height={15} />
                        </IconButton>
                      }
                    >
                      <DropoffNotesEditForm closePopup={() => setIsClosePopup(true)} />
                    </AxlModal>
                  </Grid>
                </Grid>
                <S.Label>{`Access Code/Additional Instructions:`}</S.Label>
                <Grid container direction={'row'} alignItems={'center'}>
                  <Grid item xs={11}>
                    <AxlBorderBox>
                      <S.Label>{`Access Code:`}</S.Label>
                      <S.Text>{get(shipment, 'dropoff_access_code', 'N/A')}</S.Text>
                      <S.Label>{`Additional Instructions:`}</S.Label>
                      <S.Text>{get(shipment, 'dropoff_additional_instruction', 'N/A')}</S.Text>
                    </AxlBorderBox>
                  </Grid>
                  <Grid item xs={1}>
                    <AxlModal
                      isClose={isClosePopup}
                      onRendered={() => setIsClosePopup(false)}
                      trigger={
                        <IconButton>
                          <img src={`/assets/images/svg/edit.svg`} width={15} height={15} />
                        </IconButton>
                      }
                    >
                      <DropoffAccessCodeEditForm closePopup={() => setIsClosePopup(true)} />
                    </AxlModal>
                  </Grid>
                </Grid>
                <S.Label>{`Shipment note:`}</S.Label>
                <Grid container direction={'row'} alignItems={'center'}>
                  <Grid item xs={11}>
                    <AxlBorderBox>
                      <ShipmentNote list={shipmentNotes} user={user} />
                    </AxlBorderBox>
                  </Grid>
                  <Grid item xs={1}>
                    <AxlModal
                      isClose={isClosePopup}
                      onRendered={() => setIsClosePopup(false)}
                      trigger={
                        <IconButton>
                          <img src={`/assets/images/svg/edit.svg`} width={15} height={15} />
                        </IconButton>
                      }
                    >
                      <ShipmentNoteEditForm
                        setShipmentNotes={setShipmentNotes}
                        user={user}
                        shipmentId={shipment.id}
                        list={shipmentNotes}
                        closePopup={() => setIsClosePopup(true)}
                      />
                    </AxlModal>
                  </Grid>
                </Grid>
              </Box>
            </AxlSimpleBox>
            <S.SubTitle>{`Assignment Info:`}</S.SubTitle>
            <AxlSimpleBox padding={2} marginBottom={1}>
              <Box marginBottom={2}>
                <Grid container direction={'row'} alignItems={'flex-start'}>
                  <Grid item xs={4}>
                    <S.Label>{`ID:`}</S.Label>
                    <S.Text>{get(assignment, 'id', 'N/A')}</S.Text>
                  </Grid>
                  <Grid item xs={4}>
                    <S.Label>{`Label:`}</S.Label>
                    <S.Text>{get(assignment, 'label', 'N/A')}</S.Text>
                  </Grid>
                  <Grid item xs={4}>
                    <S.Label>{`Status:`}</S.Label>
                    <S.Text>{get(assignment, 'status', 'N/A')}</S.Text>
                  </Grid>
                </Grid>
              </Box>
              <S.Label>{`Assignment note:`}</S.Label>
              <Grid container direction={'row'} alignItems={'center'}>
                <Grid item xs={11}>
                  <AxlBorderBox>
                    <ShipmentNote list={assignmentNotes} user={user} />
                  </AxlBorderBox>
                </Grid>
                <Grid item xs={1}>
                  <AxlModal
                    isClose={isClosePopup}
                    onRendered={() => setIsClosePopup(false)}
                    trigger={
                      <IconButton>
                        <img src={`/assets/images/svg/edit.svg`} width={15} height={15} />
                      </IconButton>
                    }
                  >
                    <AssignmentNoteEditForm
                      setAssignmentNotes={setAssignmentNotes}
                      user={user}
                      assignmentId={shipment.assignment_id}
                      list={assignmentNotes}
                      closePopup={() => setIsClosePopup(true)}
                    />
                  </AxlModal>
                </Grid>
              </Grid>
            </AxlSimpleBox>
          </S.Scroll>
        </S.ContainerScroll>
      </S.Container>
    </AxlBox>
  );
}
