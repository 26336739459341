import React, { useRef } from 'react';
import Box from '@material-ui/core/Box';
import * as S from './styles';
import AxlInput from '../AxlInput';
import AxlButton from '../Button';

import { saveAssignmentNotes, updateAssignmentNotes, getAssignmentNotes } from '../../repositories/Api';

export default function AssignmentNoteEditForm({ assignmentId, list, closePopup, user, setAssignmentNotes }) {
  const lastNote = list.find((note) => note.subject.uid === `US_${user?.user?.id}`);
  const contentRef = useRef(lastNote);
  return (
    <S.Container>
      <S.Title>{`ASSIGNMENT NOTE`}</S.Title>
      <form noValidate autoComplete="off">
        <AxlInput
          color={`secondary`}
          inputRef={contentRef}
          multiline
          rows={7}
          placeholder={lastNote?.content || `Add assignment note here..`}
        />
        <Box textAlign={'right'} paddingTop={3}>
          <AxlButton
            bgcolor={'primary.main'}
            color={'primary.gray'}
            variant="outlined"
            onClick={closePopup}
          >{`CANCEL`}</AxlButton>
          <AxlButton
            bgcolor={'primary.green'}
            color={'primary.main'}
            variant="contained"
            disableElevation
            onClick={() => {
              if (lastNote) {
                updateAssignmentNotes(assignmentId, contentRef.current.value).then((res) => {
                  if (res.ok || res.status === 200) {
                    getAssignmentNotes(assignmentId).then((resp) => {
                      setAssignmentNotes(resp.data);
                    });
                  }
                });
              } else {
                saveAssignmentNotes(assignmentId, contentRef.current.value).then((res) => {
                  if (res.ok || res.status === 200) {
                    getAssignmentNotes(assignmentId).then((resp) => {
                      setAssignmentNotes(resp.data);
                    });
                  }
                });
              }
              closePopup();
            }}
          >{`SAVE`}</AxlButton>
        </Box>
      </form>
    </S.Container>
  );
}
