import React from 'react';
import * as M from '@material-ui/core';
import _ from 'lodash';

export const Container = M.styled(M.Box)(({theme}) => ({
  position: 'relative',
}));

export const Line = M.styled(M.Box)(({theme}) => ({
  width: 5,
  height: 'calc(100% - 7px)',
  position: 'absolute',
  top: 0,
  left: 95,
  margin: 'auto',
  backgroundColor: theme.palette.primary.gray400,
  boxSizing: 'border-box',
}));

export const StickContainer = M.styled(M.Box)(({theme, active}) => ({
  '&::after': {
    content: '""',
    width: 5,
    height: 'calc(100% - 25px)',
    position: 'absolute',
    top: 45,
    left: 95,
    margin: 'auto',
    backgroundColor: theme.palette.primary.gray400,
    boxSizing: 'border-box',
  },
  '&.last': {
    '&::after': {
      display: 'none'
    }
  }
}));

export const Stick = M.styled(M.Box)(({theme, bgcolor}) => ({
  width: 25,
  height: 25,
  borderRadius: '50%',
  margin: '0 15px',
  backgroundColor: bgcolor || theme.palette.primary.periwinkle,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&::before': {
    content: '""',
    display: 'inline-block',
    width: 10,
    height: 4,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    transform: 'rotate(-45deg)'
  },
}));

export const Item = M.styled(M.Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  padding: '20px 0 15px',
  alignItems: 'flex-start',
}));

export const DateContainer = M.styled(M.Box)(({theme}) => ({
  width: 70,
  minWidth: 70,
  textAlign: 'right'
}));

export const Date = M.styled(M.Box)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 13,
  fontWeight: 600,
  textAlign: 'right',
  color: theme.palette.primary.blackSecondary,
  textTransform: 'uppercase',
  marginBottom: 5
}));

export const Time = M.styled(M.Box)(({theme}) => ({
  fontFamily: 'AvenirNext-DemiBold',
  fontSize: 11,
  fontWeight: 600,
  textAlign: 'right',
  color: theme.palette.primary.graySecond,
  textTransform: 'uppercase',
}));

export const Content = M.styled(M.Box)(({theme}) => ({
  flex: 1
}));

export const Text = M.styled(M.Typography)(({theme, active}) => ({
  fontFamily: 'AvenirNext-Medium',
  fontSize: 13,
  fontWeight: 500,
  lineHeight: 1.6,
  color: active ? theme.palette.primary.blackThird : theme.palette.primary.graySecond
}));